import { useContext } from 'react'

import { ModalDispatchContext } from '@components/contexts/ModalContext'

export type PropTypes<T extends (...args: any) => unknown> = Parameters<T>[0]

const useModalContext = () => {
  const { open, close } = useContext(ModalDispatchContext)

  const openModal = <T extends (props: any) => JSX.Element>(
    Component: (props: PropTypes<T>) => JSX.Element,
    props: PropTypes<T>
  ) => open(Component, props)

  const closeModal = (Component: (props: any) => JSX.Element) => close(Component)

  return { openModal, closeModal }
}

export default useModalContext
