import * as COMMON from 'gadjet-v2-types/dist/api/common'

import axios from '@utils/axios'

const CommonAPI = {
  // 이미지 업로드
  addImage: async ({ image }: COMMON.ADD_IMAGE_BODY): Promise<{ data: COMMON.ADD_IMAGE_RESPONSE }> =>
    axios.post('/images', { image }),

  // 파일 업로드
  addFile: async ({ file }: COMMON.ADD_FILE_BODY): Promise<{ data: COMMON.ADD_FILE_RESPONSE }> =>
    axios.post('/files', { file }),

  // GICS 코드 조회
  getGics: async (): Promise<{ data: COMMON.GET_GICS_RESPONSE }> => axios.get('/gics'),

  // 은행 코드 조회
  getBanks: async (): Promise<{ data: COMMON.GET_BANKS_RESPONSE }> => axios.get('/banks'),
}

export default CommonAPI
