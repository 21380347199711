import useMenu from '@hooks/useMenu'
import useModalContext from '@hooks/useModalContext'
import dayjs from 'dayjs'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { ContractSearch } from 'gadjet-v2-types/dist/type/search'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  searchContracts,
  setContractDefault,
  setContractPagination,
  setContractSearch,
  setContractTableConfig,
} from '@reducers/branch/contract.reducer'
import { RootState } from '@reducers/index'

import ContractListTable from '@components/organisms/Branch/Contract/ListTable'
import { ContractRowAction } from '@components/organisms/Branch/Contract/ListTable/Columns/Action'
import ContractAutomationConfigModal from '@components/organisms/Branch/Contract/Modals/AutomationConfigModal'
import ContractDeleteModal from '@components/organisms/Branch/Contract/Modals/ContractDeleteModal'
import ContractDepositModal from '@components/organisms/Branch/Contract/Modals/ContractDepositModal'
import ContractExportModal from '@components/organisms/Branch/Contract/Modals/ContractExportModal'
import ContractInviteModal from '@components/organisms/Branch/Contract/Modals/ContractInviteModal'
import ContractSuspendModal from '@components/organisms/Branch/Contract/Modals/ContractSuspendModal'
import DepositExportModal from '@components/organisms/Branch/Contract/Modals/DepositExportModal'
import ContractFormModal from '@components/organisms/Branch/Contract/Modals/FormModal'
import UpdateSignatureStatusModal from '@components/organisms/Branch/Contract/Modals/UpdateSignatureStatusModal'
import ContractUploadModal from '@components/organisms/Branch/Contract/Modals/UploadModal'
import ContractSearchBox from '@components/organisms/Branch/Contract/SearchBox'
import ContractTitle, { BranchContractActionType } from '@components/organisms/Branch/Contract/Title'

export default function ContractPage(): JSX.Element {
  const location = useLocation<{ query?: string }>()

  const { hqId, branchId } = useMenu()
  const dispatch = useDispatch()
  const { loading, search, pagination, searchResult, tableConfig } = useSelector((state: RootState) => state.contract)

  const { openModal } = useModalContext()

  const isBranchSwitched = searchResult.branchId !== branchId

  const setSearch = (_search: Partial<ContractSearch>) => dispatch(setContractSearch(_search))
  const setPagination = (_pagination: Partial<Pagination>) => dispatch(setContractPagination(_pagination))
  const setTableConfig = (_tableConfig: { activeColumns: Array<keyof Contracts> }) =>
    dispatch(setContractTableConfig(_tableConfig))

  const onChangeTableColumns = (_activeColumns: Array<keyof Contracts>) =>
    setTableConfig({ activeColumns: _activeColumns })

  const onChangeSearch = (_search: Partial<ContractSearch>) => setSearch(_search)
  const onChangePagination = (_pagination: Pagination) => {
    setPagination(_pagination)
    dispatch(searchContracts(hqId, branchId))
  }

  const onSearch = () => {
    setPagination({ current: 1 })
    dispatch(searchContracts(hqId, branchId))
  }
  const onResetAndSearch = () => dispatch(setContractDefault(hqId, branchId))

  const onAction = (action: BranchContractActionType) => {
    if (action === 'deposit-export') openModal<typeof DepositExportModal>(DepositExportModal, { hqId, branchId })
    if (action === 'contract-export') openModal<typeof ContractExportModal>(ContractExportModal, { branchId, hqId })
    if (action === 'upload')
      openModal<typeof ContractUploadModal>(ContractUploadModal, { hqId, branchId, onDone: onResetAndSearch })
    if (action === 'automation-config')
      openModal<typeof ContractAutomationConfigModal>(ContractAutomationConfigModal, { hqId, branchId })
    if (action === 'add')
      openModal<typeof ContractFormModal>(ContractFormModal, { hqId, branchId, onDone: onResetAndSearch })
  }

  const onRowAction = (action: ContractRowAction, c: Contracts) => {
    if (action === 'form-memo')
      openModal<typeof ContractFormModal>(ContractFormModal, {
        hqId,
        branchId,
        contractId: c.contractId,
        activeTab: 'etc',
        onDone: onResetAndSearch,
      })
    if (action === 'form-deposit')
      openModal<typeof ContractFormModal>(ContractFormModal, {
        hqId,
        branchId,
        contractId: c.contractId,
        activeTab: 'deposit',
        onDone: onResetAndSearch,
      })
    if (action === 'update')
      openModal<typeof ContractFormModal>(ContractFormModal, {
        hqId,
        branchId,
        contractId: c.contractId,
        onDone: onResetAndSearch,
      })
    if (action === 'extend')
      openModal<typeof ContractFormModal>(ContractFormModal, {
        hqId,
        branchId,
        defaultContract: {
          ...c,
          extendStatus: 'approval',
          contractId: undefined,
          startDate: dayjs(c.suspendDate).add(1, 'day').format('YYYY-MM-DD'),
          endDate: dayjs(c.suspendDate).add(1, 'day').format('YYYY-MM-DD'),
          extendContractId: c.contractId,
          extendSinceDate: c.extendSinceDate,
        },
        onDone: onResetAndSearch,
      })

    if (action === 'suspend')
      openModal<typeof ContractSuspendModal>(ContractSuspendModal, {
        hqId,
        branchId,
        contractId: c.contractId,
        onDone: onResetAndSearch,
      })
    if (action === 'delete')
      openModal<typeof ContractDeleteModal>(ContractDeleteModal, {
        hqId,
        branchId,
        contractId: c.contractId,
        onDone: onResetAndSearch,
      })
    if (action === 'invite')
      openModal<typeof ContractInviteModal>(ContractInviteModal, { hqId, branchId, contractId: c.contractId })
    if (action === 'signature-status')
      openModal<typeof UpdateSignatureStatusModal>(UpdateSignatureStatusModal, {
        hqId,
        branchId,
        contractId: c.contractId,
        onDone: onResetAndSearch,
      })
    if (action === 'deposit')
      openModal<typeof ContractDepositModal>(ContractDepositModal, {
        hqId,
        branchId,
        contractId: c.contractId,
        onDone: onResetAndSearch,
      })
  }

  useEffect(() => {
    if (location?.state?.query) {
      onChangeSearch({ query: location.state.query })
      onSearch()
    } else {
      dispatch(searchContracts(hqId, branchId))
    }
  }, [location])

  useEffect(() => {
    if (searchResult.total === 0 || isBranchSwitched) {
      onResetAndSearch()
    }
  }, [branchId])

  return (
    <div>
      <ContractSearchBox
        hqId={hqId}
        branchId={branchId}
        isBranchSwitched={isBranchSwitched}
        search={search}
        onChangeSearch={onChangeSearch}
        onSearch={onSearch}
      />

      <ContractTitle onAction={onAction} />

      <ContractListTable
        loading={loading}
        activeColumnKeys={tableConfig.activeColumns}
        total={searchResult.total}
        contracts={searchResult.contracts}
        pagination={pagination}
        onChangePagination={onChangePagination}
        onChangeColumns={onChangeTableColumns}
        onRowAction={onRowAction}
      />
    </div>
  )
}
