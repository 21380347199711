import { ReactComponent as ChevronRightIcon } from '@assets/Icons/chevron-right.svg'
import { ReactComponent as InfoIcon } from '@assets/Icons/info-dark.svg'
import { ReactComponent as PlusIcon } from '@assets/Icons/plus.svg'
import { BREAKPOINTS, FREE_TRIAL_URL } from '@const'
import { Form, InputNumber, Tooltip } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import CalulatorBox from '../../atoms/CalculatorBox'
import EstimateResultBox from '../../atoms/EstimateResultBox'
import PromotionSwitch from '../../atoms/PromotionSwitch'
import CalculatorPriceBox from '../../molecules/CalculatorPriceBox'

type Props = {
  gadjetPlans: GadjetPlan[]
  promotion: Promotion
  selectedPlan: GadjetPlan
  onClickSelectPlan: (plan: GadjetPlan) => void
  selectedMonthlyAmount: number
}

export default function Estimate({
  gadjetPlans,
  selectedPlan,
  promotion,
  onClickSelectPlan,
  selectedMonthlyAmount,
}: Props): JSX.Element {
  const [form] = useForm()
  const [promotionSwitch, setPromotionSwitch] = useState<string>('on')
  const [contractSize, setContractSize] = useState<number>(100)
  const [contractManageFee, setContractManageFee] = useState<number>(0)
  const montlyTotal =
    promotionSwitch === 'on'
      ? contractManageFee + selectedMonthlyAmount
      : contractManageFee + selectedMonthlyAmount / promotion.rate
  const pricePerContract = contractSize && Math.round(montlyTotal / contractSize)

  const [initialContractSize, setInitialContractSize] = useState<number | undefined>(100)

  const onClickCalculate = () => {
    const contracts = form.getFieldValue('contracts')
    setContractSize(contracts || 100)
    getContractManageFee(contracts)
  }

  const getContractManageFee = (contracts: number) => {
    if (contracts >= 101 && contracts <= 299) {
      return setContractManageFee((contracts - 100) * 1400)
    }
    if (contracts >= 300 && contracts <= 499) {
      return setContractManageFee(199 * 1400 + (contracts - 300) * 1200)
    }
    return setContractManageFee(0)
  }

  const onClickPromotionSwitch = (button: string) => {
    setPromotionSwitch(button)
  }

  const onClickContractSizeForm = () => {
    setInitialContractSize(undefined)
    form.setFieldsValue({ contracts: undefined })
  }

  return (
    <Wrapper>
      <Title>
        우리 지점의 월 예상 비용,
        <MediaBr /> 얼마나 될까?
      </Title>
      {promotion.isVisible && <PromotionSwitch selectedButton={promotionSwitch} onClick={onClickPromotionSwitch} />}
      <Calculator>
        <CalulatorBox topTitle="가젯 구독 플랜" subTitle="원하는 구독 플랜을 선택하세요">
          <PlanButtonGroup>
            {gadjetPlans.map((plan) => (
              <PlanButton
                key={plan.key}
                isSelected={plan.key === selectedPlan.key}
                onClick={() => onClickSelectPlan(plan)}
              >
                {plan.label}
              </PlanButton>
            ))}
          </PlanButtonGroup>

          <CalculatorPriceBox
            isPromotionSwtichOn={promotionSwitch === 'on'}
            amount={promotionSwitch === 'on' ? selectedMonthlyAmount : selectedMonthlyAmount / promotion.rate}
            discountRate={selectedPlan.discountRate}
            promotion={promotion}
            isYearPrepaySelected={selectedPlan.key === 'yearPrepay'}
            isPlanBox
          />
        </CalulatorBox>
        <CalulatorBox
          topTitle={
            <Tooltip
              title={
                <TooltipContent>
                  <span>
                    예상 계약 관리비는 기본 구독료와 별개로
                    <br />
                    계약 101개부터 추가 계약수 당 부과되는 금액입니다.
                  </span>
                  <Ul>
                    <li>1 - 100개 : 무료</li>
                    <li>101 - 299개 : 1,400원 (계약 1개당)</li>
                    <li>300 - 499개 : 1,200원 (계약 1개당)</li>
                    <li>500개 이상 : 별도 협의</li>
                  </Ul>
                </TooltipContent>
              }
              placement="top"
              color="#2e2e2e"
              overlayInnerStyle={{
                width: '315px',
                borderRadius: '16px',
                padding: '20px',
              }}
            >
              <TooltipTitle>
                <span>예상 계약 관리비</span>
                <InfoIcon />
              </TooltipTitle>
            </Tooltip>
          }
          subTitle="활성 계약 수를 입력하세요."
        >
          <Form form={form}>
            <Form.Item name="contracts" noStyle>
              <ContractCountWrapper>
                <InputWrapper>
                  <InputContract
                    controls={false}
                    pattern="\d*"
                    value={initialContractSize}
                    min={1}
                    onClick={onClickContractSizeForm}
                  />
                  <CountUnit>개</CountUnit>
                </InputWrapper>
                <CalculateButton onClick={onClickCalculate}>계산하기</CalculateButton>
              </ContractCountWrapper>
            </Form.Item>
          </Form>
          <CalculatorPriceBox amount={contractManageFee} isUnknownPrice={contractSize >= 500} />
        </CalulatorBox>
        <Plus>
          <PlusIcon width="24" height="24" stroke="#2E2E2E" />
        </Plus>
      </Calculator>
      <EstimateResultBox isUnknownPrice={contractSize >= 500} total={montlyTotal} pricePerContract={pricePerContract} />
      <Link to={{ pathname: FREE_TRIAL_URL }} target="_blank">
        <StartButton>
          이 플랜으로 가젯 시작
          <ChevronRightIcon width="24" height="24" stroke="#ffffff" />
        </StartButton>
      </Link>
    </Wrapper>
  )
}

type StyleProps = {
  isSelected?: boolean
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 201px;
  @media ${BREAKPOINTS.lg} {
    padding-bottom: 100px;
  }
  @media ${BREAKPOINTS.sm} {
    padding: 0px 16px 100px 16px;
  }
`

const Title = styled.h3`
  width: max-content;
  font-weight: 700;
  font-size: 35px;
  line-height: 140%;
  text-align: center;
  color: #2e2e2e;
  margin-bottom: 20px;
  @media ${BREAKPOINTS.lg} {
    font-size: 28px;
  }
`

const TooltipTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const MediaBr = styled.br`
  display: none;
  @media ${BREAKPOINTS.lg} {
    display: block;
  }
`

const Plus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;

  background: #ffffff;
  border-radius: 32px;
  position: absolute;
  @media ${BREAKPOINTS.sm} {
    width: 60px;
    height: 60px;
  }
`

const Calculator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  position: relative;
  padding-bottom: 20px;
  margin-top: 28px;
  @media ${BREAKPOINTS.lg} {
    flex-direction: column;
    align-items: center;
  }
  @media ${BREAKPOINTS.sm} {
    gap: 14px;
  }
`

const PlanButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`
const PlanButton = styled.button`
  box-sizing: border-box;
  width: 90px;
  height: 48px;
  padding: 0px;
  background: #ffffff;
  border-radius: 12px;
  white-space: nowrap;
  word-break: keep-all;

  border: ${({ isSelected }: StyleProps) => (isSelected ? '2px solid #22AF5D' : '2px solid #ffffff')};

  font-weight: ${({ isSelected }: StyleProps) => (isSelected ? 700 : 400)};

  font-size: 16px;
  line-height: 24px;
  color: ${({ isSelected }: StyleProps) => (isSelected ? '#2E2E2E' : '#595a5b')};

  box-shadow: ${({ isSelected }: StyleProps) => (isSelected ? '0px 8px 8px rgba(24, 39, 75, 0.08)' : 'none')};

  cursor: pointer;
  :hover {
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 8px 8px rgba(24, 39, 75, 0.08);
  }
`

const CalculateButton = styled.button`
  width: 90px;
  height: 48px;

  background: #2e2e2e;
  box-shadow: 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  border: none;
  border-radius: 12px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  :hover {
    transition: all 0.3s ease-in-out;
    background: rgba(46, 46, 46, 0.8);
  }
`

const ContractCountWrapper = styled.div`
  display: flex;
  gap: 16px;
`
const InputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
`
const InputContract = styled(InputNumber)`
  box-sizing: border-box;
  width: 161px;
  padding: 0px 20px;
  border: 2px solid #22af5d;
  border-radius: 12px;
  .ant-input-number-input {
    height: 44px;
    padding: 0px;
    text-align: right;
  }
`
const CountUnit = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2e2e2e;
`
const StartButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 14px 14px 14px 20px;
  margin-top: 32px;
  width: max-content;
  height: 52px;
  background: #2e2e2e;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  border: none;
  border-radius: 12px;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  :hover {
    transition: all 0.3s ease-in-out;
    background: rgba(46, 46, 46, 0.8);
  }
  @media ${BREAKPOINTS.lg} {
    margin-top: 29px;
  }
`

const TooltipContent = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
`

const Ul = styled.ul`
  margin: 10px 0px 0px 0px;
  padding-left: 20px;
`
