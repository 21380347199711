import { takeLatest } from 'redux-saga/effects'
import { all, call, put } from 'typed-redux-saga'

import HqAPI from '@apis/hq'
import HqBranchAPI from '@apis/hq/branch'

import { setHqBranches } from '@reducers/hq/branch.reducer'
import { GET_HQ, HqAction, setHq, setHqLoading } from '@reducers/hq/hq.reducer'

function* getHqSaga(action: HqAction): Generator {
  if (action.type !== GET_HQ) return
  const { hqId } = action.payload

  yield* put(setHqLoading(true))

  const { data: hq } = yield* call(HqAPI.getHqDetail, { hqId })
  const { data: branches } = yield* call(HqBranchAPI.getHqBranches, { hqId })

  yield* put(setHq({ ...hq, loading: false }))
  yield* put(setHqBranches(branches))
}

export default function* hqSaga(): Generator {
  yield* all([takeLatest(GET_HQ, getHqSaga)])
}
