import { COLORS } from '@const'
import { Styles } from '@types'
import { Col } from 'antd'
import { CSSProperties, useMemo } from 'react'

export type SummaryItemType = 'default' | 'primary' | 'error' | 'warn' | 'gray' | 'success'

type Props = {
  label: string
  value: string | number | JSX.Element
  span?: number
  flex?: number | string
  labelStyle?: CSSProperties
  valueStyle?: CSSProperties
  type?: SummaryItemType
}
export default function SummaryItem({
  label,
  value,
  span,
  flex,
  labelStyle = {},
  valueStyle = {},
  type = 'default',
}: Props): JSX.Element {
  const typeStyle = useMemo(() => {
    if (type === 'success') return { col: {}, label: {}, value: { color: COLORS.success } }
    if (type === 'warn') return { col: {}, label: {}, value: { color: COLORS.warn } }
    if (type === 'error') return { col: {}, label: {}, value: { color: COLORS.error } }
    if (type === 'gray') return { col: {}, label: {}, value: { color: 'gray' } }
    if (type === 'primary') return { col: {}, label: {}, value: { color: COLORS.primary } }
    return { col: {}, label: {}, value: {} }
  }, [type])

  return (
    <Col span={span} flex={flex} style={{ ...styles.col, ...typeStyle.col }}>
      <div style={{ ...styles.label, ...typeStyle.label, ...labelStyle }}>{label}</div>
      <div style={{ ...styles.value, ...typeStyle.value, ...valueStyle }}>{value}</div>
    </Col>
  )
}

const styles: Styles = {
  col: { padding: '10px 0' },
  label: { opacity: 0.6, textAlign: 'center', marginBottom: '4px' },
  value: { textAlign: 'center', fontWeight: 'bold', fontSize: '20px', wordBreak: 'break-all' },
}
