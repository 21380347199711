import { FormInstance, Form, Input, Row, Col } from 'antd'
import { Branches } from 'gadjet-v2-types/dist/model'

import mask from '@utils/mask'

type Props = {
  form: FormInstance<Branches>
}

export default function BranchBusinessTab({ form }: Props): JSX.Element {
  return (
    <>
      <Form.Item label="사업자 이름" name={['business', 'name']}>
        <Input />
      </Form.Item>
      <Form.Item label="대표자" name={['business', 'director']}>
        <Input />
      </Form.Item>
      <Form.Item label="주소" name={['business', 'address']}>
        <Input />
      </Form.Item>

      <Row gutter={10}>
        <Col span={18}>
          <Form.Item label="사업자번호" name={['business', 'businessNumber']} {...mask.businessLicense}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="종 사업장" name={['business', 'identityNumber']}>
            <Input placeholder="0000" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="법인번호" name={['business', 'corporationNumber']} {...mask.personalLicense}>
        <Input />
      </Form.Item>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label="업태" name={['business', 'businessType']}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="업종" name={['business', 'businessClass']}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.List name={['business', 'managers']}>
        {(fields) =>
          fields.map((field) => (
            <Row key={field.key} gutter={10}>
              <Col span={12}>
                <Form.Item isListField name={[field.name, 'name']} label="담당자 이름">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item isListField name={[field.name, 'email']} label="담당자 이메일">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          ))
        }
      </Form.List>
    </>
  )
}
