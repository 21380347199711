import { MoreOutlined } from '@ant-design/icons'
import useModal from '@hooks/useModal'
import { Styles } from '@types'
import { Button, Dropdown, Menu, Table, TableColumnType, TablePaginationConfig, Tag } from 'antd'
import { Notices } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'

import { BranchNoticeAction } from '@pages/Branch/NoticePage'

import TableTotalRows from '@components/atoms/TableTotalRows'
import TableWrapper from '@components/atoms/TableWrapper'
import TypeTag from '@components/molecules/TypeTag'
import NoticeDetailModal from '@components/organisms/Notice/Modals/DetailModal'

type Props = {
  hqId: number
  branchId: number
  loading: boolean
  total: number
  pagination: Pagination
  notices: Omit<Notices, 'content'>[]
  onChangePagination: (pagination: Pagination) => void
  onAction: (action: BranchNoticeAction, noticeId: number) => void
}
export default function BranchNoticeListTable({
  hqId,
  branchId,
  loading,
  total,
  pagination,
  notices,
  onChangePagination,
  onAction,
}: Props): JSX.Element {
  const [detailModal, onVisibleDetailModal, onCloseDetailModal] = useModal<{ noticeId: number }>({
    visible: false,
    noticeId: 0,
  })

  const onChange = (_pagination: TablePaginationConfig) => onChangePagination({ ...pagination, ..._pagination })

  const columns: TableColumnType<Omit<Notices, 'content'>>[] = [
    {
      key: 'hqId',
      dataIndex: 'hqId',
      title: '지점',
      render: (_, { hq, branch }) => {
        if (branch) return branch.name
        if (hq) return hq.name
        return '-'
      },
    },
    {
      key: 'title',
      dataIndex: 'title',
      title: '제목',
      render: (title, { noticeId }) => (
        <Button type="text" onClick={() => onVisibleDetailModal({ visible: true, noticeId })}>
          {title}
        </Button>
      ),
    },
    {
      key: 'branchId',
      dataIndex: 'branchId',
      title: '타겟',
      width: '200px',
      render: (_, { hq, branch }) => {
        return (
          <>
            {hq && <Tag>{hq.name}</Tag>}
            {branch && <Tag>{branch.name}</Tag>}
          </>
        )
      },
    },
    {
      key: 'writeDate',
      dataIndex: 'writeDate',
      title: '작성일',
      width: '200px',
      render: (writeDate) => `${writeDate}`,
    },
    {
      key: 'openFlag',
      dataIndex: 'openFlag',
      title: '공개여부',
      width: '100px',
      align: 'center',
      render: (openFlag) => <TypeTag type={openFlag ? 'success' : 'fail'} label={openFlag ? '공개' : '비공개'} />,
    },
    {
      key: 'noticeId',
      dataIndex: 'noticeId',
      title: '',
      width: '80px',
      render: (noticeId, { branch }) => (
        <Dropdown
          disabled={!branch}
          overlay={
            <Menu>
              <Menu.Item key="update" onClick={() => onAction('update', noticeId)}>
                수정
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => onAction('delete', noticeId)}>
                삭제
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]

  return (
    <>
      <TableWrapper>
        <Table<Omit<Notices, 'content'>>
          loading={loading}
          style={styles.table}
          scroll={{ x: true }}
          rowKey={({ noticeId }) => `${noticeId}`}
          dataSource={notices}
          columns={columns}
          onChange={onChange}
          pagination={{
            total,
            position: ['bottomCenter'],
            current: pagination.current,
            pageSize: pagination.pageSize,
            showSizeChanger: true,
            showTotal: (total) => <TableTotalRows total={total} />,
          }}
        />
      </TableWrapper>
      <NoticeDetailModal
        hqId={hqId}
        branchId={branchId}
        visible={detailModal.visible}
        noticeId={detailModal.noticeId}
        onClose={onCloseDetailModal}
      />
    </>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
}
