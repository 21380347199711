import { Modal, Form, Input, notification, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Additions } from 'gadjet-v2-types/dist/model'
import { useState, useEffect } from 'react'

import formRule from '@utils/formRule'
import mask from '@utils/mask'

import AdditionAPI from '@apis/branch/addition'

import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  additionId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function AdditionFormModal({
  hqId,
  branchId,
  additionId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const [form] = useForm<Additions>()
  const [loading, setLoading] = useState(false)
  const [priceType, setPriceType] = useState('netPrice')

  const onOk = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      if (additionId) await AdditionAPI.updateAddition({ hqId, branchId, additionId }, { addition: values })
      if (!additionId) await AdditionAPI.addAddition({ hqId, branchId }, { addition: values })

      notification.success({ message: '저장되었습니다.' })

      setLoading(false)
      onDone()
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const getAddition = async () => {
    if (!additionId) return
    setLoading(true)
    const { data } = await AdditionAPI.getAdditionDetail({ hqId, branchId, additionId })
    form.setFieldsValue(data)
    setLoading(false)
  }

  const reset = () => {
    form.resetFields()
  }

  const handlePriceTypeChange = (value: string) => {
    setPriceType(value) // Update priceType when the Select value changes
  }

  useEffect(() => {
    if (visible) {
      reset()
      getAddition()
    }
  }, [visible])

  const initialValues: Partial<Additions> = {
    name: '',
    price: 0,
  }

  return (
    <Modal
      title="부가서비스"
      okText="저장"
      onOk={onOk}
      confirmLoading={loading}
      visible={visible}
      onCancel={onClose}
      maskClosable={false}
    >
      <Loading loading={loading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onValuesChange={(changedValues) => {
            if (changedValues.priceType) {
              handlePriceTypeChange(changedValues.priceType) // Track changes to priceType
            }
          }}
        >
          <Form.Item name="name" label="이름" required rules={[formRule.required]}>
            <Input />
          </Form.Item>
          <Form.Item name="priceType" label="가격 유형" required rules={[formRule.required]}>
            <Select defaultValue="netPrice">
              <Select.Option value="netPrice">공급가</Select.Option>
              <Select.Option value="consumerPrice">소비자가</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="price"
            label="금액"
            extra={priceType === 'netPrice' ? '부가세 미포함 금액' : '부가세 포함 금액'}
            required
            rules={[formRule.required, formRule.price, formRule.positiveNumber]}
            {...mask.krw}
          >
            <Input addonAfter="원" />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}
