import styled from 'styled-components'

type Props = {
  total: number
}

export default function TableTotalRows({ total }: Props): JSX.Element {
  return <Total>총 {total}건</Total>
}

const Total = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
`
