import { ReactComponent as MessageLinesIcon } from '@assets/Icons/message-lines.svg'
import { Styles } from '@types'

type Props = {
  title: string
  memo: string
}

export default function WeekAndDayEvent({ title, memo }: Props) {
  return (
    <div style={styles.weekEvent}>
      {memo && (
        <span style={{ marginRight: '5px' }}>
          <MessageLinesIcon fill="#7A7B7C" />
        </span>
      )}
      <span>{title}</span>
    </div>
  )
}

const styles: Styles = {
  weekEvent: {
    display: 'flex',
    flexDirection: 'row',
  },
}
