import { BREAKPOINTS } from '@const'
import styled from 'styled-components'

import { krw } from '@utils/format'

import * as S from '../../styles'

type Props = {
  isUnknownPrice?: boolean
  total: number | string
  pricePerContract: number | undefined
}

export default function EstimateResultBox({ total, pricePerContract, isUnknownPrice }: Props): JSX.Element {
  return (
    <Wrapper>
      <Container>
        {!isUnknownPrice ? (
          <TotalWrapper>
            <TotalPriceLabel>총</TotalPriceLabel>
            <TotalPrice>{krw(total, true)}</TotalPrice>
            <Won>원</Won>
          </TotalWrapper>
        ) : (
          <UnknownPrice>별도 문의</UnknownPrice>
        )}
        {!isUnknownPrice && (
          <PricePerContractWrapper>
            <PricePerContractLabel>계약 당 서비스 이용료</PricePerContractLabel>
            <PricePerContract>{krw(pricePerContract, true)}</PricePerContract>
            <Won>원</Won>
          </PricePerContractWrapper>
        )}
      </Container>
      <NoticeList>
        <S.NoticeItem>위 예상 비용은 부가세(10%) 미포함 금액입니다.</S.NoticeItem>
      </NoticeList>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 860px;
  @media ${BREAKPOINTS.lg} {
    width: 600px;
  }
  @media ${BREAKPOINTS.md} {
    width: 382px;
  }
  @media ${BREAKPOINTS.sm} {
    width: 100%;
    min-width: 340px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 26px 0px 20px;

  height: 108px;
  background: #ffffff;

  border: 1px solid #2e2e2e;
  border-radius: 24px;
`
const TotalWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  color: #22af5d;
`

const TotalPriceLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2e2e2e;
  padding-right: 10px;
`
const TotalPrice = styled.span`
  font-family: 'Akshar';
  font-weight: 500;
  font-size: 32px;
  line-height: 27px;
  color: #22af5d;
`
const Won = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  padding-left: 4px;
`
const PricePerContractLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #595a5b;
  padding-right: 10px;
`
const PricePerContract = styled.span`
  font-family: 'Akshar';
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  color: #2e2e2e;
`
const PricePerContractWrapper = styled.div`
  display: flex;
`
const UnknownPrice = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 110%;
  color: #22af5d;
`
const NoticeList = styled.ul`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0 0 0 10px;
`
