import { ReactNode } from 'react'

import * as S from '@pages/Landing/FeaturePages/styles'

import DashboardView from '../../organisms/DashboardView'
import EasyPromotion from '../../organisms/EasyPromotion'
import ElectronicContract from '../../organisms/ElectronicContract'
import FeaturePageTemplate from '../../organisms/FeaturePageTemplate'
import ManageContract from '../../organisms/ManageContract'

export default function ContractFeaturePage(): JSX.Element {
  const features: { key: string; title: string; component: ReactNode }[] = [
    {
      key: 'electronicContract',
      title: '비대면으로 간편한 계약 체결',
      component: <ElectronicContract />,
    },
    {
      key: 'manageContract',
      title: '효율적인 계약 관리',
      component: <ManageContract />,
    },
    {
      key: 'dashboardView',
      title: '직관적인 대시보드',
      component: <DashboardView />,
    },
    {
      key: 'easyPromotion',
      title: '더 쉬운 프로모션',
      component: <EasyPromotion />,
    },
  ]
  return (
    <FeaturePageTemplate
      description="이렇게 쉽고 효율적인 계약 관리"
      title="계약 체결・계약 관리"
      icon={{ src: '/images/landing/features/chart.png', alt: 'chart-icon' }}
    >
      {features.map(({ key, title, component }) => (
        <S.Section key={key}>
          <S.SectionTitle>{title}</S.SectionTitle>
          {component}
        </S.Section>
      ))}
    </FeaturePageTemplate>
  )
}
