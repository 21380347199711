import { Route, Switch, useRouteMatch } from 'react-router-dom'

import BranchServicePage from '@pages/Branch/ServicePage'
import BranchServiceSalePage from '@pages/Branch/ServiceSalePage'
import NotFoundPage from '@pages/Error/NotFoundPage'

export default function BranchServiceRouter(): JSX.Element {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${url}/list`}>
        <BranchServicePage />
      </Route>
      <Route path={`${url}/sale`}>
        <BranchServiceSalePage />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  )
}
