import { Styles } from '@types'
import { Col, Form, Input, Modal, notification, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { BillingCard } from 'gadjet-v2-types/dist/type'
import { useEffect, useState } from 'react'

import BranchCardAPI from '@apis/branch/branch-card'

import Loading from '@components/molecules/Loading'

type Props = { hqId: number; branchId: number; visible: boolean; onClose: () => void; onDone: () => void }

export default function BranchCardForm({ hqId, branchId, visible, onClose, onDone }: Props): JSX.Element {
  const [form] = useForm<BillingCard>()
  const [loading, setLoading] = useState(false)

  const onOk = async () => {
    const values = await form.validateFields()

    setLoading(true)
    const { data } = await BranchCardAPI.addBranchCard({ hqId, branchId }, { card: values })

    if (data.isSuccess) {
      notification.success({ message: data.message })
      onDone()
      onClose()
    } else {
      notification.error({ message: data.message })
    }

    setLoading(false)
  }

  const initialValues: BillingCard = {
    number: '',
    password: '',
    expYear: '',
    expMonth: '',
    identityNumber: '',
  }

  useEffect(() => {
    if (!visible) form.resetFields()
  }, [visible])

  return (
    <Modal title="결제수단 추가" visible={visible} onOk={onOk} okText="추가하기" onCancel={onClose} width="400px">
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues} style={styles.form}>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item label="카드번호(숫자만)" name="number">
                <Input maxLength={16} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="비밀번호 앞 2자리" name="password">
                <Input type="password" maxLength={2} />
              </Form.Item>
            </Col>
            <Col span={2} />
            <Col span={4}>
              <Form.Item label="유효기간" name="expYear">
                <Input maxLength={2} placeholder="YY" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label=" " name="expMonth">
                <Input maxLength={2} placeholder="MM" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="생년월일 또는 사업자번호" name="identityNumber" extra="YYMMDD 또는 사업자번호 10자리">
                <Input maxLength={10} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  form: {},
}
