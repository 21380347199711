import { Form, Modal, notification, Tabs } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { Branches } from 'gadjet-v2-types/dist/model'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import branchDefaultValues from '@utils/branchDefaultValues'

import HqBranchAPI from '@apis/hq/branch'

import { RootState } from '@reducers/index'

import BranchBusinessTab from '@components/organisms/AddBranch/Tabs/BranchBusinessTab'
import BranchCardTab from '@components/organisms/AddBranch/Tabs/BranchCardTab'
import BranchContractDocumentTab from '@components/organisms/AddBranch/Tabs/BranchContractDocumentTab'
import BranchSettlementTab from '@components/organisms/AddBranch/Tabs/BranchSettlementTab'
import BranchTab from '@components/organisms/AddBranch/Tabs/BranchTab'

const INITIAL_ACTIVE_KEY = 'branch'

type Props = {
  visible: boolean
  onClose: () => void
  hqId: number
}

export default function AddBranchFormModal({ onClose, visible, hqId }: Props) {
  const [form] = useForm<Branches>()
  const [activeKey, setActiveKey] = useState(INITIAL_ACTIVE_KEY)
  const managerId = useSelector((state: RootState) => state.auth.manager?.managerId || 0)

  const onOk = async () => {
    try {
      const {
        name,
        contact,
        email,
        zipcode,
        address,
        addressDetail,
        business,
        settlement,
        contractDocument,
        sublet,
        rentee,
      } = await form.validateFields()

      const data = await HqBranchAPI.addHqBranches(
        { hqId },
        {
          branch: {
            name,
            contact,
            email,
            zipcode,
            address,
            addressDetail,
            business,
            settlement,
            contractDocument,
            sublet,
            rentee,
            cards: form.getFieldValue('cards'),
          },
          managerId,
        }
      )
      notification.success({ message: '저장되었습니다.' })

      if (onClose) onClose()
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const tabs = useMemo(
    () => [
      {
        label: '지점',
        key: 'branch',
        component: <BranchTab form={form} />,
      },
      {
        label: '사업자',
        key: 'branch-business',
        component: <BranchBusinessTab form={form} />,
      },
      {
        label: '계약서',
        key: 'branch-contract-document',
        component: <BranchContractDocumentTab form={form} />,
      },
      {
        label: '정산',
        key: 'branch-settlement',
        component: <BranchSettlementTab form={form} />,
      },
      {
        label: '결제수단',
        key: 'branch-card',
        component: <BranchCardTab form={form} hqId={hqId} />,
      },
    ],
    [hqId]
  )

  return (
    <>
      <Modal title="지점추가" visible={visible} width="800px" onCancel={onClose} onOk={onOk} maskClosable={false}>
        <Form form={form} layout="vertical" initialValues={branchDefaultValues}>
          <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
            {tabs.map(({ label, key, component }) => (
              <Tabs.TabPane tab={label} key={key} forceRender>
                {component}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Form>
      </Modal>
    </>
  )
}
