import PaymentAPI from '@apis/branch/payment'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  paymentId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function PaymentConfirmModal({
  hqId,
  branchId,
  paymentId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    if (!paymentId) return
    await PaymentAPI.confirmPayment({ hqId, branchId, paymentId })
  }

  return (
    <ConfirmModal
      type="success"
      visible={visible}
      title="매출확정"
      description="매출확정 이후 환불할 수 없습니다."
      onDone={onDone}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  )
}
