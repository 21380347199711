import Picture from '@pages/Landing/FeaturePages/atoms/Picture'
import TenantIcon from '@pages/Landing/FeaturePages/atoms/TenantIcon'
import * as S from '@pages/Landing/FeaturePages/styles'

export default function ReserveSpace(): JSX.Element {
  return (
    <S.SectionInnerWrapper>
      <S.Container
        defaultStyles={{
          flexDirection: 'column',
          alignItems: 'center',
          height: '500px',
          background: 'linear-gradient(95.81deg, #F3F4F5 5.02%, #E4F6EC 99.87%)',
          position: 'relative',
        }}
        mediaStyles={{ height: '470px' }}
      >
        <S.DescriptionWrapper defaultStyle={{ padding: '40px 0 0 80px' }} mediaStyle={{ padding: '40px 0 0 30px' }}>
          <S.BoxTitle>대관 관리</S.BoxTitle>
          <S.Description>
            회의실, 세미나실 예약도
            <br />
            실시간으로 현황을 파악해요.
          </S.Description>
        </S.DescriptionWrapper>
        <S.ImageWrapper defaultStyle={{ alignItems: 'flex-end' }} mediaStyle={{ justifyContent: 'flex-end' }}>
          <Picture
            srcPNG="/images/landing/features/store/reserve-calendar.png"
            srcWEBP="/images/landing/features/store/reserve-calendar.webp"
            width={1090}
            height={452}
            alt="reserve-modal"
            mediaScale={0.94}
            defaultStyle={{ position: 'absolute', bottom: '-22px', left: '26px' }}
            mediaStyle={{ bottom: '0px' }}
          />
        </S.ImageWrapper>
      </S.Container>

      <S.Container
        defaultStyles={{
          flexDirection: 'row',
          alignItems: 'center',
          background: 'linear-gradient(90deg, rgba(26, 26, 26, 0.95) 0%, rgba(26, 26, 26, 0.90) 100%)',
          position: 'relative',
          height: '480px',
        }}
        mediaStyles={{ height: '520px', flexDirection: 'column' }}
      >
        <S.DescriptionWrapper defaultStyle={{ padding: '0 0 0 80px' }} mediaStyle={{ padding: '40px 0 0 30px' }}>
          <S.BoxTitle color="#FFFFFF">대관 예약</S.BoxTitle>
          <S.Description color="#FFFFFF">
            앱에서 이용 가능 시간을
            <br /> 바로 확인하고 <br />
            예약할 수 있어요.
          </S.Description>
        </S.DescriptionWrapper>
        <S.ImageWrapper defaultStyle={{ alignItems: 'flex-end' }} mediaStyle={{ justifyContent: 'flex-end' }}>
          <Picture
            srcPNG="/images/landing/features/store/reserve-modal.png"
            srcWEBP="/images/landing/features/store/reserve-modal.webp"
            width={430}
            height={430}
            alt="reserve-modal"
            defaultStyle={{ margin: '50px 95px 0 0 ' }}
            mediaStyle={{ margin: '5px 0 0 0' }}
            mediaScale={0.77}
          />
        </S.ImageWrapper>
        <TenantIcon />
      </S.Container>
    </S.SectionInnerWrapper>
  )
}
