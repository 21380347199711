import { Col, Form, Input, Modal, notification, Radio, Row, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import * as RECEIPT from 'gadjet-v2-types/dist/api/branch/receipt'
import { useEffect, useState } from 'react'

import ReceiptAPI from '@apis/branch/receipt'

import Loading from '@components/molecules/Loading'

type CashReceiptForm = RECEIPT.CANCEL_CASH_RECEIPT_BODY['cashReceipt']

type Props = {
  hqId: number
  branchId: number
  billId: number
  receiptId: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function CancelCashReceiptModal({
  hqId,
  branchId,
  billId,
  receiptId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [form] = useForm<CashReceiptForm>()

  const onOk = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()
      const { data } = await ReceiptAPI.cancelCashReceipt(
        { hqId, branchId, receiptId },
        { billId, cashReceipt: values }
      )

      if (!data.isSuccess) {
        notification.error({ message: data.message })
      }

      if (data.isSuccess) {
        notification.success({ message: data.message })
        onDone()
        onClose()
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const getReceipt = async () => {
    form.resetFields()
    setLoading(true)
    const { data } = await ReceiptAPI.getReceiptDetail({ hqId, branchId, receiptId })
    const { serviceFee, supplyCost, tax, totalAmount } = data.cashBillJson
    form.setFieldsValue({
      cancelType: 1,
      isPartCancel: false,
      serviceFee,
      supplyCost,
      tax,
      totalAmount,
    })

    setLoading(false)
  }

  useEffect(() => {
    if (visible && receiptId) getReceipt()
  }, [visible, receiptId])

  return (
    <Modal title="현금영수증" visible={visible} onCancel={onClose} okText="현금영수증 취소" onOk={onOk}>
      <Loading loading={loading}>
        <Form form={form} layout="vertical">
          <Row gutter={20}>
            <Col span={18}>
              <Form.Item label="취소사유" name="cancelType">
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={1}>거래취소</Radio.Button>
                  <Radio.Button value={2}>오류발급취소</Radio.Button>
                  <Radio.Button value={3}>기타</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="부분취소" name="isPartCancel" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const disabled = !getFieldValue('isPartCancel')
              return (
                <Row gutter={20}>
                  <Col span={6}>
                    <Form.Item label="거래금액" name="totalAmount" required>
                      <Input disabled={disabled} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="공급가액" name="supplyCost" required>
                      <Input disabled={disabled} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="부가세" name="tax" required>
                      <Input disabled={disabled} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="봉사료" name="serviceFee">
                      <Input disabled={disabled} />
                    </Form.Item>
                  </Col>
                </Row>
              )
            }}
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}
