import useQueryString from '@hooks/useQueryString'
import { Form, Input, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import formRule from '@utils/formRule'

import AuthAPI from '@apis/auth'

import AuthFormTemplate from '@templates/AuthFormTemplate'

export default function ResetPasswordPage(): JSX.Element {
  const [form] = useForm<{ password: string }>()
  const [loading, setLoading] = useState(false)
  const { push } = useHistory()
  const [queryState] = useQueryString<{ key?: string }>()

  const onSubmit = async () => {
    if (!queryState.key) return
    const values = await form.validateFields()
    setLoading(true)
    const { data } = await AuthAPI.resetPassword({}, { key: queryState.key, password: values.password })

    if (data) notification.success({ message: '비밀번호가 변경되었습니다.' })
    else notification.error({ message: '비밀번호 변경 실패' })

    setLoading(false)
  }

  useEffect(() => {
    if (!queryState.key) {
      notification.error({ message: '잘못된 접근 입니다.' })
      push('/')
    }
  }, [])

  const initialValues = { password: '' }

  return (
    <AuthFormTemplate title="비밀번호 변경" submit={{ label: '비밀번호 변경', onClick: onSubmit }} loading={loading}>
      <Form form={form} initialValues={initialValues} layout="vertical" requiredMark={false}>
        <Form.Item name="password" label="비밀번호" required rules={[formRule.required, formRule.password]}>
          <Input type="password" size="large" onPressEnter={onSubmit} />
        </Form.Item>
      </Form>
    </AuthFormTemplate>
  )
}
