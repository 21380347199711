import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
import { Pagination } from 'gadjet-v2-types/dist/type'

export const onChangeTable = <T extends unknown>(
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<T> | SorterResult<T>[]
): Pagination => {
  const { current = 1, pageSize = 10 } = pagination

  const isArray = Array.isArray(sorter)
  const _sorter = isArray ? sorter : [sorter]

  const sort: Pagination['sort'] = _sorter
    .filter((sorter) => !!sorter.column && !!sorter.columnKey)
    .map(({ columnKey = '', order }: SorterResult<T>) => {
      let _order: 'desc' | 'asc' = 'desc'
      if (order === 'ascend') _order = 'asc'
      if (order === 'descend') _order = 'desc'

      return [columnKey?.toString(), _order]
    })

  return { current, pageSize, sort }
}

const antdTable = { onChangeTable }

export default antdTable
