import styled from 'styled-components'

import { krw } from '@utils/format'

import { Badge } from '../../atoms/DiscountBadge'

type Props = {
  discountRate?: string
  amount: number | string
  isUnknownPrice?: boolean
  isYearPrepaySelected?: boolean
  promotion?: Promotion
  isPlanBox?: boolean
  isPromotionSwtichOn?: boolean
}

export default function CalculatorPriceBox({
  discountRate,
  promotion,
  amount,
  isUnknownPrice,
  isYearPrepaySelected,
  isPlanBox,
  isPromotionSwtichOn,
}: Props): JSX.Element {
  return (
    <Container>
      <InnerWrapper>
        {isPlanBox && (
          <Badge isVisible={(promotion?.isVisible && isPromotionSwtichOn) || !!discountRate}>
            {discountRate}
            {promotion?.isVisible && isPromotionSwtichOn && (
              <>
                {discountRate && '+'}
                {`${promotion.rate * 100}%`}
              </>
            )}
          </Badge>
        )}
        {!isUnknownPrice ? (
          <Price>
            <Amount>{krw(amount, true)}</Amount>
            {!isUnknownPrice && <PriceUnit>원</PriceUnit>}
          </Price>
        ) : (
          <UnknownPrice>별도 문의</UnknownPrice>
        )}
      </InnerWrapper>

      {isYearPrepaySelected && <YearPrepay>( 전체 이용료/12 )</YearPrepay>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 286px;
  height: 96px;
  color: #2e2e2e;
  background: #ffffff;
  border-radius: 24px;
  position: relative;
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const Price = styled.div`
  display: flex;
  gap: 4px;
  align-items: flex-end;
`
const Amount = styled.span`
  font-family: 'Akshar';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 25px;
`
const PriceUnit = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
`
const UnknownPrice = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
`
const YearPrepay = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #7a7b7c;
  position: absolute;
  bottom: 10px;
`
