import { Col, Row } from 'antd'
import { useContext } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import HqAPI from '@apis/hq'

import { AdminGlobalContext } from '@components/contexts/AdminGlobal'
import HqForm from '@components/organisms/Hq/Config/HqForm'

export default function ConfigPage(): JSX.Element {
  const { hqId } = useContext(AdminGlobalContext)

  const queryClient = useQueryClient()
  const query = useQuery(['HQ_CONFIG'], () => HqAPI.getHqDetail({ hqId }))

  const onRefresh = () => {
    queryClient.invalidateQueries(['HQ_CONFIG'])
  }

  return (
    <Row>
      <Col span={10}>
        <HqForm hqId={hqId} hq={query.data?.data} onRefresh={onRefresh} />
      </Col>
    </Row>
  )
}
