import { notification } from 'antd'

import TenantAPI from '@apis/branch/tenant'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  tenantId?: number
  userId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function BillDeleteModal({
  hqId,
  branchId,
  tenantId,
  userId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    if (!tenantId || !userId) return
    await TenantAPI.deleteUserFromTenant({ hqId, branchId, tenantId, userId })
    notification.success({ message: '삭제되었습니다.' })
  }

  return (
    <ConfirmModal
      title="사용자 삭제"
      description="사용자를 삭제하시겠습니까?"
      okText="삭제"
      onConfirm={onConfirm}
      visible={visible}
      onClose={onClose}
      onDone={onDone}
    />
  )
}
