import { Bills } from 'gadjet-v2-types/dist/model'
import { BillSummary, Pagination } from 'gadjet-v2-types/dist/type'
import { BillSearch } from 'gadjet-v2-types/dist/type/search'

import { getDefaultRange } from '@utils/datetime'

// ActionType
export const SEARCH_BILLS = 'bill/SEARCH_BILLS' as const
export const SET_BILL_DEFAULT = 'bill/SET_BILL_DEFAULT' as const
export const SET_BILL_LOADING = 'bill/SET_BILL_LOADING' as const
export const SET_BILL_SEARCH_RESULT = 'bill/SET_BILL_SEARCH_RESULT' as const
export const SET_BILL_SEARCH = 'bill/SET_BILL_SEARCH' as const
export const SET_BILL_PAGINATION = 'bill/SET_BILL_PAGINATION' as const
export const SET_BILL_SUMMARY = 'bill/SET_BILL_SUMMARY' as const
export const SET_BILL_PAGE_CONFIG = 'bill/SET_BILL_PAGE_CONFIG' as const

// Action
export const searchBills = (hqId: number, branchId: number) => ({
  type: SEARCH_BILLS,
  payload: { hqId, branchId },
})
export const setBillDefault = (hqId: number, branchId: number) => ({
  type: SET_BILL_DEFAULT,
  payload: { hqId, branchId },
})
export const setBillLoading = (loading: boolean) => ({
  type: SET_BILL_LOADING,
  payload: { loading },
})
export const setBillSearchResult = (result: Partial<BillState['searchResult']>) => ({
  type: SET_BILL_SEARCH_RESULT,
  payload: { result },
})
export const setBillSearch = (search: Partial<BillSearch>) => ({
  type: SET_BILL_SEARCH,
  payload: { search },
})
export const setBillPagination = (pagination: Partial<Pagination>) => ({
  type: SET_BILL_PAGINATION,
  payload: { pagination },
})
export const setBillSummary = (summary: BillSummary) => ({
  type: SET_BILL_SUMMARY,
  payload: { summary },
})

export type BillAction =
  | ReturnType<typeof searchBills>
  | ReturnType<typeof setBillDefault>
  | ReturnType<typeof setBillLoading>
  | ReturnType<typeof setBillSearchResult>
  | ReturnType<typeof setBillSearch>
  | ReturnType<typeof setBillPagination>
  | ReturnType<typeof setBillSummary>

type BillState = {
  loading: boolean
  searchResult: { branchId: number; total: number; bills: Bills[] }
  search: BillSearch
  pagination: Pagination
  summary: BillSummary
}

const billState: BillState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, bills: [] },
  search: { query: '', startDate: getDefaultRange('month', 'date'), spaceTypes: [], type: ['sales', 'deposit'] },
  pagination: { current: 1, pageSize: 10 },
  summary: { addition: 0, etc: 0, expectedSales: 0, sales: 0, space: 0, unpaid: 0 },
}

const billReducer = (state = billState, action: BillAction): BillState => {
  switch (action.type) {
    case SET_BILL_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_BILL_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_BILL_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_BILL_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    case SET_BILL_SUMMARY:
      return { ...state, summary: action.payload.summary }
    default:
      return state
  }
}

export default billReducer
