import useMenu from '@hooks/useMenu'
import { Styles } from '@types'
import { Menu } from 'antd'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { RootState } from '@reducers/index'

import HqHomeButton from '@components/atoms/Menu/HqHomeButton'
import HqLinkButton from '@components/atoms/Menu/HqLinkButton'
import BranchSelector from '@components/organisms/Menu/BranchSelector'

const { Item, ItemGroup, Divider } = Menu

export default function AdminMenu(): JSX.Element {
  const { hq, branch, branches, hqs } = useSelector((state: RootState) => state.menu)
  const { hqId, hqMenus, branchMenus, setBranch } = useMenu()
  const { push } = useHistory()
  const routeMath = useRouteMatch()
  const { pathname } = useLocation()

  const visibleHq = useMemo(
    () => hq?.roles?.some((r) => r.adminFlag || r.config || r.dashboard || r.graph || r.role || r.notice),
    [hq]
  )
  const isActiveHq = useMemo(() => hqMenus.map(({ path }) => path).some((p) => pathname === p), [pathname])

  const onChangeBranch = (_branchId: number) => {
    const { path, url } = routeMath
    const pushTo = path.replace(':hqId', `${hqId}`).replace(':branchId', `${_branchId}`) + pathname.replace(url, '')
    push(pushTo)
    setBranch(_branchId)
  }

  return (
    <>
      {visibleHq && (
        <>
          <HqLinkButton hq={hq} hqs={hqs} onChangeBranch={onChangeBranch} />
          <HqHomeButton hq={hq} isActive={isActiveHq} />
        </>
      )}

      <BranchSelector branch={branch} branches={hq?.branches || branches} onChange={onChangeBranch} />

      <Menu selectedKeys={[pathname]}>
        {branchMenus.map(({ label, path, sub }) =>
          !sub ? (
            <Item key={path}>
              <Link to={path}>{label}</Link>
            </Item>
          ) : (
            <ItemGroup key={path} title={<div style={styles.itemGroupTitle}>{label}</div>}>
              <Divider />
              {sub.map((s) => (
                <Item key={`${path}/${s.path}`}>
                  <Link to={`${path}/${s.path}`}>{s.label}</Link>
                </Item>
              ))}
            </ItemGroup>
          )
        )}
      </Menu>

      <div style={styles.spacer} />
    </>
  )
}

const styles: Styles = {
  spacer: { height: '30px' },
}
