import { Form, FormItemProps } from 'antd'
import styled from 'styled-components'

export default function AuthFormItem(props: FormItemProps): JSX.Element {
  return <FormItem {...props} />
}

const FormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    color: #4e5257;
  }
`
