import { BranchRoles } from 'gadjet-v2-types/dist/model'
import { createContext } from 'react'

type BranchRoleKey = keyof Omit<
  BranchRoles,
  'branchRoleId' | 'hqId' | 'branchId' | 'managerId' | 'adminFlag' | 'branch' | 'manager'
>

const branchRoleColumnLabels: { [key in BranchRoleKey]: string } = {
  dashboard: '대시보드',
  contract: '계약',
  space: '공간',
  addition: '부가서비스',
  bill: '청구서',
  receipt: '증빙',
  payment: '결제내역',
  rental: '대관/대여',
  tenant: '입주사',
  notice: '공지사항',
  product: '상품',
  service: '서비스',
  expenditure: '지출',
  event: '이벤트',
  cash: '현금',
  accessLog: '접근기록',
  role: '권한',
  config: '설정',
}

const branchRoleColumnKeys = Object.keys(branchRoleColumnLabels) as BranchRoleKey[]

type BranchRoleContextState = {
  branchRoleColumnLabels: { [key in BranchRoleKey]: string }
  branchRoleColumnKeys: BranchRoleKey[]
}
const BranchRoleContext = createContext<BranchRoleContextState>({
  branchRoleColumnLabels,
  branchRoleColumnKeys,
})

type Props = { children: React.ReactNode }
const BranchRoleProvider = ({ children }: Props) => {
  const state: BranchRoleContextState = {
    branchRoleColumnLabels,
    branchRoleColumnKeys,
  }

  return <BranchRoleContext.Provider value={state}>{children}</BranchRoleContext.Provider>
}

export { BranchRoleContext, BranchRoleProvider }
