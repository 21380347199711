import * as CASH from 'gadjet-v2-types/dist/api/branch/cash'

import axios from '@utils/axios'

const CashAPI = {
  // 현금 조회
  getCash: async (
    { hqId, branchId }: CASH.GET_LIST_PARAMS,
    query: CASH.GET_LIST_QUERY
  ): Promise<{ data: CASH.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/cash`, { params: query }),

  // 현금 상세 조회
  getCashDetail: async ({
    hqId,
    branchId,
    cashId,
  }: CASH.GET_DETAIL_PARAMS): Promise<{ data: CASH.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/cash/${cashId}`),

  getCashSummary: async (
    { hqId, branchId }: CASH.GET_SUMMARY_PARAMS,
    query: CASH.GET_SUMMARY_QUERY
  ): Promise<{ data: CASH.GET_SUMMARY_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/cash/summary`, { params: query }),

  // 현금 추가
  addCash: async ({ hqId, branchId }: CASH.ADD_PARAMS, { cash }: CASH.ADD_BODY): Promise<{ data: CASH.ADD_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/cash`, { cash }),

  // 현금 수정
  updateCash: async (
    { hqId, branchId, cashId }: CASH.UPDATE_PARAMS,
    { cash }: CASH.UPDATE_BODY
  ): Promise<{ data: CASH.UPDATE_RESPONSE }> => axios.put(`/hqs/${hqId}/branches/${branchId}/cash/${cashId}`, { cash }),

  // 현금 삭제
  deleteCash: async ({ hqId, branchId, cashId }: CASH.DELETE_PARAMS): Promise<{ data: CASH.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/cash/${cashId}`),

  // 현금 다운로드
  exportCash: async (
    { hqId, branchId }: CASH.EXPORT_PARAMS,
    query: CASH.EXPORT_QUERY
  ): Promise<{ data: CASH.EXPORT_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/cash/export`, { params: query }),
}

export default CashAPI
