import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import ContractAPI from '@apis/branch/contract'

import {
  ContractAction,
  setContractLoading,
  setContractSearchResult,
  SEARCH_CONTRACTS,
  SET_CONTRACT_DEFAULT,
  SET_CONTRACT_PAGINATION,
  SET_CONTRACT_SEARCH,
  SET_CONTRACT_TABLE_CONFIG,
  setContractSearch,
  setContractPagination,
  setContractTableConfig,
  searchContracts,
} from '@reducers/branch/contract.reducer'
import { RootState } from '@reducers/index'
import { setStorageContract } from '@reducers/storage.reducer'

function* searchContractSaga(action: ContractAction): Generator {
  if (action.type !== SEARCH_CONTRACTS) return
  const { hqId, branchId } = action.payload
  yield* put(setContractLoading(true))

  const search = yield* select((state: RootState) => state.contract.search)
  const pagination = yield* select((state: RootState) => state.contract.pagination)

  const {
    data: { contracts, total },
  } = yield* call(ContractAPI.getContracts, { hqId, branchId }, { ...search, ...pagination })

  yield* put(setContractSearchResult({ branchId, contracts, total }))

  yield* put(setContractLoading(false))
}

function* savePageConfigSaga(action: ContractAction): Generator {
  const state: Parameters<typeof setStorageContract>[0] = {}

  if (action.type === SET_CONTRACT_SEARCH) {
    const { spaceTypes } = action.payload.search
    state.spaceTypes = spaceTypes
  } else if (action.type === SET_CONTRACT_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  } else if (action.type === SET_CONTRACT_TABLE_CONFIG) {
    const { activeColumns } = action.payload.tableConfig
    state.activeColumns = activeColumns
  }

  yield* put(setStorageContract(state))
}

function* setDefaultPageConfigSaga(action: ContractAction): Generator {
  if (action.type !== SET_CONTRACT_DEFAULT) return
  const { activeColumns, pageSize, spaceTypes } = yield* select((state: RootState) => state.storage.contractConfig)

  yield* all([
    put(setContractSearch({ spaceTypes })),
    put(setContractPagination({ pageSize })),
    put(setContractTableConfig({ activeColumns })),
  ])
  const { hqId, branchId } = action.payload
  yield* put(searchContracts(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_CONTRACTS, searchContractSaga),
    takeEvery(SET_CONTRACT_SEARCH, savePageConfigSaga),
    takeEvery(SET_CONTRACT_PAGINATION, savePageConfigSaga),
    takeEvery(SET_CONTRACT_TABLE_CONFIG, savePageConfigSaga),
    takeEvery(SET_CONTRACT_DEFAULT, setDefaultPageConfigSaga),
  ])
}
