import useMenu from '@hooks/useMenu'
import { BranchDashboardEventSummary } from 'gadjet-v2-types/dist/type'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { krw } from '@utils/format'

import SummaryCard from '@components/organisms/Branch/Dashboard/DashboardSummary/SummaryCard'

type Props = {
  summary: BranchDashboardEventSummary
  loading: boolean
  dateRange: [string, string]
}

type ItemKey = keyof BranchDashboardEventSummary

export type Items = {
  label: string
  value?: (summary: BranchDashboardEventSummary) => string | number
  color?: string
  onClick?: () => void
  isButtonHidden?: boolean
}[]

type SummaryItems = {
  key: ItemKey
  items: Items
}[]

export default function DashboardSummary({ summary, loading, dateRange }: Props): JSX.Element {
  const { push } = useHistory()
  const { hqId, branchId } = useMenu()

  const summaryItems: SummaryItems = [
    {
      key: 'expectSalePrice',
      items: [
        {
          label: '예상매출',
          value: ({ expectSalePrice }) => krw(expectSalePrice),
          onClick: () => push(`/hq/${hqId}/branch/${branchId}/bill/list`, { dateRange }),
        },
      ],
    },

    {
      key: 'paymentPrice',
      items: [
        {
          label: '결제',
          value: ({ paymentPrice }) => krw(paymentPrice),
          onClick: () => push(`/hq/${hqId}/branch/${branchId}/bill/payment`, { dateRange }),
        },
      ],
    },

    {
      key: 'unpaidPrice',
      items: [
        {
          label: '미납',
          value: ({ unpaidPrice }) => krw(unpaidPrice),
          color: '#F43F24',
          onClick: () => push(`/hq/${hqId}/branch/${branchId}/bill/list`, { dateRange, isUnpaidPrice: true }),
        },
      ],
    },

    {
      key: 'expenditurePrice',
      items: [
        {
          label: '지출',
          value: ({ expenditurePrice }) => krw(expenditurePrice),
          color: '#353CDE',
          onClick: () => push(`/hq/${hqId}/branch/${branchId}/expenditure`, { dateRange }),
        },
      ],
    },

    {
      key: 'contractStartCount',
      items: [
        { label: '계약 시작', value: ({ contractStartCount }) => `${contractStartCount}건`, isButtonHidden: true },
        { label: '계약 종료', value: ({ contractEndCount }) => `${contractEndCount}건`, isButtonHidden: true },
      ],
    },

    {
      key: 'billingCount',
      items: [
        {
          label: '청구',
          value: ({ billingCount }) => `${billingCount}건`,
          onClick: () => push(`/hq/${hqId}/branch/${branchId}/bill/list`, { dateRange }),
        },
      ],
    },

    {
      key: 'rentalReservationCount',
      items: [
        {
          label: '대여/대관 예약',
          value: ({ rentalReservationCount }) => `${rentalReservationCount}건`,
          onClick: () => push(`/hq/${hqId}/branch/${branchId}/rental/reservation`, { dateRange }),
        },
      ],
    },
  ]
  return (
    <Wrapper>
      {summaryItems.map(({ items, key }) => (
        <SummaryCard items={items} summary={summary} loading={loading} key={key} />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 1423px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 1423px) {
    width: auto;
  }
`
