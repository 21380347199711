import { createContext, useState } from 'react'

type ModalProps = { onClose: () => void } & unknown
type ModalComponent = (props: ModalProps) => JSX.Element
type Modal = { Component: ModalComponent; props: Parameters<Modal['Component']>[0] }

export const ModalDispatchContext = createContext({
  open: (Component: ModalComponent, props: Parameters<typeof Component>[0]) => {},
  close: (Component: ModalComponent) => {},
})

export const ModalStateContext = createContext<Modal[]>([])

type Props = { children: React.ReactNode }
export const ModalProvider = ({ children }: Props) => {
  const [openModals, setOpenModals] = useState<Modal[]>([])

  const open = (Component: ModalComponent, props: Parameters<typeof Component>[0]) =>
    setOpenModals((modals) => [...modals, { Component, props }])

  const close = (Component: ModalComponent) => {
    setOpenModals((modals) => modals.filter((modal) => modal.Component !== Component))
  }

  const dispatch = { open, close }

  return (
    <ModalStateContext.Provider value={openModals}>
      <ModalDispatchContext.Provider value={dispatch}>
        {children}
        {openModals.map(({ Component, props }) => (
          <Component key={Component.name} {...props} onClose={() => close(Component)} />
        ))}
      </ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  )
}
