import { GADJET_BIZ_COLORS } from '@const'
import { Form, Input, Select } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { ChangeEvent, useState } from 'react'
import styled from 'styled-components'

import formRule from '@utils/formRule'
import mask from '@utils/mask'

import { ContactSales } from '@apis/contact'

type ContactFormProps = {
  isEtcChecked: boolean
  form: FormInstance<ContactSales>
  onSubmit: () => Promise<void>
  setEtcFeature: React.Dispatch<React.SetStateAction<string>>
  setIsEtcChecked: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ContactForm({
  form,
  onSubmit,
  isEtcChecked,
  setEtcFeature,
  setIsEtcChecked,
}: ContactFormProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false)
  const interestedFeatures: { key: string; value: string; label: string; hasTextArea?: boolean }[] = [
    { key: 'propertyManagement', value: '부동산 운영', label: '부동산 운영' },
    { key: 'serviceManagement', value: '용역 관리', label: '용역 관리' },
    { key: 'maintenance', value: '유지보수', label: '유지보수' },
    { key: 'buildingManagement', value: '건물 관리', label: '건물 관리' },
    { key: 'buildingUsageAuth', value: '건물 이용 권한 설정', label: '건물 이용 권한 설정' },
    { key: 'managementReport', value: '운영 보고서', label: '운영 보고서' },
    {
      key: 'etc',
      value: 'etc',
      label: '그 외',
      hasTextArea: true,
    },
  ]

  const industryOptions: { value: string; label: string }[] = [
    { value: '건물주', label: '건물주' },
    { value: 'AM (Asset Management)', label: 'AM (Asset Management)' },
    { value: 'FM (Facility Management)', label: 'FM (Facility Management)' },
    { value: 'PM (Property Management)', label: 'PM (Property Management)' },
    { value: '공유공간 서비스', label: '공유공간 서비스' },
  ]
  return (
    <Form form={form} layout="vertical">
      <Form.Item name="companyName" rules={[formRule.required]} requiredMark="optional" label={<Label>회사명*</Label>}>
        <StyledInput placeholder="회사명을 입력해 주세요" />
      </Form.Item>
      <Form.Item
        name="industryName"
        rules={[formRule.required]}
        label={<Label>산업분야*</Label>}
        requiredMark="optional"
      >
        <StyledSelect
          showArrow={false}
          placeholder="산업분야를 선택해 주세요"
          options={industryOptions}
          style={{ height: '48px' }}
          dropdownStyle={{ borderRadius: '6px' }}
        />
      </Form.Item>
      <Form.Item name="userName" rules={[formRule.required]} requiredMark="optional" label={<Label>이름*</Label>}>
        <StyledInput placeholder="이름을 입력해 주세요" />
      </Form.Item>
      <Form.Item
        name="mailAddress"
        rules={[formRule.required, formRule.email]}
        requiredMark="optional"
        label={<Label>회사 이메일*</Label>}
      >
        <StyledInput placeholder="hello@gadjet.io" />
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        rules={[formRule.phone]}
        required
        requiredMark="optional"
        label={<Label>연락처*</Label>}
        {...mask.contact}
      >
        <StyledInput placeholder="010-" />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() => {
          const onChangeCheckbox = (e: CheckboxChangeEvent) => {
            const { value } = e.target
            if (value === 'etc') {
              setIsEtcChecked(!isEtcChecked)
            }
          }

          const onChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
            setEtcFeature(e.target.value)
          }

          return (
            <Form.Item
              name="interestedFeatureName"
              rules={[formRule.required]}
              requiredMark="optional"
              label={<Label>관심 솔루션* (중복 체크 가능합니다)</Label>}
            >
              <Checkbox.Group>
                {interestedFeatures.map(({ key, value, label, hasTextArea }) => (
                  <CheckboxWrap key={key}>
                    <StyledCheckbox value={value} onChange={onChangeCheckbox}>
                      <SoutionLabel>{label}</SoutionLabel>
                      {isEtcChecked && hasTextArea && (
                        <Input.TextArea autoSize={false} onChange={onChangeTextArea} style={{ resize: 'none' }} />
                      )}
                    </StyledCheckbox>
                  </CheckboxWrap>
                ))}
              </Checkbox.Group>
            </Form.Item>
          )
        }}
      </Form.Item>
      <Divider />
      <Form.Item
        name="termsAgree"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('필수입니다.'))),
          },
        ]}
      >
        <StyledCheckbox>
          <AgreeText>상담을 위한 개인정보 처리방침에 동의</AgreeText>
        </StyledCheckbox>
      </Form.Item>
      <Button onClick={onSubmit} disabled={isSubmitDisabled}>
        문의하기
      </Button>
      <Form.Item shouldUpdate noStyle>
        {({ isFieldsTouched, getFieldsError }) => {
          const isSubmitDisabled =
            !isFieldsTouched(true) || getFieldsError().filter(({ errors }) => errors.length).length > 0
          setIsSubmitDisabled(isSubmitDisabled)
        }}
      </Form.Item>
    </Form>
  )
}

const StyledInput = styled(Input)`
  height: 48px;
  padding: 12px 14px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  font-size: 15px;
  color: #2e2e2e;
  ::placeholder {
    color: #9e9e9e;
  }
`
const StyledSelect = styled(Select)`
  .ant-select-selection-search-input {
    height: 48px;
  }

  .ant-select-selection-placeholder {
    color: #9e9e9e;
  }

  .ant-select-selector {
    display: flex;
    align-items: center;
    height: 48px !important;
    padding: 12px 14px;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    font-size: 15px;
    color: #2e2e2e;
  }
`
const CheckboxWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 4px 0px;
`
const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${GADJET_BIZ_COLORS.primary};
  }

  .ant-checkbox .ant-checkbox-inner {
    border-color: #ebebeb;
    :hover {
      border-color: ${GADJET_BIZ_COLORS.primary};
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: ${GADJET_BIZ_COLORS.primary};
    border-color: ${GADJET_BIZ_COLORS.primary};
    :hover {
      border-color: ${GADJET_BIZ_COLORS.primary};
    }
  }
`

const Label = styled.div`
  color: #000b43;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #ebebeb;
  margin-bottom: 24px;
`
const AgreeText = styled.span`
  color: ${GADJET_BIZ_COLORS.primary};

  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`
const SoutionLabel = styled.span`
  color: #2e2e2e;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
`
const Button = styled.button<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 12px;
  background: ${({ disabled }) => (disabled ? '#F4F4F4' : GADJET_BIZ_COLORS.primary)};
  opacity: 0.9;
  backdrop-filter: blur(2px);
  color: ${({ disabled }) => (disabled ? '#9E9E9E' : '#ffffff')};
  border-radius: 8px;
  border: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  :hover {
    transition: all 0.3s ease-in-out;
    background: ${({ disabled }) => (disabled ? '#F4F4F4' : GADJET_BIZ_COLORS.primary)};
    color: ${({ disabled }) => (disabled ? '#9E9E9E' : '#ffffff')};
  }
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')}; ;
`
