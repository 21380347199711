import { Modal, Form, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useState, useEffect } from 'react'

import bufferToFileDownload from '@utils/download'

import SpaceAPI from '@apis/branch/space'

import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function SpaceExportModal({ hqId, branchId, visible, onClose, onDone }: Props): JSX.Element {
  const [form] = useForm<{ emptyFlag: boolean; query: '' }>()
  const [loading, setLoading] = useState(false)

  const onOk = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      const { data } = await SpaceAPI.exportSpaces({ hqId, branchId }, values)

      bufferToFileDownload('공간.xlsx', Buffer.from(data))

      setLoading(false)
      onDone()
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const reset = () => {
    form.resetFields()
  }

  useEffect(() => {
    if (visible) {
      reset()
    }
  }, [visible])

  const initialValues = {}

  return (
    <Modal title="다운로드" okText="다운로드" onOk={onOk} confirmLoading={loading} visible={visible} onCancel={onClose}>
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item name="emptyFlag" label="공실만 다운로드" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}
