import { ReactComponent as ChevronDownLgIcon } from '@assets/Icons/chevron-down-lg.svg'
import { ReactComponent as ChevronUpLgIcon } from '@assets/Icons/chevron-up-lg.svg'
import { BREAKPOINTS } from '@const'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'

type Props = {
  title: string
  content: string | ReactNode
}

export default function CollapsibleItem({ title, content }: Props): JSX.Element {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  return (
    <Wrapper isExpanded={isExpanded}>
      <CollapseHeader onClick={() => setIsExpanded(!isExpanded)}>
        <Title>{title}</Title>
        <Icon>{!isExpanded ? <ChevronDownLgIcon stroke="#2e2e2e" /> : <ChevronUpLgIcon stroke="#2e2e2e" />}</Icon>
      </CollapseHeader>
      {isExpanded && <CollapseContent>{content}</CollapseContent>}
    </Wrapper>
  )
}

type StyleProps = {
  isExpanded: boolean
}

const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  list-style: none;
  border-bottom: 1px solid #d9dbdc;

  padding: ${({ isExpanded }: StyleProps) => (isExpanded ? '32px 0px 24px 0px' : '32px 0px')};
`

const CollapseHeader = styled.button`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 36px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #2e2e2e;
  cursor: pointer;
  border: none;
  background: transparent;
`

const Title = styled.span`
  text-align: left;
`

const CollapseContent = styled.div`
  width: 100%;
  padding: 16px 20px;
  margin-top: 20px;
  background: #ffffff;
  border-radius: 16px;

  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #595a5b;
`
const Icon = styled.span`
  @media ${BREAKPOINTS.lg} {
    display: none;
  }
`
