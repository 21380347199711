import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Bills } from 'gadjet-v2-types/dist/model'

import { krw } from '@utils/format'

type Props = {
  loading?: boolean
  bills: Bills[]
  onClickReturn?: (bill: Bills) => void
}

export default function DepositBillListTable({ loading, bills, onClickReturn = () => undefined }: Props): JSX.Element {
  const columns: ColumnsType<Bills> = [
    { key: 'price', dataIndex: 'price', title: '청구 금액', render: (price) => krw(price) },
    { key: 'unpaidPrice', dataIndex: 'unpaidPrice', title: '미납', render: (unpaidPrice) => krw(unpaidPrice) },
    {
      key: 'billId',
      dataIndex: 'billId',
      title: '',
      width: '120px',
      align: 'center',
      render: (_, bill) => <Button onClick={() => onClickReturn(bill)}>반환내역추가</Button>,
    },
  ]
  return <Table loading={loading} dataSource={bills} rowKey={({ billId }) => `${billId}`} columns={columns} />
}
