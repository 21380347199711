import { COLORS } from '@const'
import { Styles } from '@types'
import { Form, Input } from 'antd'
import styled from 'styled-components'

import { krw } from '@utils/format'
import mask from '@utils/mask'

type Props = {
  label: string
  currentCredit: number | undefined
  disabled: boolean
  creditType: string
}

export default function UseCreditInput({ label, currentCredit, disabled, creditType }: Props): JSX.Element {
  return (
    <div style={styles.inputWrapper}>
      <div style={styles.label}>
        <div>{label} 크레딧</div>
        <div style={styles.myCredit}>
          <span style={styles.myCreditLabel}>보유 </span>
          <CurrentCredit isPositiveCredit={currentCredit}>{krw(currentCredit, true)}</CurrentCredit>
        </div>
      </div>
      <Form.Item
        noStyle
        name={['usedCreditAmount', creditType]}
        rules={[{ min: 0, max: currentCredit, type: 'number', message: '' }]}
        {...mask.krw}
      >
        <Input style={styles.inputNumber} disabled={disabled} />
      </Form.Item>
    </div>
  )
}

const styles: Styles = {
  inputWrapper: { display: 'flex', flexDirection: 'row', marginBottom: '18px', gap: '20px' },
  inputNumber: {
    width: '100%',
    minHeight: '34px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'right',
  },
  label: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 0, gap: '2px', width: '30%' },
  myCredit: { fontSize: '12px', lineHeight: '16px' },
  myCreditLabel: { color: '#7A7B7C' },
}

const CurrentCredit = styled.span`
  color: ${COLORS.primary};
  text-decoration: ${({ isPositiveCredit }: { isPositiveCredit?: number }) =>
    isPositiveCredit ? 'underline' : 'none'};
`
