import { Styles } from '@types'
import dayjs, { Dayjs } from 'dayjs'
import { RentalReservations } from 'gadjet-v2-types/dist/model'
import { useMemo } from 'react'
import * as RBC from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import styled from 'styled-components'

import spaceLabelBg from '@utils/spaceLabelBg'

import CalendarWrapper from '@components/atoms/CalendarWrapper'
import Calendar from '@components/molecules/Calendar'

import MonthEvent from './CustomCalendarViews/MonthEvent'
import WeekAndDayEvent from './CustomCalendarViews/WeekAndDayEvent'
import CustomToolBar from './CustomToolBar'
import './calendar-custom.scss'

type Props = {
  loading: boolean
  view: RBC.View
  date: Dayjs
  rentalReservations: RentalReservations[]
  onNavigate?: (date: Dayjs) => void
  onChangeView?: (view: RBC.View) => void
  onClickEvent?: (reservation: RentalReservations) => void
  onSelectRange?: (range: { start: Dayjs; end: Dayjs }) => void
  onRangeChange?: (range: { start: Dayjs; end: Dayjs }) => void
}
export default function BranchRentalReservationCalendar({
  loading,
  view,
  date,
  rentalReservations,
  onChangeView = () => undefined,
  onNavigate = () => undefined,
  onClickEvent = () => undefined,
  onSelectRange = () => undefined,
  onRangeChange = () => undefined,
}: Props): JSX.Element {
  const onSelectEvent = ({ resource }: RBC.Event) => onClickEvent(resource)
  const onSelecting = () => true
  const onSelectSlot = ({ start, end }: RBC.SlotInfo) => onSelectRange({ start: dayjs(start), end: dayjs(end) })
  const onChangeRange = (range: { start: RBC.stringOrDate; end: RBC.stringOrDate } | Date[]) => {
    if (Array.isArray(range)) onRangeChange({ start: dayjs(range[0]), end: dayjs(range[range.length - 1]) })
    else onRangeChange({ start: dayjs(range.start), end: dayjs(range.end) })
  }

  const events = useMemo(
    (): RBC.Event[] =>
      rentalReservations.map((r) => ({
        title: `${r.tenant?.name || '관리자 예약'}`,
        start: dayjs(`${r.date} ${r.startTime}`).toDate(),
        end: dayjs(`${r.date} ${r.endTime}`).toDate(),
        resource: r,
      })),
    [rentalReservations]
  )

  return (
    <CalendarWrapper loading={loading}>
      <Calendar
        view={view}
        views={['month', 'week', 'day']}
        onView={onChangeView}
        events={events}
        popup
        onSelectEvent={onSelectEvent}
        onSelecting={onSelecting}
        onSelectSlot={onSelectSlot}
        selectable="ignoreEvents"
        drilldownView="week"
        scrollToTime={dayjs().add(-2, 'hour').toDate()}
        date={date.toDate()}
        onNavigate={(d) => onNavigate(dayjs(d))}
        culture="ko-KR"
        onRangeChange={onChangeRange}
        dayLayoutAlgorithm="no-overlap"
        style={styles.calendar}
        components={{
          toolbar: CustomToolBar,
          month: {
            event: (event) => (
              <MonthEvent
                title={event.title}
                startTime={event.event.resource?.startTime}
                memo={event.event.resource?.memo}
              />
            ),
          },
          week: {
            header: ({ date }) => {
              const day = dayjs(date).day()
              return <WeekHeader isWeekday={day !== 0 && day !== 6}>{dayjs(date).format('D dd')}</WeekHeader>
            },
            event: (event) => <WeekAndDayEvent title={event.title} memo={event.event.resource?.memo} />,
          },
          day: {
            event: (event) => <WeekAndDayEvent title={event.title} memo={event.event.resource?.memo} />,
          },
        }}
        eventPropGetter={(event) => ({
          style: {
            border: `1px solid ${event.resource?.rental?.colorInfo || '#3ebd73'}`,
            backgroundColor: spaceLabelBg(event.resource?.rental?.colorInfo || '#e8ffed'),
          },
        })}
      />
    </CalendarWrapper>
  )
}

const styles: Styles = {
  calendar: { height: '100%' },
}

const WeekHeader = styled.div`
  color: ${({ isWeekday }: { isWeekday: boolean }) => (isWeekday ? '#5c5c5c' : '#777D83')};
`
