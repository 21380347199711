import useModal from '@hooks/useModal'
import { Styles } from '@types'
import { TableColumnType, Table, Button, Tag } from 'antd'
import { Users } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'

import TenantAPI from '@apis/branch/tenant'

import Loading from '@components/molecules/Loading'
import ModalAlert from '@components/molecules/ModalAlert'

import UserDeleteModal from './DeleteModal'
import InviteMemberModal from './InviteMemberModal'

type Props = {
  hqId: number
  branchId: number
  visible: boolean
  tenantId: number
  onDone?: () => void
  onClose: () => void
}
export default function TenantMemberModal({ hqId, branchId, tenantId, visible, onClose, onDone }: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState<Users[]>([])
  const [inviteModal, onVisibleInviteModal, onCloseInviteModal] = useModal({ visible: false })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal<{ userId: number }>({
    visible: false,
    userId: 0,
  })

  const columns: TableColumnType<Users>[] = [
    { key: 'userId', dataIndex: 'userId', title: 'userId' },
    { key: 'name', dataIndex: 'name', title: '이름' },
    { key: 'email', dataIndex: 'email', title: '이메일' },
    { key: 'contact', dataIndex: 'contact', title: '전화번호' },
    {
      title: '권한',
      key: 'tenantRoles',
      dataIndex: 'tenantRoles',
      render: () => {
        return <Tag color="blue">관리자</Tag>
      },
    },
    {
      title: '삭제',
      dataIndex: 'Delete',
      render: (_, { userId }) => {
        return (
          <Button
            danger
            size="small"
            style={{ borderColor: '#ff4d4f' }}
            onClick={() => onVisibleDeleteModal({ userId })}
          >
            삭제
          </Button>
        )
      },
    },
  ]

  const getTenantUsers = async () => {
    setLoading(true)
    const { data } = await TenantAPI.getTenantUsers(
      { hqId, branchId, tenantId },
      { query: '', current: 1, pageSize: 1000 }
    )
    setUsers(data.users)
    setLoading(false)
  }

  const onRemoved = () => {
    getTenantUsers()
  }

  useEffect(() => {
    if (visible) {
      setUsers([])
      getTenantUsers()
    }
  }, [visible])

  return (
    <ModalAlert title="입주사 멤버" visible={visible} onCancel={onClose}>
      <Loading loading={loading}>
        <Table
          dataSource={users}
          columns={columns}
          pagination={false}
          size="small"
          rowKey={({ userId }) => `${userId}`}
          title={() => (
            <div style={styles.title}>
              <Button size="small" onClick={() => onVisibleInviteModal()}>
                새 멤버 초대
              </Button>
            </div>
          )}
          footer={() => {
            return (
              <InviteMemberModal
                hqId={hqId}
                branchId={branchId}
                tenantId={tenantId}
                visible={inviteModal.visible}
                onClose={onCloseInviteModal}
              />
            )
          }}
        />
        <UserDeleteModal
          hqId={hqId}
          branchId={branchId}
          tenantId={tenantId}
          userId={deleteModal.userId}
          visible={deleteModal.visible}
          onClose={onCloseDeleteModal}
          onDone={onRemoved}
        />
      </Loading>
    </ModalAlert>
  )
}

const styles: Styles = {
  title: { display: 'flex', justifyContent: 'flex-end' },
}
