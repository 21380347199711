import { Row } from 'antd'
import { PaymentSummary } from 'gadjet-v2-types/dist/type'
import { paymentType } from 'gadjet-v2-types/dist/type/label'
import { useMemo } from 'react'

import { krw } from '@utils/format'

import ContentWrapper from '@components/atoms/ContentWrapper'
import SummaryItem, { SummaryItemType } from '@components/molecules/SummaryItem'

type Props = {
  summary: PaymentSummary
}

export default function BranchPaymentSummary({ summary }: Props): JSX.Element {
  const items = useMemo(
    (): { key: string; label: string; value: string | number; type: SummaryItemType }[] => [
      { key: 'total', label: '총 결제금액', value: krw(summary.total), type: 'success' },
      { key: 'card', label: paymentType.card, value: krw(summary.card), type: 'default' },
      { key: 'transfer', label: paymentType.transfer, value: krw(summary.transfer), type: 'default' },
      { key: 'direct', label: paymentType.direct, value: krw(summary.direct), type: 'default' },
      { key: 'cash', label: paymentType.cash, value: krw(summary.cash), type: 'default' },
      { key: 'refund', label: '환불', value: krw(summary.refund), type: 'error' },
      { key: 'oversea-card', label: paymentType['oversea-card'], value: krw(summary['oversea-card']), type: 'default' },
      // { key: 'paypal', label: paymentType.paypal, value: krw(summary.paypal), type:'default' },
      // { key: 'alipay', label: paymentType.alipay, value: krw(summary.alipay), type:'default' },
    ],
    [summary]
  )

  const flex = `${100 / items.length}%`

  return (
    <ContentWrapper>
      <Row>
        {items.map(({ key, label, value, type }) => (
          <SummaryItem key={key} label={label} value={value} type={type} flex={flex} />
        ))}
      </Row>
    </ContentWrapper>
  )
}
