import { HqRoles } from 'gadjet-v2-types/dist/model'

// ActionType
export const GET_HQ_ROLES = 'hq/role/GET_HQ_ROLE' as const
export const SET_HQ_ROLES = 'hq/role/SET_HQ_ROLE' as const
export const SET_HQ_ROLE_LOADING = 'hq/role/SET_HQ_ROLE_LOADING' as const

// Action
export const getHqRoles = (hqId: number) => ({
  type: GET_HQ_ROLES,
  payload: { hqId },
})
export const setHqRoles = (roles: HqRoles[]) => ({
  type: SET_HQ_ROLES,
  payload: { roles },
})
export const setHqRoleLoading = (loading: boolean) => ({
  type: SET_HQ_ROLE_LOADING,
  payload: { loading },
})

export type HqRoleAction =
  | ReturnType<typeof getHqRoles>
  | ReturnType<typeof setHqRoles>
  | ReturnType<typeof setHqRoleLoading>

type HqRoleState = {
  loading: boolean
  roles: HqRoles[]
}

const hqRoleState: HqRoleState = {
  loading: false,
  roles: [],
}

const hqRoleReducer = (state = hqRoleState, action: HqRoleAction): HqRoleState => {
  switch (action.type) {
    case SET_HQ_ROLES:
      return { ...state, roles: action.payload.roles }
    case SET_HQ_ROLE_LOADING:
      return { ...state, loading: action.payload.loading }
    default:
      return state
  }
}

export default hqRoleReducer
