import * as DASHBOARD from 'gadjet-v2-types/dist/api/branch/dashboard'

import axios from '@utils/axios'

const DashboardAPI = {
  getDashboardEvents: async (
    { hqId, branchId }: DASHBOARD.GET_EVENT_PARAMS,
    query: DASHBOARD.GET_EVENT_QUERY
  ): Promise<{ data: DASHBOARD.GET_EVENT_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/dashboard/events`, { params: query }),

  getDashboardEventSummary: async (
    { hqId, branchId }: DASHBOARD.GET_EVENT_SUMMARY_PARAMS,
    query: DASHBOARD.GET_EVENT_SUMMARY_QUERY
  ): Promise<{ data: DASHBOARD.GET_EVENT_SUMMARY_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/dashboard/event-summary`, { params: query }),

  getDashboardEventDetail: async ({
    hqId,
    branchId,
    date,
  }: DASHBOARD.GET_EVENT_DETAIL_PARAMS): Promise<{ data: DASHBOARD.GET_EVENT_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/dashboard/events/${date}`),
}

export default DashboardAPI
