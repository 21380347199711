import { Tabs } from 'antd'
import { useCallback } from 'react'
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import NotFoundPage from '@pages/Error/NotFoundPage'

type Props = {
  routes: { path: string; key: string; label: string; component: JSX.Element }[]
}

export default function TabPageTemplate({ routes }: Props): JSX.Element {
  const { url } = useRouteMatch()
  const { pathname } = useLocation()
  const { push } = useHistory()
  const onChangeTab = (_path: string) => push(_path)

  const getPath = useCallback(
    (_path: string) => {
      if (url.endsWith('/')) {
        return `${url}${_path}`
      }
      return `${url}/${_path}`
    },
    [url]
  )
  return (
    <div>
      <Tabs onChange={onChangeTab} activeKey={pathname} type="line">
        {routes.map((r) => (
          <Tabs.TabPane key={getPath(r.path)} tab={r.label} />
        ))}
      </Tabs>
      <Switch>
        {routes.map((r) => (
          <Route exact path={getPath(r.path)} key={r.key}>
            {r.component}
          </Route>
        ))}
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </div>
  )
}
