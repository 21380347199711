import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import BillAPI from '@apis/branch/bill'

import {
  BillAction,
  setBillLoading,
  setBillSearchResult,
  SEARCH_BILLS,
  SET_BILL_DEFAULT,
  SET_BILL_PAGINATION,
  SET_BILL_SEARCH,
  SET_BILL_PAGE_CONFIG,
  setBillSearch,
  setBillPagination,
  searchBills,
  setBillSummary,
} from '@reducers/branch/bill.reducer'
import { RootState } from '@reducers/index'
import { setStorageBill } from '@reducers/storage.reducer'

function* searchBillSaga(action: BillAction): Generator {
  if (action.type !== SEARCH_BILLS) return
  const { hqId, branchId } = action.payload
  yield* put(setBillLoading(true))

  const search = yield* select((state: RootState) => state.bill.search)
  const pagination = yield* select((state: RootState) => state.bill.pagination)

  const {
    data: { bills, total },
  } = yield* call(BillAPI.getBills, { hqId, branchId }, { ...search, ...pagination })

  const { data: summary } = yield* call(BillAPI.getBillSummary, { hqId, branchId }, { ...search })

  yield* put(setBillSummary(summary))
  yield* put(setBillSearchResult({ branchId, bills, total }))

  yield* put(setBillLoading(false))
}

function* savePageConfigSaga(action: BillAction): Generator {
  const state: Parameters<typeof setStorageBill>[0] = {}

  if (action.type === SET_BILL_SEARCH) {
    const { spaceTypes } = action.payload.search
    state.spaceTypes = spaceTypes
  } else if (action.type === SET_BILL_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageBill(state))
}

function* setDefaultPageConfigSaga(action: BillAction): Generator {
  if (action.type !== SET_BILL_DEFAULT) return
  const { pageSize, spaceTypes } = yield* select((state: RootState) => state.storage.billConfig)

  yield* all([put(setBillSearch({ spaceTypes })), put(setBillPagination({ pageSize }))])

  const { hqId, branchId } = action.payload
  yield* put(searchBills(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_BILLS, searchBillSaga),
    takeEvery(SET_BILL_SEARCH, savePageConfigSaga),
    takeEvery(SET_BILL_PAGINATION, savePageConfigSaga),
    takeEvery(SET_BILL_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_BILL_DEFAULT, setDefaultPageConfigSaga),
  ])
}
