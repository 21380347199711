import useModal from '@hooks/useModal'
import { Button, Row } from 'antd'

import ModalAlert from '@components/molecules/ModalAlert'
import ModalAlertItem from '@components/molecules/ModalAlert/Item'

export default function Contractor({ contract }: ContractColumnProps): JSX.Element {
  const { contractor } = contract
  const [modal, onVisible, onClose] = useModal({ visible: false })

  return (
    <>
      <Button onClick={() => onVisible()}>{contractor.name}</Button>
      <ModalAlert title="계약자" visible={modal.visible} onCancel={onClose}>
        <Row gutter={[20, 40]}>
          <ModalAlertItem label="이름" value={contractor.name} span={8} />
          <ModalAlertItem label="연락처" value={contractor.contact} span={8} />
          <ModalAlertItem label="이메일" value={contractor.email} span={8} />
          <ModalAlertItem label="대표자와의 관계" value={contractor.relationshipWithDirector} span={8} />
        </Row>
      </ModalAlert>
    </>
  )
}
