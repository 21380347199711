import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import RentalAPI from '@apis/branch/rental'

import {
  RentalAction,
  setRentalLoading,
  setRentalSearchResult,
  SEARCH_RENTALS,
  SET_RENTAL_DEFAULT,
  SET_RENTAL_PAGINATION,
  SET_RENTAL_SEARCH,
  SET_RENTAL_PAGE_CONFIG,
  setRentalPagination,
  searchRentals,
} from '@reducers/branch/rental.reducer'
import { RootState } from '@reducers/index'
import { setStorageRental } from '@reducers/storage.reducer'

function* searchRentalSaga(action: RentalAction): Generator {
  if (action.type !== SEARCH_RENTALS) return
  const { hqId, branchId } = action.payload
  yield* put(setRentalLoading(true))

  const search = yield* select((state: RootState) => state.rental.search)
  const pagination = yield* select((state: RootState) => state.rental.pagination)

  const {
    data: { rentals, total },
  } = yield* call(RentalAPI.getRentals, { hqId, branchId }, { ...search, ...pagination })

  yield* put(setRentalSearchResult({ branchId, rentals, total }))

  yield* put(setRentalLoading(false))
}

function* savePageConfigSaga(action: RentalAction): Generator {
  const state: Parameters<typeof setStorageRental>[0] = {}

  if (action.type === SET_RENTAL_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageRental(state))
}

function* setDefaultPageConfigSaga(action: RentalAction): Generator {
  if (action.type !== SET_RENTAL_DEFAULT) return
  const { hqId, branchId } = action.payload
  const { pageSize } = yield* select((state: RootState) => state.storage.rentalConfig)

  yield* put(setRentalPagination({ pageSize }))

  yield* put(searchRentals(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_RENTALS, searchRentalSaga),
    takeEvery(SET_RENTAL_SEARCH, savePageConfigSaga),
    takeEvery(SET_RENTAL_PAGINATION, savePageConfigSaga),
    takeEvery(SET_RENTAL_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_RENTAL_DEFAULT, setDefaultPageConfigSaga),
  ])
}
