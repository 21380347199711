import { Input, InputProps, InputRef } from 'antd'
import { ForwardedRef, forwardRef } from 'react'
import styled from 'styled-components'

const AuthInput = forwardRef((props: InputProps, ref: ForwardedRef<InputRef>): JSX.Element => {
  return <CustomInput {...props} ref={ref} />
})

const CustomInput = styled(Input)`
  height: 52px;
  border-radius: 8px;
`
export default AuthInput
