import xlsx from 'xlsx'

const xlsxToJson = async <T = { [key: string]: string }>(file: File): Promise<T[]> =>
  new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader()

      fileReader.onload = () => {
        const workBook = xlsx.read(fileReader.result, { type: 'binary' })

        const sheetName = workBook.SheetNames[0]
        const sheet = workBook.Sheets[sheetName]

        resolve(xlsx.utils.sheet_to_json(sheet, { defval: '' }))
      }

      fileReader.readAsBinaryString(file)
    } catch (err) {
      reject(err)
    }
  })

export default xlsxToJson
