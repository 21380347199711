import Picture from '@pages/Landing/FeaturePages/atoms/Picture'
import TenantIcon from '@pages/Landing/FeaturePages/atoms/TenantIcon'
import * as S from '@pages/Landing/FeaturePages/styles'

export default function SendInvoice(): JSX.Element {
  return (
    <S.SectionInnerWrapper>
      <S.Container
        defaultStyles={{ flexDirection: 'column', alignItems: 'center', height: '520px', background: '#ebfbf2' }}
        mediaStyles={{ height: '500px', position: 'relative' }}
      >
        <S.DescriptionWrapper defaultStyle={{ textAlign: 'center', padding: '40px 0 0 0' }}>
          <S.BoxTitle>청구서 발송</S.BoxTitle>
          <S.Description>
            매달 한 건 한 건 신경 쓸 <S.LineBreakMedia />
            필요 없이 <S.LineBreakPC />
            지정한 날짜에 <S.LineBreakMedia />
            자동으로 발송돼요.
          </S.Description>
        </S.DescriptionWrapper>
        <S.ImageWrapper
          defaultStyle={{ alignItems: 'flex-end', width: '100%' }}
          mediaStyle={{ justifyContent: 'flex-end' }}
        >
          <Picture
            srcPNG="/images/landing/features/invoice/send-invoice.png"
            srcWEBP="/images/landing/features/invoice/send-invoice.webp"
            alt="send-invoice"
            width={836}
            height={354}
            mediaScale={0.9}
          />
        </S.ImageWrapper>
      </S.Container>
      <S.BoxGroup>
        <S.Container
          defaultStyles={{
            flexDirection: 'column',
            width: '100%',
            height: '540px',
            background: 'linear-gradient(90deg, rgba(26, 26, 26, 0.95) 0%, rgba(26, 26, 26, 0.90) 100%)',
            position: 'relative',
          }}
          mediaStyles={{ height: '520px' }}
        >
          <S.DescriptionWrapper defaultStyle={{ padding: '40px 0 0 40px' }}>
            <S.BoxTitle color="#ffffff">온라인 결제</S.BoxTitle>
            <S.Description color="#FFFFFF">
              결제는 앱과 PC에서
              <br />
              간편하고 빠르게
            </S.Description>
            <S.SubDescription color="#FFFFFF">가젯 앱, 이메일 청구서를 통한 바로 결제</S.SubDescription>
          </S.DescriptionWrapper>
          <S.ImageWrapper defaultStyle={{ alignItems: 'cetner' }}>
            <Picture
              srcPNG="/images/landing/features/invoice/online-payment.png"
              srcWEBP="/images/landing/features/invoice/online-payment.webp"
              width={320}
              height={340}
              alt="online-payment"
            />
          </S.ImageWrapper>
          <TenantIcon />
        </S.Container>
        <S.Container
          defaultStyles={{
            flexDirection: 'column',
            width: '100%',
            height: '540px',
            background: 'linear-gradient(95.81deg, #E4F6EC 5.02%, #E2DDFF 99.87%)',
            position: 'relative',
          }}
          mediaStyles={{ height: '520px' }}
        >
          <S.DescriptionWrapper defaultStyle={{ padding: '40px 0 0 40px' }}>
            <S.BoxTitle>카드 결제</S.BoxTitle>
            <S.Description>
              PG사 계약 없이 가능한
              <br />
              카드 결제
            </S.Description>
          </S.DescriptionWrapper>
          <S.ImageWrapper defaultStyle={{ alignItems: 'cetner' }}>
            <Picture
              srcPNG="/images/landing/features/invoice/card-pay.png"
              srcWEBP="/images/landing/features/invoice/card-pay.webp"
              width={248}
              height={182}
              alt="cards"
              defaultStyle={{ margin: '78px 0 0 0' }}
            />
          </S.ImageWrapper>
        </S.Container>
      </S.BoxGroup>
    </S.SectionInnerWrapper>
  )
}
