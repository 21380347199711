import { MoreOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Button, Dropdown, Menu, Table, TableColumnType, TablePaginationConfig, Image } from 'antd'
import { Spaces } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'

import { krw } from '@utils/format'
import { netPriceToVAT } from '@utils/price'

import { BranchSpaceActionType } from '@pages/Branch/SpacePage'

import TableTotalRows from '@components/atoms/TableTotalRows'
import TableWrapper from '@components/atoms/TableWrapper'
import TableColumnItem from '@components/molecules/TableColumnItem'

type Props = {
  loading: boolean
  onChangePagination: (pagination: Pagination) => void
  pagination: Pagination
  spaces: Spaces[]
  total: number
  onAction: (action: BranchSpaceActionType, spaceId: number) => void
  onChangeActiveStatus: (status: boolean, rentalId: number) => void
}
export default function BranchSpaceListTable({
  loading,
  pagination,
  spaces,
  total,
  onChangePagination,
  onAction,
  onChangeActiveStatus,
}: Props): JSX.Element {
  const onChange = (_pagination: TablePaginationConfig) => onChangePagination({ ...pagination, ..._pagination })

  const columns: TableColumnType<Spaces>[] = [
    {
      key: 'imageId',
      dataIndex: 'imageId',
      title: '이미지',
      render: (_, { image }) => <Image src={image?.uri || ''} style={styles.image} alt="space" />,
    },
    { key: 'name', dataIndex: 'name', title: '이름' },
    { key: 'spaceTypeId', dataIndex: 'spaceTypeId', title: '유형', render: (_, { spaceType }) => spaceType?.name },
    {
      key: 'price',
      dataIndex: 'price',
      title: '이용료',
      align: 'right',
      render: (price) => {
        return (
          <>
            <TableColumnItem label="공급가액" value={krw(price)} />
            <TableColumnItem label="부가세" value={krw(netPriceToVAT(price))} />
          </>
        )
      },
    },
    { key: 'capacity', dataIndex: 'capacity', title: '수용인원' },
    { key: 'area', dataIndex: 'area', title: '면적' },
    {
      key: 'spaceId',
      dataIndex: 'spaceId',
      title: '계약내역',
      render: (_, { spaceId }) => <Button onClick={() => onAction('contract', spaceId)}>계약내역</Button>,
    },
    {
      key: 'isActive',
      dataIndex: 'isActive',
      title: '활성 / 비활성',
      align: 'center',
      width: '150px',
      render: (isActive, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="true" onClick={() => onChangeActiveStatus(true, record.spaceId)}>
                활성
              </Menu.Item>
              <Menu.Item key="false" onClick={() => onChangeActiveStatus(false, record.spaceId)}>
                비활성
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button className={isActive ? 'green-btn' : 'red-btn'}>{isActive ? '활성' : '비활성'}</Button>
        </Dropdown>
      ),
    },
    {
      key: 'spaceId',
      dataIndex: 'spaceId',
      title: '',
      width: '80px',
      render: (_, { spaceId }) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="update" onClick={() => onAction('update', spaceId)}>
                수정
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => onAction('delete', spaceId)}>
                삭제
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]

  return (
    <TableWrapper>
      <Table<Spaces>
        loading={loading}
        style={styles.table}
        scroll={{ x: true }}
        rowKey={({ spaceId }) => `${spaceId}`}
        dataSource={spaces}
        columns={columns}
        onChange={onChange}
        pagination={{
          total,
          current: pagination.current,
          pageSize: pagination.pageSize,
          position: ['bottomCenter'],
          showSizeChanger: true,
          showTotal: (total) => <TableTotalRows total={total} />,
        }}
      />
    </TableWrapper>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
  image: { width: '100px', height: '100px', borderRadius: '8px' },
}
