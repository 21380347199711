import * as BRANCH from 'gadjet-v2-types/dist/api/branch'

import axios from '@utils/axios'

const BranchAPI = {
  // 지점 조회
  getBranch: async ({ hqId, branchId }: BRANCH.GET_DETAIL_PARAMS): Promise<{ data: BRANCH.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}`),

  // 지점 수정
  updateBranch: async (
    { hqId, branchId }: BRANCH.UPDATE_PARAMS,
    { branch }: BRANCH.UPDATE_BODY
  ): Promise<{ data: BRANCH.UPDATE_RESPONSE }> => axios.put(`/hqs/${hqId}/branches/${branchId}`, { branch }),

  // 지점 사업자 수정
  updateBranchBusiness: async (
    { hqId, branchId }: BRANCH.UPDATE_BUSINESS_PARAMS,
    { business }: BRANCH.UPDATE_BUSINESS_BODY
  ): Promise<{ data: BRANCH.UPDATE_BUSINESS_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/business`, { business }),

  // 지점 정산 수정
  updateBranchSettlement: async (
    { hqId, branchId }: BRANCH.UPDATE_SETTLEMENT_PARAMS,
    { settlement }: BRANCH.UPDATE_SETTLEMENT_BODY
  ): Promise<{ data: BRANCH.UPDATE_SETTLEMENT_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/settlement`, { settlement }),

  // 지점 계약서 수정
  updateBranchContractDocument: async (
    { hqId, branchId }: BRANCH.UPDATE_CONTRACT_DOCUMENT_PARAMS,
    { contractDocument }: BRANCH.UPDATE_CONTRACT_DOCUMENT_BODY
  ): Promise<{ data: BRANCH.UPDATE_CONTRACT_DOCUMENT_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/contract-document`, { contractDocument }),

  // 지점 전대차 수정
  updateBranchSublet: async (
    { hqId, branchId }: BRANCH.UPDATE_SUBLET_PARAMS,
    { sublet }: BRANCH.UPDATE_SUBLET_BODY
  ): Promise<{ data: BRANCH.UPDATE_SUBLET_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/sublet`, { sublet }),

  // 지점 임대차 수정
  updateBranchRentee: async (
    { hqId, branchId }: BRANCH.UPDATE_RENTEE_PARAMS,
    { rentee }: BRANCH.UPDATE_RENTEE_BODY
  ): Promise<{ data: BRANCH.UPDATE_RENTEE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/rentee`, { rentee }),

  // 지점 자동화 조회
  getBranchAutomation: async ({ hqId, branchId }: BRANCH.GET_AUTOMATION_PARAMS) =>
    axios.get<BRANCH.GET_AUTOMATION_RESPONSE>(`/hqs/${hqId}/branches/${branchId}/automations`),

  // 지점 자동화 수정
  updateBranchAutomation: async (
    { hqId, branchId }: BRANCH.UPDATE_AUTOMATION_PARAMS,
    { automation }: BRANCH.UPDATE_AUTOMATION_BODY
  ): Promise<{ data: BRANCH.UPDATE_AUTOMATION_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/automation`, { automation }),

  // 팝빌 회원가입
  joinPopbill: async (
    { hqId, branchId }: BRANCH.JOIN_POPBILL_PARAMS,
    { form }: BRANCH.JOIN_POPBILL_BODY
  ): Promise<{ data: BRANCH.JOIN_POPBILL_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/popbill`, { form }),

  // 지점 알림 조회
  getBranchNotifications: async (
    { hqId, branchId }: BRANCH.GET_BRANCH_NOTIFICATIONS_PARAMS,
    { current, pageSize }: BRANCH.GET_BRANCH_NOTIFICATIONS_QUERY
  ) =>
    axios.get<BRANCH.GET_BRANCH_NOTIFICATIONS_RESPONSE>(`/hqs/${hqId}/branches/${branchId}/notifications`, {
      params: { current, pageSize },
    }),

  // 가젯서비스이용 결제까지 남은 일자 조회
  // 1 : adminFlag가 false && remainDate <= 0
  // 2 : adminFlag가 true && remainDate <= 0 // 운영자이면서, 결제를 해야하는 경우
  getRemainDate: async ({ hqId, branchId, managerId }: BRANCH.GET_REMAIN_DATE) =>
    axios.get<BRANCH.GET_REMAIN_DATE_RESPONSE>(`/hqs/${hqId}/branches/${branchId}/checkRemainDate/${managerId}`),

  // 유료공지전환 상태 업데이트
  updateChargeNotice: async (
    { hqId, branchId }: BRANCH.UPDATE_CHARGE_NOTICE_PARAMS,
    { chargeNotice, managerId }: BRANCH.UPDATE_CHARGE_NOTICE_BODY
  ) => {
    axios.put<BRANCH.UPDATE_CHARGE_NOTICE_RESPONSE>(`/hqs/${hqId}/branches/${branchId}/chargeNotice`, {
      chargeNotice,
      managerId,
    })
  },
}

export default BranchAPI
