import useModal from '@hooks/useModal'
import { Button, Row } from 'antd'

import ModalAlert from '@components/molecules/ModalAlert'
import ModalAlertItem from '@components/molecules/ModalAlert/Item'

export default function Director({ contract }: ContractColumnProps): JSX.Element {
  const { director } = contract
  const [modal, onVisible, onClose] = useModal({ visible: false })

  return (
    <>
      <Button onClick={() => onVisible()}>{director.name}</Button>
      <ModalAlert title="대표자" visible={modal.visible} onCancel={onClose}>
        <Row gutter={[20, 40]}>
          <ModalAlertItem label="이름" value={director.name} span={8} />
          <ModalAlertItem label="연락처" value={director.contact} span={8} />
          <ModalAlertItem label="이메일" value={director.email} span={8} />
          <ModalAlertItem label="주민번호" value={director.residentNumber} span={8} />
          <ModalAlertItem label="법인번호" value={director.corporationNumber} span={8} />
        </Row>
      </ModalAlert>
    </>
  )
}
