import { Styles } from '@types'
import { Button } from 'antd'
import { ButtonType } from 'antd/lib/button'
import { ReactNode } from 'react'

export type PageTitleAction = {
  label: string
  onClick: () => void
  buttonType?: ButtonType
  color?: string
  disabled?: boolean
  icon?: ReactNode
}

type Props = {
  title: string
  noItemOnTop?: boolean
  actions?: PageTitleAction[]
}
export default function PageTitle({ title, actions = [], noItemOnTop }: Props): JSX.Element {
  return (
    <div style={{ ...styles.div, paddingTop: noItemOnTop ? '0px' : '20px' }}>
      <div style={styles.title}>{title}</div>
      <div style={styles.actionsContainer}>
        {actions.map(({ label, buttonType, disabled, icon, onClick }) => (
          <Button key={label} type={buttonType} onClick={onClick} style={styles.button} disabled={disabled} icon={icon}>
            {label}
          </Button>
        ))}
      </div>
    </div>
  )
}

const styles: Styles = {
  div: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '20px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  title: { fontSize: 22, fontWeight: 600 },
  actionsContainer: { display: 'flex', alignItems: 'center' },
  button: { marginLeft: '10px' },
}
