import { FormItemProps } from 'antd'
import { ChangeEvent } from 'react'

type Mask = 'businessLicense' | 'contact' | 'personalLicense' | 'krw' | 'identityNum'

type MaskProps = {
  getValueFromEvent: (e: ChangeEvent<HTMLInputElement>) => string | number
  getValueProps: (value: string | number) => { value?: string | number }
} & FormItemProps

const mask: Record<Mask, MaskProps> = {
  businessLicense: {
    // maxLength={12}
    getValueFromEvent: (e) =>
      String(e.target.value)
        .replace(/[^0-9]/g, '')
        .trim(),
    getValueProps: (value) =>
      value ? { value: String(value).replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3') } : { value },
  },
  contact: {
    // maxLength={13}
    getValueFromEvent: (e) =>
      String(e.target.value)
        .replace(/[^0-9]/g, '')
        .trim(),
    getValueProps: (value) => {
      if (!value) return { value }

      const _value = String(value)

      if (_value.length === 8) {
        return { value: _value.replace(/(\d{4})(\d{4})/, '$1-$2') }
      }

      const isSeoul = _value.startsWith('02')

      if (isSeoul) return { value: _value.replace(/(\d{2})(\d{3,4})(\d{4})/, '$1-$2-$3') }

      return { value: _value.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3') }
    },
  },
  personalLicense: {
    //  maxLength={14}
    getValueFromEvent: (e) =>
      String(e.target.value)
        .replace(/[^0-9]/g, '')
        .trim(),
    getValueProps: (value) => (value ? { value: String(value).replace(/(\d{6})(\d{7})/, '$1-$2') } : { value }),
  },
  krw: {
    getValueFromEvent: (e) => {
      const number = String(e.target.value)
        .replace(/[^0-9-]/g, '')
        .trim()
      if (number.endsWith('-')) return 0
      return Number(number)
    },
    getValueProps: (value) => (value ? { value: String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',') } : { value }),
  },
  identityNum: {
    getValueFromEvent: (e) =>
      String(e.target.value)
        .replace(/[^0-9]/g, '')
        .trim(),
    getValueProps: (value) => ({ value: String(value) }),
  },
}

export default mask
