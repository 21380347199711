import { Branches, Hqs } from 'gadjet-v2-types/dist/model'

// ActionType
export const GET_MENU = 'menu/GET_MENU' as const
export const GET_MENU_SUCCESS = 'menu/GET_MENU_SUCCESS' as const
export const GET_MENU_FAIL = 'menu/GET_MENU_FAIL' as const
export const SET_MENU = 'menu/SET_MENU' as const

// Action
export const getMenu = (managerId: number) => ({
  type: GET_MENU,
  payload: { managerId },
})
export const getMenuSuccess = (state: Partial<MenuState>) => ({
  type: GET_MENU_SUCCESS,
  payload: { state },
})
export const getMenuFail = () => ({
  type: GET_MENU_FAIL,
  payload: {},
})
export const setMenu = (state: Partial<MenuState>) => ({
  type: SET_MENU,
  payload: { state },
})

export type MenuAction =
  | ReturnType<typeof getMenu>
  | ReturnType<typeof getMenuSuccess>
  | ReturnType<typeof getMenuFail>
  | ReturnType<typeof setMenu>

type MenuState = {
  loading: boolean
  hq: Hqs | null
  branch: Branches | null
  hqs: Hqs[]
  branches: Branches[]
  indexPath: string
}

const menuState: MenuState = {
  loading: true,
  hq: null,
  branch: null,
  hqs: [],
  branches: [],
  indexPath: '',
}

const menuReducer = (state = menuState, action: MenuAction): MenuState => {
  switch (action.type) {
    case GET_MENU:
      return { ...state, loading: true }
    case GET_MENU_SUCCESS:
      return { ...state, loading: false, ...action.payload.state }
    case GET_MENU_FAIL:
      return { ...state, loading: false }
    case SET_MENU:
      return { ...state, ...action.payload.state }
    default:
      return state
  }
}

export default menuReducer
