import { Notices } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { NoticeSearch } from 'gadjet-v2-types/dist/type/search'

import { getDefaultRange } from '@utils/datetime'

// ActionType
export const SEARCH_NOTICES = 'notice/SEARCH_NOTICES' as const
export const SET_NOTICE_DEFAULT = 'notice/SET_DEFAULT' as const
export const SET_NOTICE_LOADING = 'notice/SET_NOTICE_LOADING' as const
export const SET_NOTICE_SEARCH_RESULT = 'notice/SET_NOTICE_SEARCH_RESULT' as const
export const SET_NOTICE_SEARCH = 'notice/SET_NOTICE_SEARCH' as const
export const SET_NOTICE_PAGINATION = 'notice/SET_NOTICE_PAGINATION' as const
export const SET_NOTICE_SUMMARY = 'notice/SET_NOTICE_SUMMARY' as const
export const SET_NOTICE_PAGE_CONFIG = 'notice/SET_NOTICE_PAGE_CONFIG' as const

// Action
export const searchNotices = (hqId: number, branchId: number) => ({
  type: SEARCH_NOTICES,
  payload: { hqId, branchId },
})
export const setNoticeDefault = (hqId: number, branchId: number) => ({
  type: SET_NOTICE_DEFAULT,
  payload: { hqId, branchId },
})
export const setNoticeLoading = (loading: boolean) => ({
  type: SET_NOTICE_LOADING,
  payload: { loading },
})
export const setNoticeSearchResult = (result: Partial<NoticeState['searchResult']>) => ({
  type: SET_NOTICE_SEARCH_RESULT,
  payload: { result },
})
export const setNoticeSearch = (search: Partial<NoticeSearch>) => ({
  type: SET_NOTICE_SEARCH,
  payload: { search },
})
export const setNoticePagination = (pagination: Partial<Pagination>) => ({
  type: SET_NOTICE_PAGINATION,
  payload: { pagination },
})

export type NoticeAction =
  | ReturnType<typeof searchNotices>
  | ReturnType<typeof setNoticeDefault>
  | ReturnType<typeof setNoticeLoading>
  | ReturnType<typeof setNoticeSearchResult>
  | ReturnType<typeof setNoticeSearch>
  | ReturnType<typeof setNoticePagination>

type NoticeState = {
  loading: boolean
  searchResult: { branchId: number; total: number; notices: Omit<Notices, 'content'>[] }
  search: NoticeSearch
  pagination: Pagination
}

const noticeState: NoticeState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, notices: [] },
  search: { query: '', writeDate: getDefaultRange('month', 'datetime') },
  pagination: { current: 1, pageSize: 10 },
}

const noticeReducer = (state = noticeState, action: NoticeAction): NoticeState => {
  switch (action.type) {
    case SET_NOTICE_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_NOTICE_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_NOTICE_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_NOTICE_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    default:
      return state
  }
}

export default noticeReducer
