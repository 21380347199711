import { Button, Form, Input, notification, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Branches, BranchSublets, Files, Images } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'

import mask from '@utils/mask'

import BranchAPI from '@apis/branch'

import HiddenItems from '@components/atoms/Form/HiddenItems'
import UploadButton from '@components/molecules/UploadButton'

type Props = { hqId: number; branchId: number; branch?: Branches; onRefresh: () => void }

export default function BranchSubletForm({ hqId, branchId, branch, onRefresh }: Props): JSX.Element {
  const [form] = useForm<BranchSublets>()
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    const values = await form.validateFields()
    setLoading(true)
    await BranchAPI.updateBranchSublet({ hqId, branchId }, { sublet: values })
    setLoading(false)
    notification.success({ message: '저장되었습니다.' })
    onRefresh()
  }

  const onUploadSignatureImage = ({ imageId }: Images) => form.setFieldsValue({ signatureImageId: imageId })
  const onUploadConsentFile = ({ fileId }: Files) => form.setFieldsValue({ consentFileId: fileId })

  const resetForm = () => {
    form.resetFields()
    if (branch?.sublet) form.setFieldsValue(branch?.sublet)
  }

  useEffect(() => {
    resetForm()
  }, [branch])

  const initialValues: Partial<BranchSublets> = {
    name: '',
    businessNumber: '',
    corporationNumber: '',
    address: '',
    contact: '',
  }

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <HiddenItems names={['signatureImageId', 'consentFileId']} />

      <Form.Item label="전대인 이름" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="전대인 사업자번호" name="businessNumber" {...mask.businessLicense}>
        <Input />
      </Form.Item>
      <Form.Item label="전대인 법인번호" name="corporationNumber" {...mask.personalLicense}>
        <Input />
      </Form.Item>
      <Form.Item label="전대인 주소" name="address">
        <Input />
      </Form.Item>
      <Form.Item label="전대인 연락처" name="contact" {...mask.contact}>
        <Input />
      </Form.Item>

      <Form.Item label="파일" shouldUpdate>
        {({ getFieldValue }) => {
          const signature = getFieldValue('signatureImage')
          const consent = getFieldValue('consentFile')
          return (
            <Space direction="vertical" style={{ width: '100%' }}>
              <UploadButton.Image
                category="signature"
                label="전대인 서명"
                accept="image/*"
                file={signature}
                onUploadDone={onUploadSignatureImage}
              />
              <UploadButton.File
                category="sublet-consent"
                label="전대차 동의서"
                accept=".pdf"
                file={consent}
                onUploadDone={onUploadConsentFile}
              />
            </Space>
          )
        }}
      </Form.Item>

      <Button type="primary" size="large" onClick={onSubmit} loading={loading}>
        저장하기
      </Button>
    </Form>
  )
}
