import { DeleteOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Button, Input, Popconfirm, Switch, Table, TableColumnType, Typography } from 'antd'
import { SpaceTypes } from 'gadjet-v2-types/dist/model'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SpaceTypeAPI from '@apis/branch/spaceType'

import { getBranch } from '@reducers/branch/branch.reducer'
import { RootState } from '@reducers/index'

import Loading from '@components/molecules/Loading'
import ModalAlert from '@components/molecules/ModalAlert'

type Props = {
  hqId: number
  branchId: number
  visible: boolean
  onClose: () => void
}

export default function SpaceTypeListModal({ hqId, branchId, visible, onClose }: Props): JSX.Element {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')

  const spaceTypes = useSelector((state: RootState) => state.branch.spaceTypes || [])

  const onAddSpaceType = async () => {
    if (name) {
      setLoading(true)
      await SpaceTypeAPI.addSpaceType({ hqId, branchId }, { spaceType: { name, virtualFlag: false } })
      getBrach()
    }
  }

  const updateSpaceType = async (spaceTypeId: number, spaceType: SpaceTypes) => {
    setLoading(true)
    await SpaceTypeAPI.updateSpaceType({ hqId, branchId, spaceTypeId }, { spaceType })
    getBrach()
  }

  const deleteSpaceType = async (spaceTypeId: number) => {
    setLoading(true)
    await SpaceTypeAPI.deleteSpaceType({ hqId, branchId, spaceTypeId })
    getBrach()
  }

  const getBrach = () => {
    dispatch(
      getBranch(hqId, branchId, () => {
        setLoading(false)
      })
    )
  }

  const onChangeSpaceType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event?.target.value)
  }

  const columns: TableColumnType<SpaceTypes>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: '이름',
      render: (name: string, s) => (
        <Typography.Text
          editable={{
            onChange: (_name) => updateSpaceType(s.spaceTypeId, { ...s, name: _name }),
          }}
        >
          {name}
        </Typography.Text>
      ),
    },
    {
      key: 'virtualFlag',
      dataIndex: 'virtualFlag',
      title: '비상주',
      width: '80px',
      render: (virtualFlag: boolean, s) => (
        <Switch
          checked={virtualFlag}
          onChange={(_virtualFlag) => updateSpaceType(s.spaceTypeId, { ...s, virtualFlag: _virtualFlag })}
        />
      ),
    },
    {
      key: 'delete',
      dataIndex: 'delete',
      title: '',
      width: '50px',
      render: (_, s) => (
        <Popconfirm
          placement="topRight"
          okText="삭제"
          cancelText="취소"
          title="공간유형이 삭제되면 해당유형의 공간도 삭제됩니다."
          onConfirm={() => deleteSpaceType(s.spaceTypeId)}
        >
          <DeleteOutlined style={styles.delete} />
        </Popconfirm>
      ),
    },
  ]

  return (
    <>
      <ModalAlert title="공간유형" visible={visible} onCancel={onClose}>
        <Loading loading={loading}>
          <Table
            title={() => (
              <div style={styles.title}>
                <Input.Group compact style={styles.add}>
                  <Input onChange={onChangeSpaceType} placeholder="추가할 공간유형을 입력해주세요" />
                  <Button onClick={onAddSpaceType} type="primary">
                    유형 추가
                  </Button>
                </Input.Group>
              </div>
            )}
            dataSource={spaceTypes}
            columns={columns}
          />
        </Loading>
      </ModalAlert>
    </>
  )
}

const styles: Styles = {
  title: { display: 'flex', justifyContent: 'flex-end' },
  add: { display: 'flex', justifyContent: 'flex-end', width: '60%' },
  delete: { cursor: 'pointer' },
}
