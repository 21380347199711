import { Events } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { EventSearch } from 'gadjet-v2-types/dist/type/search'

// ActionType
export const SEARCH_EVENTS = 'event/SEARCH_EVENTS' as const
export const SET_EVENT_DEFAULT = 'event/SET_DEFAULT' as const
export const SET_EVENT_LOADING = 'event/SET_EVENT_LOADING' as const
export const SET_EVENT_SEARCH_RESULT = 'event/SET_EVENT_SEARCH_RESULT' as const
export const SET_EVENT_SEARCH = 'event/SET_EVENT_SEARCH' as const
export const SET_EVENT_PAGINATION = 'event/SET_EVENT_PAGINATION' as const
export const SET_EVENT_SUMMARY = 'event/SET_EVENT_SUMMARY' as const
export const SET_EVENT_PAGE_CONFIG = 'event/SET_EVENT_PAGE_CONFIG' as const

// Action
export const searchEvents = (hqId: number, branchId: number) => ({
  type: SEARCH_EVENTS,
  payload: { hqId, branchId },
})
export const setEventDefault = (hqId: number, branchId: number) => ({
  type: SET_EVENT_DEFAULT,
  payload: { hqId, branchId },
})
export const setEventLoading = (loading: boolean) => ({
  type: SET_EVENT_LOADING,
  payload: { loading },
})
export const setEventSearchResult = (result: Partial<EventState['searchResult']>) => ({
  type: SET_EVENT_SEARCH_RESULT,
  payload: { result },
})
export const setEventSearch = (search: Partial<EventSearch>) => ({
  type: SET_EVENT_SEARCH,
  payload: { search },
})
export const setEventPagination = (pagination: Partial<Pagination>) => ({
  type: SET_EVENT_PAGINATION,
  payload: { pagination },
})

export type EventAction =
  | ReturnType<typeof searchEvents>
  | ReturnType<typeof setEventDefault>
  | ReturnType<typeof setEventLoading>
  | ReturnType<typeof setEventSearchResult>
  | ReturnType<typeof setEventSearch>
  | ReturnType<typeof setEventPagination>

type EventState = {
  loading: boolean
  searchResult: { branchId: number; total: number; events: Omit<Events, 'content'>[] }
  search: EventSearch
  pagination: Pagination
}

const eventState: EventState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, events: [] },
  search: { query: '' },
  pagination: { current: 1, pageSize: 10 },
}

const eventReducer = (state = eventState, action: EventAction): EventState => {
  switch (action.type) {
    case SET_EVENT_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_EVENT_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_EVENT_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_EVENT_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    default:
      return state
  }
}

export default eventReducer
