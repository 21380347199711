import { all, call, put, takeLatest } from 'typed-redux-saga'

import HqBranchAPI from '@apis/hq/branch'

import { GET_HQ_BRANCHES, HqBranchAction, setHqBranches, setHqBranchLoading } from '@reducers/hq/branch.reducer'

function* getHqSaga(action: HqBranchAction): Generator {
  if (action.type !== GET_HQ_BRANCHES) return
  const { hqId } = action.payload
  yield* put(setHqBranchLoading(true))

  const { data } = yield* call(HqBranchAPI.getHqBranches, { hqId })

  yield* put(setHqBranches(data))
  yield* put(setHqBranchLoading(false))
}

export default function* hqBranchSaga(): Generator {
  yield* all([takeLatest(GET_HQ_BRANCHES, getHqSaga)])
}
