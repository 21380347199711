type Props = {
  color: string | undefined
  marginRight?: string
}

export default function DotBadge({ color, marginRight }: Props): JSX.Element {
  return (
    <div
      style={{
        backgroundColor: color || '#3ebd73',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        marginRight: marginRight || '6px',
      }}
    />
  )
}
