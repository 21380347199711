import { ReactComponent as ArrowRightMIcon } from '@assets/Icons/arrow-right-m.svg'
import { BREAKPOINTS, FREE_TRIAL_URL } from '@const'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import BottomFeatureMenu from '@pages/Landing/FeaturePages/organisms/BottomFeatureMenu'
import FAQ from '@pages/Landing/PricingPage/organisms/FAQ'

type Props = {
  title: string
  description: string
  icon: { src: string; alt: string }
  children: ReactNode
}

export default function FeaturePageTemplate({ title, description, icon, children }: Props): JSX.Element {
  return (
    <Background>
      <PageTopSection>
        <InnerWrapper>
          <img src={icon.src} width={100} height={100} alt={icon.alt} />
          <Title>{title}</Title>
          <Description>{description}</Description>
        </InnerWrapper>
        <Link to={{ pathname: FREE_TRIAL_URL }} target="_blank">
          <FreeTrialButton>
            지금 무료로 사용해보기
            <ArrowRightMIcon stroke="#ffffff" />
          </FreeTrialButton>
        </Link>
      </PageTopSection>
      <MainWrapper>
        <Wrapper>{children}</Wrapper>
        <BottomFeatureMenu />
      </MainWrapper>

      <FaqBackgrond>
        <FAQ />
      </FaqBackgrond>
    </Background>
  )
}

const Background = styled.div`
  background: #ffffff;
  width: 100%;
`

const MainWrapper = styled.div`
  @media ${BREAKPOINTS.lg} {
    padding: 0 16px 0px 16px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 200px;
  @media ${BREAKPOINTS.lg} {
    gap: 100px;
  }
`

const PageTopSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  padding: 170px 0px 140px 0px;
  background: linear-gradient(0deg, #ffffff 0%, #f6f7f8 20.31%, #f4f5f7 100%);
  @media ${BREAKPOINTS.lg} {
    gap: 20px;
    padding: 170px 0px 100px 0px;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`
const Title = styled.h1`
  font-weight: 600;
  font-size: 50px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #2e2e2e;

  margin: 0px;
  @media ${BREAKPOINTS.lg} {
    font-size: 32px;
  }
`
const Description = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  letter-spacing: -0.02em;
  margin: 0;
  color: #777d83;
  @media ${BREAKPOINTS.lg} {
    font-size: 18px;
  }
`
const FreeTrialButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 18px 24px 18px 30px;
  height: 64px;
  font-weight: 500;
  font-size: 19px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #ffffff;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    linear-gradient(90deg, #40da80 0.2%, #26a25a 100.2%);
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.16), 0px 12px 16px -6px rgba(24, 39, 75, 0.12);
  border-radius: 32px;
  border: none;
  cursor: pointer;
  :hover {
    background: linear-gradient(90deg, #26a25a 100.2%, #40da80 0.2%);
  }
`

const FaqBackgrond = styled.div`
  background: #f4f5f7;
  padding-top: 120px;
`
