import { Col, Row, Table, TableColumnType, TablePaginationConfig } from 'antd'
import dayjs from 'dayjs'
import { RentalReservations } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'

import TenantAPI from '@apis/branch/tenant'

import Loading from '@components/molecules/Loading'
import ModalAlert from '@components/molecules/ModalAlert'
import TableColumnItem from '@components/molecules/TableColumnItem'

type Props = {
  hqId: number
  branchId: number
  visible: boolean
  tenantId: number
  onDone?: () => void
  onClose: () => void
}

export default function TenantRentalModal({ hqId, branchId, tenantId, visible, onClose, onDone }: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [rentalReservations, setRentalReservations] = useState<RentalReservations[]>([])
  const [pagination, setPagination] = useState<{ current: number; pageSize: number; total: number }>({
    current: 1,
    pageSize: 10,
    total: 0,
  })

  const columns: TableColumnType<RentalReservations>[] = [
    {
      key: 'rental',
      dataIndex: 'rental',
      title: '대여/대관',
      render: (_, { rental }) => rental?.name || '-',
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: '날짜/시간',
      render: (_, { date, startTime, endTime }) => (
        <>
          <TableColumnItem label="날짜" value={dayjs(date).format('YYYY년 MM월 DD일')} />
          <TableColumnItem label="시간" value={`${startTime} ~ ${endTime}`} />
        </>
      ),
    },
    { key: 'memo', dataIndex: 'memo', title: '메모', render: (_, { memo }) => memo },
    { key: 'user', dataIndex: 'user', title: '예약자', render: (_, { user }) => user?.name || '-' },
    {
      key: 'usedCreditAmount',
      dataIndex: 'usedCreditAmount',
      title: '크레딧 사용량',
      render: (_, { usedCreditAmount: { buy = 0, mileage = 0, contract = 0 } }) => (
        <Row>
          <Col span={8}>
            <TableColumnItem label="계약" value={contract} />
          </Col>
          <Col span={8}>
            <TableColumnItem label="마일리지" value={mileage} />
          </Col>
          <Col span={8}>
            <TableColumnItem label="구매" value={buy} />
          </Col>
        </Row>
      ),
    },
  ]

  const onChange = (_pagination: TablePaginationConfig) => {
    const { current = 1, pageSize = 10 } = _pagination
    setPagination({ ...pagination, current, pageSize })
  }

  const getTenantRentalResevations = async () => {
    setLoading(true)
    const { data } = await TenantAPI.getTenantRentalReservations(
      { hqId, branchId, tenantId },
      { query: '', current: pagination.current, pageSize: pagination.pageSize }
    )

    setRentalReservations(data.rentalReservations)
    setPagination({ ...pagination, total: data.total })
    setLoading(false)
  }

  useEffect(() => {
    getTenantRentalResevations()
  }, [pagination.current, pagination.pageSize])

  useEffect(() => {
    if (visible) {
      setRentalReservations([])
      getTenantRentalResevations()
    }
  }, [visible])
  return (
    <ModalAlert title="입주사 대여/대관" visible={visible} onCancel={onClose} width="1000px">
      <Loading loading={loading}>
        <Table
          dataSource={rentalReservations}
          columns={columns}
          pagination={pagination}
          onChange={onChange}
          size="small"
        />
      </Loading>
    </ModalAlert>
  )
}
