import { notification } from 'antd'

import ServiceAPI from '@apis/branch/service'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  serviceId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function ServiceDeleteModal({
  hqId,
  branchId,
  serviceId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    if (!serviceId) return
    await ServiceAPI.deleteService({ hqId, branchId, serviceId })
    notification.success({ message: '삭제되었습니다.' })
  }

  return (
    <ConfirmModal
      visible={visible}
      title="서비스가 목록에서 삭제됩니다."
      description="서비스를 삭제하면 해당 서비스의 구매내역도 삭제됩니다."
      okText="삭제"
      onClose={onClose}
      onDone={onDone}
      onConfirm={onConfirm}
    />
  )
}
