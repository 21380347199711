type Config = {
  IS_DEV: boolean
  API_HOST: string
  API_HOST_DEV: string
  FCM_VAPID_KEY: string
}

const config: Config = {
  IS_DEV: process.env.NODE_ENV === 'development',
  API_HOST: 'https://api-dot-gadjet-v2.du.r.appspot.com/api/v2',
  // API_HOST_DEV: 'https://api-dev-dot-gadjet-v2.du.r.appspot.com/api/v2',
  API_HOST_DEV: 'http://localhost:8085/api/v2', // local api server 사용
  FCM_VAPID_KEY: 'BJHLeQc6lZGYVHYJoxnMU1r2KF7_NXer72Vt-9quciXzwPoWWNU4P9QCWoY70m4zryUhuZ9KFNY8OEzP_Pcq_K4',
}

export default config
