import { BIZ_BREAKPOINTS, GADJET_BIZ_COLORS } from '@const'
import { useState } from 'react'
import styled from 'styled-components'

import ContactFormModal from '../../organisms/ContactFormModal'

export default function Header() {
  const [openFormModal, setOpenFormModal] = useState(false)
  return (
    <Background>
      <TitleWrapper>
        <HeadTitle>
          가젯 비즈니스 솔루션,
          <br />
          부동산에 집중한 전문 운영관리 솔루션입니다
        </HeadTitle>
        <SubTitle>
          부동산 경영 7년의 노하우와 인사이트가
          <br />
          당신의 부동산 비즈니스의 성공에 함께합니다
        </SubTitle>
        <ContactButton onClick={() => setOpenFormModal(true)}>{`도입문의 >`}</ContactButton>
      </TitleWrapper>
      <ContactFormModal visible={openFormModal} onCloseModal={() => setOpenFormModal(false)} />
    </Background>
  )
}

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${GADJET_BIZ_COLORS.background};
  padding: 170px 16px 80px 16px;
  width: 100%;

  @media ${BIZ_BREAKPOINTS.lg} {
    padding: 140px 16px 70px 16px;
  }
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 26px;
  width: 100%;
  max-width: 1000px;
  text-align: center;
  color: ${GADJET_BIZ_COLORS.textBlack};
  @media ${BIZ_BREAKPOINTS.lg} {
    gap: 20px;
  }
`

const HeadTitle = styled.h1`
  font-weight: 700;
  font-size: 45px;
  line-height: 140%;
  margin-bottom: 0;

  @media ${BIZ_BREAKPOINTS.lg} {
    font-size: 30px;
  }
`

const SubTitle = styled.h4`
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 0;

  @media ${BIZ_BREAKPOINTS.lg} {
    font-size: 16px;
  }
`

const ContactButton = styled.button`
  width: fit-content;
  padding: 17px 33px;
  border: none;
  border-radius: 16px;
  background: rgba(0, 11, 67, 0.9);
  box-shadow: 0px 4px 20px 0px rgba(0, 11, 67, 0.25);

  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
  cursor: pointer;

  @media ${BIZ_BREAKPOINTS.lg} {
    font-size: 16px;
  }
`
