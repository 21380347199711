import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { ServiceSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ServiceAPI from '@apis/branch/service'

import {
  setServicePagination,
  setServiceSearch,
  searchServices,
  setServiceDefault,
  setServiceLoading,
} from '@reducers/branch/service.reducer'
import { RootState } from '@reducers/index'

import BranchServiceListTable from '@components/organisms/Branch/Service/ListTable'
import ServiceFormModal from '@components/organisms/Branch/Service/Modals/ServiceFormModal'
import ServiceDeleteModal from '@components/organisms/Branch/Service/Modals/ServiceFormModal/ServiceDeleteModal'
import BranchServiceSearchBox from '@components/organisms/Branch/Service/SearchBox'
import BranchServiceTitle from '@components/organisms/Branch/Service/Title'

export type BranchServiceAction = 'add' | 'update' | 'delete'

export default function ServicePage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const { loading, search, pagination, searchResult } = useSelector((state: RootState) => state.service)

  const [formModal, onVisibleFormModal, onCloseFormModal] = useModal<{ serviceId?: number }>({
    visible: false,
    serviceId: undefined,
  })

  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal<{ serviceId?: number }>({
    visible: false,
    serviceId: undefined,
  })

  const onChangeSearch = (_search: Partial<ServiceSearch>) => dispatch(setServiceSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setServicePagination(_pagination))
    dispatch(searchServices(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setServicePagination({ current: 1 }))
    dispatch(searchServices(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setServiceDefault(hqId, branchId)), [hqId, branchId])
  const onDone = () => onResetAndSearch()

  const onAction = (action: BranchServiceAction, serviceId?: number) => {
    if (action === 'add') onVisibleFormModal({ serviceId: undefined })
    if (action === 'update') onVisibleFormModal({ serviceId })
    if (action === 'delete') onVisibleDeleteModal({ serviceId })
  }

  const refreshPage = () => {
    dispatch(setServicePagination(pagination))
    dispatch(searchServices(hqId, branchId))
  }

  const onChangeActiveStatus = async (isActive: boolean, serviceId: number) => {
    dispatch(setServiceLoading(true))
    await ServiceAPI.updateService({ hqId, branchId, serviceId }, { service: { isActive } })
    refreshPage()
  }

  useEffect(() => {
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchServiceSearchBox search={search} onChangeSearch={onChangeSearch} onSearch={onSearch} />

      <BranchServiceTitle onAction={onAction} />

      <BranchServiceListTable
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        services={searchResult.services}
        total={searchResult.total}
        onAction={onAction}
        onChangeActiveStatus={onChangeActiveStatus}
      />

      <ServiceFormModal
        hqId={hqId}
        branchId={branchId}
        serviceId={formModal.serviceId}
        visible={formModal.visible}
        onClose={onCloseFormModal}
        onDone={onDone}
      />
      <ServiceDeleteModal
        hqId={hqId}
        branchId={branchId}
        serviceId={deleteModal.serviceId}
        visible={deleteModal.visible}
        onClose={onCloseDeleteModal}
        onDone={onDone}
      />
    </div>
  )
}
