import { Styles } from '@types'
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { ContractDirector, ContractTenant } from 'gadjet-v2-types/dist/type'

import formRule from '@utils/formRule'

import FormListTable, { FormListTableColumn } from '@components/molecules/FormListTable'

type Props = { form: FormInstance<Contracts>; isUpdate: boolean }

export default function AlertTab({ form, isUpdate }: Props): JSX.Element {
  const getTenant = () => {
    const tenant: ContractTenant = form.getFieldValue('tenant')
    const { name, email } = tenant

    form.setFieldsValue({
      receiver: {
        bill: [{ type: 'email', value: email }],
        invoice: [{ name, email }],
      },
    })
  }

  const getDirector = () => {
    const director: ContractDirector = form.getFieldValue('director')
    const { name, email } = director

    form.setFieldsValue({
      receiver: {
        bill: [{ type: 'email', value: email }],
        invoice: [{ name, email }],
      },
    })
  }

  const billColumns: FormListTableColumn[] = [
    {
      label: '타입',
      width: '25%',
      render: (field, index) => (
        <Form.Item isListField name={[field.name, 'type']} initialValue="sms">
          <Select style={styles.select}>
            <Select.Option value="sms">문자</Select.Option>
            <Select.Option value="email">이메일</Select.Option>
          </Select>
        </Form.Item>
      ),
    },

    {
      label: '값',
      width: '65%',
      render: (field, index) => (
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const billReceivers = getFieldValue(['receiver', 'bill']) || []
            const { type } = billReceivers[index] || { type: 'sms' }
            const rules = [formRule.required]
            if (type === 'sms') rules.push(formRule.contact)
            if (type === 'email') rules.push(formRule.email)
            return (
              <Form.Item isListField name={[field.name, 'value']} rules={rules} initialValue="">
                <Input />
              </Form.Item>
            )
          }}
        </Form.Item>
      ),
    },
  ]

  const inoviceColumns: FormListTableColumn[] = [
    {
      label: '담당자 이름',
      width: '25%',
      render: (field) => (
        <Form.Item isListField name={[field.name, 'name']} initialValue="">
          <Input />
        </Form.Item>
      ),
    },
    {
      label: '담당자 이메일',
      width: '65%',
      render: (field) => (
        <Form.Item isListField name={[field.name, 'email']} initialValue="" rules={[formRule.email]}>
          <Input />
        </Form.Item>
      ),
    },
  ]

  return (
    <>
      <Form.Item noStyle>
        <Row gutter={10}>
          <Col>
            <Button type="default" size="small" onClick={getTenant}>
              입주사 정보 가져오기
            </Button>
          </Col>
          <Col>
            <Button type="default" size="small" onClick={getDirector}>
              대표자 정보 가져오기
            </Button>
          </Col>
        </Row>
      </Form.Item>
      <FormListTable title="청구서 알림" name={['receiver', 'bill']} columns={billColumns} />
      <div style={styles.spacer} />
      <FormListTable
        title="증빙 알림"
        subTitle="세금계산서, 현금영수증 알림 입니다."
        name={['receiver', 'invoice']}
        columns={inoviceColumns}
      />
    </>
  )
}

const styles: Styles = {
  select: { width: '100%' },
  spacer: { height: '100px' },
}
