import { Input } from 'antd'
import { InputRef, PasswordProps } from 'antd/lib/input'
import { ForwardedRef, forwardRef } from 'react'
import styled from 'styled-components'

const { Password } = Input

const AuthInputPassword = forwardRef((props: PasswordProps, ref: ForwardedRef<InputRef>): JSX.Element => {
  return <CustomInput {...props} ref={ref} />
})

const CustomInput = styled(Password)`
  height: 52px;
  border-radius: 8px;
`
export default AuthInputPassword
