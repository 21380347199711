import * as ACCESS_LOG from 'gadjet-v2-types/dist/api/branch/access-log'

import axios from '@utils/axios'

const AccessLogAPI = {
  // 접근기록 조회
  getAccessLogs: async (
    { hqId, branchId }: ACCESS_LOG.GET_LIST_PARAMS,
    query: ACCESS_LOG.GET_LIST_QUERY
  ): Promise<{ data: ACCESS_LOG.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/access-logs`, { params: query }),

  // 접근기록 추가
  addAccessLog: async (
    { branchId, hqId }: ACCESS_LOG.ADD_PARAMS,
    { accessLog }: ACCESS_LOG.ADD_BODY
  ): Promise<{ data: ACCESS_LOG.ADD_RESPONSE }> => axios.post(`/hqs/${hqId}/branches/${branchId}/`, { accessLog }),
}

export default AccessLogAPI
