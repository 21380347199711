import { Tag } from 'antd'
import { CSSProperties, useMemo } from 'react'

type Type = 'default' | 'ongoing' | 'success' | 'fail'

type Props<T> = {
  label: string
  value?: T
  switchCase?: {
    values: T[][]
    types: Type[]
  }
  type?: Type
  style?: CSSProperties
}

export default function TypeTag<T = string>({
  label,
  type = 'default',
  value,
  switchCase,
  style,
}: Props<T>): JSX.Element {
  const color: string = useMemo(() => {
    const colors: Record<Type, string> = {
      default: 'default',
      ongoing: 'yellow',
      success: 'green',
      fail: 'red',
    }
    if (!switchCase || !value || !switchCase) return colors[type]

    const { values, types } = switchCase
    const index = values.findIndex((arr) => arr.includes(value))
    return colors[types[index]]
  }, [type, switchCase])

  return (
    <Tag style={style} color={color}>
      {label}
    </Tag>
  )
}
