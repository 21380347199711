import { Form, Modal, notification, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useEffect, useState } from 'react'

import TenantAPI from '@apis/branch/tenant'

import Loading from '@components/molecules/Loading'

type Props = {
  visible: boolean
  onClose: () => void
  hqId: number
  branchId: number
  tenantId: number
}

export default function InviteMemberModal({ visible, onClose, hqId, branchId, tenantId }: Props): JSX.Element {
  const [form] = useForm<{ phone: string[] }>()
  const [loading, setLoading] = useState(false)

  const onOk = async () => {
    try {
      const { phone } = await form.validateFields()
      setLoading(true)
      // api phone
      await TenantAPI.inviteTenantMembers({ hqId, branchId, tenantId }, { phone })
      notification.success({ message: '초대문자가 발송되었습니다.' })
      setLoading(false)
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const reset = () => {
    form.resetFields()
    setLoading(false)
  }

  useEffect(() => {
    if (visible) reset()
  }, [visible])

  return (
    <Modal
      title="입주사 멤버 초대"
      visible={visible}
      onCancel={onClose}
      onOk={onOk}
      okText="초대하기"
      okButtonProps={{ loading }}
    >
      <Loading loading={loading}>
        <Form form={form} layout="vertical">
          <Form.Item
            label="전화번호"
            name="phone"
            required
            extra="전화번호 입력 후 엔터 / 숫자만 입력"
            getValueFromEvent={(value: string[]) => value.map((str) => str.replace(/\D+/g, '')).filter((v) => v)}
            rules={[{ required: true, type: 'array', min: 1, message: '하나 이상의 전화번호를 입력하세요.' }]}
          >
            <Select mode="tags" />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}
