import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { ServiceSaleSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  setServiceSalePagination,
  setServiceSaleSearch,
  searchServiceSales,
  setServiceSaleDefault,
} from '@reducers/branch/service-sale.reducer'
import { RootState } from '@reducers/index'

import BranchServiceSaleListTable from '@components/organisms/Branch/ServiceSale/ListTable'
import ServiceSaleExportModal from '@components/organisms/Branch/ServiceSale/Modals/ServiceSaleExportModal'
import ServiceSaleRefundModal from '@components/organisms/Branch/ServiceSale/Modals/ServiceSaleRefundModal'
import BranchServiceSaleSearchBox from '@components/organisms/Branch/ServiceSale/SearchBox'
import BranchServiceSaleTitle from '@components/organisms/Branch/ServiceSale/Title'

export type BranchServiceSaleAction = 'export' | 'refund'

export default function ServiceSalePage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const { loading, search, pagination, searchResult, service } = useSelector((state: RootState) => state.serviceSale)

  const [exportModal, onVisibleExportModal, onCloseExportModal] = useModal({ visible: false })
  const [refundModal, onVisibleRefundModal, onCloseRefundModal] = useModal<{ serviceSaleId?: number }>({
    visible: false,
    serviceSaleId: undefined,
  })

  const onChangeSearch = (_search: Partial<ServiceSaleSearch>) => dispatch(setServiceSaleSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setServiceSalePagination(_pagination))
    dispatch(searchServiceSales(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setServiceSalePagination({ current: 1 }))
    dispatch(searchServiceSales(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setServiceSaleDefault(hqId, branchId)), [hqId, branchId])
  const onDone = () => onResetAndSearch()

  const onAction = (action: BranchServiceSaleAction, serviceSaleId?: number) => {
    if (action === 'export') onVisibleExportModal()
    if (action === 'refund') onVisibleRefundModal({ serviceSaleId })
  }

  useEffect(() => {
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchServiceSaleSearchBox
        branchId={branchId}
        search={search}
        service={service}
        onChangeSearch={onChangeSearch}
        onSearch={onSearch}
      />

      <BranchServiceSaleTitle onAction={onAction} />

      <BranchServiceSaleListTable
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        serviceSales={searchResult.serviceSales}
        total={searchResult.total}
        onAction={onAction}
      />

      <ServiceSaleRefundModal
        hqId={hqId}
        branchId={branchId}
        serviceSaleId={refundModal.serviceSaleId}
        visible={refundModal.visible}
        onClose={onCloseRefundModal}
        onDone={onDone}
      />
      <ServiceSaleExportModal
        hqId={hqId}
        branchId={branchId}
        visible={exportModal.visible}
        onClose={onCloseExportModal}
        onDone={onDone}
      />
    </div>
  )
}
