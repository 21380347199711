import { Form, FormInstance, Input } from 'antd'
import { valueType } from 'antd/lib/statistic/utils'
import { Bills } from 'gadjet-v2-types/dist/model'
import { BillPrice } from 'gadjet-v2-types/dist/type'

import InputPrice from '@components/atoms/InputPrice'
import FormListTable, { FormListTableColumn } from '@components/molecules/FormListTable'

type Props = {
  label: string
  name: 'spaces' | 'additions' | 'surcharges'
  form: FormInstance<Bills>
}

export default function BillItemList({ label, name, form }: Props): JSX.Element {
  const onChangePrice = (value: valueType | null, index: number) => {
    const vat = Math.floor(Number(value) / 10)

    const values = form.getFieldsValue()
    const fieldValue = [...values[name]]
    fieldValue[index].vat = vat

    form.setFieldsValue({ [name]: fieldValue })
  }

  const getValueFromEvent = (value: number) => value || 0

  const columns: FormListTableColumn[] = [
    {
      label: '이름',
      width: '30%',
      render: (field) => (
        <Form.Item name={[field.name, 'name']} noStyle>
          <Input />
        </Form.Item>
      ),
    },
    {
      label: '가격',
      width: '35%',
      render: (field, index) => (
        <Form.Item name={[field.name, 'price']} noStyle getValueFromEvent={getValueFromEvent}>
          <InputPrice onChange={(value) => onChangePrice(value, index)} />
        </Form.Item>
      ),
    },
    {
      label: '부가세',
      width: '35%',
      render: (field) => (
        <Form.Item name={[field.name, 'vat']} noStyle getValueFromEvent={getValueFromEvent}>
          <InputPrice />
        </Form.Item>
      ),
    },
  ]

  const defaultValue: Partial<BillPrice> = { name: '', price: 0, vat: 0 }

  return <FormListTable title={label} name={name} columns={columns} defaultValue={defaultValue} />
}
