import { BREAKPOINTS } from '@const'
import { ReactNode } from 'react'
import styled from 'styled-components'

import KeyFeatureButton from '../../atoms/KeyFeatureButton'
import SectionTitle from '../../atoms/SectionTitle'

export default function KeyFeatures(): JSX.Element {
  const keyFeatures: { key: string; text: string | ReactNode; path: string; icon: { src: string; alt: string } }[] = [
    {
      key: 'contract',
      text: (
        <span>
          쉽고 효율적인 <br />
          <Bold>계약 체결 • 계약 관리</Bold>
        </span>
      ),
      path: '/features/contracts',
      icon: { src: '/images/landing/features/chart.png', alt: 'chart' },
    },
    {
      key: 'invoice',
      text: (
        <span>
          관리시간을 확 줄여주는
          <br />
          <Bold>청구 • 증빙 자동화</Bold>
        </span>
      ),
      path: '/features/invoices',
      icon: { src: '/images/landing/features/funnel.png', alt: 'funnel' },
    },
    {
      key: 'space',
      text: (
        <span>
          복잡할수록 단순하게
          <br />
          <Bold>공간 • 입주사 관리</Bold>
        </span>
      ),
      path: '/features/spaces',
      icon: { src: '/images/landing/features/diagram.png', alt: 'diagram' },
    },
    {
      key: 'rental',
      text: (
        <span>
          빠르고 간편한
          <br />
          <Bold>대관 • 서비스 관리</Bold>
        </span>
      ),
      path: '/features/stores',
      icon: { src: '/images/landing/features/calendar.png', alt: 'calendar' },
    },
  ]
  return (
    <Wrapper>
      <SectionTitle
        text={
          <span>
            가젯으로 쉽게 할 수 있는 일들,
            <br />더 보여드릴게요
          </span>
        }
      />
      <KeyFeatureList>
        {keyFeatures.map(({ key, text, path, icon }) => (
          <li key={key}>
            <KeyFeatureButton text={text} icon={icon} path={path} />
          </li>
        ))}
      </KeyFeatureList>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 200px;
  @media ${BREAKPOINTS.md} {
    padding-bottom: 100px;
  }
`
const KeyFeatureList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  max-width: 772px;
  list-style: none;
  margin: 0;
  padding: 0;
  @media ${BREAKPOINTS.lg} {
    flex-direction: column;
  }
`
const Bold = styled.b`
  font-weight: 500;
`
