import { BREAKPOINTS } from '@const'
import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  background: { src: string; width: number; height: number }
  icon: { src: string; alt: string }
  text: string | ReactNode
  mobileBackground: string
}

export default function BalloonGreen({ icon, text, background, mobileBackground }: Props): JSX.Element {
  return (
    <Balloon>
      <InnerWrapper>
        <Text>{text}</Text>
        <Icon src={icon.src} alt={icon.alt} />
      </InnerWrapper>
      <Background src={background.src} alt="text-balloon-green" width={background.width} height={background.height} />
      <MobileBackground src={mobileBackground} alt="text-balloon-green" width={330} height={125} />
    </Balloon>
  )
}

const Balloon = styled.div`
  position: relative;
  align-self: flex-end;
`

const Background = styled.img`
  filter: drop-shadow(0px 6px 12px rgba(36, 187, 99, 0.12)) drop-shadow(0px 8px 24px rgba(36, 187, 99, 0.08));
  @media ${BREAKPOINTS.lg} {
    display: none;
  }
`

const MobileBackground = styled.img`
  display: none;
  @media ${BREAKPOINTS.lg} {
    display: block;

    filter: drop-shadow(0px 6px 12px rgba(36, 187, 99, 0.12)) drop-shadow(0px 8px 24px rgba(36, 187, 99, 0.08));
  }
`

const Icon = styled.img`
  width: 90px;
  height: 90px;
  filter: drop-shadow(0px 6px 12px -6px rgba(24, 39, 75, 0.12)) drop-shadow(0px 8px 24px -4px rgba(24, 39, 75, 0.08));
  @media ${BREAKPOINTS.lg} {
    width: 64px;
    height: 64px;
  }
`

const Text = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #2e2e2e;
  @media ${BREAKPOINTS.lg} {
    font-size: 20px;
  }
`

const InnerWrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin: 22px 22px 0px 30px;
  @media ${BREAKPOINTS.lg} {
    width: calc(100% - 40px);
    margin: 20px;
    gap: 0px;
    display: flex;
    justify-content: space-between;
  }
`
