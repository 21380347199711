import { Role } from 'gadjet-v2-types/dist/type'

export const VISIBLE_ROLES: (Role | number)[] = [1, 2]
export const TOKEN_KEY = 'GADJET_TOKEN'
export const TOKEN_TYPE = 'Bearer' as const

export const COLORS = {
  success: '#52c41a',
  warn: '#faad14',
  error: '#ff4d4f',
  primary: '#3ebd73',
  active: '#F0FCF1',
  secondary: '#d9d9d9',

  calendarEvents: [
    { backgroundColor: '#AD1457', color: '#FFFFFF' },
    { backgroundColor: '#F4511E', color: '#FFFFFF' },
    { backgroundColor: '#E4C441', color: '#FFFFFF' },
    { backgroundColor: '#0B8043', color: '#FFFFFF' },
    { backgroundColor: '#3F51B5', color: '#FFFFFF' },
    { backgroundColor: '#8E24AA', color: '#FFFFFF' },
    { backgroundColor: '#D81B60', color: '#FFFFFF' },
    { backgroundColor: '#EF6C00', color: '#FFFFFF' },
    { backgroundColor: '#C0CA33', color: '#FFFFFF' },
    { backgroundColor: '#009688', color: '#FFFFFF' },
    { backgroundColor: '#7986CB', color: '#FFFFFF' },
    { backgroundColor: '#795548', color: '#FFFFFF' },
    { backgroundColor: '#D50000', color: '#FFFFFF' },
    { backgroundColor: '#F09300', color: '#FFFFFF' },
    { backgroundColor: '#7CB342', color: '#FFFFFF' },
    { backgroundColor: '#039BE5', color: '#FFFFFF' },
    { backgroundColor: '#B39DDB', color: '#FFFFFF' },
    { backgroundColor: '#616161', color: '#FFFFFF' },
    { backgroundColor: '#E67C73', color: '#FFFFFF' },
    { backgroundColor: '#F6BF26', color: '#FFFFFF' },
    { backgroundColor: '#33B679', color: '#FFFFFF' },
    { backgroundColor: '#4285F4', color: '#FFFFFF' },
    { backgroundColor: '#9E69AF', color: '#FFFFFF' },
    { backgroundColor: '#A79B8E', color: '#FFFFFF' },
  ],
}

export const GADJET_BIZ_COLORS: { background: '#F1F3F7'; primary: '#182255'; boxShadow: string; textBlack: '#0D0D0D' } =
  {
    background: '#F1F3F7',
    primary: '#182255',
    boxShadow: '8px 8px 80px 0px rgba(24, 34, 85, 0.40)',
    textBlack: '#0D0D0D',
  }

export const BREAKPOINTS: {
  lg: '(max-width: 1100px)'
  md: '(max-width: 768px)'
  sm: '(max-width: 430px)'
  xs: '(max-width: 375px)'
} = {
  lg: '(max-width: 1100px)',
  md: '(max-width: 768px)',
  sm: '(max-width: 430px)',
  xs: '(max-width: 375px)',
}

export const BIZ_BREAKPOINTS: {
  lg: '(max-width: 1199px)'
} = {
  lg: '(max-width: 1199px)',
}

export const FREE_TRIAL_URL = 'https://tally.so/r/nGed8p'
export const BOOK_DEMO_URL = 'https://whattime.co.kr/gadjet/howtouse'
export const TERMS_OF_SERVICE_URL = 'https://www.notion.so/gadjet/fe1a92e5a0274b3490a5643ac0047c2e?pvs=4'
export const PRIVACY_POLICY_URL = 'https://gadjet.notion.site/22-05-23-c8524f3fc1c741faab9a55a680a55bc2'
export const CHANNEL_TALK_URL = 'https://gadjet.channel.io/'
