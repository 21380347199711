import { ReactComponent as ChevronDownSmIcon } from '@assets/Icons/chevron-down-sm.svg'
import { ReactComponent as EllipsisStrokeIcon } from '@assets/Icons/ellipsis-stroke.svg'
import { Button } from 'antd'
import { RentalReservationSearch } from 'gadjet-v2-types/dist/type/search'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import RentalAPI from '@apis/branch/rental'
import branchQueryKey from '@apis/queryKey/branch'

import NoRentals from '@components/molecules/NoItems/NoRentals'
import RentalSelectBox from '@components/molecules/RentalSelectBox'

type Props = {
  hqId: number
  branchId: number
  search: RentalReservationSearch
  onChangeSearch: (search: Partial<RentalReservationSearch>) => void
  onSearch: () => void
}

export default function CalendarRentals({ hqId, branchId, search, onChangeSearch, onSearch }: Props): JSX.Element {
  const [showChevronDown, setShowChevronDown] = useState(false)

  const { isLoading, data } = useQuery(
    branchQueryKey.getBranchRentals(branchId),
    () => RentalAPI.getRentals({ hqId, branchId }, { current: 1, pageSize: 500, query: '' }),
    { refetchOnWindowFocus: false }
  )

  const rentals = useMemo(() => {
    if (!data) return []

    return data.data.rentals.map(({ rentalId, name, colorInfo }) => ({
      label: name,
      value: rentalId,
      color: colorInfo,
    }))
  }, [data, showChevronDown])

  const onChange = (_rentalIds: number[]) => {
    onChangeSearch({ rentalIds: _rentalIds })
    onSearch()
  }

  return (
    <CalendarRentalsWrapper showChevronDown={showChevronDown}>
      {rentals.length === 0 ? (
        <NoRentals hqId={hqId} branchId={branchId} />
      ) : (
        <>
          <RentalSelectBox onChange={onChange} rentals={rentals} showChevronDown={showChevronDown} />
          <div
            style={{
              width: '10%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              icon={
                showChevronDown ? (
                  <ChevronDownSmIcon stroke="#2B2B2B" />
                ) : (
                  <EllipsisStrokeIcon width="24" height="24" stroke="#2B2B2B" />
                )
              }
              size="middle"
              type="text"
              onClick={() => {
                setShowChevronDown(!showChevronDown)
              }}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            />
          </div>
        </>
      )}
    </CalendarRentalsWrapper>
  )
}

const CalendarRentalsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({ showChevronDown }: { showChevronDown: boolean }) => (showChevronDown ? 'calc(100% - 10px)' : '100%')};
  min-height: 120px;
  max-height: 100%;
  height: ${({ showChevronDown }: { showChevronDown: boolean }) => (showChevronDown ? 'max-content' : '120px')};
  position: ${({ showChevronDown }: { showChevronDown: boolean }) => (showChevronDown ? 'absolute' : 'static')};
  bottom: 0;
  background-color: white;
  border-radius: 16px;
  padding: 20px 16px;
  box-shadow: ${({ showChevronDown }: { showChevronDown: boolean }) =>
    showChevronDown ? '0px 4px 40px rgba(0, 0, 0, 0.25)' : '0'};
`
