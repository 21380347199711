import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { EventSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setEventPagination, setEventSearch, searchEvents, setEventDefault } from '@reducers/branch/event.reducer'
import { RootState } from '@reducers/index'

import BranchEventListTable from '@components/organisms/Branch/Event/ListTable'
import EventAttendeeModal from '@components/organisms/Branch/Event/Modals/EventAttendeeModal'
import EventDeleteModal from '@components/organisms/Branch/Event/Modals/EventDeleteModal'
import EventFormModal from '@components/organisms/Branch/Event/Modals/EventFormModal'
import BranchEventSearchBox from '@components/organisms/Branch/Event/SearchBox'
import BranchEventTitle from '@components/organisms/Branch/Event/Title'

export type BranchEventAction = 'add' | 'update' | 'delete' | 'attendee'

export default function EventPage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const { loading, search, pagination, searchResult } = useSelector((state: RootState) => state.event)

  const [formModal, onVisibleFormModal, onCloseFormModal] = useModal<{ eventId?: number }>({
    visible: false,
    eventId: undefined,
  })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal<{ eventId?: number }>({
    visible: false,
    eventId: undefined,
  })
  const [attendeeModal, onVisibleAttendeeModal, onCloseAttendeeModal] = useModal<{ eventId?: number }>({
    visible: false,
    eventId: undefined,
  })

  const onChangeSearch = (_search: Partial<EventSearch>) => dispatch(setEventSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setEventPagination(_pagination))
    dispatch(searchEvents(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setEventPagination({ current: 1 }))
    dispatch(searchEvents(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setEventDefault(hqId, branchId)), [hqId, branchId])
  const onDone = () => onResetAndSearch()

  const onAction = (action: BranchEventAction, eventId?: number) => {
    if (action === 'add') onVisibleFormModal({ eventId: undefined })
    if (action === 'update') onVisibleFormModal({ eventId })
    if (action === 'delete') onVisibleDeleteModal({ eventId })
    if (action === 'attendee') onVisibleAttendeeModal({ eventId })
  }

  useEffect(() => {
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchEventSearchBox search={search} onChangeSearch={onChangeSearch} onSearch={onSearch} />

      <BranchEventTitle onAction={onAction} />

      <BranchEventListTable
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        events={searchResult.events}
        total={searchResult.total}
        onAction={onAction}
      />

      <EventFormModal
        hqId={hqId}
        branchId={branchId}
        eventId={formModal.eventId}
        visible={formModal.visible}
        onClose={onCloseFormModal}
        onDone={onDone}
      />
      <EventDeleteModal
        hqId={hqId}
        branchId={branchId}
        eventId={deleteModal.eventId}
        visible={deleteModal.visible}
        onClose={onCloseDeleteModal}
        onDone={onDone}
      />
      <EventAttendeeModal
        hqId={hqId}
        branchId={branchId}
        eventId={attendeeModal.eventId}
        visible={attendeeModal.visible}
        onClose={onCloseAttendeeModal}
        onDone={onDone}
      />
    </div>
  )
}
