import {
  BranchBusinesses,
  BranchContractDocuments,
  BranchRentees,
  BranchSettlements,
  BranchSublets,
} from 'gadjet-v2-types/dist/model'

const rentee: Partial<BranchRentees> = {
  name: '',
  businessNumber: '',
  corporationNumber: '',
  address: '',
  contact: '',
}

const sublet: Partial<BranchSublets> = {
  name: '',
  businessNumber: '',
  corporationNumber: '',
  address: '',
  contact: '',
}

const contractDocument: Partial<BranchContractDocuments> = {
  zipcode: '',
  address: '',
  addressDetail: '',
  area: '',
  buildingContractType: 'none',
  creditPolicy: 'weekly',
  deposit: '',
  managementFee: '',
  rent: '',
  signatureImageId: null,
  policyFileId: null,
  termsFileId: null,
  etcFileId: null,
}

const settlement: Partial<BranchSettlements> = {
  bankCode: '',
  bankName: '',
  account: '',
  holder: '',
}

const business: Partial<BranchBusinesses> = {
  director: '',
  name: '',
  address: '',
  identityNumber: '',
  businessNumber: '',
  corporationNumber: '',
  businessType: '',
  businessClass: '',
  managers: [
    { name: '', department: '', tel: '', contact: '', email: '' },
    { name: '', department: '', tel: '', contact: '', email: '' },
  ],
}

const branchDefaultValues = {
  name: '',
  contact: '',
  email: '',
  zipcode: '',
  address: '',
  addressDetail: '',

  business,
  settlement,
  contractDocument,
  sublet,
  rentee,
}

export default branchDefaultValues
