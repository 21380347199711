import { BREAKPOINTS } from '@const'
import styled from 'styled-components'

type StyleProps = {
  color?: string
}

export const SectionTitle = styled.h2`
  margin: 0 0 40px 0;
  font-weight: 600;
  font-size: 38px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #777d83;
  @media ${BREAKPOINTS.lg} {
    font-size: 25px;
    margin: 0 0 28px 0;
  }
`
export const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const BoxGroup = styled.div`
  display: flex;
  width: 1000px;
  flex-direction: row;
  gap: 16px;
  @media ${BREAKPOINTS.lg} {
    width: 100%;
  }
  @media ${BREAKPOINTS.md} {
    flex-direction: column;
  }
`

type DescriptionWrapperStyleProps = {
  defaultStyle?: {
    textAlign?: string
    padding?: string
  }
  mediaStyle?: { textAlign?: string; padding?: string }
}

export const DescriptionWrapper = styled.div`
  width: 100%;
  text-align: ${({ defaultStyle }: DescriptionWrapperStyleProps) => defaultStyle?.textAlign || 'left'};
  padding: ${({ defaultStyle }: DescriptionWrapperStyleProps) => defaultStyle?.padding};
  @media ${BREAKPOINTS.lg} {
    padding: ${({ mediaStyle }: DescriptionWrapperStyleProps) => mediaStyle?.padding};
  }
`

export const Description = styled.p`
  font-weight: 600;
  font-size: 30px;
  line-height: 140%;
  color: ${({ color }: { color?: string }) => color || '#2e2e2e'};
  margin: 0;
  @media ${BREAKPOINTS.lg} {
    font-size: 24px;
  }
`

export const SubDescription = styled.div`
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  color: ${({ color }: { color: string }) => color};
  padding-top: 8px;
`
type SectionInnerWrapperStyles = {
  width?: string
  flexDirection?: string
}

export const SectionInnerWrapper = styled.div`
  width: ${({ width }: SectionInnerWrapperStyles) => width || 'auto'};
  display: flex;
  flex-direction: ${({ flexDirection }: SectionInnerWrapperStyles) => flexDirection || 'column'};
  gap: 16px;

  @media ${BREAKPOINTS.lg} {
    width: 100%;
  }
  @media ${BREAKPOINTS.md} {
    flex-direction: column;
  }
`

type StyleContainerProps = {
  defaultStyles: {
    flexDirection: string
    // justifyContent: string
    alignItems?: string
    width?: string
    minHeight?: string
    height?: string
    background: string
    position?: string
    padding?: string
  }
  mediaStyles?: {
    flexDirection?: string
    position?: string
    height?: string
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ defaultStyles }: StyleContainerProps) => defaultStyles.flexDirection};
  justify-content: space-between;
  align-items: ${({ defaultStyles }: StyleContainerProps) => defaultStyles.alignItems};
  width: ${({ defaultStyles }: StyleContainerProps) => defaultStyles.width || '1000px'};
  min-height: ${({ defaultStyles }: StyleContainerProps) => defaultStyles.minHeight};
  height: ${({ defaultStyles }: StyleContainerProps) => defaultStyles.height};
  background: ${({ defaultStyles }: StyleContainerProps) => defaultStyles.background};
  border-radius: 40px;
  overflow: hidden;
  position: ${({ defaultStyles }: StyleContainerProps) => defaultStyles.position};
  padding: ${({ defaultStyles }: StyleContainerProps) => defaultStyles.padding};
  @media ${BREAKPOINTS.lg} {
    width: 100%;
    height: ${({ mediaStyles }: StyleContainerProps) => mediaStyles?.height};
    position: ${({ mediaStyles }: StyleContainerProps) => mediaStyles?.position};
    flex-direction: ${({ mediaStyles }: StyleContainerProps) => mediaStyles?.flexDirection};
  }
`

export const BoxTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: ${({ color }: { color?: StyleProps }) => color || '#4e5257'};

  opacity: 0.8;
  margin-bottom: 10px;
  @media ${BREAKPOINTS.lg} {
    font-size: 18px;
    margin-bottom: 12px;
  }
`

type ImageWrapperStyles = {
  defaultStyle: {
    width?: string
    alignItems: string
  }
  mediaStyle?: {
    justifyContent: string
  }
}
export const ImageWrapper = styled.div`
  width: ${({ defaultStyle }: ImageWrapperStyles) => defaultStyle.width || 'auto'};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: ${({ defaultStyle }: ImageWrapperStyles) => defaultStyle.alignItems};
  @media ${BREAKPOINTS.lg} {
    justify-content: ${({ mediaStyle }: ImageWrapperStyles) => mediaStyle?.justifyContent || 'center'};
  }
`

export const LineBreakMedia = styled.br`
  display: none;
  @media ${BREAKPOINTS.lg} {
    display: block;
  }
`
export const LineBreakPC = styled.br`
  @media ${BREAKPOINTS.lg} {
    display: none;
  }
`
export const LineBreakMobile = styled.br`
  display: none;
  @media ${BREAKPOINTS.sm} {
    display: block;
  }
`
