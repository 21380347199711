import { Row } from 'antd'
import { CashSummary } from 'gadjet-v2-types/dist/type'
import { useMemo } from 'react'

import { krw } from '@utils/format'

import ContentWrapper from '@components/atoms/ContentWrapper'
import SummaryItem from '@components/molecules/SummaryItem'

type Props = {
  summary: CashSummary
}

export default function BranchCashSummary({ summary }: Props): JSX.Element {
  const items = useMemo(() => [{ key: 'amount', label: '현재잔액', value: krw(summary.amount) }], [summary])

  return (
    <ContentWrapper>
      <Row>
        {items.map(({ key, label, value }) => (
          <SummaryItem key={key} label={label} value={value} span={4} />
        ))}
      </Row>
    </ContentWrapper>
  )
}
