import { notification } from 'antd'

import CashAPI from '@apis/branch/cash'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  cashId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function CashDeleteModal({ hqId, branchId, cashId, visible, onClose, onDone }: Props): JSX.Element {
  const onConfirm = async () => {
    if (!cashId) return
    await CashAPI.deleteCash({ hqId, branchId, cashId })
    notification.success({ message: '현금 내역이 삭제되었습니다.' })
  }

  return (
    <ConfirmModal
      title="현금 내역 삭제"
      description="기재한 현금 출납 내역이 완전히 삭제되어 이후 복구할 수 없습니다."
      okText="삭제"
      visible={visible}
      onConfirm={onConfirm}
      onClose={onClose}
      onDone={onDone}
    />
  )
}
