import { ReactComponent as ChevronLeftIcon } from '@assets/Icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from '@assets/Icons/chevron-right.svg'
import { Space, Button } from 'antd'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import styled from 'styled-components'

type Props = {
  month: string
  onChange: (month: string) => void
}

export default function DashboardMonthPicker({ month, onChange }: Props): JSX.Element {
  const monthStr = useMemo(() => dayjs(month, 'YYYY-MM').format('YYYY년 MM월'), [month])

  const onClickLeft = () => onChange(dayjs(month, 'YYYY-MM').subtract(1, 'month').format('YYYY-MM'))
  const onClickRight = () => onChange(dayjs(month, 'YYYY-MM').add(1, 'month').format('YYYY-MM'))
  const onClickThisMonth = () => onChange(dayjs().format('YYYY-MM'))

  return (
    <Space size={20}>
      <YearMonth>{monthStr}</YearMonth>
      <Space size={4}>
        <Button onClick={onClickThisMonth}>이번 달</Button>
        <ArrowButton onClick={onClickLeft} icon={<ChevronLeftIcon width="24" height="24" stroke="#2E2E2E" />} />
        <ArrowButton onClick={onClickRight} icon={<ChevronRightIcon width="24" height="24" stroke="#2E2E2E" />} />
      </Space>
    </Space>
  )
}

const YearMonth = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 36px;
`
const ArrowButton = styled(Button)`
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
`
