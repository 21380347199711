import { DownloadOutlined } from '@ant-design/icons'

import { BranchPaymentAction } from '@pages/Branch/PaymentPage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchPaymentAction) => void
}

export default function BranchPaymentTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [
    { label: '다운로드', buttonType: 'dashed', icon: <DownloadOutlined />, onClick: () => onAction('export') },
  ]

  return <PageTitle title="결제내역" actions={actions} />
}
