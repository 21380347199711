import { notification } from 'antd'

import HqRoleAPI from '@apis/hq/role'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  hqRoleId?: number
  visible: boolean
  onClose: () => void
  onDone?: () => void
}

export default function DeleteModal({ hqId, hqRoleId, visible, onClose, onDone }: Props): JSX.Element {
  const onConfirm = async () => {
    if (!hqRoleId) return
    await HqRoleAPI.deleteRole({ hqId, hqRoleId })
    notification.success({ message: '선택한 사용자의 접근 권한이 삭제되었습니다.' })

    if (onDone) onDone()
  }

  return (
    <ConfirmModal
      title="접근 권한 삭제"
      description="해당 사용자의 접근 권한을 삭제해 가젯 로그인을 막습니다."
      visible={visible}
      okText="삭제"
      onConfirm={onConfirm}
      onClose={onClose}
    />
  )
}
