import { BREAKPOINTS } from '@const'
import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  dDay?: number
  endDate?: string
  content?: ReactNode
}

export default function PromotionBanner({ dDay, endDate, content }: Props): JSX.Element {
  return (
    <Wrapper>
      {/* // NOTE: 프로모션 기한, 디데이 게시 필요할 때 주석해제 */}
      {/* <DDay>프로모션 D-{dDay}</DDay> */}
      <Content>{content}</Content>
      {/* <EndDate>~ {endDate}까지</EndDate> */}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  margin-bottom: 30px;
  gap: 30px;

  width: 100%;
  max-width: 860px;

  background: #ffffff;
  border-radius: 20px;

  @media ${BREAKPOINTS.lg} {
    width: 600px;
    /* flex-direction: column;
    gap: 12px; */
    margin-bottom: 40px;
  }
  @media ${BREAKPOINTS.md} {
    width: 382px;
  }
  @media ${BREAKPOINTS.sm} {
    width: 100%;
    min-width: 340px;
  }
`

// const DDay = styled.span`
//   padding: 12px 14px;
//   background: #f43f24;
//   border-radius: 20px;

//   font-weight: 700;
//   font-size: 16px;
//   line-height: 14px;
//   color: #ffffff;
// `
const Content = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #232530;
`

// const EndDate = styled.span`
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 150%;
//   color: #7a7b7c;
// `
