import { BIZ_BREAKPOINTS, BOOK_DEMO_URL, CHANNEL_TALK_URL, GADJET_BIZ_COLORS } from '@const'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'

import ContactFormModal from '../ContactFormModal'

export default function ContactLinkGroup() {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const contactLinkList: {
    key: string
    title: string
    workingHours: string | ReactNode
    linkUrl?: string
    icon?: string
  }[] = [
    {
      key: 'channel-talk',
      title: '실시간 채팅 문의 >',
      workingHours: '평일 오전 8시 30분 ~ 오후 5시',
      linkUrl: CHANNEL_TALK_URL,
      icon: 'talk.png',
    },
    {
      key: 'book-demo',
      title: '서비스 데모 신청 >',
      workingHours: '평일 오전 10시 30분 ~ 오후 3시\n(점심시간 오후 12시 ~ 1시)',
      linkUrl: BOOK_DEMO_URL,
      icon: 'demo.png',
    },
  ]

  return (
    <Wrapper>
      <LinkWrapper>
        {contactLinkList.map(({ key, title, workingHours, linkUrl, icon }) => (
          <Link key={key} href={linkUrl} target="_blank" rel="noreferrer">
            <LinkButton>
              <Icon src={`/images/landing/business/icons/${icon}`} alt={key} />
              <Title>{title}</Title>
              <WorkingHours>{workingHours}</WorkingHours>
            </LinkButton>
          </Link>
        ))}
        <LinkButton onClick={() => setOpenModal(true)}>
          <Icon src="/images/landing/business/icons/form.png" alt="form-icon" />
          <Title>{`이용 견적 문의 >`}</Title>
          <WorkingHours>영업일 1~2일 소요</WorkingHours>
        </LinkButton>
      </LinkWrapper>
      <ContactFormModal visible={openModal} onCloseModal={() => setOpenModal(false)} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  width: 100%;
`

const Icon = styled.img`
  width: 38px;
  height: 38px;
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media ${BIZ_BREAKPOINTS.lg} {
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  }
`

const Link = styled.a`
  width: 320px;
  height: 200px;
  @media ${BIZ_BREAKPOINTS.lg} {
    max-width: 288px;
    width: 100%;
    height: 150px;
  }
`

const LinkButton = styled.button`
  width: 320px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  background: rgba(255, 255, 255, 0.96);
  box-shadow: 4px 6px 40px 0px #101739;

  padding: 36px 24px;
  border-radius: 26px;
  border: none;
  color: #2e2e2e;
  white-space: break-spaces;

  cursor: pointer;
  :hover {
    color: #2e2e2e;
  }

  @media ${BIZ_BREAKPOINTS.lg} {
    max-width: 288px;
    width: 100%;
    height: 150px;
  }
`

const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  color: ${GADJET_BIZ_COLORS.primary};
`

const WorkingHours = styled.span`
  color: #4e5257;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  line-height: 140%;
`
