import { Styles } from '@types'
import { FormInstance } from 'antd'
import { Additions, Contracts, Spaces } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'

import AdditionAPI from '@apis/branch/addition'
import SpaceAPI from '@apis/branch/space'

import ContractFeeAdditionList from './AdditionList'
import ContractFeeSpaceList from './SpaceList'

type Props = { hqId: number; branchId: number; form: FormInstance<Contracts>; isUpdate: boolean }

export default function FeeTab({ hqId, branchId, form, isUpdate }: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ spaces: Spaces[]; additions: Additions[] }>({
    spaces: [],
    additions: [],
  })

  const getData = async () => {
    setLoading(true)

    const [spaceRes, additionRes] = await Promise.all([
      SpaceAPI.getSpaces(
        { hqId, branchId },
        { current: 1, pageSize: 1000, emptyFlag: false, query: '', isActive: true }
      ),
      AdditionAPI.getAdditions({ hqId, branchId }, { current: 1, pageSize: 1000, query: '', isActive: true }),
    ])
    setData({
      spaces: spaceRes.data.spaces,
      additions: additionRes.data.additions,
    })

    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <ContractFeeSpaceList spaces={data.spaces} loading={loading} form={form} />
      <div style={styles.spacer} />
      <ContractFeeAdditionList additions={data.additions} loading={loading} form={form} />
    </>
  )
}

const styles: Styles = {
  spacer: { height: '100px' },
}
