import { ExportOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Button, Input, Table, TableColumnsType } from 'antd'
import dayjs from 'dayjs'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { ContractStatus } from 'gadjet-v2-types/dist/type'
import { contractStatus } from 'gadjet-v2-types/dist/type/label'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { searchDashboardContracts } from '@reducers/hq/dashboard.reducer'
import { RootState } from '@reducers/index'

import DashboardCard from '@components/molecules/Dashboard/Card'
import TypeTag from '@components/molecules/TypeTag'

type Props = { hqId: number }

export default function DashboardSearchContracts({ hqId }: Props): JSX.Element {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const [loading, setLoading] = useState(false)
  const contracts = useSelector((state: RootState) => state.hqDashboard.searchContracts)

  const onClickRoute = ({ branchId, tenant }: Contracts) =>
    push(`/hq/${hqId}/branch/${branchId}/contract/list`, { query: tenant.name })

  const onSearch = async (query: string) => {
    setLoading(true)
    dispatch(
      searchDashboardContracts(hqId, query, () => {
        setLoading(false)
      })
    )
  }

  const columns = useMemo(
    (): TableColumnsType<Contracts> => [
      { key: 'branch', dataIndex: 'branch', title: '지점', render: (_, { branch }) => branch?.name },
      { key: 'tenant', dataIndex: 'tenant', title: '입주사', render: (_, { tenant }) => tenant?.name },
      {
        key: 'startDate',
        dataIndex: 'startDate',
        title: '기간',
        render: (_, { startDate, suspendDate }) =>
          `${dayjs(startDate).format('YYYY-MM-DD')} - ${dayjs(suspendDate).format('YYYY-MM-DD')}`,
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: '상태',
        render: (_, { status }) => (
          <TypeTag<ContractStatus>
            label={contractStatus[status]}
            value={status}
            switchCase={{
              values: [['before-started'], ['started'], ['extended'], ['expired', 'suspended']],
              types: ['default', 'success', 'ongoing', 'fail'],
            }}
          />
        ),
      },
      {
        key: 'contractId',
        dataIndex: 'contractId',
        title: '',
        align: 'center',
        width: '50px',
        render: (_, contract) => (
          <Button type="text" icon={<ExportOutlined />} onClick={() => onClickRoute(contract)} />
        ),
      },
    ],
    []
  )

  return (
    <DashboardCard title="계약 검색" loading={loading}>
      <div style={styles.wrapper}>
        <Input.Search autoFocus onSearch={onSearch} />
        <Table
          dataSource={contracts}
          columns={columns}
          size="small"
          pagination={false}
          rowKey={({ contractId }) => `${contractId}`}
        />
      </div>
    </DashboardCard>
  )
}

const styles: Styles = {
  wrapper: { display: 'flex', flexDirection: 'column', gap: '10px' },
}
