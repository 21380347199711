import { BREAKPOINTS } from '@const'
import styled from 'styled-components'

import BalloonBlack from '../../atoms/BalloonBlack'
import BalloonGreen from '../../atoms/BalloonGreen'
import BookDemoButton from '../../atoms/BookDemoButton'
import PreviewBox from '../../atoms/PreviewBox'
import SectionTitle from '../../atoms/SectionTitle'
import TenantPreviewBox from '../../atoms/TenantPreviewBox'

export default function ContractFeature(): JSX.Element {
  return (
    <Wrapper>
      <SectionTitle
        text={
          <span>
            가젯으로 달라질 업무 방식,
            <br />
            미리 보여드릴게요!
          </span>
        }
      />
      <TextWrapper>
        <BalloonGroup>
          <BalloonBlack
            background={{ src: '/images/landing/home/balloons/balloon-black-contract.png', width: 600, height: 167 }}
            icon={{ src: '/images/landing/home/balloons/knocked-out-eyes.png', alt: 'knocked-out-eyes' }}
            mobileBackground="/images/landing/home/balloons/balloon-black-mobile.png"
            text={
              <span>
                <Black>계약서</Black>는 워드에서 만들고, <Black>계약 관리</Black>는 엑셀에 <Br />
                따로 <MediaBr /> 입력하니까 <Black>너무 복잡하고 비효율적이에요</Black>
              </span>
            }
          />
          <BalloonGreen
            background={{ src: '/images/landing/home/balloons/balloon-green-contract.png', width: 348, height: 167 }}
            mobileBackground="/images/landing/home/balloons/balloon-green-mobile.png"
            icon={{ src: '/images/landing/home/balloons/smiling-with-heart-eyes.png', alt: 'smiling-with-heart-eyes' }}
            text={
              <span>
                이제 걱정 마세요.
                <br />
                <Green>가젯은 쉽고 빨라요!</Green>
              </span>
            }
          />
        </BalloonGroup>
      </TextWrapper>
      <BoxGroup>
        <Space>
          <PreviewBox
            width="540px"
            mediaWidth="464px"
            background="#F3F4F5"
            label="전자 계약"
            content={{
              text: (
                <span>
                  계약 정보만 입력하면 계약서가
                  <br />
                  입주사의 가젯 앱으로 전송돼요.
                </span>
              ),
              align: 'start',
            }}
            image={{
              src: '/images/landing/home/previews/contract-form.png',
              alt: 'add-contract-form',
              width: 538,
              height: 366,
            }}
            mobileImage={{
              src: '/images/landing/home/previews/contract-form-m.png',
              alt: 'add-contract-form',
              width: 343,
              height: 358,
            }}
          />

          <TenantPreviewBox
            width="440px"
            mediaWidth="420px"
            label="모바일 서명"
            content={
              <span>
                앱에서 바로 서명하고
                <br />
                계약을 완료할 수 있어요.
              </span>
            }
            image={{
              src: '/images/landing/home/previews/contract-signature.png',
              alt: 'mobile-contract-signature',
              width: 410,
              height: 353,
            }}
            mobileImage={{
              src: '/images/landing/home/previews/contract-signature-m.png',
              alt: 'mobile-contract-signature',
              width: 343,
              height: 350,
            }}
          />
        </Space>
        <PreviewBox
          width="1000px"
          mediaWidth="904px"
          label="계약 관리"
          background="#F3F4F5"
          content={{
            text: (
              <span>
                목록에서 계약 현황을 한눈에 파악하고
                <Br /> 필요한 정보를
                <MediaBr /> 빠르게 확인해요.
              </span>
            ),
            align: 'center',
          }}
          image={{
            src: '/images/landing/home/previews/contract-manage.png',
            alt: 'contract-list-table',
            width: 998,
            height: 370,
          }}
          mobileImage={{
            src: '/images/landing/home/previews/contract-manage-m.png',
            alt: 'contract-list-table',
            width: 343,
            height: 328,
          }}
        />
        <BookDemoButton text="계약부터 관리까지 더 자세히 알고싶다면," />
      </BoxGroup>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 200px;
  @media ${BREAKPOINTS.lg} {
    padding-bottom: 100px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`

const BalloonGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  margin-bottom: 60px;
  @media ${BREAKPOINTS.lg} {
    width: 100%;
    align-items: center;
    gap: 12px;
  }
`

const Black = styled.span`
  color: #2e2e2e;
`

const Green = styled.span`
  color: #22af5d;
`

const BoxGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`
const Space = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media ${BREAKPOINTS.lg} {
    flex-direction: column;
  }
`
const Br = styled.br`
  @media ${BREAKPOINTS.lg} {
    display: none;
  }
`
const MediaBr = styled.br`
  display: none;
  @media ${BREAKPOINTS.lg} {
    display: block;
  }
`
