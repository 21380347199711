import * as BRANCH_CARD from 'gadjet-v2-types/dist/api/branch/card'

import axios from '@utils/axios'

const BranchCardAPI = {
  // 지점 결제수단 조회
  getBranchCards: async ({ hqId, branchId }: BRANCH_CARD.GET_LIST_PARAMS, {}: BRANCH_CARD.GET_LIST_QUERY) =>
    axios.get<BRANCH_CARD.GET_LIST_RESPONSE>(`/hqs/${hqId}/branches/${branchId}/cards`),

  // 지점 결제수단 추가
  addBranchCard: async ({ hqId, branchId }: BRANCH_CARD.ADD_PARAMS, { card }: BRANCH_CARD.ADD_BODY) =>
    axios.post<BRANCH_CARD.ADD_RESPONSE>(`/hqs/${hqId}/branches/${branchId}/cards`, { card }),

  // 지점 결제수단 삭제
  deleteBranchCard: async ({ hqId, branchId, branchCardId }: BRANCH_CARD.DELETE_PARAMS) =>
    axios.delete<BRANCH_CARD.DELETE_RESPONSE>(`/hqs/${hqId}/branches/${branchId}/cards/${branchCardId}`),

  // 지점 결제수단 검증
  validateBranchCard: async ({ hqId, branchId, branchCardId }: BRANCH_CARD.VALIDATE_PARAMS) =>
    axios.get<BRANCH_CARD.VALIDATE_RESPONSE>(`/hqs/${hqId}/branches/${branchId}/cards/${branchCardId}/validate`),

  // 지점 결제수단 활성화 변경
  updateBranchCardBilling: async ({ hqId, branchId, branchCardId }: BRANCH_CARD.UPDATE_BILLING_PARAMS) =>
    axios.put<BRANCH_CARD.UPDATE_BILLING_RESPONSE>(`/hqs/${hqId}/branches/${branchId}/cards/${branchCardId}/active`),
}

export default BranchCardAPI
