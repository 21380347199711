import { BIZ_BREAKPOINTS } from '@const'
import Marquee from 'react-fast-marquee'
import styled from 'styled-components'

export default function CompanyLogoGroup() {
  const companies: { key: string; image: string }[] = [
    { key: 'humane', image: 'humane.png' },
    { key: 'one-stop-workspace', image: 'one-stop-workspace.png' },
    { key: 'work-and-co', image: 'work-and-co.png' },
    { key: 'microwave', image: 'microwave.png' },
    { key: 'workbox', image: 'workbox.png' },
    { key: 'workrium', image: 'workrium.png' },
    { key: 'proceed', image: 'proceed.png' },
    { key: 'garage', image: 'garage.png' },
    { key: 'ghxgarage', image: 'ghxgarage.png' },
    { key: 'currentpage', image: 'currentpage.png' },
  ]
  return (
    <Wrapper>
      <Marquee>
        <LogoWrapper>
          {companies.map(({ key, image }) => (
            <Logo key={key} src={`/images/landing/business/companies/${image}`} alt={key} />
          ))}
        </LogoWrapper>
      </Marquee>
      <MobileTickerWrap>
        <Marquee direction="right">
          <MobileLogoWrapper>
            {companies.map(({ key, image }) => (
              <Logo key={key} src={`/images/landing/business/companies/${image}`} alt={key} />
            ))}
          </MobileLogoWrapper>
        </Marquee>
      </MobileTickerWrap>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  @media ${BIZ_BREAKPOINTS.lg} {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`
const MobileTickerWrap = styled.div`
  display: none;
  @media ${BIZ_BREAKPOINTS.lg} {
    display: block;
  }
`
const LogoWrapper = styled.div`
  display: flex;
`
const MobileLogoWrapper = styled.div`
  display: flex;
  @media ${BIZ_BREAKPOINTS.lg} {
    flex-direction: row-reverse;
  }
`

const Logo = styled.img`
  width: 216px;
  height: 80px;
  margin: 0 8px;

  @media ${BIZ_BREAKPOINTS.lg} {
    width: 173px;
    height: 64px;
    margin: 0 6px;
  }
`
