import { Contracts } from 'gadjet-v2-types/dist/model'

const DEFAULT_PAGE_SIZE = 10 as const

// ActionType
export const GET_STORAGE = 'storage/GET_STORAGE' as const
export const SET_STORAGE_TOKEN = 'storage/SET_STORAGE_TOKEN' as const
export const SET_STORAGE_CONTRACT = 'storage/SET_STORAGE_CONTRACT' as const
export const SET_STORAGE_BILL = 'storage/SET_STORAGE_BILL' as const
export const SET_STORAGE_RECEIPT = 'storage/SET_STORAGE_RECEIPT' as const
export const SET_STORAGE_SPACE = 'storage/SET_STORAGE_SPACE' as const
export const SET_STORAGE_RENTAL = 'storage/SET_STORAGE_RENTAL' as const
export const SET_STORAGE_RENTAL_RESERVATION = 'storage/SET_STORAGE_RENTAL_RESERVATION' as const
export const SET_STORAGE_TENANT = 'storage/SET_STORAGE_TENANT' as const
export const SET_STORAGE_NOTICE = 'storage/SET_STORAGE_NOTICE' as const
export const SET_STORAGE_PRODUCT = 'storage/SET_STORAGE_PRODUCT' as const
export const SET_STORAGE_PRODUCT_SALE = 'storage/SET_STORAGE_PRODUCT_SALE' as const
export const SET_STORAGE_SERVICE = 'storage/SET_STORAGE_SERVICE' as const
export const SET_STORAGE_SERVICE_SALE = 'storage/SET_STORAGE_SERVICE_SALE' as const
export const SET_STORAGE_EXPENDITURE = 'storage/SET_STORAGE_EXPENDITURE' as const
export const SET_STORAGE_PAYMENT = 'storage/SET_STORAGE_PAYMENT' as const
export const SET_STORAGE_EVENT = 'storage/SET_STORAGE_EVENT' as const
export const SET_STORAGE_ADDITION = 'storage/SET_STORAGE_ADDITION' as const
export const SET_STORAGE_CASH = 'storage/SET_STORAGE_CASH' as const
export const SET_STORAGE_ACCESS_LOG = 'storage/SET_STORAGE_ACCESS_LOG' as const

// Action
export const getStorage = () => ({
  type: GET_STORAGE,
  payload: {},
})
export const setStorageToken = (token: string | null) => ({
  type: SET_STORAGE_TOKEN,
  payload: { token },
})
export const setStorageContract = (config: Partial<StorageState['contractConfig']>) => ({
  type: SET_STORAGE_CONTRACT,
  payload: { config },
})
export const setStorageBill = (config: Partial<StorageState['billConfig']>) => ({
  type: SET_STORAGE_BILL,
  payload: { config },
})
export const setStorageReceipt = (config: Partial<StorageState['receiptConfig']>) => ({
  type: SET_STORAGE_RECEIPT,
  payload: { config },
})
export const setStorageSpace = (config: Partial<StorageState['spaceConfig']>) => ({
  type: SET_STORAGE_SPACE,
  payload: { config },
})
export const setStorageRental = (config: Partial<StorageState['rentalConfig']>) => ({
  type: SET_STORAGE_RENTAL,
  payload: { config },
})
export const setStorageRentalReservation = (config: Partial<StorageState['rentalReservationConfig']>) => ({
  type: SET_STORAGE_RENTAL_RESERVATION,
  payload: { config },
})
export const setStorageTenant = (config: Partial<StorageState['tenantConfig']>) => ({
  type: SET_STORAGE_TENANT,
  payload: { config },
})
export const setStorageNotice = (config: Partial<StorageState['noticeConfig']>) => ({
  type: SET_STORAGE_NOTICE,
  payload: { config },
})
export const setStorageProduct = (config: Partial<StorageState['productConfig']>) => ({
  type: SET_STORAGE_PRODUCT,
  payload: { config },
})
export const setStorageProductSale = (config: Partial<StorageState['productSaleConfig']>) => ({
  type: SET_STORAGE_PRODUCT_SALE,
  payload: { config },
})
export const setStorageService = (config: Partial<StorageState['serviceConfig']>) => ({
  type: SET_STORAGE_SERVICE,
  payload: { config },
})
export const setStorageServiceSale = (config: Partial<StorageState['serviceSaleConfig']>) => ({
  type: SET_STORAGE_SERVICE_SALE,
  payload: { config },
})
export const setStorageExpenditure = (config: Partial<StorageState['expenditureConfig']>) => ({
  type: SET_STORAGE_EXPENDITURE,
  payload: { config },
})
export const setStoragePayment = (config: Partial<StorageState['paymentConfig']>) => ({
  type: SET_STORAGE_PAYMENT,
  payload: { config },
})
export const setStorageEvent = (config: Partial<StorageState['eventConfig']>) => ({
  type: SET_STORAGE_EVENT,
  payload: { config },
})
export const setStorageAddition = (config: Partial<StorageState['additionConfig']>) => ({
  type: SET_STORAGE_ADDITION,
  payload: { config },
})
export const setStorageCash = (config: Partial<StorageState['cashConfig']>) => ({
  type: SET_STORAGE_CASH,
  payload: { config },
})
export const setStorageAccessLog = (config: Partial<StorageState['accessLogConfig']>) => ({
  type: SET_STORAGE_ACCESS_LOG,
  payload: { config },
})

export type StorageAction =
  | ReturnType<typeof getStorage>
  | ReturnType<typeof setStorageToken>
  | ReturnType<typeof setStorageContract>
  | ReturnType<typeof setStorageBill>
  | ReturnType<typeof setStorageReceipt>
  | ReturnType<typeof setStorageSpace>
  | ReturnType<typeof setStorageRental>
  | ReturnType<typeof setStorageRentalReservation>
  | ReturnType<typeof setStorageTenant>
  | ReturnType<typeof setStorageNotice>
  | ReturnType<typeof setStorageProduct>
  | ReturnType<typeof setStorageProductSale>
  | ReturnType<typeof setStorageService>
  | ReturnType<typeof setStorageServiceSale>
  | ReturnType<typeof setStorageExpenditure>
  | ReturnType<typeof setStoragePayment>
  | ReturnType<typeof setStorageEvent>
  | ReturnType<typeof setStorageAddition>
  | ReturnType<typeof setStorageCash>
  | ReturnType<typeof setStorageAccessLog>

type StorageState = {
  token?: string | null
  contractConfig: { activeColumns: Array<keyof Contracts>; pageSize: number; spaceTypes: number[] }
  billConfig: { pageSize: number; spaceTypes: number[] }
  receiptConfig: { pageSize: number }
  spaceConfig: { pageSize: number }
  rentalConfig: { pageSize: number }
  rentalReservationConfig: { pageSize: number }
  tenantConfig: { pageSize: number }
  noticeConfig: { pageSize: number }
  productConfig: { pageSize: number }
  productSaleConfig: { pageSize: number }
  serviceConfig: { pageSize: number }
  serviceSaleConfig: { pageSize: number }
  expenditureConfig: { pageSize: number }
  paymentConfig: { pageSize: number }
  eventConfig: { pageSize: number }
  additionConfig: { pageSize: number }
  cashConfig: { pageSize: number }
  accessLogConfig: { pageSize: number }
}

const storageState: StorageState = {
  token: null,
  contractConfig: { pageSize: DEFAULT_PAGE_SIZE, activeColumns: [], spaceTypes: [] },
  billConfig: { pageSize: DEFAULT_PAGE_SIZE, spaceTypes: [] },
  receiptConfig: { pageSize: DEFAULT_PAGE_SIZE },
  spaceConfig: { pageSize: DEFAULT_PAGE_SIZE },
  rentalConfig: { pageSize: DEFAULT_PAGE_SIZE },
  rentalReservationConfig: { pageSize: DEFAULT_PAGE_SIZE },
  tenantConfig: { pageSize: DEFAULT_PAGE_SIZE },
  noticeConfig: { pageSize: DEFAULT_PAGE_SIZE },
  productConfig: { pageSize: DEFAULT_PAGE_SIZE },
  productSaleConfig: { pageSize: DEFAULT_PAGE_SIZE },
  serviceConfig: { pageSize: DEFAULT_PAGE_SIZE },
  serviceSaleConfig: { pageSize: DEFAULT_PAGE_SIZE },
  expenditureConfig: { pageSize: DEFAULT_PAGE_SIZE },
  paymentConfig: { pageSize: DEFAULT_PAGE_SIZE },
  eventConfig: { pageSize: DEFAULT_PAGE_SIZE },
  additionConfig: { pageSize: DEFAULT_PAGE_SIZE },
  cashConfig: { pageSize: DEFAULT_PAGE_SIZE },
  accessLogConfig: { pageSize: DEFAULT_PAGE_SIZE },
}

const storageReducer = (state = storageState, action: StorageAction): StorageState => {
  switch (action.type) {
    case GET_STORAGE:
      return state
    case SET_STORAGE_CONTRACT:
      return { ...state, contractConfig: { ...state.contractConfig, ...action.payload.config } }
    case SET_STORAGE_BILL:
      return { ...state, billConfig: { ...state.billConfig, ...action.payload.config } }
    case SET_STORAGE_RECEIPT:
      return { ...state, receiptConfig: { ...state.receiptConfig, ...action.payload.config } }
    case SET_STORAGE_SPACE:
      return { ...state, spaceConfig: { ...state.spaceConfig, ...action.payload.config } }
    case SET_STORAGE_RENTAL:
      return { ...state, rentalConfig: { ...state.rentalConfig, ...action.payload.config } }
    case SET_STORAGE_RENTAL_RESERVATION:
      return { ...state, rentalReservationConfig: { ...state.rentalReservationConfig, ...action.payload.config } }
    case SET_STORAGE_NOTICE:
      return { ...state, noticeConfig: { ...state.noticeConfig, ...action.payload.config } }
    case SET_STORAGE_PRODUCT:
      return { ...state, productConfig: { ...state.productConfig, ...action.payload.config } }
    case SET_STORAGE_PRODUCT_SALE:
      return { ...state, productSaleConfig: { ...state.productSaleConfig, ...action.payload.config } }
    case SET_STORAGE_SERVICE:
      return { ...state, serviceConfig: { ...state.serviceConfig, ...action.payload.config } }
    case SET_STORAGE_EXPENDITURE:
      return { ...state, expenditureConfig: { ...state.expenditureConfig, ...action.payload.config } }
    case SET_STORAGE_PAYMENT:
      return { ...state, paymentConfig: { ...state.paymentConfig, ...action.payload.config } }
    case SET_STORAGE_EVENT:
      return { ...state, eventConfig: { ...state.eventConfig, ...action.payload.config } }
    case SET_STORAGE_ADDITION:
      return { ...state, additionConfig: { ...state.additionConfig, ...action.payload.config } }
    case SET_STORAGE_CASH:
      return { ...state, cashConfig: { ...state.cashConfig, ...action.payload.config } }
    default:
      return state
  }
}

export default storageReducer
