import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import SpaceAPI from '@apis/branch/space'

import {
  SpaceAction,
  setSpaceLoading,
  setSpaceSearchResult,
  SEARCH_SPACES,
  SET_SPACE_DEFAULT,
  SET_SPACE_PAGINATION,
  SET_SPACE_SEARCH,
  SET_SPACE_PAGE_CONFIG,
  setSpacePagination,
  searchSpaces,
  setSpaceSummary,
  setSpaceSearch,
} from '@reducers/branch/space.reducer'
import { RootState } from '@reducers/index'
import { setStorageSpace } from '@reducers/storage.reducer'

function* searchSpaceSaga(action: SpaceAction): Generator {
  if (action.type !== SEARCH_SPACES) return
  const { hqId, branchId } = action.payload
  yield* put(setSpaceLoading(true))

  const search = yield* select((state: RootState) => state.space.search)
  const pagination = yield* select((state: RootState) => state.space.pagination)

  const {
    data: { spaces, total },
  } = yield* call(SpaceAPI.getSpaces, { hqId, branchId }, { ...search, ...pagination })
  const { data: summary } = yield* call(SpaceAPI.getSpaceSummary, { hqId, branchId }, { ...search })

  yield* put(setSpaceSearchResult({ branchId, spaces, total }))
  yield* put(setSpaceSummary(summary))

  yield* put(setSpaceLoading(false))
}

function* savePageConfigSaga(action: SpaceAction): Generator {
  const state: Parameters<typeof setStorageSpace>[0] = {}

  if (action.type === SET_SPACE_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageSpace(state))
}

function* setDefaultPageConfigSaga(action: SpaceAction): Generator {
  if (action.type !== SET_SPACE_DEFAULT) return
  const { hqId, branchId } = action.payload
  const { pageSize } = yield* select((state: RootState) => state.storage.spaceConfig)

  yield* all([put(setSpaceSearch({})), put(setSpacePagination({ pageSize }))])

  yield* put(searchSpaces(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_SPACES, searchSpaceSaga),
    takeEvery(SET_SPACE_SEARCH, savePageConfigSaga),
    takeEvery(SET_SPACE_PAGINATION, savePageConfigSaga),
    takeEvery(SET_SPACE_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_SPACE_DEFAULT, setDefaultPageConfigSaga),
  ])
}
