import { COLORS } from '@const'
import { Select, Typography } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { Role } from 'gadjet-v2-types/dist/type'

type Props = {
  role?: Role
  onChange?: (r: Role) => void
  disabled?: boolean
  size?: SizeType
}

export default function RoleSelector({ role, onChange, disabled, size = 'middle' }: Props): JSX.Element {
  return (
    <Select size={size} value={role} onChange={onChange} disabled={disabled}>
      <Select.Option value={1}>
        <Typography.Text style={{ color: COLORS.primary }}>편집, 조회</Typography.Text>
      </Select.Option>
      {/* <Select.Option value={2}>
        <Typography.Text style={{ color: COLORS.success }}>조회</Typography.Text>
      </Select.Option> */}
      <Select.Option value={3}>
        <Typography.Text style={{ color: COLORS.secondary }}>권한없음</Typography.Text>
      </Select.Option>
    </Select>
  )
}
