import { ReactComponent as InfoIcon } from '@assets/Icons/info-dark.svg'
import { BREAKPOINTS } from '@const'
import { Tooltip } from 'antd'
import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  width: string
  mediaWidth: string
  background: string
  label: string
  content: { text: ReactNode; align: string }
  toggleBox?: {
    title: string
    label: string
    tootipText: ReactNode
    styles: { width: string; top?: string; right?: string }
  }
  image: { src: string; alt: string; width: number; height: number }
  mobileImage?: { src: string; alt: string; width: number; height: number }
}

export default function PreviewBox({
  width,
  mediaWidth,
  background,
  label,
  content,
  image,
  mobileImage,
  toggleBox,
}: Props): JSX.Element {
  return (
    <Container width={width} mediaWidth={mediaWidth} background={background}>
      {toggleBox && (
        <ToggleBox {...toggleBox.styles}>
          <ToggleTitle>{toggleBox.title}</ToggleTitle>
          <Tooltip
            title={toggleBox.tootipText}
            color="#2e2e2e"
            overlayInnerStyle={{
              borderRadius: '10px',
              padding: '10px 14px',
              textAlign: 'center',
            }}
          >
            <ToolTipText>
              {toggleBox.label}

              <InfoIcon />
            </ToolTipText>
          </Tooltip>
          <img src="/images/landing/home/previews/toggle.png" alt="toggle" width={38} height={24} />
        </ToggleBox>
      )}
      <ContentWrapper align={content.align}>
        <Label>{label}</Label>
        <Content align={content.align}>{content.text}</Content>
      </ContentWrapper>

      <Image src={image.src} alt={image.alt} width={image.width} height={image.height} />
      <MobileImage
        src={mobileImage?.src}
        alt={mobileImage?.alt}
        width={mobileImage?.width}
        height={mobileImage?.height}
      />
    </Container>
  )
}

type StylesProps = {
  width: string
  mediaWidth: string
  background: string
}

type ToggleBoxStylesProps = {
  width: string
  top?: string
  right?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  isolation: isolate;
  position: relative;

  width: ${({ width }: StylesProps) => width};
  height: 550px;
  background: ${({ background }: StylesProps) => background};

  border: 1px solid #e9eaeb;
  border-radius: 40px;
  overflow: hidden;

  @media ${BREAKPOINTS.lg} {
    width: 343px;
    height: 100%;
    border-radius: 24px;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }: { align: string }) => align};
  gap: 14px;
  width: 100%;
  padding: 40px 0px 0px 40px;
  @media ${BREAKPOINTS.lg} {
    padding: 24px 24px 0px 24px;
  }
`
const Label = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 12px;
  width: max-content;
  background: #cec8ff;
  border-radius: 12px;
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  color: #2e2e2e;
`

const Content = styled.h3`
  width: 100%;
  font-weight: 700;
  font-size: 30px;
  line-height: 140%;
  text-align: ${({ align }: { align: string }) => align};
  color: #2e2e2e;
  margin-bottom: 0;
  @media ${BREAKPOINTS.lg} {
    font-size: 24px;
  }
`

const Image = styled.img`
  @media ${BREAKPOINTS.lg} {
    display: none;
  }
`

const MobileImage = styled.img`
  display: none;
  @media ${BREAKPOINTS.lg} {
    display: block;
  }
`

const ToggleBox = styled.div`
  position: absolute;
  right: ${({ right }: ToggleBoxStylesProps) => right};
  top: ${({ top }: ToggleBoxStylesProps) => top};

  width: ${({ width }: ToggleBoxStylesProps) => width};
  height: 124px;
  background: #ffffff;
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px 0px 20px 24px;
  @media ${BREAKPOINTS.lg} {
    display: none;
  }
`
const ToggleTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;

  color: #232530;
`
const ToolTipText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #232530;
  display: flex;
  align-items: center;
  gap: 4px;
`
