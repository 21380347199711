import * as NOTIFICATION from 'gadjet-v2-types/dist/api/manager/notification'

import axios from '@utils/axios'

const ManagerAPI = {
  // 알림 조회
  getNotifications: async ({ managerId }: NOTIFICATION.GET_PARAMS): Promise<{ data: NOTIFICATION.GET_RESPONSE }> =>
    axios.get(`/managers/${managerId}/notifications`),
}

export default ManagerAPI
