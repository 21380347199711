import { notification } from 'antd'
import { useEffect, useState } from 'react'

import ProductAPI from '@apis/branch/product'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  productSaleId: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function ProductSaleRefundModal({
  hqId,
  branchId,
  productSaleId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const onConfirm = async () => {
    if (!productSaleId) return
    setLoading(true)
    await ProductAPI.refundProductSale({ hqId, branchId, productSaleId })
    notification.success({ message: '환불되었습니다.' })
    setLoading(false)
    onDone()
    onClose()
  }

  useEffect(() => {
    if (visible) setLoading(false)
  }, [visible])

  return (
    <ConfirmModal
      title="상품 환불"
      description="환불 요청 상품을 확인하세요. 상품 환불을 진행하면 크레딧이 반환됩니다."
      visible={visible}
      onConfirm={onConfirm}
      onClose={onClose}
      onDone={onDone}
      okText="환불"
      loading={loading}
    />
  )
}
