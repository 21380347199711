import { ReactNode } from 'react'

import FeaturePageTemplate from '@pages/Landing/FeaturePages/organisms/FeaturePageTemplate'
import * as S from '@pages/Landing/FeaturePages/styles'

import ManageBranch from '../../organisms/ManageBranch'
import ManageSpace from '../../organisms/ManageSpace'

export default function SpaceFeaturePage(): JSX.Element {
  const content: { key: string; title: string; component: ReactNode }[] = [
    {
      key: 'manageBranch',
      title: '여러 개의 지점도 쉽게 통합 관리',
      component: <ManageBranch />,
    },
    {
      key: 'manageSpace',
      title: '맞춤 공간 관리',
      component: <ManageSpace />,
    },
  ]
  return (
    <FeaturePageTemplate
      title="지점・공간 관리"
      description="복잡할수록 단순하게 모아주는 통합 관리"
      icon={{ src: '/images/landing/features/diagram.png', alt: 'diagram-icon' }}
    >
      {content.map(({ key, title, component }) => (
        <S.Section key={key}>
          <S.SectionTitle>{title}</S.SectionTitle>
          {component}
        </S.Section>
      ))}
    </FeaturePageTemplate>
  )
}
