import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import NoticeAPI from '@apis/branch/notice'

import {
  NoticeAction,
  setNoticeLoading,
  setNoticeSearchResult,
  SEARCH_NOTICES,
  SET_NOTICE_DEFAULT,
  SET_NOTICE_PAGINATION,
  SET_NOTICE_SEARCH,
  SET_NOTICE_PAGE_CONFIG,
  setNoticePagination,
  searchNotices,
} from '@reducers/branch/notice.reducer'
import { RootState } from '@reducers/index'
import { setStorageNotice } from '@reducers/storage.reducer'

function* searchNoticeSaga(action: NoticeAction): Generator {
  if (action.type !== SEARCH_NOTICES) return
  const { hqId, branchId } = action.payload
  yield* put(setNoticeLoading(true))

  const search = yield* select((state: RootState) => state.notice.search)
  const pagination = yield* select((state: RootState) => state.notice.pagination)

  const {
    data: { notices, total },
  } = yield* call(NoticeAPI.getNotices, { hqId, branchId }, { ...search, ...pagination })

  yield* put(setNoticeSearchResult({ branchId, notices, total }))

  yield* put(setNoticeLoading(false))
}

function* savePageConfigSaga(action: NoticeAction): Generator {
  const state: Parameters<typeof setStorageNotice>[0] = {}

  if (action.type === SET_NOTICE_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageNotice(state))
}

function* setDefaultPageConfigSaga(action: NoticeAction): Generator {
  if (action.type !== SET_NOTICE_DEFAULT) return
  const { pageSize } = yield* select((state: RootState) => state.storage.noticeConfig)
  yield* all([put(setNoticePagination({ pageSize }))])

  const { hqId, branchId } = action.payload
  yield* put(searchNotices(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_NOTICES, searchNoticeSaga),
    takeEvery(SET_NOTICE_SEARCH, savePageConfigSaga),
    takeEvery(SET_NOTICE_PAGINATION, savePageConfigSaga),
    takeEvery(SET_NOTICE_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_NOTICE_DEFAULT, setDefaultPageConfigSaga),
  ])
}
