import { Form, Modal, notification, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useEffect, useState } from 'react'

import ContractAPI from '@apis/branch/contract'

import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  contractId: number
  onClose?: () => void
}

export default function ContractInviteModal({ onClose, hqId, branchId, contractId }: Props): JSX.Element {
  const [form] = useForm<{ contacts: string[] }>()
  const [loading, setLoading] = useState(false)

  const onOk = async () => {
    if (!contractId) return
    try {
      const { contacts } = await form.validateFields()
      setLoading(true)
      const { data } = await ContractAPI.sendContractInivite({ hqId, branchId, contractId }, { contacts })
      notification.success({
        message: `초대문자가 발송되었습니다.
        ${data.map((el) => `전송번호: ${el.to} 초대코드: ${el.inviteCode}`)}`,
      })
      setLoading(false)
      if (onClose) onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const reset = () => {
    form.resetFields()
    setLoading(false)
  }

  useEffect(() => {
    reset()
  }, [])

  return (
    <Modal
      title="계약 입주사 초대"
      onCancel={onClose}
      onOk={onOk}
      okText="초대하기"
      okButtonProps={{ loading }}
      visible
      maskClosable={false}
    >
      <Loading loading={loading}>
        <Form form={form} layout="vertical">
          <Form.Item
            label="전화번호"
            name="contacts"
            required
            extra="전화번호 입력 후 엔터 / 숫자만 입력"
            getValueFromEvent={(value: string[]) => value.map((str) => str.replace(/\D+/g, '')).filter((v) => v)}
            rules={[{ required: true, type: 'array', min: 1, message: '하나 이상의 전화번호를 입력하세요.' }]}
          >
            <Select mode="tags" />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}
