import { notification } from 'antd'

import ExpenditureAPI from '@apis/branch/expenditure'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  expenditureId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function ExpenditureDeleteModal({
  hqId,
  branchId,
  expenditureId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    if (!expenditureId) return
    await ExpenditureAPI.deleteExpenditure({ hqId, branchId, expenditureId })
    notification.success({ message: '지출 내역이 삭제되었습니다.' })
  }

  return (
    <ConfirmModal
      title="지출 내역이 삭제됩니다."
      description="삭제된 내역은 복구할 수 없습니다."
      okText="삭제"
      visible={visible}
      onClose={onClose}
      onDone={onDone}
      onConfirm={onConfirm}
    />
  )
}
