import { ReactComponent as ChevronLeftIcon } from '@assets/Icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from '@assets/Icons/chevron-right.svg'
import { Button } from 'antd'
import { ToolbarProps, View } from 'react-big-calendar'
import styled from 'styled-components'

export default function CustomToolBar({ label, onNavigate, onView, view }: ToolbarProps) {
  const viewOptions: { label: string; value: View }[] = [
    { label: '월', value: 'month' },
    { label: '주', value: 'week' },
    { label: '일', value: 'day' },
  ]

  return (
    <div className="rbc-toolbar">
      <Button type="default" onClick={() => onNavigate('TODAY')}>
        오늘
      </Button>
      <Button type="default" onClick={() => onNavigate('PREV')}>
        <ChevronLeftIcon width="24" height="24" stroke="#2b2b2b" />
      </Button>
      <Button type="default" onClick={() => onNavigate('NEXT')}>
        <ChevronRightIcon width="24" height="24" stroke="#2b2b2b" />
      </Button>
      <div className="rbc-toolbar-label">{label}</div>
      <div className="rbc-btn-group">
        <Views>
          {viewOptions.map(({ label, value }) => (
            <SelectedView
              selected={view === value}
              onClick={() => {
                onView(value)
              }}
            >
              {label}
            </SelectedView>
          ))}
        </Views>
      </div>
    </div>
  )
}

const Views = styled.div`
  width: 154px;
  height: 36px;
  border-radius: 8px;
  background: #dfe0e4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const SelectedView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2b2b2b;
  padding: 8px 19px;
  background: ${({ selected }: { selected: boolean }) => (selected ? '#ffffff' : '#dfe0e4')};
  box-shadow: ${({ selected }: { selected: boolean }) => (selected ? '0px 2px 8px rgba(0, 0, 0, 0.1)' : '0')};
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    background: ${({ selected }: { selected: boolean }) => (selected ? '#ffffff' : '#f4f5f7')};
  }
`
