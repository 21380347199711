import { Divider, Modal, Row } from 'antd'
import { Bills, Contracts } from 'gadjet-v2-types/dist/model'
import { contractDepositStatus } from 'gadjet-v2-types/dist/type/label'
import { useEffect, useMemo, useState } from 'react'

import { krw } from '@utils/format'

import BillAPI from '@apis/branch/bill'
import ContractAPI from '@apis/branch/contract'

import { prompt } from '@components/atoms/Prompt'
import Loading from '@components/molecules/Loading'
import ModalAlertItem from '@components/molecules/ModalAlert/Item'
import DepositBillListTable from '@components/organisms/Branch/Bill/ListTable/Deposit'

type Props = {
  hqId: number
  branchId: number
  contractId: number
  onClose?: () => void
  onDone?: () => void
}

export default function ContractDepositModal({ hqId, branchId, contractId, onClose, onDone }: Props): JSX.Element {
  const [contractState, setContractState] = useState<{ loading: boolean; contract: Contracts | null }>({
    loading: false,
    contract: null,
  })
  const [billState, setBillState] = useState<{ loading: boolean; bills: Bills[] }>({
    loading: false,
    bills: [],
  })

  const contractDeposit = useMemo(() => contractState.contract?.contractDeposit, [contractState.contract])
  const label = useMemo(() => {
    return {
      status: contractDeposit?.status ? contractDepositStatus[contractDeposit?.status] : '',
      price: krw(contractDeposit?.price),
      bank: contractDeposit?.bankName || '',
      account: contractDeposit?.account || '',
      holder: contractDeposit?.holder || '',
    }
  }, [contractDeposit])

  const onClickReturn = async ({ billId, price }: Bills) => {
    const { isConfirm, value } = await prompt('보증금 반환', {
      type: 'number',
      min: 0,
      placeholder: '반환한 보증금 금액을 적어주세요.',
      value: price,
      suffix: '원',
    })
    if (!isConfirm) return
    setBillState({ ...billState, loading: true })
    await BillAPI.returnDeposit({ hqId, branchId, billId }, { price: Number(value), type: 'transfer' })
    getContract()
    getDepositBills()
  }

  const getContract = async () => {
    if (!contractId) {
      setContractState({ loading: false, contract: null })
      return
    }

    setContractState({ loading: true, contract: null })
    const { data } = await ContractAPI.getContractDetail({ hqId, branchId, contractId })
    setContractState({ loading: false, contract: data })
  }

  const getDepositBills = async () => {
    if (!contractId) {
      setBillState({ loading: false, bills: [] })
      return
    }
    setBillState({ loading: true, bills: [] })
    const { data } = await ContractAPI.getContractDepositBills({ hqId, branchId, contractId }, {})
    setBillState({ loading: false, bills: data.bills })
  }

  useEffect(() => {
    getContract()
    getDepositBills()
  }, [])

  return (
    <Modal title="보증금" visible onCancel={onClose} cancelText="닫기" okButtonProps={{ hidden: true }} width="800px">
      <Loading loading={contractState.loading}>
        <Row gutter={[20, 40]}>
          <ModalAlertItem label="상태" value={label.status} span={6} />
          <ModalAlertItem label="금액" value={label.price} span={18} />
          <ModalAlertItem label="은행" value={label.bank} span={6} />
          <ModalAlertItem label="계좌" value={label.account} span={12} />
          <ModalAlertItem label="예금주" value={label.holder} span={6} />
        </Row>
      </Loading>
      <Divider />
      <DepositBillListTable loading={billState.loading} bills={billState.bills} onClickReturn={onClickReturn} />
    </Modal>
  )
}
