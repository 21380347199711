import { Additions, Spaces } from 'gadjet-v2-types/dist/model'

import bankCodes from './bankCodes'

export type ParserData = {
  options?: { label: string; value: string }[]
  branchSpaces?: Spaces[]
  branchAdditions?: Additions[]
}

export const number = (value: string) => {
  const n = Number(`${value}`.replace(/[^0-9]/g, ''))
  return isNaN(n) ? false : n
}

export const stringifyNumber = (value: string) => {
  const n = String(`${value}`.replace(/[^0-9]/g, ''))
  return n
}

export const option = (value: string, { options = [] }: ParserData) => {
  const findOption = options.find((o) => o.label === value)
  if (!findOption) return false
  return findOption.value
}

export const spaces = (value: string, { branchSpaces = [] }: ParserData) => {
  const results: { spaceId: number; name: string; price: number }[] = []
  const splited = value.split('\n')

  for (const str of splited) {
    if (str.trim() !== '') {
      const [name = '', price = ''] = str.split('/')
      const spaceName = name.replace('[', '').replace(']', '')
      const spacePrice = price.replace('[', '').replace(']', '')

      const findBranchSpace = branchSpaces.find((bs) => bs.name === spaceName)
      if (!findBranchSpace) return false
      results.push({ spaceId: findBranchSpace.spaceId, name: spaceName, price: Number(spacePrice) })
    }
  }

  return results
}
export const additions = (value: string, { branchAdditions = [] }: ParserData) => {
  const results: { additionId: number; name: string; price: number }[] = []
  const splited = value.split('\n')

  for (const str of splited) {
    if (str.trim() !== '') {
      const [name, price] = str.split('/')
      const additionName = name.replace('[', '').replace(']', '')
      const addtionPrice = price.replace('[', '').replace(']', '')

      const findBranchAddition = branchAdditions.find((ba) => ba.name === additionName)
      if (!findBranchAddition) return false
      results.push({ additionId: findBranchAddition.additionId, name: additionName, price: Number(addtionPrice) })
    }
  }

  return results
}
export const bank = (value: string) => {
  const findBankCode = bankCodes.find((bc) => bc.label === value)
  return findBankCode ? findBankCode.value : `${value}`
}
