import Picture from '@pages/Landing/FeaturePages/atoms/Picture'
import TenantIcon from '@pages/Landing/FeaturePages/atoms/TenantIcon'
import * as S from '@pages/Landing/FeaturePages/styles'

export default function ManageProduct(): JSX.Element {
  return (
    <S.SectionInnerWrapper flexDirection="row" width="1000px">
      <S.Container
        defaultStyles={{
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '460px',
          background: 'linear-gradient(145.76deg, #F1EFFC 10.41%, #F0F0FB 46.62%, #EAF8F0 86.82%)',
          position: 'relative',
        }}
        mediaStyles={{
          flexDirection: 'column',
          height: '440px',
        }}
      >
        <S.DescriptionWrapper defaultStyle={{ textAlign: 'center', padding: '40px 0 0 0' }}>
          <S.BoxTitle>서비스/상품 관리</S.BoxTitle>
          <S.Description>
            서비스와 상품도 <br />
            등록 한 번이면 OK.
          </S.Description>
        </S.DescriptionWrapper>
        <S.ImageWrapper defaultStyle={{ alignItems: 'flex-end' }} mediaStyle={{ justifyContent: 'center' }}>
          <Picture
            srcPNG="/images/landing/features/store/product-service.png"
            srcWEBP="/images/landing/features/store/product-service.webp"
            alt="product-service"
            width={620}
            height={220}
            defaultStyle={{ margin: '0 0 62px 0 ' }}
          />
        </S.ImageWrapper>
      </S.Container>
      <S.Container
        defaultStyles={{
          flexDirection: 'column',
          alignItems: 'center',
          height: '460px',
          background: 'linear-gradient(90deg, rgba(26, 26, 26, 0.95) 0%, rgba(26, 26, 26, 0.90) 100%)',
          position: 'relative',
        }}
        mediaStyles={{
          flexDirection: 'column',
          height: '440px',
        }}
      >
        <S.DescriptionWrapper defaultStyle={{ textAlign: 'center', padding: '40px 0 0 0' }}>
          <S.BoxTitle color="#ffffff">서비스/상품 이용</S.BoxTitle>
          <S.Description color="#ffffff">
            입주사는 앱에서 <br />
            간편하게 구매해요.
          </S.Description>
        </S.DescriptionWrapper>
        <S.ImageWrapper defaultStyle={{ alignItems: 'flex-end' }} mediaStyle={{ justifyContent: 'center' }}>
          <Picture
            srcPNG="/images/landing/features/store/service-use.png"
            srcWEBP="/images/landing/features/store/service-use.webp"
            alt="service-use"
            width={330}
            height={120}
            defaultStyle={{ margin: '0 0 116px  0 ' }}
          />
        </S.ImageWrapper>
        <TenantIcon />
      </S.Container>
    </S.SectionInnerWrapper>
  )
}
