import { Branches } from 'gadjet-v2-types/dist/model'

// ActionType
export const GET_HQ_BRANCHES = 'hq/branch/GET_HQ_BRANCHES' as const
export const SET_HQ_BRANCHES = 'hq/branch/SET_HQ_BRANCHES' as const
export const SET_HQ_BRANCH_LOADING = 'hq/branch/SET_HQ_BRANCH_LOADING' as const

// Action
export const getHqBranches = (hqId: number) => ({
  type: GET_HQ_BRANCHES,
  payload: { hqId },
})
export const setHqBranches = (branches: Branches[]) => ({
  type: SET_HQ_BRANCHES,
  payload: { branches },
})
export const setHqBranchLoading = (loading: boolean) => ({
  type: SET_HQ_BRANCH_LOADING,
  payload: { loading },
})

export type HqBranchAction =
  | ReturnType<typeof getHqBranches>
  | ReturnType<typeof setHqBranches>
  | ReturnType<typeof setHqBranchLoading>

type HqBranchState = {
  branches: Branches[]
  loading: boolean
}

const hqBranchState: HqBranchState = {
  branches: [],
  loading: false,
}

const hqBranchReducer = (state = hqBranchState, action: HqBranchAction): HqBranchState => {
  switch (action.type) {
    case SET_HQ_BRANCHES:
      return { ...state, branches: action.payload.branches }
    case SET_HQ_BRANCH_LOADING:
      return { ...state, loading: action.payload.loading }
    default:
      return state
  }
}

export default hqBranchReducer
