import { BREAKPOINTS } from '@const'
import dayjs from 'dayjs'
import { useState } from 'react'
import styled from 'styled-components'

import Estimate from './organisms/Estimate'
import FAQ from './organisms/FAQ'
import GadjetPlans from './organisms/GadjetPlans'

export default function PricingPage(): JSX.Element {
  const gadjetPlans: GadjetPlan[] = [
    {
      label: '월 구독',
      key: 'monthly',
      monthlyPrice: 190000,
      details: [
        { key: 'monthly-pay', content: '후불 월 결제\n(이번 달 이용 금액 다음 달 결제)' },
        { key: 'cancellable', content: '중도해지 가능' },
        { key: 'no-cancellation-fee', content: '위약금 없음' },
      ],
    },
    {
      label: '1년 구독',
      key: 'yearly',
      monthlyPrice: 170000,
      discountRate: '10%',
      details: [
        { key: 'monthly-pay', content: '후불 월 결제\n(이번 달 이용 금액 다음 달 결제)' },
        { key: 'cancellable', content: '중도해지 가능' },
        { key: 'cancellation-fee', content: '위약금 있음' },
      ],
      isMostPopular: true,
    },
    {
      label: '1년 선결제',
      key: 'yearPrepay',
      monthlyPrice: 150000,
      discountRate: '22%',
      details: [
        { key: 'prepay-for-year', content: '선불 연 결제\n(1년 이용료 선결제)' },
        { key: 'uncancellable', content: '중도해지 불가' },
        { key: 'no-cancellation-fee', content: '위약금 없음' },
      ],
    },
  ]

  const promotion: Promotion = {
    isVisible: true,
    rate: 0.5,
    durationMonth: 2,
    endDate: dayjs('2022-11-30'),
  }

  const [selectedPlan, setSelectedPlan] = useState(gadjetPlans[1])
  const popularMontlyPrice = gadjetPlans[1].monthlyPrice
  const [selectedMonthlyAmount, setSelectedMonthlyAmount] = useState<number>(
    promotion.isVisible ? popularMontlyPrice * promotion.rate : popularMontlyPrice
  )

  const onClickSelectPlan = (plan: GadjetPlan) => {
    const { monthlyPrice } = plan
    const promotionMonthlyPrice = monthlyPrice * promotion.rate

    setSelectedPlan(plan)
    setSelectedMonthlyAmount(promotion.isVisible ? promotionMonthlyPrice : monthlyPrice)
  }
  return (
    <Background>
      <GadjetPlans
        gadjetPlans={gadjetPlans}
        promotion={promotion}
        selectedPlan={selectedPlan}
        onClickSelectPlan={onClickSelectPlan}
      />
      <Estimate
        gadjetPlans={gadjetPlans}
        promotion={promotion}
        selectedPlan={selectedPlan}
        selectedMonthlyAmount={selectedMonthlyAmount}
        onClickSelectPlan={onClickSelectPlan}
      />
      <FAQ />
    </Background>
  )
}

const Background = styled.div`
  width: 100%;
  background-image: url('/images/landing/pricing/pricing-bg-1920.png');
  overflow: hidden;
  padding-top: 200px;
  @media ${BREAKPOINTS.lg} {
    padding-top: 119px;
  }
  @media ${BREAKPOINTS.sm} {
    background-image: url('/images/landing/pricing/pricing-bg-mobile.png');
  }
`
