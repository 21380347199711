/* eslint-disable react/no-danger */
import { Styles } from '@types'
import { Notices } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'

import NoticeAPI from '@apis/branch/notice'
import HqNoticeAPI from '@apis/hq/notice'

import Loading from '@components/molecules/Loading'
import ModalAlert from '@components/molecules/ModalAlert'

type Props = {
  hqId: number
  branchId?: number
  noticeId?: number
  visible: boolean
  onClose: () => void
}

export default function NoticeDetailModal({ hqId, branchId, noticeId, visible, onClose }: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [notice, setNotice] = useState<Notices>()

  const getNotice = async () => {
    if (!hqId || !noticeId) return
    setLoading(true)
    const call = branchId
      ? NoticeAPI.getNoticeDetail({ hqId, branchId, noticeId })
      : HqNoticeAPI.getNoticeDetail({ hqId, noticeId })

    const { data } = await call
    setNotice(data)
    setLoading(false)
  }

  useEffect(() => {
    if (!visible || !hqId || !noticeId) return
    getNotice()
  }, [visible])

  return (
    <ModalAlert title={notice?.title || '공지사항'} visible={visible} onCancel={onClose} width="600px">
      <Loading loading={loading}>
        <div style={styles.content} dangerouslySetInnerHTML={{ __html: notice?.content || '' }} />
      </Loading>
    </ModalAlert>
  )
}

const styles: Styles = {
  content: { minHeight: '300px' },
}
