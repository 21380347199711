import { ReactComponent as Tick } from '@assets/Icons/biz-tick.svg'
import { BIZ_BREAKPOINTS, GADJET_BIZ_COLORS } from '@const'
import styled from 'styled-components'

type FeatureKey = 'property' | 'maintain' | 'manage-contract' | 'usage-auth' | 'advanced-feature'

export default function RecommendationTable() {
  const sideCols: { key: FeatureKey; featureLabel: string }[] = [
    { key: 'property', featureLabel: '부동산 운영' },
    { key: 'maintain', featureLabel: '유지보수' },
    { key: 'manage-contract', featureLabel: '계약 관리' },
    { key: 'usage-auth', featureLabel: '이용 권한 설정' },
    { key: 'advanced-feature', featureLabel: '고급 기능' },
  ]

  const rowHeader: {
    title: string
    key: string
    features: {
      key: FeatureKey
      ticked: boolean
    }[]
  }[] = [
    {
      title: '공유공간 운영자',
      key: 'spaceManager',
      features: [
        { key: 'property', ticked: false },
        { key: 'maintain', ticked: true },
        { key: 'manage-contract', ticked: true },
        { key: 'usage-auth', ticked: true },
        { key: 'advanced-feature', ticked: true },
      ],
    },
    {
      title: '건물주',
      key: 'landlord',
      features: [
        { key: 'property', ticked: true },
        { key: 'maintain', ticked: false },
        { key: 'manage-contract', ticked: false },
        { key: 'usage-auth', ticked: true },
        { key: 'advanced-feature', ticked: true },
      ],
    },
    {
      title: 'PMC',
      key: 'PMC',
      features: [
        { key: 'property', ticked: true },
        { key: 'maintain', ticked: true },
        { key: 'manage-contract', ticked: true },
        { key: 'usage-auth', ticked: true },
        { key: 'advanced-feature', ticked: true },
      ],
    },
    {
      title: 'FM 전문회사',
      key: 'fmCompany',
      features: [
        { key: 'property', ticked: false },
        { key: 'maintain', ticked: true },
        { key: 'manage-contract', ticked: false },
        { key: 'usage-auth', ticked: true },
        { key: 'advanced-feature', ticked: true },
      ],
    },
  ]

  return (
    <Wrapper>
      <Table>
        <SideHeader>
          {sideCols.map(({ key, featureLabel }) => (
            <SideHeaderCell key={key}>{featureLabel}</SideHeaderCell>
          ))}
        </SideHeader>
        <MainWrapper>
          <TopHeaderRow>
            {rowHeader.map(({ key, title }) => (
              <TopHeaderCell key={key}>{title}</TopHeaderCell>
            ))}
          </TopHeaderRow>
          <MainColWrap>
            {rowHeader.map(({ key, features }) => (
              <MainCol key={key}>
                {features.map(({ key, ticked }) => (
                  <MainCell key={key}>{ticked && <Tick />}</MainCell>
                ))}
              </MainCol>
            ))}
          </MainColWrap>
        </MainWrapper>
      </Table>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Table = styled.div`
  width: 1000px;
  display: flex;
  gap: 12px;
  filter: drop-shadow(8px 8px 80px rgba(24, 34, 85, 0.4));
  padding: 0 16px;
  @media ${BIZ_BREAKPOINTS.lg} {
    width: 100%;
    max-width: 500px;
    gap: 8px;
  }
`
const SideHeaderCell = styled.div`
  white-space: nowrap;
  width: 200px;
  height: 70px;
  display: flex;
  padding: 14px 0px;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  background: rgba(24, 34, 85, 0.8);

  backdrop-filter: blur(2.475247383117676px);

  color: #fff;

  text-align: center;
  font-size: 17px;
  font-weight: 700;
  line-height: 30px;

  :first-child {
    border-top: 1px solid #fff;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
  }

  :last-child {
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
  }
  @media ${BIZ_BREAKPOINTS.lg} {
    width: 120px;
    height: 54px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.3px;

    :first-child {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
    }

    :last-child {
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
    }
  }
`
const SideHeader = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  @media ${BIZ_BREAKPOINTS.lg} {
    margin-top: 60px;
  }
`

const TopHeaderRow = styled.div`
  display: flex;
  width: 100%;
  gap: 4px;
`
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  @media ${BIZ_BREAKPOINTS.lg} {
    gap: 8px;
    overflow-x: scroll;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`
const MainCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const MainColWrap = styled.div`
  display: flex;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`
const MainCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 93px;
  padding: 12px;
  white-space: nowrap;
  height: 70px;

  background: rgba(255, 255, 255, 0.94);
  backdrop-filter: blur(2px);

  border-bottom: 1px solid #e3e3e3;

  :first-child {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  :last-child {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom: none;
  }
  @media ${BIZ_BREAKPOINTS.lg} {
    height: 54px;
    :first-child {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
    }

    :last-child {
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
    }
  }
`

const TopHeaderCell = styled.div`
  width: 100%;
  min-width: 90px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  border: 1px solid ${GADJET_BIZ_COLORS.primary};

  background: rgba(24, 34, 85, 0.9);

  color: #fff;

  text-align: center;
  font-size: 17px;
  font-weight: 700;
  line-height: 30px;
  padding: 8px;

  @media ${BIZ_BREAKPOINTS.lg} {
    height: 52px;
    font-size: 15px;
    line-height: 18px;
    border-radius: 8px;
  }
`
