import { notification } from 'antd'

import RentalAPI from '@apis/branch/rental'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  rentalId?: number
  rentalReservationId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function RentalReservationCancelModal({
  hqId,
  branchId,
  rentalId,
  rentalReservationId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    if (!rentalReservationId || !rentalId) return
    await RentalAPI.deleteRentalReservation({ hqId, branchId, rentalId, rentalReservationId })
    notification.success({ message: '예약이 취소되었습니다.' })
  }

  return (
    <ConfirmModal
      title="대관/대여 예약취소"
      description="예약이 취소됩니다."
      onConfirm={onConfirm}
      visible={visible}
      onClose={onClose}
      onDone={onDone}
    />
  )
}
