import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { CSSProperties, useMemo } from 'react'

type Props = {
  loading?: boolean
  size?: 'small' | 'normal' | 'large'
  children?: JSX.Element | JSX.Element[]
  style?: CSSProperties
}
export default function Loading({ loading = true, size = 'normal', children, style }: Props): JSX.Element {
  const fontSize = useMemo(() => {
    if (size === 'small') return 24
    if (size === 'large') return 40
    return 32
  }, [size])

  return (
    <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize }} spin />}>
      {children}
    </Spin>
  )
}
