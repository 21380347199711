import { Point, ResponsiveLine, Serie } from '@nivo/line'

import Tooltip from '../Tooltip'

type Axis = {
  legend?: string
  format?: (v: string) => string | number
}

type Props = {
  data: Serie[]
  axis: {
    top?: Axis
    bottom?: Axis
    left?: Axis
    right?: Axis
  }
  labelFormat?: (p: Point) => string
  height: string
}

export default function GraphLine({ data, axis, labelFormat, height }: Props): JSX.Element {
  return (
    <div style={{ width: '100%', height }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', reverse: false }}
        axisTop={axis.top}
        axisRight={axis.right}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
          ...axis.bottom,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -40,
          legendPosition: 'middle',
          ...axis.left,
        }}
        pointSize={10}
        pointBorderWidth={2}
        pointLabelYOffset={-12}
        useMesh
        enableSlices="x"
        sliceTooltip={({ slice }) => <Tooltip points={slice.points} labelFormat={labelFormat} />}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            toggleSerie: true,
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  )
}
