import { Notices } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'

// ActionType
export const GET_HQ_NOTICES = 'hq/notice/GET_HQ_NOTICES' as const
export const SET_HQ_NOTICES = 'hq/notice/SET_HQ_NOTICES' as const
export const SET_HQ_NOTICE_LOADING = 'hq/notice/SET_HQ_NOTICE_LOADING' as const

// Action
export const getHqNotices = (hqId: number, pagination: Pagination) => ({
  type: GET_HQ_NOTICES,
  payload: { hqId, pagination },
})
export const setHqNotices = (notices: Omit<Notices, 'content'>[], total: number) => ({
  type: SET_HQ_NOTICES,
  payload: { notices, total },
})
export const setHqNoticeLoading = (loading: boolean) => ({
  type: SET_HQ_NOTICE_LOADING,
  payload: { loading },
})

export type HqNoticeAction =
  | ReturnType<typeof getHqNotices>
  | ReturnType<typeof setHqNotices>
  | ReturnType<typeof setHqNoticeLoading>

type HqNoticeState = {
  loading: boolean
  total: number
  notices: Omit<Notices, 'content'>[]
}

const hqNoticeState: HqNoticeState = {
  loading: true,
  total: 0,
  notices: [],
}

const hqNoticeReducer = (state = hqNoticeState, action: HqNoticeAction): HqNoticeState => {
  switch (action.type) {
    case SET_HQ_NOTICES:
      return { ...state, ...action.payload }
    case SET_HQ_NOTICE_LOADING:
      return { ...state, loading: action.payload.loading }
    default:
      return state
  }
}

export default hqNoticeReducer
