import Picture from '@pages/Landing/FeaturePages/atoms/Picture'
import * as S from '@pages/Landing/FeaturePages/styles'

export default function DashboardView(): JSX.Element {
  return (
    <S.Container
      defaultStyles={{ flexDirection: 'column', minHeight: '480px', background: '#f2f0fc', padding: '40px 0 0 0' }}
    >
      <S.DescriptionWrapper defaultStyle={{ textAlign: 'center' }}>
        <S.BoxTitle>지점 대시보드</S.BoxTitle>
        <S.Description>
          이번 달 현황을
          <br />
          한눈에 살펴봐요.
        </S.Description>
        <S.ImageWrapper defaultStyle={{ alignItems: 'center' }}>
          <Picture
            srcPNG="/images/landing/features/contract/dashboard-view.png"
            srcWEBP="/images/landing/features/contract/dashboard-view.webp"
            alt="dashboard-view"
            width={922}
            height={300}
            defaultStyle={{ margin: '20px 0 17px 0', alignSelf: 'flex-end' }}
            mediaStyle={{ margin: '20px 0 61px 0' }}
            mediaScale={0.82}
          />
        </S.ImageWrapper>
      </S.DescriptionWrapper>
    </S.Container>
  )
}
