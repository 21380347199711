import { Styles } from '@types'

import Loading from '@components/molecules/Loading'

export default function FullPageLoading(): JSX.Element {
  return (
    <div style={style.div}>
      <Loading size="large" />
    </div>
  )
}

const style: Styles = {
  div: {
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}
