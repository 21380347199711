import { takeLatest } from 'redux-saga/effects'
import { all, call, put } from 'typed-redux-saga'

import BranchAPI from '@apis/branch'

import { BranchAction, GET_BRANCH, setBranch } from '@reducers/branch/branch.reducer'

function* getBranchSaga(action: BranchAction): Generator {
  if (action.type !== GET_BRANCH) return
  const { hqId, branchId, cb } = action.payload

  const { data: branch } = yield* call(BranchAPI.getBranch, { hqId, branchId })
  yield* put(setBranch(branch))
  if (cb) cb(branch)
}

export default function* branchSaga(): Generator {
  yield* all([takeLatest(GET_BRANCH, getBranchSaga)])
}
