import * as BRANCH from 'gadjet-v2-types/dist/api/hq/branch'

import axios from '@utils/axios'

const HqBranchCardAPI = {
  // 지점추가 결제수단 카드추가
  addBranchCard: async ({ hqId }: BRANCH.ADD_CARD_PARAMS, { card, contact, email, name }: BRANCH.ADD_CARD_BODY) =>
    axios.post<BRANCH.ADD_CARD_RESPONSE>(`/hqs/${hqId}/branches/card`, { card, contact, email, name }),
}

export default HqBranchCardAPI
