import { Row } from 'antd'
import { BillSummary } from 'gadjet-v2-types/dist/type'
import { useMemo } from 'react'

import { krw } from '@utils/format'

import ContentWrapper from '@components/atoms/ContentWrapper'
import SummaryItem, { SummaryItemType } from '@components/molecules/SummaryItem'

type Props = {
  summary: BillSummary
}

export default function BranchBillSummary({ summary }: Props): JSX.Element {
  const items = useMemo(
    (): { key: keyof BillSummary; label: string; value: string | number; type: SummaryItemType }[] => [
      { key: 'sales', label: '매출', value: krw(summary.sales), type: 'primary' },
      { key: 'unpaid', label: '미납', value: krw(summary.unpaid), type: 'error' },
      { key: 'expectedSales', label: '총 예상매출', value: krw(summary.expectedSales), type: 'default' },
      { key: 'space', label: '예상매출 - 공간이용료', value: krw(summary.space), type: 'default' },
      { key: 'addition', label: '예상매출 - 부가서비스', value: krw(summary.addition), type: 'default' },
      { key: 'etc', label: '예상매출 - 기타', value: krw(summary.etc), type: 'default' },
    ],
    [summary]
  )

  return (
    <ContentWrapper>
      <Row>
        {items.map(({ key, label, value, type }) => (
          <SummaryItem key={key} label={label} value={value} span={4} type={type} />
        ))}
      </Row>
    </ContentWrapper>
  )
}
