import { Form, Input } from 'antd'

type Props = { names: string[] }
export default function HiddenItems({ names }: Props): JSX.Element {
  return (
    <>
      {names.map((name) => (
        <Form.Item key={name} name={name} noStyle hidden>
          <Input />
        </Form.Item>
      ))}
    </>
  )
}
