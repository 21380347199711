// ActionType
export const GET_HQ_GRAPH_PAYMENTS = 'hq/graph/GET_HQ_GRAPH_PAYMENTS' as const
export const GET_HQ_GRAPH_EXPECT_PAYMENTS = 'hq/graph/GET_HQ_GRAPH_EXPECT_PAYMENTS' as const
export const GET_HQ_GRAPH_CONTRACTS = 'hq/graph/GET_HQ_GRAPH_CONTRACTS' as const
export const GET_HQ_GRAPH_SPACE_TYPES = 'hq/graph/GET_HQ_GRAPH_SPACE_TYPES' as const
export const SET_HQ_GRAPH = 'hq/graph/SET_HQ_GRAPH' as const

// Action
export const getHqGraphPayments = (hqId: number, cb?: () => void) => ({
  type: GET_HQ_GRAPH_PAYMENTS,
  payload: { hqId, cb },
})
export const getHqGraphExpectPayments = (hqId: number, cb?: () => void) => ({
  type: GET_HQ_GRAPH_EXPECT_PAYMENTS,
  payload: { hqId, cb },
})
export const getHqGraphContracts = (hqId: number, cb?: () => void) => ({
  type: GET_HQ_GRAPH_CONTRACTS,
  payload: { hqId, cb },
})
export const getHqGraphSpaceTypes = (hqId: number, cb?: () => void) => ({
  type: GET_HQ_GRAPH_SPACE_TYPES,
  payload: { hqId, cb },
})
export const setHqGraph = (state: Partial<HqGraphState>) => ({
  type: SET_HQ_GRAPH,
  payload: { state },
})

export type HqGraphAction =
  | ReturnType<typeof getHqGraphPayments>
  | ReturnType<typeof getHqGraphExpectPayments>
  | ReturnType<typeof getHqGraphContracts>
  | ReturnType<typeof getHqGraphSpaceTypes>
  | ReturnType<typeof setHqGraph>

type HqGraphState = {
  payments: {
    payments: { month: string; price: number }[] // 결제내역 (청구서 기준)
    expectPayments: { month: string; price: number }[] // 예상 결제내역 (청구서 기준)
    expenditure: { month: string; price: number }[] // 지출
  }
  expectPayments: {
    [branchName: string]: { month: string; price: number }[] // 지점별
  }
  contracts: {
    [branchName: string]: { month: string; start: number; end: number }[] // 지점별
  }
  spaceTypes: {
    [branchName: string]: {
      [spaceTypeName: string]: { month: string; price: number }[]
    }
  }
}

const hqGraphState: HqGraphState = {
  payments: { payments: [], expectPayments: [], expenditure: [] },
  expectPayments: { total: [] },
  contracts: { total: [] },
  spaceTypes: {},
}

const hqGraphReducer = (state = hqGraphState, action: HqGraphAction): HqGraphState => {
  switch (action.type) {
    case SET_HQ_GRAPH:
      return { ...state, ...action.payload.state }
    default:
      return state
  }
}

export default hqGraphReducer
