import { Button, Result } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'

const NotFoundPage: React.FC = () => {
  const { push } = useHistory()

  return (
    <Result
      status="404"
      title="404"
      subTitle="죄송합니다. 방문한 페이지가 존재하지 않습니다."
      extra={
        <Button type="primary" onClick={() => push('/')}>
          홈으로 가기
        </Button>
      }
    />
  )
}

export default NotFoundPage
