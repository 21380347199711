import useModal from '@hooks/useModal'
import { Button, Table, TableColumnType } from 'antd'
import { ContractSpaces } from 'gadjet-v2-types/dist/model'
import { useMemo } from 'react'

import { krw } from '@utils/format'

import ModalAlert from '@components/molecules/ModalAlert'

export default function Space({ contract }: ContractColumnProps): JSX.Element {
  const { contractSpaces } = contract
  const [modal, onVisible, onClose] = useModal({ visible: false })

  const label = useMemo(() => {
    if (!contractSpaces || contractSpaces.length === 0) return '-'
    if (contractSpaces.length === 1) return contractSpaces[0].name
    return `${contractSpaces[0].name} 외 ${contractSpaces.length - 1}건`
  }, [contractSpaces])

  const columns: TableColumnType<ContractSpaces>[] = [
    { key: 'name', dataIndex: 'name', title: '이름' },
    { key: 'price', dataIndex: 'price', title: '계약가', render: (price) => krw(price) },
  ]

  return (
    <>
      <Button onClick={() => onVisible()}>{label}</Button>
      <ModalAlert title="공간" visible={modal.visible} onCancel={onClose}>
        <Table
          rowKey={({ spaceId }) => `${spaceId}`}
          columns={columns}
          dataSource={contractSpaces || []}
          size="small"
          pagination={false}
        />
      </ModalAlert>
    </>
  )
}
