import spaceLabelBg from '@utils/spaceLabelBg'

type Props = {
  name: string
  colorInfo: string
}

export default function ColoredSpaceLabel({ name, colorInfo }: Props) {
  return (
    <>
      <div
        style={{ ...styles, border: `1px solid ${colorInfo || '#3ebd73'}`, backgroundColor: spaceLabelBg(colorInfo) }}
      >
        {name}
      </div>
    </>
  )
}

const styles = {
  border: `1px solid #3EBD73`,
  width: 'max-content',
  borderRadius: '6px',
  padding: '3px 8px',
}
