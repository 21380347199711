import { BREAKPOINTS } from '@const'
import { HTMLAttributeAnchorTarget } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

type Props = {
  label: string
  path: string
  target?: HTMLAttributeAnchorTarget
}

export default function HeaderCTAButton({ label, path, target }: Props): JSX.Element {
  return (
    <Button to={{ pathname: path }} target={target}>
      {label}
    </Button>
  )
}

const Button = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 10px 14px;
  background: rgba(13, 13, 13, 0.9);
  opacity: 0.9;
  backdrop-filter: blur(2px);
  color: #ffffff;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  :hover {
    transition: all 0.3s ease-in-out;
    background: rgba(13, 13, 13, 0.75);
    color: #ffffff;
  }
  cursor: pointer;
  @media ${BREAKPOINTS.lg} {
    width: 100%;
    height: 60px;
    padding: 20px 0px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    margin-top: 50px;
    :hover {
      transition: none;
      background: rgba(13, 13, 13, 0.9);
      color: #ffffff;
    }
  }
`
