import { ReactComponent as ArrowRightMIcon } from '@assets/Icons/arrow-right-m.svg'
import { BREAKPOINTS, FREE_TRIAL_URL } from '@const'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export default function FreeTrial(): JSX.Element {
  return (
    <FreeTrialWrapper>
      <InnerSection>
        <FunnelIcon src="/images/landing/home/funnel.png" alt="funnel" />
        <Text>
          많은 시간이 드는
          <MediaBr /> 반복적인 일은 가젯에게 맡기고 <br />
          <Emphasis>
            더 좋은 공간 경험을
            <MediaBr /> 제공하는 일에만 집중하세요
          </Emphasis>
        </Text>
        <Link to={{ pathname: FREE_TRIAL_URL }} target="_blank">
          <FreeTrialButton>
            가젯 도입하기
            <ArrowRightMIcon stroke="#ffffff" />
          </FreeTrialButton>
        </Link>
      </InnerSection>
    </FreeTrialWrapper>
  )
}

const FreeTrialWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 89.5px 112px;
  background-color: #2e2e2e;
  @media ${BREAKPOINTS.lg} {
    padding: 59.5px 32px;
  }
`

const InnerSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  @media ${BREAKPOINTS.lg} {
    gap: 18px;
  }
`
const FunnelIcon = styled.img`
  width: 80px;
  height: 99px;
`
const Text = styled.h2`
  font-weight: 600;
  font-size: 35px;
  line-height: 150%;
  text-align: center;
  color: #a2a6be;
  margin-bottom: 0;
  @media ${BREAKPOINTS.lg} {
    font-size: 26px;
    text-align: start;
    margin-bottom: 14px;
  }
`
const Emphasis = styled.span`
  color: #ffffff;
`
const FreeTrialButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 14px 20px 14px 24px;
  background: linear-gradient(240deg, #26a25a 0.2%, #40da80 100.2%);
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  border-radius: 12px;
  border: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #ffffff;
  cursor: pointer;
  :hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
      linear-gradient(90deg, #26a25a 0.2%, #40da80 100.2%);
  }
`

const MediaBr = styled.br`
  display: none;
  @media ${BREAKPOINTS.lg} {
    display: block;
  }
`
