import { Styles } from '@types'
import { Table, TableColumnType, TablePaginationConfig, Tag } from 'antd'
import { AccessLogs } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'

import ContentWrapper from '@components/atoms/ContentWrapper'
import TableColumnItem from '@components/molecules/TableColumnItem'

type Props = {
  loading: boolean
  total: number
  pagination: Pagination
  accessLogs: AccessLogs[]
  onChangePagination: (pagination: Pagination) => void
}
export default function BranchAccessLogListTable({
  loading,
  total,
  pagination,
  accessLogs,
  onChangePagination,
}: Props): JSX.Element {
  const onChange = (_pagination: TablePaginationConfig) => onChangePagination({ ...pagination, ..._pagination })

  const columns: TableColumnType<AccessLogs>[] = [
    { key: 'title', dataIndex: 'title', title: '메뉴' },
    {
      key: 'successFlag',
      dataIndex: 'successFlag',
      title: '성공여부',
      render: (successFlag) => {
        if (successFlag) return <Tag color="blue">성공</Tag>
        return <Tag color="red">실패</Tag>
      },
    },
    { key: 'datetime', dataIndex: 'datetime', title: '일시' },
    {
      key: 'accessLogId',
      dataIndex: 'accessLogId',
      title: '접속정보',
      render: (_, a) => {
        return (
          <>
            <TableColumnItem label="URL" value={a.url} />
            <TableColumnItem label="IP" value={a.ip} />
            <TableColumnItem label="UA" value={a.ua} />
          </>
        )
      },
    },
    {
      key: 'managerId',
      dataIndex: 'managerId',
      title: '담당자',
      render: (_, { managerName, manager }) => manager?.name || managerName,
    },
  ]

  return (
    <ContentWrapper>
      <Table<AccessLogs>
        loading={loading}
        style={styles.table}
        scroll={{ x: true }}
        rowKey={({ accessLogId }) => `${accessLogId}`}
        dataSource={accessLogs}
        columns={columns}
        onChange={onChange}
        pagination={{
          total,
          position: ['bottomCenter'],
          current: pagination.current,
          pageSize: pagination.pageSize,
        }}
      />
    </ContentWrapper>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
}
