import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import TenantAPI from '@apis/branch/tenant'

import {
  TenantAction,
  setTenantLoading,
  setTenantSearchResult,
  SEARCH_TENANTS,
  SET_TENANT_DEFAULT,
  SET_TENANT_PAGINATION,
  SET_TENANT_SEARCH,
  SET_TENANT_PAGE_CONFIG,
  setTenantPagination,
  searchTenants,
  setTenantSearch,
} from '@reducers/branch/tenant.reducer'
import { RootState } from '@reducers/index'
import { setStorageTenant } from '@reducers/storage.reducer'

function* searchTenantSaga(action: TenantAction): Generator {
  if (action.type !== SEARCH_TENANTS) return
  const { hqId, branchId } = action.payload
  yield* put(setTenantLoading(true))

  const search = yield* select((state: RootState) => state.tenant.search)
  const pagination = yield* select((state: RootState) => state.tenant.pagination)

  const {
    data: { tenants, total },
  } = yield* call(TenantAPI.getTenants, { hqId, branchId }, { ...search, ...pagination })

  yield* put(setTenantSearchResult({ branchId, tenants, total }))

  yield* put(setTenantLoading(false))
}

function* savePageConfigSaga(action: TenantAction): Generator {
  const state: Parameters<typeof setStorageTenant>[0] = {}

  if (action.type === SET_TENANT_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageTenant(state))
}

function* setDefaultPageConfigSaga(action: TenantAction): Generator {
  if (action.type !== SET_TENANT_DEFAULT) return
  const { pageSize } = yield* select((state: RootState) => state.storage.tenantConfig)
  yield* all([put(setTenantSearch({})), put(setTenantPagination({ pageSize }))])

  const { hqId, branchId } = action.payload
  yield* put(searchTenants(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_TENANTS, searchTenantSaga),
    takeEvery(SET_TENANT_SEARCH, savePageConfigSaga),
    takeEvery(SET_TENANT_PAGINATION, savePageConfigSaga),
    takeEvery(SET_TENANT_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_TENANT_DEFAULT, setDefaultPageConfigSaga),
  ])
}
