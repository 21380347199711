import { Form, Modal, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { BranchRoles } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'

import RoleAPI from '@apis/branch/role'

import Loading from '@components/molecules/Loading'
import RoleSelector from '@components/molecules/RoleSelector'

type Props = { hqId: number; branchId: number; branchRoleId: number; visible: boolean; onClose: () => void }
export default function BranchRoleUpdateModal({ hqId, branchId, branchRoleId, visible, onClose }: Props): JSX.Element {
  const [form] = useForm<BranchRoles>()
  const [loading, setLoading] = useState(false)

  const onOk = async () => {
    const values = await form.validateFields()
    setLoading(true)
    await RoleAPI.updateRole({ hqId, branchId, branchRoleId }, { role: values })
    notification.success({ message: '수정되었습니다.' })
    setLoading(false)
    onClose()
  }

  const getBranchRole = async () => {
    setLoading(true)
    const { data } = await RoleAPI.getRoleDetail({ hqId, branchId, branchRoleId })
    form.setFieldsValue(data)
    setLoading(false)
  }

  useEffect(() => {
    form.resetFields()
    if (visible && branchRoleId) getBranchRole()
  }, [visible, branchRoleId])

  return (
    <Modal title="권한 수정" visible={visible} onCancel={onClose} okText="수정하기" onOk={onOk} width="400px">
      <Loading loading={loading}>
        <Form<BranchRoles> form={form} layout="vertical">
          <Form.Item name="dashboard" label="대시보드" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="contract" label="계약" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="space" label="공간" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="addition" label="부가서비스" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="bill" label="청구서" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="receipt" label="증빙" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="payment" label="결제내역" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="rental" label="대관/대여" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="tenant" label="입주사" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="notice" label="공지사항" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="product" label="상품" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="service" label="서비스" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="expenditure" label="지출" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="event" label="이벤트" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="cash" label="현금" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="accessLog" label="접근기록" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="role" label="권한" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="config" label="설정" valuePropName="role">
            <RoleSelector />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}
