import { Styles } from '@types'
import { Form, Input, Modal, notification, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ContractReceiver } from 'gadjet-v2-types/dist/type'
import { useEffect, useState } from 'react'

import formRule from '@utils/formRule'

import BillAPI from '@apis/branch/bill'

import FormListTable, { FormListTableColumn } from '@components/molecules/FormListTable'
import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  billId: number
  visible: boolean
  onClose: () => void
}

export default function BillSendModal({ hqId, branchId, billId, visible, onClose }: Props): JSX.Element {
  const [form] = useForm<{ receivers: ContractReceiver['bill'] }>()
  const [loading, setLoading] = useState(false)

  const getBill = async () => {
    setLoading(true)
    form.resetFields()
    const { data } = await BillAPI.getBill({ hqId, branchId, billId })
    const receivers = data.contract?.receiver?.bill || []

    form.setFieldsValue({ receivers })

    setLoading(false)
  }

  const onOk = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)
      await BillAPI.sendBill({ hqId, branchId, billId }, values)
      notification.success({ message: '발송되었습니다.' })
      setLoading(false)
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (visible) getBill()
  }, [visible, billId])

  const columns: FormListTableColumn[] = [
    {
      label: '타입',
      render: (field) => (
        <Form.Item isListField name={[field.name, 'type']} help="문자/이메일">
          <Select style={styles.select}>
            <Select.Option value="sms">문자</Select.Option>
            <Select.Option value="email">이메일</Select.Option>
          </Select>
        </Form.Item>
      ),
    },
    {
      label: '값',
      render: (field, index) => (
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const billReceivers = getFieldValue('receivers') || []
            const { type } = billReceivers[index] || { type: 'sms' }
            const rules = [formRule.required]
            if (type === 'sms') rules.push(formRule.contact)
            if (type === 'email') rules.push(formRule.email)
            const help = type === 'sms' ? '숫자만 입력하세요.' : '이메일을 입력하세요.'

            return (
              <Form.Item isListField name={[field.name, 'value']} rules={rules} required help={help}>
                <Input />
              </Form.Item>
            )
          }}
        </Form.Item>
      ),
    },
  ]

  return (
    <Modal
      title="청구서 발송"
      visible={visible}
      onCancel={onClose}
      okText="발송하기"
      onOk={onOk}
      confirmLoading={loading}
    >
      <Loading loading={loading}>
        <Form layout="vertical" form={form}>
          <FormListTable name="receivers" columns={columns} title="받는사람" />
        </Form>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  select: { width: '100%' },
}
