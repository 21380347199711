import { Col, Divider, Form, Input, Modal, notification, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useState } from 'react'

import formRule from '@utils/formRule'

import HqRoleAPI from '@apis/hq/role'

import Loading from '@components/molecules/Loading'
import RoleSelector from '@components/molecules/RoleSelector'

type Props = {
  hqId: number
  visible: boolean
  onClose: () => void
}

export default function InviteModal({ hqId, visible, onClose }: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [form] = useForm()

  const onOk = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)
      await HqRoleAPI.inviteRole({ hqId }, { email: values.email, hqRole: values.role })
      notification.success({ message: '이메일이 발송되었습니다.' })
      setLoading(false)
      onClose()
    } catch (err) {
      notification.error({ message: '이메일 발송에 실패했습니다. 이메일 주소를 확인해주세요.' })
      setLoading(false)
    }
  }

  const initialValues = {
    email: '',
    role: {
      dashboard: 1,
      graph: 1,
      notice: 1,
      role: 1,
      config: 1,
    },
  }

  return (
    <Modal
      title="초대하기"
      visible={visible}
      onCancel={onClose}
      onOk={onOk}
      okText="이메일 보내기"
      maskClosable={false}
    >
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item
            label="이메일"
            name="email"
            required
            rules={[formRule.email]}
            extra="코드가 담긴 이메일이 발송됩니다."
          >
            <Input />
          </Form.Item>
          <Divider />
          <Row gutter={[20, 20]}>
            <Col span={8}>
              <Form.Item label="대시보드" name={['role', 'dashboard']} valuePropName="role">
                <RoleSelector />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="그래프" name={['role', 'graph']} valuePropName="role">
                <RoleSelector />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="공지사항" name={['role', 'notice']} valuePropName="role">
                <RoleSelector />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="권한관리" name={['role', 'role']} valuePropName="role">
                <RoleSelector />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="설정" name={['role', 'config']} valuePropName="role">
                <RoleSelector />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Loading>
    </Modal>
  )
}
