import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import RentalAPI from '@apis/branch/rental'

import {
  RentalReservationAction,
  setRentalReservationLoading,
  setRentalReservationSearchResult,
  SEARCH_RENTAL_RESERVATIONS,
  SET_RENTAL_RESERVATION_DEFAULT,
  SET_RENTAL_RESERVATION_PAGINATION,
  SET_RENTAL_RESERVATION_SEARCH,
  SET_RENTAL_RESERVATION_PAGE_CONFIG,
  setRentalReservationPagination,
  searchRentalReservations,
  setRentalReservationRental,
} from '@reducers/branch/rental-reservation.reducer'
import { RootState } from '@reducers/index'
import { setStorageRentalReservation } from '@reducers/storage.reducer'

function* searchRentalReservationSaga(action: RentalReservationAction): Generator {
  if (action.type !== SEARCH_RENTAL_RESERVATIONS) return
  const { hqId, branchId } = action.payload
  yield* put(setRentalReservationLoading(true))

  const search = yield* select((state: RootState) => state.rentalReservation.search)
  const pagination = yield* select((state: RootState) => state.rentalReservation.pagination)

  const {
    data: { rentalReservations, total },
  } = yield* call(RentalAPI.getRentalReservations, { hqId, branchId }, { ...search, ...pagination })

  yield* put(setRentalReservationSearchResult({ branchId, rentalReservations, total }))

  yield* put(setRentalReservationLoading(false))
}

function* getRentals(hqId: number, branchId: number): Generator {
  yield* put(setRentalReservationRental({ loading: true }))

  const {
    data: { rentals },
  } = yield* call(RentalAPI.getRentals, { hqId, branchId }, { current: 1, pageSize: 500, query: '' })

  yield* put(setRentalReservationRental({ list: rentals, loading: false }))
}

function* savePageConfigSaga(action: RentalReservationAction): Generator {
  const state: Parameters<typeof setStorageRentalReservation>[0] = {}

  if (action.type === SET_RENTAL_RESERVATION_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageRentalReservation(state))
}

function* setDefaultPageConfigSaga(action: RentalReservationAction): Generator {
  if (action.type !== SET_RENTAL_RESERVATION_DEFAULT) return
  const { hqId, branchId } = action.payload
  const {} = yield* select((state: RootState) => state.storage.rentalReservationConfig)

  yield* put(setRentalReservationPagination({}))

  yield* getRentals(hqId, branchId)

  yield* put(searchRentalReservations(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_RENTAL_RESERVATIONS, searchRentalReservationSaga),
    takeEvery(SET_RENTAL_RESERVATION_SEARCH, savePageConfigSaga),
    takeEvery(SET_RENTAL_RESERVATION_PAGINATION, savePageConfigSaga),
    takeEvery(SET_RENTAL_RESERVATION_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_RENTAL_RESERVATION_DEFAULT, setDefaultPageConfigSaga),
  ])
}
