import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import CashAPI from '@apis/branch/cash'

import {
  CashAction,
  setCashLoading,
  setCashSearchResult,
  SEARCH_CASH,
  SET_CASH_DEFAULT,
  SET_CASH_PAGINATION,
  SET_CASH_SEARCH,
  SET_CASH_PAGE_CONFIG,
  setCashPagination,
  searchCash,
  setCashSummary,
} from '@reducers/branch/cash.reducer'
import { RootState } from '@reducers/index'
import { setStorageCash } from '@reducers/storage.reducer'

function* searchCashSaga(action: CashAction): Generator {
  if (action.type !== SEARCH_CASH) return
  const { hqId, branchId } = action.payload
  yield* put(setCashLoading(true))

  const search = yield* select((state: RootState) => state.cash.search)
  const pagination = yield* select((state: RootState) => state.cash.pagination)

  const {
    data: { cash, total },
  } = yield* call(CashAPI.getCash, { hqId, branchId }, { ...search, ...pagination })
  const { data: summary } = yield* call(CashAPI.getCashSummary, { hqId, branchId }, { ...search })

  yield* put(setCashSummary(summary))
  yield* put(setCashSearchResult({ branchId, cash, total }))

  yield* put(setCashLoading(false))
}

function* savePageConfigSaga(action: CashAction): Generator {
  const state: Parameters<typeof setStorageCash>[0] = {}

  if (action.type === SET_CASH_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageCash(state))
}

function* setDefaultPageConfigSaga(action: CashAction): Generator {
  if (action.type !== SET_CASH_DEFAULT) return
  const { pageSize } = yield* select((state: RootState) => state.storage.cashConfig)
  yield* all([put(setCashPagination({ pageSize }))])

  const { hqId, branchId } = action.payload
  yield* put(searchCash(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_CASH, searchCashSaga),
    takeEvery(SET_CASH_SEARCH, savePageConfigSaga),
    takeEvery(SET_CASH_PAGINATION, savePageConfigSaga),
    takeEvery(SET_CASH_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_CASH_DEFAULT, setDefaultPageConfigSaga),
  ])
}
