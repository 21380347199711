import { Divider, Form, Input, Modal, Radio, Select, notification, RadioChangeEvent } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { Bills, Contracts } from 'gadjet-v2-types/dist/model'
import { ContractStatus, ReceiptStatus } from 'gadjet-v2-types/dist/type'
import { contractStatus, receiptStatus } from 'gadjet-v2-types/dist/type/label'
import debounce from 'lodash.debounce'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import defaultValues from '@utils/defaultValues'
import formRule from '@utils/formRule'

import BillAPI from '@apis/branch/bill'
import ContractAPI from '@apis/branch/contract'

import { RootState } from '@reducers/index'

import AntAlert from '@components/atoms/AntAlert'
import HiddenItems from '@components/atoms/Form/HiddenItems'
import LocalDatePicker from '@components/atoms/LocalDatePicker'
import TypeTag from '@components/molecules/TypeTag'

import ItemList from './ItemList'

type Props = {
  hqId: number
  branchId: number
  billId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function BillFormModal({ hqId, branchId, billId, visible, onClose, onDone }: Props): JSX.Element {
  const option = useSelector((state: RootState) => state.option)
  const [selectedContract, setSelectedContract] = useState<Contracts>()
  const [contracts, setContracts] = useState<Contracts[]>([])
  const [loading, setLoading] = useState(false)
  const [searching, setSearching] = useState(false)
  const [form] = useForm<Bills>()

  const receiptStatusDescriptions: { key: ReceiptStatus; content: ReactNode }[] = [
    {
      key: 'none',
      content: (
        <span>
          해당 금액을 매출에 포함하지 않을 경우 선택하세요.
          <br /> 가젯이 자동으로 증빙 발행을 하지 않습니다.
        </span>
      ),
    },
    {
      key: 'before-receipt',
      content: (
        <span>
          실제 매출에 포함되는 금액입니다. <br />
          증빙 자동발행 설정 시 <Bold>매 월 9일 자동으로 세금계산서가 발행</Bold>됩니다. <br />
          <Bold>현금영수증 발행이 필요한 경우 매 월 8일까지 발행</Bold>을 마감해 주세요.
        </span>
      ),
    },
    {
      key: 'seperately-receipt',
      content: (
        <span>
          가젯 외의 방법으로 증빙을 발행하는 경우 선택하세요.
          <br />
          가젯이 자동으로 증빙 발행을 하지 않습니다.
        </span>
      ),
    },
  ]

  const searchContracts = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) return

        setSearching(true)
        const { data } = await ContractAPI.searchContracts(
          { hqId, branchId },
          { query, status: ['before-started', 'started', 'expired', 'extended', 'suspended'] }
        )
        setContracts(data.contracts)
        setSearching(false)
      }, 400),
    []
  )

  const getBill = async () => {
    if (!billId) return
    setLoading(true)
    const { data: bill } = await BillAPI.getBill({ hqId, branchId, billId })
    if (bill.contract) setSelectedContract(bill.contract)
    form.setFieldsValue(bill)
    setLoading(false)
  }

  const onOk = async () => {
    setLoading(true)
    try {
      const values = await form.validateFields()
      if (!billId) await BillAPI.addBill({ hqId, branchId }, { bill: values })
      if (billId) await BillAPI.updateBiil({ hqId, branchId, billId }, { bill: values })
      setLoading(false)

      notification.success({ message: '저장되었습니다.' })
      onDone()
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const initialValues = defaultValues.bill({ branchId })

  const reset = () => {
    form.resetFields()
    setContracts([])
    setSelectedContract(undefined)
    if (billId) getBill()
  }

  useEffect(() => {
    if (visible) reset()
  }, [visible])

  const getContractlabel = (contract: Contracts) => {
    const { tenant, suspendDate, status } = contract
    return (
      <div>
        {tenant.name} ( ~ {dayjs(suspendDate).format('YY.MM.DD')}){' '}
        <TypeTag<ContractStatus>
          label={contractStatus[status]}
          value={status}
          switchCase={{
            values: [['before-started'], ['started'], ['extended'], ['expired', 'suspended']],
            types: ['default', 'success', 'ongoing', 'fail'],
          }}
        />
      </div>
    )
  }

  const contractOptions = useMemo(() => {
    const search = contracts.map((contract) => ({ label: getContractlabel(contract), value: contract.contractId }))
    if (selectedContract && !search.some(({ value }) => value === selectedContract.contractId)) {
      return [{ label: getContractlabel(selectedContract), value: selectedContract.contractId }, ...search]
    }
    return search
  }, [contracts, selectedContract])

  return (
    <Modal
      title="청구서"
      visible={visible}
      onCancel={onClose}
      onOk={onOk}
      okButtonProps={{ loading }}
      maskClosable={false}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item label="계약" name="contractId" required rules={[formRule.required]}>
          <Select
            showSearch
            onSearch={searchContracts}
            filterOption={false}
            loading={searching}
            options={contractOptions}
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ setFieldsValue }) => {
            const onChange = (event: RadioChangeEvent) => {
              const selectedBillType = event.target.value
              if (selectedBillType === 'sales') {
                setFieldsValue({ receiptStatus: 'before-receipt' })
              } else {
                setFieldsValue({ receiptStatus: 'none' })
              }
            }
            return (
              <Form.Item label="청구서 타입" name="type">
                <Radio.Group>
                  <Radio value="sales" onChange={onChange}>
                    매출
                  </Radio>
                  <Radio value="deposit" onChange={onChange}>
                    보증금
                  </Radio>
                </Radio.Group>
              </Form.Item>
            )
          }}
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => {
            const start = dayjs(getFieldValue('startDate'))
            const end = dayjs(getFieldValue('endDate'))
            return (
              <Form.Item label="제공기간" required rules={[formRule.required]}>
                <LocalDatePicker.RangePicker
                  value={[start, end]}
                  allowClear={false}
                  allowEmpty={[false, false]}
                  onChange={(range) => {
                    if (!range) return
                    setFieldsValue({
                      startDate: dayjs(range[0] || undefined).format('YYYY-MM-DD'),
                      endDate: dayjs(range[1] || undefined).format('YYYY-MM-DD'),
                    })
                  }}
                />
              </Form.Item>
            )
          }}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => {
            const start = dayjs(getFieldValue('paymentStartDate'))
            const end = dayjs(getFieldValue('paymentEndDate'))
            return (
              <Form.Item label="결제기간" required rules={[formRule.required]}>
                <LocalDatePicker.RangePicker
                  value={[start, end]}
                  allowClear={false}
                  allowEmpty={[false, false]}
                  onChange={(range) => {
                    if (!range) return
                    setFieldsValue({
                      paymentStartDate: dayjs(range[0] || undefined).format('YYYY-MM-DD'),
                      paymentEndDate: dayjs(range[1] || undefined).format('YYYY-MM-DD'),
                    })
                  }}
                />
              </Form.Item>
            )
          }}
        </Form.Item>

        <Form.Item
          label="연체료 부과일"
          name="lateFeeDate"
          getValueProps={(d) => ({ value: d ? dayjs(d) : undefined })}
          getValueFromEvent={(_, s) => s}
        >
          <LocalDatePicker allowClear />
        </Form.Item>

        <Form.Item label="증빙상태" name="receiptStatus" style={{ marginBottom: '8px' }}>
          <Select
            onChange={(value: ReceiptStatus) => {
              form.setFieldsValue({ receiptStatus: value })
            }}
          >
            {option.receiptStatus.map((status) => (
              <Select.Option key={status} value={status}>
                {receiptStatus[status]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const receiptStatus = getFieldValue('receiptStatus')
            const receiptStatusDescription = receiptStatusDescriptions.find((el) => el.key === receiptStatus)?.content
            const isVisible = option.receiptStatus.includes(receiptStatus)
            return isVisible && <AntAlert type="warning" message={receiptStatusDescription} />
          }}
        </Form.Item>

        <Divider />
        <ItemList label="공간" name="spaces" form={form} />
        <ItemList label="부가서비스" name="additions" form={form} />
        <ItemList label="추가비용" name="surcharges" form={form} />

        <Form.Item label="비고" name="note" extra="세금계산서 발행 시 비고란에 기재됩니다">
          <Input.TextArea style={{ height: '100px' }} showCount maxLength={140} />
        </Form.Item>

        <Form.Item label="메모" name="memo">
          <Input.TextArea style={{ height: '100px' }} />
        </Form.Item>

        {/** 데이터 전송용 hidden form */}
        <HiddenItems names={['startDate', 'endDate', 'paymentStartDate', 'paymentEndDate', 'manualFlag']} />
      </Form>
    </Modal>
  )
}

const Bold = styled.span`
  font-weight: 600;
`
