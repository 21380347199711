import useMenu, { HQ_MENUS } from '@hooks/useMenu'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { getHq } from '@reducers/hq/hq.reducer'

import TabPageTemplate from '@templates/TabPageTemplate'

import HqBranchPage from '@pages/Hq/BranchPage'
import HqConfigPage from '@pages/Hq/ConfigPage'
import HqDashboardPage from '@pages/Hq/DashboardPage'
import HqGraphPage from '@pages/Hq/GraphPage'
import HqIndexPage from '@pages/Hq/IndexPage'
import HqNoticePage from '@pages/Hq/NoticePage'
import HqRolePage from '@pages/Hq/RolePage'

const routePages = HQ_MENUS.map((m) => {
  let component: JSX.Element = <div />

  if (m.key === 'dashboard') component = <HqDashboardPage />
  else if (m.key === 'graph') component = <HqGraphPage />
  else if (m.key === 'notice') component = <HqNoticePage />
  else if (m.key === 'role') component = <HqRolePage />
  else if (m.key === 'branch') component = <HqBranchPage />
  else if (m.key === 'config') component = <HqConfigPage />

  return { ...m, component }
}).concat([{ path: '', key: 'dashboard', label: '', component: <HqIndexPage /> }])

export default function HqRouter(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, hqMenus } = useMenu()
  useEffect(() => {
    dispatch(getHq(hqId))
  }, [hqId])

  const routes = useMemo(() => routePages.filter((rp) => hqMenus.some((hm) => hm.key === rp.key)), [hqMenus])

  return <TabPageTemplate key={hqId} routes={routes} />
}
