/* eslint-disable */

type AddressData = {
  address: string
  zonecode: string
}

const addressFinder = {
  open: (onSelect: (data: AddressData) => void) => {
    const _w = window as any
    new _w.daum.Postcode({
      oncomplete: onSelect,
    }).open()
  },
}

export default addressFinder
