import { Form, Modal, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { HqRoles } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'

import RoleAPI from '@apis/hq/role'

import Loading from '@components/molecules/Loading'
import RoleSelector from '@components/molecules/RoleSelector'

type Props = { hqId: number; hqRoleId: number; visible: boolean; onClose: () => void }
export default function HqRoleUpdateModal({ hqId, hqRoleId, visible, onClose }: Props): JSX.Element {
  const [form] = useForm<HqRoles>()
  const [loading, setLoading] = useState(false)

  const onOk = async () => {
    const values = await form.validateFields()
    setLoading(true)
    await RoleAPI.updateRole({ hqId, hqRoleId }, { role: values })
    notification.success({ message: '수정되었습니다.' })
    setLoading(false)
    onClose()
  }

  const getHqRole = async () => {
    setLoading(true)
    const { data } = await RoleAPI.getRoleDetail({ hqId, hqRoleId })
    form.setFieldsValue(data)
    setLoading(false)
  }

  useEffect(() => {
    form.resetFields()
    if (visible && hqRoleId) getHqRole()
  }, [visible, hqRoleId])

  return (
    <Modal title="권한 수정" visible={visible} onCancel={onClose} okText="수정하기" onOk={onOk} width="400px">
      <Loading loading={loading}>
        <Form<HqRoles> form={form} layout="vertical">
          <Form.Item name="dashboard" label="대시보드" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="graph" label="그래프" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="notice" label="공지사항" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="role" label="권한관리" valuePropName="role">
            <RoleSelector />
          </Form.Item>
          <Form.Item name="config" label="설정" valuePropName="role">
            <RoleSelector />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}
