import axios from '@utils/axios'

export type ContactSales = {
  companyName: string
  industryName: string
  userName: string
  mailAddress: string
  phoneNumber: string
  interestedFeatureName: string[]
  termsAgree: string | boolean
}

const ContactAPI = {
  // 영업팀 문의
  contactSales: async (data: ContactSales): Promise<any> => axios.post(`contact`, data),
}

export default ContactAPI
