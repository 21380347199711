import { Row } from 'antd'
import * as BRANCH_RECEIPT from 'gadjet-v2-types/dist/api/branch/receipt'
import { useMemo } from 'react'

import { krw } from '@utils/format'

import ContentWrapper from '@components/atoms/ContentWrapper'
import SummaryItem from '@components/molecules/SummaryItem'

type Props = {
  summary: BRANCH_RECEIPT.GET_SUMMARY_RESPONSE
}

export default function BranchReceiptSummary({ summary }: Props): JSX.Element {
  const items = useMemo(() => {
    const { total, taxInvoice, cashReceipt } = summary
    return [
      { key: 'total', label: '총 증빙금액', value: krw(total) },
      { key: 'taxInvoice', label: '세금계산서', value: krw(taxInvoice) },
      { key: 'cashReceipt', label: '현금영수증', value: krw(cashReceipt) },
    ]
  }, [summary])

  return (
    <ContentWrapper>
      <Row>
        {items.map(({ key, label, value }) => (
          <SummaryItem key={key} label={label} value={value} span={4} />
        ))}
      </Row>
    </ContentWrapper>
  )
}
