import dayjs from 'dayjs'
import { RentalReservations, Rentals } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { RentalReservationSearch } from 'gadjet-v2-types/dist/type/search'

// ActionType
export const SEARCH_RENTAL_RESERVATIONS = 'RentalReservation/SEARCH_RENTAL_RESERVATIONS' as const
export const SET_RENTAL_RESERVATION_DEFAULT = 'RentalReservation/SET_DEFAULT' as const
export const SET_RENTAL_RESERVATION_LOADING = 'RentalReservation/SET_RENTAL_RESERVATION_LOADING' as const
export const SET_RENTAL_RESERVATION_SEARCH_RESULT = 'RentalReservation/SET_RENTAL_RESERVATION_SEARCH_RESULT' as const
export const SET_RENTAL_RESERVATION_SEARCH = 'RentalReservation/SET_RENTAL_RESERVATION_SEARCH' as const
export const SET_RENTAL_RESERVATION_PAGINATION = 'RentalReservation/SET_RENTAL_RESERVATION_PAGINATION' as const
export const SET_RENTAL_RESERVATION_PAGE_CONFIG = 'RentalReservation/SET_RENTAL_RESERVATION_PAGE_CONFIG' as const
export const SET_RENTAL_RESERVATION_RENTAL = 'RentalReservation/SET_RENTAL_RESERVATION_RENTAL' as const

// Action
export const searchRentalReservations = (hqId: number, branchId: number) => ({
  type: SEARCH_RENTAL_RESERVATIONS,
  payload: { hqId, branchId },
})
export const setRentalReservationDefault = (hqId: number, branchId: number) => ({
  type: SET_RENTAL_RESERVATION_DEFAULT,
  payload: { hqId, branchId },
})
export const setRentalReservationLoading = (loading: boolean) => ({
  type: SET_RENTAL_RESERVATION_LOADING,
  payload: { loading },
})
export const setRentalReservationSearchResult = (result: Partial<RentalReservationState['searchResult']>) => ({
  type: SET_RENTAL_RESERVATION_SEARCH_RESULT,
  payload: { result },
})
export const setRentalReservationSearch = (search: Partial<RentalReservationSearch>) => ({
  type: SET_RENTAL_RESERVATION_SEARCH,
  payload: { search },
})
export const setRentalReservationPagination = (pagination: Partial<Pagination>) => ({
  type: SET_RENTAL_RESERVATION_PAGINATION,
  payload: { pagination },
})
export const setRentalReservationRental = (rental: Partial<RentalReservationState['rental']>) => ({
  type: SET_RENTAL_RESERVATION_RENTAL,
  payload: { rental },
})

export type RentalReservationAction =
  | ReturnType<typeof searchRentalReservations>
  | ReturnType<typeof setRentalReservationDefault>
  | ReturnType<typeof setRentalReservationLoading>
  | ReturnType<typeof setRentalReservationSearchResult>
  | ReturnType<typeof setRentalReservationSearch>
  | ReturnType<typeof setRentalReservationPagination>
  | ReturnType<typeof setRentalReservationRental>

type RentalReservationState = {
  rental: { list: Rentals[]; loading: boolean }
  loading: boolean
  searchResult: { branchId: number; total: number; rentalReservations: RentalReservations[] }
  search: RentalReservationSearch
  pagination: Pagination
}

const rentalReservationState: RentalReservationState = {
  rental: { list: [], loading: false },
  loading: true,
  searchResult: { branchId: 0, total: 0, rentalReservations: [] },
  search: {
    date: [dayjs().startOf('week').format('YYYY-MM-DD'), dayjs().endOf('week').format('YYYY-MM-DD')],
    rentalIds: [],
  },
  pagination: { current: 1, pageSize: 1000 },
}

const RentalReservationReducer = (
  state = rentalReservationState,
  action: RentalReservationAction
): RentalReservationState => {
  switch (action.type) {
    case SET_RENTAL_RESERVATION_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_RENTAL_RESERVATION_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_RENTAL_RESERVATION_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_RENTAL_RESERVATION_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    case SET_RENTAL_RESERVATION_RENTAL:
      return { ...state, rental: { ...state.rental, ...action.payload.rental } }
    default:
      return state
  }
}

export default RentalReservationReducer
