import { Styles } from '@types'
import { Alert, Col, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd'
import dayjs from 'dayjs'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { ContractExtendStatus } from 'gadjet-v2-types/dist/type'
import { contractExtendStatus } from 'gadjet-v2-types/dist/type/label'
import { useMemo } from 'react'

import formRule from '@utils/formRule'

import HiddenItems from '@components/atoms/Form/HiddenItems'
import LocalDatePicker from '@components/atoms/LocalDatePicker'

type Props = {
  form: FormInstance<Contracts>
  isUpdate: boolean
  onValuesChange: (changedValues: Partial<Contracts>) => void
}

export default function ContractTab({ form, isUpdate, onValuesChange }: Props): JSX.Element {
  const extendStatusOptions = useMemo(() => {
    const extendStatus: ContractExtendStatus[] = ['none', 'asked', 'approval', 'refusal']
    return extendStatus.map((status) => ({
      label: contractExtendStatus[status],
      value: status,
    }))
  }, [])

  return (
    <>
      <HiddenItems names={['startDate', 'endDate']} />

      <Alert showIcon message="계약 시작일, 종료일은 이후 수정이 불가능합니다." type="info" style={styles.alert} />

      <Form.Item shouldUpdate label="계약 기간" rules={[formRule.required]}>
        {({ getFieldValue, setFieldsValue }) => {
          const start = dayjs(getFieldValue('startDate'))
          const end = dayjs(getFieldValue('endDate'))
          return (
            <LocalDatePicker.RangePicker
              disabled={isUpdate}
              value={[start, end]}
              allowClear={false}
              allowEmpty={[false, false]}
              onChange={(range) => {
                if (!range) return
                const newRange = {
                  startDate: dayjs(range[0] || undefined).format('YYYY-MM-DD'),
                  endDate: dayjs(range[1] || undefined).format('YYYY-MM-DD'),
                }
                setFieldsValue(newRange)
                onValuesChange(newRange)
              }}
            />
          )
        }}
      </Form.Item>
      <Row gutter={10}>
        <Col>
          <Form.Item
            label="연장 확인 날짜"
            name="extendCheckDate"
            extra="설정 된 날짜에 연장여부를 확인합니다."
            getValueProps={(value) => ({ value: value ? dayjs(value) : undefined })}
            getValueFromEvent={(value) => (value ? dayjs(value).format('YYYY-MM-DD') : undefined)}
          >
            <LocalDatePicker allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="연장" name="extendStatus">
            <Select<ContractExtendStatus> options={extendStatusOptions} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="계약주소" name="address" extra="ex. 101호">
        <Input />
      </Form.Item>
      <Form.Item label="인원" name="population">
        <InputNumber addonAfter="명" min={0} style={styles.inputNumber} />
      </Form.Item>
    </>
  )
}

const styles: Styles = {
  alert: { marginBottom: '30px' },
  inputNumber: { width: '100%' },
}
