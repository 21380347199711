import { ReactNode } from 'react'

import FeaturePageTemplate from '@pages/Landing/FeaturePages/organisms/FeaturePageTemplate'
import * as S from '@pages/Landing/FeaturePages/styles'

import IssueInvoice from '../../organisms/IssueInvoice'
import SendInvoice from '../../organisms/SendInvoice'

export default function InvoiceFeaturePage(): JSX.Element {
  const content: { key: string; title: string; component: ReactNode }[] = [
    {
      key: 'issueReciept',
      title: '클릭 한 번으로 끝나는 증빙',
      component: <IssueInvoice />,
    },
    {
      key: 'sendInvoice',
      title: '자동으로 발행되는 청구서',
      component: <SendInvoice />,
    },
  ]
  return (
    <FeaturePageTemplate
      title="청구・증빙 자동화"
      description="관리 시간을 확 줄여주는 자동화 시스템"
      icon={{ src: '/images/landing/features/funnel.png', alt: 'chart-icon' }}
    >
      {content.map(({ key, title, component }) => (
        <S.Section key={key}>
          <S.SectionTitle>{title}</S.SectionTitle>
          {component}
        </S.Section>
      ))}
    </FeaturePageTemplate>
  )
}
