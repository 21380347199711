import { BREAKPOINTS } from '@const'
import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  width: string
  mediaWidth: string
  label: string
  content: ReactNode
  image: { src: string; alt: string; width: number; height: number }
  mobileImage?: { src: string; alt: string; width: number; height: number }
}

export default function TenantPreviewBox({
  width,
  mediaWidth,
  label,
  content,
  image,
  mobileImage,
}: Props): JSX.Element {
  return (
    <Container width={width} mediaWidth={mediaWidth}>
      <ContentWrapper>
        <TenantEmojiIcon src="/images/landing/home/previews/tenant-working-man.png" alt="tenant-working-man-emoji" />
        <Label>{label}</Label>
        <Content>{content}</Content>
      </ContentWrapper>
      <Image src={image.src} alt={image.alt} width={image.width} height={image.height} />
      <MobileImage
        src={mobileImage?.src || image.src}
        alt={mobileImage?.alt || image.alt}
        width={mobileImage?.width || image.width}
        height={mobileImage?.height || image.height}
      />
    </Container>
  )
}

type StylesProps = {
  width: string
  mediaWidth: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0px 0px;
  isolation: isolate;

  width: ${({ width }: StylesProps) => width};
  height: 550px;

  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  border-radius: 40px;
  overflow: hidden;

  @media ${BREAKPOINTS.lg} {
    width: 343px;
    height: 500px;
    border-radius: 24px;
    padding: 24px 0px 0px;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  position: relative;
  width: 100%;
`
const Label = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 12px;
  width: max-content;
  background: #f6ff89;
  border-radius: 12px;
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  color: #2e2e2e;
`

const Content = styled.h3`
  font-weight: 600;
  font-size: 30px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  @media ${BREAKPOINTS.lg} {
    font-size: 24px;
  }
`
const TenantEmojiIcon = styled.img`
  position: absolute;
  width: 38px;
  height: 38px;
  right: 40px;
  @media ${BREAKPOINTS.lg} {
    right: 20px;
  }
`

const Image = styled.img`
  @media ${BREAKPOINTS.lg} {
    display: none;
  }
`

const MobileImage = styled.img`
  display: none;
  @media ${BREAKPOINTS.lg} {
    display: block;
  }
`
