import { Hqs } from 'gadjet-v2-types/dist/model'

// ActionType
export const GET_HQ = 'hq/GET_HQ' as const
export const SET_HQ = 'hq/SET_HQ' as const
export const SET_HQ_LOADING = 'hq/SET_HQ_LOADING' as const

// Action
export const getHq = (hqId: number) => ({
  type: GET_HQ,
  payload: { hqId },
})
export const setHq = (state: Partial<HqState>) => ({
  type: SET_HQ,
  payload: { state },
})
export const setHqLoading = (loading: boolean) => ({
  type: SET_HQ_LOADING,
  payload: { loading },
})

export type HqAction = ReturnType<typeof getHq> | ReturnType<typeof setHq> | ReturnType<typeof setHqLoading>

type HqState = Hqs & {
  loading: boolean
}

const hqState: HqState = {
  hqId: 0,
  email: '',
  logoImageId: 0,
  name: '',
  image: { uri: '', category: 'hq', height: 0, imageId: 0, path: '', size: 0, width: 0 },
  branches: [],
  loading: false,
}

const hqReducer = (state = hqState, action: HqAction): HqState => {
  switch (action.type) {
    case SET_HQ:
      return { ...state, ...action.payload.state }
    case SET_HQ_LOADING:
      return { ...state, loading: action.payload.loading }
    default:
      return state
  }
}

export default hqReducer
