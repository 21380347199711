import * as GRAPH from 'gadjet-v2-types/dist/api/hq/graph'

import axios from '@utils/axios'

const HqGraphAPI = {
  // 매출
  getGraphPayments: async (
    { hqId }: GRAPH.GRAPH_PAYMENT_PARAMS,
    query: GRAPH.GRAPH_PAYMENT_QUERY
  ): Promise<{ data: GRAPH.GRAPH_PAYMENT_RESPONSE }> => axios.get(`/hqs/${hqId}/graphs/payments`, { params: query }),

  // 예상매출
  getGraphExpectPayments: async (
    { hqId }: GRAPH.GRAPH_EXPECT_PAYMENT_PARAMS,
    query: GRAPH.GRAPH_EXPECT_PAYMENT_QUERY
  ): Promise<{ data: GRAPH.GRAPH_EXPECT_PAYMENT_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/graphs/expect-payments`, { params: query }),

  // 계약 수
  getGraphContracts: async (
    { hqId }: GRAPH.GRAPH_CONTRACT_PARAMS,
    query: GRAPH.GRAPH_CONTRACT_QUERY
  ): Promise<{ data: GRAPH.GRAPH_CONTRACT_RESPONSE }> => axios.get(`/hqs/${hqId}/graphs/contracts`, { params: query }),

  // 공간 유형 별 매출
  getGraphSpaceTypes: async (
    { hqId }: GRAPH.GRAPH_SPACE_TYPE_PARAMS,
    query: GRAPH.GRAPH_SPACE_TYPE_QUERY
  ): Promise<{ data: GRAPH.GRAPH_SPACE_TYPE_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/graphs/space-types`, { params: query }),
}

export default HqGraphAPI
