import { Styles } from '@types'
import dayjs from 'dayjs'
import { RentalReservations } from 'gadjet-v2-types/dist/model'
import styled from 'styled-components'

import AgendaEventItem from '../AgendaEventItem'

type Props = {
  agendaEvent: {
    date: string
    reservations: RentalReservations[]
  }
  lastEventItem: {
    date: string
    reservations: RentalReservations[]
  }
  onClickEvent: (reservation: RentalReservations) => void
}

export default function AgendaEventByDate({ agendaEvent, onClickEvent, lastEventItem }: Props): JSX.Element {
  return (
    <div>
      <div style={{ paddingLeft: '16px' }}>
        <div style={styles.date}>{dayjs(agendaEvent.date).format('M월 D일 dd')}</div>
        {agendaEvent.reservations
          .sort((a, b) => {
            if (a.startTime > b.startTime) {
              return 1
            }
            if (a.startTime < b.startTime) {
              return -1
            }

            return 0
          })
          .map((reservation) => (
            <AgendaEventItem onClickEvent={onClickEvent} reservation={reservation} />
          ))}
      </div>

      {agendaEvent !== lastEventItem && <Divider />}
    </div>
  )
}

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e9eaeb;
  margin: 20px 0px;
`

const styles: Styles = {
  date: { fontWeight: 500, marginBottom: '15px' },
}
