import useModal from '@hooks/useModal'
import { Branches } from 'gadjet-v2-types/dist/model'
import { useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import BranchCardAPI from '@apis/branch/branch-card'

import BranchCardActiveConfirm from '@components/organisms/Branch/Config/Modals/BranchCardActiveConfirm'
import BranchCardDeleteConfirm from '@components/organisms/Branch/Config/Modals/BranchCardDeleteConfirm'
import BranchCardForm from '@components/organisms/Branch/Config/Modals/BranchCardForm'
import BranchCardTable from '@components/organisms/Branch/Config/Tables/BranchCardTable'

const getBranchCardKey = (branchId: number) => ['getBranchCards', branchId]

export type BranchCardAction = 'add' | 'delete' | 'active'
type Props = { hqId: number; branchId: number; branch?: Branches }

export default function BranchCardTab({ hqId, branchId, branch }: Props): JSX.Element {
  const [cardFormModal, onVisibleCardFormModal, onCloseCardFormModal] = useModal({ visible: false })
  const [cardDeleteModal, onVisibleCardDeleteModal, onCloseCardDeleteModal] = useModal({
    visible: false,
    branchCardId: 0,
  })
  const [cardActiveModal, onVisibleCardActiveModal, onCloseCardActiveModal] = useModal({
    visible: false,
    branchCardId: 0,
  })

  const queryClient = useQueryClient()
  const { data } = useQuery(getBranchCardKey(branchId), () => BranchCardAPI.getBranchCards({ hqId, branchId }, {}))
  const branchCards = useMemo(() => {
    if (!data) return []
    return data.data.branchCards
  }, [data])

  const onRefresh = () => queryClient.invalidateQueries(getBranchCardKey(branchId))
  const onAction = (action: BranchCardAction, branchCardId?: number) => {
    if (action === 'add') onVisibleCardFormModal()
    if (action === 'delete') onVisibleCardDeleteModal({ branchCardId })
    if (action === 'active') onVisibleCardActiveModal({ branchCardId })
  }

  return (
    <>
      <BranchCardTable branchCards={branchCards} onAction={onAction} />

      <BranchCardForm
        hqId={hqId}
        branchId={branchId}
        visible={cardFormModal.visible}
        onClose={onCloseCardFormModal}
        onDone={onRefresh}
      />

      <BranchCardActiveConfirm
        hqId={hqId}
        branchId={branchId}
        branchCardId={cardActiveModal.branchCardId}
        visible={cardActiveModal.visible}
        onClose={onCloseCardActiveModal}
        onDone={onRefresh}
      />
      <BranchCardDeleteConfirm
        hqId={hqId}
        branchId={branchId}
        branchCardId={cardDeleteModal.branchCardId}
        visible={cardDeleteModal.visible}
        onClose={onCloseCardDeleteModal}
        onDone={onRefresh}
      />
    </>
  )
}
