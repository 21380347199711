import useModal from '@hooks/useModal'
import { useContext } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import HqRoleAPI from '@apis/hq/role'

import { AdminGlobalContext } from '@components/contexts/AdminGlobal'
import ActionButtons, { ActionButton } from '@components/molecules/ActionButtons'
import ListTable from '@components/organisms/Hq/Role/ListTable'
import DeleteModal from '@components/organisms/Hq/Role/Modals/DeleteModal'
import InviteModal from '@components/organisms/Hq/Role/Modals/InviteModal'
import HqRoleUpdateModal from '@components/organisms/Hq/Role/Modals/UpdateModal'

export type HqRoleAction = 'invite' | 'delete' | 'update'

export default function RolePage(): JSX.Element {
  const { hqId } = useContext(AdminGlobalContext)
  const [inviteModal, onVisibleInviteModal, onCloseInviteModal] = useModal({ visible: false })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal<{ hqRoleId: number }>({
    visible: false,
    hqRoleId: 0,
  })
  const [updateModal, onVisibleUpdateModal, onCloseUpdateModal] = useModal<{ hqRoleId: number }>({
    visible: false,
    hqRoleId: 0,
  })
  const queryClient = useQueryClient()
  const { isFetching, data } = useQuery(['getHqRoles', hqId], () => HqRoleAPI.getRoles({ hqId }), {})

  const onAction = (action: HqRoleAction, hqRoleId?: number) => {
    if (action === 'invite') onVisibleInviteModal()
    if (action === 'update') onVisibleUpdateModal({ hqRoleId })
    if (action === 'delete') onVisibleDeleteModal({ hqRoleId })
  }

  const actions: ActionButton[] = [
    { key: 'add', label: '초대하기', type: 'primary', onClick: () => onAction('invite') },
  ]

  const refresh = () => {
    queryClient.invalidateQueries(['getHqRoles', hqId])
  }

  return (
    <div>
      <ActionButtons actions={actions} />
      <ListTable loading={isFetching} roles={data?.data || []} onAction={onAction} />

      <InviteModal hqId={hqId} visible={inviteModal.visible} onClose={onCloseInviteModal} />
      <DeleteModal
        hqId={hqId}
        hqRoleId={deleteModal.hqRoleId}
        visible={deleteModal.visible}
        onClose={onCloseDeleteModal}
        onDone={refresh}
      />

      <HqRoleUpdateModal
        hqId={hqId}
        hqRoleId={updateModal.hqRoleId}
        visible={updateModal.visible}
        onClose={onCloseUpdateModal}
      />
    </div>
  )
}
