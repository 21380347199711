import { Button } from 'antd'

type Props = {
  onClick: () => void
  label: string
  width: string
  height: string
}

export default function BlockTextButton({ onClick, label, width, height }: Props): JSX.Element {
  return (
    <Button type="text" block onClick={onClick} style={{ width, height }}>
      {label}
    </Button>
  )
}
