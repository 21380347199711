import { MoreOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Button, Dropdown, Menu, Table, TableColumnType, TableProps, Tag } from 'antd'
import { BranchRoles } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'

import { BranchRoleAction } from '@pages/Branch/RolePage'

import TableTotalRows from '@components/atoms/TableTotalRows'
import TableWrapper from '@components/atoms/TableWrapper'

type Props = {
  loading: boolean
  total: number
  pagination: Pagination
  branchRoles: BranchRoles[]
  onChangePagination: TableProps<BranchRoles>['onChange']
  onAction: (action: BranchRoleAction, branchRoleId: number) => void
}
export default function BranchRoleListTable({
  loading,
  total,
  pagination,
  branchRoles,
  onChangePagination,
  onAction,
}: Props): JSX.Element {
  const columns: TableColumnType<BranchRoles>[] = [
    {
      key: 'managerId',
      dataIndex: 'managerId',
      title: '매니저',
      width: '20%',
      render: (_, { manager, adminFlag }) => (
        <div>
          {manager?.name} {adminFlag && <Tag color="blue">관리자</Tag>}
        </div>
      ),
    },
    {
      key: 'manager',
      dataIndex: 'manager',
      title: '이메일',
      render: (_, { manager }) => manager?.email || '',
    },
    // {
    //   key: 'branchRoleId',
    //   dataIndex: 'branchRoleId',
    //   title: '권한',
    //   render: (_, { adminFlag }) => {
    //     if (adminFlag) return <Tag color="blue">관리자</Tag>
    //     return ''
    //   },
    // },
    {
      key: 'branchRoleId',
      dataIndex: 'branchRoleId',
      title: '',
      align: 'center',
      fixed: 'right',
      width: '80px',
      render: (branchRoleId, { adminFlag }) => (
        <Dropdown
          overlay={
            <Menu disabled={adminFlag}>
              <Menu.Item onClick={() => onAction('update', branchRoleId)}>수정</Menu.Item>
              <Menu.Item onClick={() => onAction('delete', branchRoleId)}>삭제</Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]

  return (
    <TableWrapper>
      <Table<BranchRoles>
        loading={loading}
        style={styles.table}
        scroll={{ x: true }}
        rowKey={({ branchRoleId }) => `${branchRoleId}`}
        dataSource={branchRoles}
        columns={columns}
        onChange={onChangePagination}
        pagination={{
          total,
          position: ['bottomCenter'],
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          showTotal: (total) => <TableTotalRows total={total} />,
        }}
      />
    </TableWrapper>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
  adminTag: { margin: 0 },
}
