import { Alert, Col, Row } from 'antd'
import { Branches } from 'gadjet-v2-types/dist/model'
import { BranchBuildingContractType } from 'gadjet-v2-types/dist/type'
import { useState } from 'react'

import BranchContractDocumentForm from '../../Forms/BranchContractDocumentForm'
import BranchRenteeForm from '../../Forms/BranchRenteeForm'
import BranchSubletForm from '../../Forms/BranchSubletForm'

type Props = { hqId: number; branchId: number; branch?: Branches; onRefresh: () => void }

export default function BranchContractDocumentTab({ hqId, branchId, branch, onRefresh }: Props): JSX.Element {
  const [buildingContractType, setBuildingContractType] = useState<BranchBuildingContractType>(
    branch?.contractDocument?.buildingContractType || 'none'
  )

  return (
    <Row gutter={[20, 20]}>
      <Col span={20}>
        <Alert type="info" showIcon message="건물 정보는 계약서 생성 시 이용됩니다." />
      </Col>
      <Col span={10}>
        <BranchContractDocumentForm
          hqId={hqId}
          branchId={branchId}
          branch={branch}
          onRefresh={onRefresh}
          onChangeBuildingContractType={setBuildingContractType}
        />
      </Col>
      <Col span={10}>
        {buildingContractType === 'rent' && (
          <BranchRenteeForm hqId={hqId} branchId={branchId} branch={branch} onRefresh={onRefresh} />
        )}
        {buildingContractType === 'sublet' && (
          <BranchSubletForm hqId={hqId} branchId={branchId} branch={branch} onRefresh={onRefresh} />
        )}
      </Col>
    </Row>
  )
}
