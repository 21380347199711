import { Form, FormInstance, Input, Select } from 'antd'
import { Branches } from 'gadjet-v2-types/dist/model'
// import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import formRule from '@utils/formRule'

import { RootState } from '@reducers/index'

type Props = {
  form: FormInstance<Branches>
}

export default function BranchSettlementTab({ form }: Props) {
  const banks = useSelector((state: RootState) => state.option.banks)
  const bankOptions = banks.map(({ bankCode, name }) => ({ label: name, value: bankCode }))

  const onSelectBank = (bankCode: string) => {
    const values = form.getFieldValue(['settlement'])
    const bank = banks.find((b) => b.bankCode === bankCode)

    form.setFieldsValue({ settlement: { ...values, bankName: bank?.name || '', bankCode: bank?.bankCode } })
  }

  // const resetForm = () => {
  //   form.resetFields()
  // }

  // useEffect(() => {
  //   resetForm()
  // }, [])

  return (
    <>
      <Form.Item label="은행" name={['settlement', 'bankCode']} rules={[formRule.required]}>
        <Select<string> options={bankOptions} onSelect={onSelectBank} />
      </Form.Item>
      <Form.Item label="은행이름" name={['settlement', 'bankName']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label="계좌번호" name={['settlement', 'account']} rules={[formRule.required]}>
        <Input />
      </Form.Item>
      <Form.Item label="예금주" name={['settlement', 'holder']} rules={[formRule.required]}>
        <Input />
      </Form.Item>
    </>
  )
}
