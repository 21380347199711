import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import AccessLogAPI from '@apis/branch/access-log'

import {
  AccessLogAction,
  setAccessLogLoading,
  setAccessLogSearchResult,
  SEARCH_ACCESS_LOGS,
  SET_ACCESS_LOG_DEFAULT,
  SET_ACCESS_LOG_PAGINATION,
  SET_ACCESS_LOG_SEARCH,
  SET_ACCESS_LOG_PAGE_CONFIG,
  setAccessLogPagination,
  searchAccessLogs,
} from '@reducers/branch/access-log.reducer'
import { RootState } from '@reducers/index'
import { setStorageAccessLog } from '@reducers/storage.reducer'

function* searchAccessLogSaga(action: AccessLogAction): Generator {
  if (action.type !== SEARCH_ACCESS_LOGS) return
  const { hqId, branchId } = action.payload
  yield* put(setAccessLogLoading(true))

  const search = yield* select((state: RootState) => state.accessLog.search)
  const pagination = yield* select((state: RootState) => state.accessLog.pagination)

  const {
    data: { accessLogs, total },
  } = yield* call(AccessLogAPI.getAccessLogs, { hqId, branchId }, { ...search, ...pagination })

  yield* put(setAccessLogSearchResult({ branchId, accessLogs, total }))

  yield* put(setAccessLogLoading(false))
}

function* savePageConfigSaga(action: AccessLogAction): Generator {
  const state: Parameters<typeof setStorageAccessLog>[0] = {}

  if (action.type === SET_ACCESS_LOG_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageAccessLog(state))
}

function* setDefaultPageConfigSaga(action: AccessLogAction): Generator {
  if (action.type !== SET_ACCESS_LOG_DEFAULT) return
  const { pageSize } = yield* select((state: RootState) => state.storage.accessLogConfig)
  yield* all([put(setAccessLogPagination({ pageSize }))])

  const { hqId, branchId } = action.payload
  yield* put(searchAccessLogs(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_ACCESS_LOGS, searchAccessLogSaga),
    takeEvery(SET_ACCESS_LOG_SEARCH, savePageConfigSaga),
    takeEvery(SET_ACCESS_LOG_PAGINATION, savePageConfigSaga),
    takeEvery(SET_ACCESS_LOG_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_ACCESS_LOG_DEFAULT, setDefaultPageConfigSaga),
  ])
}
