import * as AUTH from 'gadjet-v2-types/dist/api/manager/auth'

import axios from '@utils/axios'

const AuthAPI = {
  // 인증 조회
  getAuth: async (params: AUTH.GET_PARAMS): Promise<{ data: AUTH.GET_RESPONSE }> => axios.get('/managers/auth'),

  // 회원가입
  join: async (params: AUTH.JOIN_PARAMS, { manager }: AUTH.JOIN_BODY): Promise<{ data: AUTH.JOIN_RESPONSE }> =>
    axios.post('/managers/join', { manager }),

  // 로그인
  login: async (params: AUTH.LOGIN_PARAMS, { manager }: AUTH.LOGIN_BODY): Promise<{ data: AUTH.LOGIN_RESPONSE }> =>
    axios.post('/managers/login', { manager }),

  // 소셜로그인
  socialLogin: async (
    params: AUTH.SOCIAL_LOGIN_PARAMS,
    { manager }: AUTH.SOCIAL_LOGIN_BODY
  ): Promise<{ data: AUTH.SOCIAL_LOGIN_RESPONSE }> => axios.post('/managers/social-login', { manager }),

  // 회원정보 수정
  updateManager: async (
    { managerId }: AUTH.UPDATE_PARAMS,
    { manager }: AUTH.UPDATE_BODY
  ): Promise<{ data: AUTH.UPDATE_RESPONSE }> => axios.put(`/managers/${managerId}`, { manager }),

  // 비밀번호 수정
  updatePassword: async (
    { managerId }: AUTH.UPDATE_PASSWORD_PARAMS,
    { manager }: AUTH.UPDATE_PASSWORD_BODY
  ): Promise<{ data: AUTH.UPDATE_PASSWORD_RESPONSE }> => axios.put(`/managers/${managerId}/password`, { manager }),

  // 소셜계정 수정
  updateSocial: async (
    { managerId }: AUTH.UPDATE_SOCIAL_PARAMS,
    { manager }: AUTH.UPDATE_SOCIAL_BODY
  ): Promise<{ data: AUTH.UPDATE_SOCIAL_RESPONSE }> => axios.put(`/managers/${managerId}/social`, { manager }),

  // 푸시토큰 수정
  updatePushToken: async (
    { managerId }: AUTH.UPDATE_PUSH_TOKEN_PARAMS,
    { pushToken }: AUTH.UPDATE_PUSH_TOKEN_BODY
  ): Promise<{ data: AUTH.UPDATE_PUSH_TOKEN_RESPONSE }> =>
    axios.put(`/managers/${managerId}/push-token`, { pushToken }),

  // 이메일 사용가능 확인
  verifyEmail: async (
    params: AUTH.EMAIL_VERIFY_PARAMS,
    query: AUTH.EMAIL_VERIFY_QUERY
  ): Promise<{ data: AUTH.EMAIL_VERIFY_RESPONSE }> => axios.get('/managers/email/verify', { params: query }),

  // 이메일 인증 코드 발송
  sendEmailCode: async (
    params: AUTH.EMAIL_CODE_PARAMS,
    { email }: AUTH.EMAIL_CODE_BODY
  ): Promise<{ data: AUTH.EMAIL_CODE_RESPONSE }> => axios.post('/managers/email/code', { email }),

  // 이메일 인증 코드 확인

  checkValidCode: async (
    { code }: AUTH.CODE_VERIFY_PARAMS,
    query: AUTH.CODE_VERIFY_QUERY
  ): Promise<{ data: AUTH.CODE_VERIFY_RESPONSE }> => axios.get(`/managers/verify/code/${code}`),

  // 비밀번호 재설정 링크 발송
  sendEmailResetPassword: async (
    params: AUTH.RESET_PASSWORD_LINK_PARAMS,
    { email }: AUTH.RESET_PASSWORD_LINK_BODY
  ): Promise<{ data: AUTH.RESET_PASSWORD_LINK_RESPONSE }> => axios.post('/managers/email/password', { email }),

  // 비밀번호 재설정

  resetPassword: async (
    params: AUTH.RESET_PASSWORD_PARAMS,
    { key, password }: AUTH.RESET_PASSWORD_BODY
  ): Promise<{ data: AUTH.RESET_PASSWORD_RESPONSE }> => axios.put('/managers/password', { key, password }),
}

export default AuthAPI
