import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { TenantSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setTenantPagination, setTenantSearch, searchTenants, setTenantDefault } from '@reducers/branch/tenant.reducer'
import { RootState } from '@reducers/index'

import BranchTenantListTable from '@components/organisms/Branch/Tenant/ListTable'
import TenantContractModal from '@components/organisms/Branch/Tenant/Modals/TenantContractModal'
import TenantCreditModal from '@components/organisms/Branch/Tenant/Modals/TenantCreditModal'
import TenantExportModal from '@components/organisms/Branch/Tenant/Modals/TenantExportModal'
import TenantMemberModal from '@components/organisms/Branch/Tenant/Modals/TenantMemberModal'
import TenantRentalModal from '@components/organisms/Branch/Tenant/Modals/TenantRentalModal'
import BranchTenantSearchBox from '@components/organisms/Branch/Tenant/SearchBox'
import BranchTenantTitle from '@components/organisms/Branch/Tenant/Title'

export type BranchTenantAction = 'contract' | 'credit' | 'rental' | 'member' | 'export'

export default function TenantPage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const { loading, search, pagination, searchResult } = useSelector((state: RootState) => state.tenant)

  useEffect(() => {
    dispatch(
      setTenantSearch({
        query: '',
        contractStatus: ['before-started', 'started', 'extended', 'expired', 'suspended'],
      })
    )
  }, [])

  const [contractModal, onVisibleContractModal, onCloseContractModal] = useModal({ visible: false, tenantId: 0 })
  const [creditModal, onVisibleCreditModal, onCloseCreditModal] = useModal({ visible: false, tenantId: 0 })
  const [rentalModal, onVisibleRentalModal, onCloseRentalModal] = useModal({ visible: false, tenantId: 0 })
  const [memberModal, onVisibleMemberModal, onCloseMemberModal] = useModal({ visible: false, tenantId: 0 })
  const [exportModal, onVisibleExportModal, onCloseExportModal] = useModal({ visible: false })

  const onChangeSearch = (_search: Partial<TenantSearch>) => dispatch(setTenantSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setTenantPagination(_pagination))
    dispatch(searchTenants(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setTenantPagination({ current: 1 }))
    dispatch(searchTenants(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setTenantDefault(hqId, branchId)), [hqId, branchId])
  const onDone = () => onResetAndSearch()

  const onAction = (action: BranchTenantAction, tenantId?: number) => {
    if (action === 'contract') onVisibleContractModal({ tenantId })
    if (action === 'credit') onVisibleCreditModal({ tenantId })
    if (action === 'rental') onVisibleRentalModal({ tenantId })
    if (action === 'member') onVisibleMemberModal({ tenantId })
    if (action === 'export') onVisibleExportModal()
  }

  useEffect(() => {
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchTenantSearchBox search={search} onChangeSearch={onChangeSearch} onSearch={onSearch} />
      <BranchTenantTitle onAction={onAction} />
      <BranchTenantListTable
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        tenants={searchResult.tenants}
        total={searchResult.total}
        onAction={onAction}
      />
      <TenantContractModal
        hqId={hqId}
        branchId={branchId}
        visible={contractModal.visible}
        onClose={onCloseContractModal}
        tenantId={contractModal.tenantId}
        onDone={onDone}
      />
      <TenantCreditModal
        hqId={hqId}
        branchId={branchId}
        visible={creditModal.visible}
        onClose={onCloseCreditModal}
        tenantId={creditModal.tenantId}
        onDone={onDone}
      />
      <TenantRentalModal
        hqId={hqId}
        branchId={branchId}
        visible={rentalModal.visible}
        onClose={onCloseRentalModal}
        tenantId={rentalModal.tenantId}
        onDone={onDone}
      />
      <TenantMemberModal
        hqId={hqId}
        branchId={branchId}
        visible={memberModal.visible}
        onClose={onCloseMemberModal}
        tenantId={memberModal.tenantId}
        onDone={onDone}
      />
      <TenantExportModal
        hqId={hqId}
        branchId={branchId}
        visible={exportModal.visible}
        onClose={onCloseExportModal}
        onDone={onDone}
      />
    </div>
  )
}
