import { notification } from 'antd'

import ProductAPI from '@apis/branch/product'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  productId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function ProductDeleteModal({
  hqId,
  branchId,
  productId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    if (!productId) return
    await ProductAPI.deleteProduct({ hqId, branchId, productId })
    notification.success({ message: '삭제되었습니다.' })
  }

  return (
    <ConfirmModal
      visible={visible}
      title="상품이 목록에서 삭제됩니다."
      description="상품을 삭제하면 해당 상품의 구매내역도 삭제됩니다."
      okText="삭제"
      onClose={onClose}
      onDone={onDone}
      onConfirm={onConfirm}
    />
  )
}
