import { Branches } from 'gadjet-v2-types/dist/model'

// ActionType
export const GET_BRANCH = 'branch/GET_BRANCH' as const
export const SET_BRANCH = 'branch/SET_BRANCH' as const
export const SET_BRANCH_LOADING = 'branch/SET_BRANCH_LOADING' as const

// Action
export const getBranch = (hqId: number, branchId: number, cb?: (branch: Branches) => void) => ({
  type: GET_BRANCH,
  payload: { hqId, branchId, cb },
})
export const setBranch = (state: Partial<BranchState>) => ({
  type: SET_BRANCH,
  payload: { state },
})

export type BranchAction = ReturnType<typeof getBranch> | ReturnType<typeof setBranch>

type BranchState = Branches

const branchState: BranchState = {
  hqId: 0,
  branchId: 0,
  name: '',
  contact: '',
  email: '',
  zipcode: '',
  address: '',
  addressDetail: '',
  popbillId: null,
  business: null,
  settlement: null,
  contractDocument: null,
  sublet: null,
  rentee: null,
  automation: null,
  roles: [],
  floors: [],
  spaceTypes: [],
  remainDate: 0,
  chargeNotice: 0,
}

const branchReducer = (state = branchState, action: BranchAction): BranchState => {
  switch (action.type) {
    case SET_BRANCH:
      return { ...state, ...action.payload.state }
    default:
      return state
  }
}

export default branchReducer
