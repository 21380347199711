import { MoreOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Button, Dropdown, Menu, Table, TableColumnType, TablePaginationConfig } from 'antd'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import { Expenditures } from 'gadjet-v2-types/dist/model'
import { ExpenditureType, Pagination } from 'gadjet-v2-types/dist/type'
import { expenditureType } from 'gadjet-v2-types/dist/type/label'

import { onChangeTable } from '@utils/antd-table'
import { krw } from '@utils/format'

import { BranchExpenditureAction } from '@pages/Branch/ExpenditurePage'

import TableTotalRows from '@components/atoms/TableTotalRows'
import TableWrapper from '@components/atoms/TableWrapper'

type Props = {
  loading: boolean
  total: number
  pagination: Pagination
  expenditure: Expenditures[]
  onChangePagination: (pagination: Pagination) => void
  onAction: (action: BranchExpenditureAction, expenditureId: number) => void
}
export default function BranchExpenditureListTable({
  loading,
  total,
  pagination,
  expenditure,
  onChangePagination,
  onAction,
}: Props): JSX.Element {
  const onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Expenditures> | SorterResult<Expenditures>[]
  ) => {
    const { current, pageSize, sort } = onChangeTable(pagination, filters, sorter)
    onChangePagination({ current, pageSize, sort })
  }
  const columns: TableColumnType<Expenditures>[] = [
    { key: 'date', dataIndex: 'date', title: '날짜' },
    { key: 'type', dataIndex: 'type', title: '타입', render: (type: ExpenditureType) => expenditureType[type] },
    {
      key: 'price',
      dataIndex: 'price',
      title: '금액',
      align: 'right',
      sorter: true,
      render: (price) => krw(price),
    },
    { key: 'memo', dataIndex: 'memo', title: '메모' },
    {
      key: 'managerId',
      dataIndex: 'managerId',
      title: '담당자',
      render: (_, { manager, managerName }) => manager?.name || managerName || '',
    },
    {
      key: 'expenditureId',
      dataIndex: 'expenditureId',
      title: '',
      width: '80px',
      render: (expenditureId) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="update" onClick={() => onAction('update', expenditureId)}>
                수정
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => onAction('delete', expenditureId)}>
                삭제
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]

  return (
    <TableWrapper>
      <Table<Expenditures>
        loading={loading}
        style={styles.table}
        scroll={{ x: true }}
        rowKey={({ expenditureId }) => `${expenditureId}`}
        dataSource={expenditure}
        columns={columns}
        onChange={onChange}
        pagination={{
          total,
          ...pagination,
          position: ['bottomCenter'],
          showSizeChanger: true,
          showTotal: (total) => <TableTotalRows total={total} />,
        }}
      />
    </TableWrapper>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
  priceCell: { textAlign: 'right' },
}
