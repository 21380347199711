import { useState } from 'react'

type State<T> = { visible: boolean } & T

type Return<T> = [
  State<T>,
  (state?: Partial<State<T>>) => void,
  () => void,
  React.Dispatch<React.SetStateAction<State<T>>>
]
const useModal = <T = unknown>(defaultState: State<T>): Return<T> => {
  const [state, setState] = useState<{ visible: boolean } & T>(defaultState)

  const onVisible = (_state?: Partial<State<T>>) => setState({ ...state, ..._state, visible: true })
  const onClose = () => setState({ ...state, visible: false })

  return [state, onVisible, onClose, setState]
}

export default useModal
