import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import EventAPI from '@apis/branch/event'

import {
  EventAction,
  setEventLoading,
  setEventSearchResult,
  SEARCH_EVENTS,
  SET_EVENT_DEFAULT,
  SET_EVENT_PAGINATION,
  SET_EVENT_SEARCH,
  SET_EVENT_PAGE_CONFIG,
  setEventPagination,
  searchEvents,
} from '@reducers/branch/event.reducer'
import { RootState } from '@reducers/index'
import { setStorageEvent } from '@reducers/storage.reducer'

function* searchEventSaga(action: EventAction): Generator {
  if (action.type !== SEARCH_EVENTS) return
  const { hqId, branchId } = action.payload
  yield* put(setEventLoading(true))

  const search = yield* select((state: RootState) => state.event.search)
  const pagination = yield* select((state: RootState) => state.event.pagination)

  const {
    data: { events, total },
  } = yield* call(EventAPI.getEvents, { hqId, branchId }, { ...search, ...pagination })

  yield* put(setEventSearchResult({ branchId, events, total }))

  yield* put(setEventLoading(false))
}

function* savePageConfigSaga(action: EventAction): Generator {
  const state: Parameters<typeof setStorageEvent>[0] = {}

  if (action.type === SET_EVENT_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageEvent(state))
}

function* setDefaultPageConfigSaga(action: EventAction): Generator {
  if (action.type !== SET_EVENT_DEFAULT) return
  const { pageSize } = yield* select((state: RootState) => state.storage.eventConfig)
  yield* all([put(setEventPagination({ pageSize }))])

  const { hqId, branchId } = action.payload
  yield* put(searchEvents(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_EVENTS, searchEventSaga),
    takeEvery(SET_EVENT_SEARCH, savePageConfigSaga),
    takeEvery(SET_EVENT_PAGINATION, savePageConfigSaga),
    takeEvery(SET_EVENT_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_EVENT_DEFAULT, setDefaultPageConfigSaga),
  ])
}
