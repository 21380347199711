import { Modal, Image } from 'antd'

// Modal
Modal.defaultProps = {
  ...Modal.defaultProps,
  okText: '완료',
  cancelText: '취소',
  bodyStyle: { maxHeight: 'calc(100vh - 300px)', overflow: 'auto' },
}

// Image
Image.defaultProps = {
  ...Image.defaultProps,
  fallback: '/images/no-image.jpg',
}

export default function AntdDefaultProps(): JSX.Element {
  return <div />
}
