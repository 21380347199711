import * as DASHBOARD from 'gadjet-v2-types/dist/api/hq/dashboard'

import axios from '@utils/axios'

const HqDashboardAPI = {
  // 전 지점 계약 검색
  searchContracts: async (
    { hqId }: DASHBOARD.SEARCH_CONTRACT_PARAMS,
    query: DASHBOARD.SEARCH_CONTRACT_QUERY
  ): Promise<{ data: DASHBOARD.SEARCH_CONTRACT_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/dashboard/contracts/search`, { params: query }),

  // 시작 전 계약
  getBeforeStartedContracts: async (
    { hqId }: DASHBOARD.GET_BEFORE_STARTED_CONTRACT_PARAMS,
    query: DASHBOARD.GET_BEFORE_STARTED_CONTRACT_QUERY
  ): Promise<{ data: DASHBOARD.GET_BEFORE_STARTED_CONTRACT_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/dashboard/contracts/before-started`, { params: query }),

  // 시작계약
  getStartContracts: async (
    { hqId }: DASHBOARD.GET_START_CONTRACT_PARAMS,
    query: DASHBOARD.GET_START_CONTRACT_QUERY
  ): Promise<{ data: DASHBOARD.GET_START_CONTRACT_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/dashboard/contracts/start`, { params: query }),

  // 종료계약
  getEndContracts: async (
    { hqId }: DASHBOARD.GET_END_CONTRACT_PARAMS,
    query: DASHBOARD.GET_END_CONTRACT_QUERY
  ): Promise<{ data: DASHBOARD.GET_END_CONTRACT_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/dashboard/contracts/end`, { params: query }),

  // 미납 청구서
  getUnpaidBills: async (
    { hqId }: DASHBOARD.GET_UNPAID_BILL_PARAMS,
    query: DASHBOARD.GET_UNPAID_BILL_QUERY
  ): Promise<{ data: DASHBOARD.GET_UNPAID_BILL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/dashboard/bills/unpaid`, { params: query }),

  // 공간 조회
  getSpaces: async (
    { hqId }: DASHBOARD.GET_SPACE_PARAMS,
    query: DASHBOARD.GET_SPACE_QUERY
  ): Promise<{ data: DASHBOARD.GET_SPACE_RESPONSE }> => axios.get(`/hqs/${hqId}/dashboard/spaces`, { params: query }),
}

export default HqDashboardAPI
