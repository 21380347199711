import { BREAKPOINTS } from '@const'
import { CSSProperties } from 'react'
import styled from 'styled-components'

type Props = {
  srcPNG: string
  srcWEBP: string
  alt: string
}
export default function Picture({
  srcPNG,
  srcWEBP,
  alt,
  width,
  height,
  defaultStyle,
  mediaStyle,
  mediaScale,
}: Props & StylePictureProps & StyleImgProps): JSX.Element {
  return (
    <CustomPicture defaultStyle={defaultStyle} mediaStyle={mediaStyle}>
      <source srcSet={srcWEBP} type="image/webp" />
      <Img src={srcPNG} alt={alt} width={width} height={height} mediaScale={mediaScale} />
    </CustomPicture>
  )
}

type StyleImgProps = {
  width: number
  height: number
  mediaScale?: number
}

type StylePictureProps = {
  defaultStyle?: CSSProperties
  mediaStyle?: CSSProperties
}

const CustomPicture = styled.picture`
  margin: ${({ defaultStyle }: StylePictureProps) => defaultStyle?.margin};
  align-self: ${({ defaultStyle }: StylePictureProps) => defaultStyle?.alignSelf};
  filter: ${({ defaultStyle }: StylePictureProps) => defaultStyle?.filter};
  position: ${({ defaultStyle }: StylePictureProps) => defaultStyle?.position};
  bottom: ${({ defaultStyle }: StylePictureProps) => defaultStyle?.bottom};
  left: ${({ defaultStyle }: StylePictureProps) => defaultStyle?.left};
  @media ${BREAKPOINTS.lg} {
    position: ${({ mediaStyle }: StylePictureProps) => mediaStyle?.position};
    bottom: ${({ mediaStyle }: StylePictureProps) => mediaStyle?.bottom};
    right: ${({ mediaStyle }: StylePictureProps) => mediaStyle?.right};
    margin: ${({ mediaStyle }: StylePictureProps) => mediaStyle?.margin};
  }
`
const Img = styled.img`
  @media ${BREAKPOINTS.lg} {
    width: ${({ width, mediaScale }: StyleImgProps) => (mediaScale ? `calc(${width * mediaScale}px)` : width)};
    height: ${({ height, mediaScale }: StyleImgProps) => (mediaScale ? `calc(${height * mediaScale}px)` : height)};
  }
`
