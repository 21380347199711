/* eslint-disable jsx-a11y/interactive-supports-focus */
import { CirclePicker, ColorResult } from 'react-color'

type Props = {
  color: string
  onChangeComplete: ({ hex }: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function ColorPicker({ onChangeComplete, color }: Props) {
  const colors: string[] = [
    '#3EBD73',
    '#F16D37',
    '#135DAC',
    '#FFC528',
    '#BE42F8',

    '#7ECB42',
    '#F58B8A',
    '#60BEDE',
    '#FFDF00',
    '#EC75FF',

    '#38AB9C',
    '#FF90CC',
    '#63B4FF',
    '#F09300',
    '#C61FE1',

    '#289148',
    '#D26441',
    '#406EF2',
    '#CDA96D',
    '#9644FF',

    '#8FD9A9',
    '#C27D56',
    '#655DE8',
    '#977255',
    '#CA87FF',
  ]

  return (
    <>
      <div
        style={{
          width: 'max-content',
          borderRadius: '6px',
          boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.25)',
          padding: '20px',
          position: 'relative',
          backgroundColor: 'white',
        }}
      >
        <CirclePicker
          color={color}
          colors={colors}
          circleSize={20}
          width="134px"
          circleSpacing={6}
          onChangeComplete={onChangeComplete}
        />
      </div>
    </>
  )
}
