import { Spaces } from 'gadjet-v2-types/dist/model'
import { SpaceSummary, Pagination } from 'gadjet-v2-types/dist/type'
import { SpaceSearch } from 'gadjet-v2-types/dist/type/search'

// ActionType
export const SEARCH_SPACES = 'space/SEARCH_SPACES' as const
export const SET_SPACE_DEFAULT = 'space/SET_DEFAULT' as const
export const SET_SPACE_LOADING = 'space/SET_SPACE_LOADING' as const
export const SET_SPACE_SEARCH_RESULT = 'space/SET_SPACE_SEARCH_RESULT' as const
export const SET_SPACE_SEARCH = 'space/SET_SPACE_SEARCH' as const
export const SET_SPACE_PAGINATION = 'space/SET_SPACE_PAGINATION' as const
export const SET_SPACE_SUMMARY = 'space/SET_SPACE_SUMMARY' as const
export const SET_SPACE_PAGE_CONFIG = 'space/SET_SPACE_PAGE_CONFIG' as const

// Action
export const searchSpaces = (hqId: number, branchId: number) => ({
  type: SEARCH_SPACES,
  payload: { hqId, branchId },
})
export const setSpaceDefault = (hqId: number, branchId: number) => ({
  type: SET_SPACE_DEFAULT,
  payload: { hqId, branchId },
})
export const setSpaceLoading = (loading: boolean) => ({
  type: SET_SPACE_LOADING,
  payload: { loading },
})
export const setSpaceSearchResult = (result: Partial<SpaceState['searchResult']>) => ({
  type: SET_SPACE_SEARCH_RESULT,
  payload: { result },
})
export const setSpaceSearch = (search: Partial<SpaceSearch>) => ({
  type: SET_SPACE_SEARCH,
  payload: { search },
})
export const setSpacePagination = (pagination: Partial<Pagination>) => ({
  type: SET_SPACE_PAGINATION,
  payload: { pagination },
})
export const setSpaceSummary = (summary: SpaceSummary) => ({
  type: SET_SPACE_SUMMARY,
  payload: { summary },
})

export type SpaceAction =
  | ReturnType<typeof searchSpaces>
  | ReturnType<typeof setSpaceDefault>
  | ReturnType<typeof setSpaceLoading>
  | ReturnType<typeof setSpaceSearchResult>
  | ReturnType<typeof setSpaceSearch>
  | ReturnType<typeof setSpacePagination>
  | ReturnType<typeof setSpaceSummary>

type SpaceState = {
  loading: boolean
  searchResult: { branchId: number; total: number; spaces: Spaces[] }
  search: SpaceSearch
  pagination: Pagination
  summary: SpaceSummary
}

const spaceState: SpaceState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, spaces: [] },
  search: { query: '', emptyFlag: false },
  pagination: { current: 1, pageSize: 10 },
  summary: { total: 0, cashSpace: 0, taxInvoice: 0 },
}

const spaceReducer = (state = spaceState, action: SpaceAction): SpaceState => {
  switch (action.type) {
    case SET_SPACE_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_SPACE_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_SPACE_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_SPACE_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    case SET_SPACE_SUMMARY:
      return { ...state, summary: { ...state.summary, ...action.payload.summary } }
    default:
      return state
  }
}

export default spaceReducer
