import { Expenditures } from 'gadjet-v2-types/dist/model'
import { ExpenditureSummary, Pagination } from 'gadjet-v2-types/dist/type'
import { ExpenditureSearch } from 'gadjet-v2-types/dist/type/search'

import { getDefaultRange } from '@utils/datetime'

// ActionType
export const SEARCH_EXPENDITURE = 'expenditure/SEARCH_EXPENDITURE' as const
export const SET_EXPENDITURE_DEFAULT = 'expenditure/SET_DEFAULT' as const
export const SET_EXPENDITURE_LOADING = 'expenditure/SET_EXPENDITURE_LOADING' as const
export const SET_EXPENDITURE_SEARCH_RESULT = 'expenditure/SET_EXPENDITURE_SEARCH_RESULT' as const
export const SET_EXPENDITURE_SEARCH = 'expenditure/SET_EXPENDITURE_SEARCH' as const
export const SET_EXPENDITURE_PAGINATION = 'expenditure/SET_EXPENDITURE_PAGINATION' as const
export const SET_EXPENDITURE_SUMMARY = 'expenditure/SET_EXPENDITURE_SUMMARY' as const
export const SET_EXPENDITURE_PAGE_CONFIG = 'expenditure/SET_EXPENDITURE_PAGE_CONFIG' as const

// Action
export const searchExpenditure = (hqId: number, branchId: number) => ({
  type: SEARCH_EXPENDITURE,
  payload: { hqId, branchId },
})
export const setExpenditureDefault = (hqId: number, branchId: number) => ({
  type: SET_EXPENDITURE_DEFAULT,
  payload: { hqId, branchId },
})
export const setExpenditureLoading = (loading: boolean) => ({
  type: SET_EXPENDITURE_LOADING,
  payload: { loading },
})
export const setExpenditureSearchResult = (result: Partial<ExpenditureState['searchResult']>) => ({
  type: SET_EXPENDITURE_SEARCH_RESULT,
  payload: { result },
})
export const setExpenditureSearch = (search: Partial<ExpenditureSearch>) => ({
  type: SET_EXPENDITURE_SEARCH,
  payload: { search },
})
export const setExpenditurePagination = (pagination: Partial<Pagination>) => ({
  type: SET_EXPENDITURE_PAGINATION,
  payload: { pagination },
})
export const setExpenditureSummary = (summary: ExpenditureSummary) => ({
  type: SET_EXPENDITURE_SUMMARY,
  payload: { summary },
})

export type ExpenditureAction =
  | ReturnType<typeof searchExpenditure>
  | ReturnType<typeof setExpenditureDefault>
  | ReturnType<typeof setExpenditureLoading>
  | ReturnType<typeof setExpenditureSearchResult>
  | ReturnType<typeof setExpenditureSearch>
  | ReturnType<typeof setExpenditurePagination>
  | ReturnType<typeof setExpenditureSummary>

type ExpenditureState = {
  loading: boolean
  searchResult: { branchId: number; total: number; expenditure: Expenditures[] }
  search: ExpenditureSearch
  pagination: Pagination
  summary: ExpenditureSummary
}

const expenditureState: ExpenditureState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, expenditure: [] },
  search: {
    query: '',
    expenditureTypes: ['card', 'cash', 'transfer', 'commission'],
    date: getDefaultRange('month', 'date'),
  },
  pagination: { current: 1, pageSize: 10 },
  summary: { total: 0, card: 0, transfer: 0, cash: 0, commission: 0 },
}

const expenditureReducer = (state = expenditureState, action: ExpenditureAction): ExpenditureState => {
  switch (action.type) {
    case SET_EXPENDITURE_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_EXPENDITURE_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_EXPENDITURE_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_EXPENDITURE_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    case SET_EXPENDITURE_SUMMARY:
      return { ...state, summary: action.payload.summary }
    default:
      return state
  }
}

export default expenditureReducer
