import Picture from '@pages/Landing/FeaturePages/atoms/Picture'
import * as S from '@pages/Landing/FeaturePages/styles'

export default function ManageContract(): JSX.Element {
  return (
    <S.SectionInnerWrapper>
      <S.Container
        defaultStyles={{
          flexDirection: 'column',
          background: '#e9f6ef',
          height: '520px',
        }}
        mediaStyles={{ height: '500px' }}
      >
        <S.DescriptionWrapper defaultStyle={{ textAlign: 'center', padding: '40px 0 0 0' }}>
          <S.BoxTitle>계약 관리</S.BoxTitle>
          <S.Description>
            목록에서 계약 현황을
            <S.LineBreakMedia /> 한눈에 파악하고
            <br />
            필요한 정보를 빠르게 확인해요.
          </S.Description>
        </S.DescriptionWrapper>
        <Picture
          srcPNG="/images/landing/features/contract/contract-manage.png"
          srcWEBP="/images/landing/features/contract/contract-manage.webp"
          alt="contract-list"
          defaultStyle={{ margin: '28px 0 0 50px' }}
          mediaStyle={{ margin: '43px 0 0 20px' }}
          mediaScale={0.79}
          width={1340}
          height={330}
        />
      </S.Container>
      <S.BoxGroup>
        <S.Container
          defaultStyles={{
            flexDirection: 'column',
            background: 'linear-gradient(341.78deg, #F3F4F5 10.65%, #E4F6EC 87.62%)',
            width: '100%',
            height: '500px',
          }}
        >
          <S.DescriptionWrapper defaultStyle={{ padding: '40px 0 0 40px' }}>
            <S.BoxTitle>엑셀 파일 가져오기 / 내려받기</S.BoxTitle>
            <S.Description>
              가젯의 계약 목록을 <br />
              엑셀 파일로 내려받아 <br />
              보관할 수 있어요.
            </S.Description>
          </S.DescriptionWrapper>
          <S.ImageWrapper defaultStyle={{ alignItems: 'center' }}>
            <Picture
              srcPNG="/images/landing/features/contract/xlsx.png"
              srcWEBP="/images/landing/features/contract/xlsx.webp"
              alt="xlsx"
              width={240}
              height={240}
            />
          </S.ImageWrapper>
        </S.Container>
        <S.Container
          defaultStyles={{
            flexDirection: 'column',
            background: '#F2F0FC',
            width: '100%',
            height: '500px',
          }}
        >
          <S.DescriptionWrapper defaultStyle={{ padding: '40px 0 0 40px' }}>
            <S.BoxTitle>연장 여부 확인</S.BoxTitle>
            <S.Description>
              다가오는 계약 종료일, <br />
              계약 연장 여부도 놓치지 않고 <br />
              가젯이 먼저 챙길게요. <br />
            </S.Description>
            <S.SubDescription color="#7A7B7C">이메일, 앱 푸시 알림 전송</S.SubDescription>
          </S.DescriptionWrapper>
          <S.ImageWrapper defaultStyle={{ alignItems: 'center' }}>
            <Picture
              srcPNG="/images/landing/features/contract/notification.png"
              srcWEBP="/images/landing/features/contract/notification.webp"
              width={410}
              height={140}
              mediaScale={0.83}
              alt="contract-end-notification"
            />
          </S.ImageWrapper>
        </S.Container>
      </S.BoxGroup>
    </S.SectionInnerWrapper>
  )
}
