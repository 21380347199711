import { COLORS } from '@const'
import { Styles } from '@types'
import { Dropdown, Menu } from 'antd'
import { Hqs } from 'gadjet-v2-types/dist/model'
import { useHistory } from 'react-router-dom'

type Props = {
  hq: Hqs | null
  hqs: Hqs[]

  onChangeBranch: (_branchId: number) => void
}

export default function HqLinkButton({ hq, hqs, onChangeBranch }: Props): JSX.Element {
  const imageUri = hq?.image?.uri

  const { push } = useHistory()

  if (!hq) return <></>

  const menu: JSX.Element = (
    <Menu>
      {hqs.map((hq) => (
        <Menu.Item
          key={hq?.hqId || 0}
          onClick={() => {
            const _branchId = hq?.branches?.find((el) => el !== undefined)?.branchId
            onChangeBranch(_branchId || 0)
            push(`/hq/${hq.hqId}/dashboard`)
          }}
        >
          {hq.name}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={hqs.length > 1 ? menu : <div />} placement="bottom" overlayStyle={{ width: '180px' }}>
      <div style={{ ...styles.wrapper, cursor: hqs.length > 1 ? 'pointer' : 'default' }}>
        {imageUri ? <img src={imageUri} style={styles.logo} alt="logo" /> : <div style={styles.name}>{hq.name}</div>}
      </div>
    </Dropdown>
  )
}

const styles: Styles = {
  wrapper: {
    width: '100%',
    height: '64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperActive: { backgroundColor: COLORS.active },
  logo: { maxWidth: '70%', maxHeight: '34px' },
  name: { fontSize: '18px', color: 'black', textAlign: 'center', lineHeight: '24px' },
}
