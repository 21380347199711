import useMenu from '@hooks/useMenu'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export default function HqIndexPage(): JSX.Element {
  const { hqId } = useMenu()
  const { replace } = useHistory()

  useEffect(() => {
    replace(`/hq/${hqId}/dashboard`)
  }, [])
  return <div />
}
