import dayjs from 'dayjs'

type RangeDiffUnit = 'hour' | 'week' | 'day' | 'month'
type RangeType = 'date' | 'datetime'

const getTypeFormat = (type: RangeType, isStart?: boolean) => {
  if (type === 'date') return 'YYYY-MM-DD'
  if (type === 'datetime' && isStart) return 'YYYY-MM-DD 00:00:00'
  if (type === 'datetime' && !isStart) return 'YYYY-MM-DD 23:59:59'

  return 'YYYY-MM-DD'
}

export const getDefaultRange = (diffUnit: RangeDiffUnit, type: RangeType = 'date'): [string, string] => {
  return [
    dayjs().startOf(diffUnit).format(getTypeFormat(type, true)),
    dayjs().endOf(diffUnit).format(getTypeFormat(type, false)),
  ]
}

export default getDefaultRange
