import { ReactNode } from 'react'

import FeaturePageTemplate from '@pages/Landing/FeaturePages/organisms/FeaturePageTemplate'
import * as S from '@pages/Landing/FeaturePages/styles'

import ManageProduct from '../../organisms/ManageProduct'
import ReserveSpace from '../../organisms/ReserveSpace'

export default function StoreFeaturePage(): JSX.Element {
  const content: { key: string; title: string; component: ReactNode }[] = [
    {
      key: 'reserveSpace',
      title: '실시간으로 관리하는 회의실 예약',
      component: <ReserveSpace />,
    },
    {
      key: 'manageProduct',
      title: '간편한 서비스・상품 관리',
      component: <ManageProduct />,
    },
  ]
  return (
    <FeaturePageTemplate
      title="대관・서비스 관리"
      description="매끄러운 회의실 예약과 관리"
      icon={{ src: '/images/landing/features/calendar.png', alt: 'calendar-icon' }}
    >
      {content.map(({ key, title, component }) => (
        <S.Section key={key}>
          <S.SectionTitle>{title}</S.SectionTitle>
          {component}
        </S.Section>
      ))}
    </FeaturePageTemplate>
  )
}
