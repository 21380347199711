import { call, all, put, takeLatest } from 'typed-redux-saga'

import HqRoleAPI from '@apis/hq/role'

import { HqRoleAction, GET_HQ_ROLES, setHqRoles, setHqRoleLoading } from '@reducers/hq/role.reducer'

function* getHqRolesSaga(action: HqRoleAction): Generator {
  if (action.type !== GET_HQ_ROLES) return
  const { hqId } = action.payload
  yield* put(setHqRoleLoading(true))
  const { data } = yield* call(HqRoleAPI.getRoles, { hqId })

  yield* put(setHqRoles(data))
  yield* put(setHqRoleLoading(false))
}

export default function* hqRoleSaga(): Generator {
  yield* all([takeLatest(GET_HQ_ROLES, getHqRolesSaga)])
}
