import { Spaces, SpaceTypes } from 'gadjet-v2-types/dist/model'

type ColumnKey = keyof Spaces

type Option = { label: string; value: string }

type Column = {
  key: ColumnKey
  title: string
  guide: (string | JSX.Element)[]
  options?: Option[]
  required?: boolean
}

const columns = (spaceTypes: SpaceTypes[]): Column[] => {
  return [
    { key: 'name', title: '이름', guide: [], required: true },
    {
      key: 'price',
      title: '이용료',
      guide: ['부가세 제외 금액을 입력하세요.', '숫자만 입력하세요.\n(ex. 1200000)'],
      required: true,
    },
    {
      key: 'spaceTypeId',
      title: '공간유형',
      guide: ['공간유형은 가젯에 등록 된 공간유형의 이름과 같아야합니다.'],
      options: spaceTypes.map(({ spaceTypeId, name }) => ({ label: name, value: `${spaceTypeId}` })),
      required: true,
    },
    { key: 'capacity', title: '수용인원', guide: ['숫자만 입력하세요\n(ex. 20)'], required: true },
    { key: 'area', title: '면적', guide: ['면적을 입력하세요.\n(ex. 120평)'], required: true },
  ]
}

export default columns
