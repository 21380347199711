import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { ExpenditureSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  setExpenditurePagination,
  setExpenditureSearch,
  searchExpenditure,
  setExpenditureDefault,
} from '@reducers/branch/expenditure.reducer'
import { RootState } from '@reducers/index'

import BranchExpenditureListTable from '@components/organisms/Branch/Expenditure/ListTable'
import ExpenditureDeleteModal from '@components/organisms/Branch/Expenditure/Modals/ExpenditureDeleteModal'
import ExpenditureExportModal from '@components/organisms/Branch/Expenditure/Modals/ExpenditureExportModal'
import ExpenditureFormModal from '@components/organisms/Branch/Expenditure/Modals/ExpenditureFormModal'
import BranchExpenditureSearchBox from '@components/organisms/Branch/Expenditure/SearchBox'
import BranchExpenditureSummary from '@components/organisms/Branch/Expenditure/Summary'
import BranchExpenditureTitle from '@components/organisms/Branch/Expenditure/Title'

export type BranchExpenditureAction = 'add' | 'update' | 'export' | 'delete'

export default function ExpenditurePage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const { loading, search, pagination, searchResult, summary } = useSelector((state: RootState) => state.expenditure)
  const location = useLocation<{ dateRange: [string, string] }>()

  const [formModal, onVisibleFormModal, onCloseFormModal] = useModal<{ expenditureId?: number }>({
    visible: false,
    expenditureId: undefined,
  })
  const [exportModal, onVisibleExportModal, onCloseExportModal] = useModal({ visible: false })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal<{ expenditureId?: number }>({
    visible: false,
    expenditureId: undefined,
  })

  const onChangeSearch = (_search: Partial<ExpenditureSearch>) => dispatch(setExpenditureSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setExpenditurePagination(_pagination))
    dispatch(searchExpenditure(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setExpenditurePagination({ current: 1 }))
    dispatch(searchExpenditure(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setExpenditureDefault(hqId, branchId)), [hqId, branchId])
  const onDone = () => onResetAndSearch()

  const onAction = (action: BranchExpenditureAction, expenditureId?: number) => {
    if (action === 'add') onVisibleFormModal({ expenditureId: undefined })
    if (action === 'update') onVisibleFormModal({ expenditureId })
    if (action === 'export') onVisibleExportModal()
    if (action === 'delete') onVisibleDeleteModal({ expenditureId })
  }

  useEffect(() => {
    if (location?.state?.dateRange) {
      onChangeSearch({ date: location?.state?.dateRange })
      onSearch()
    }
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchExpenditureSearchBox search={search} onChangeSearch={onChangeSearch} onSearch={onSearch} />

      <BranchExpenditureSummary summary={summary} />

      <BranchExpenditureTitle onAction={onAction} />

      <BranchExpenditureListTable
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        expenditure={searchResult.expenditure}
        total={searchResult.total}
        onAction={onAction}
      />

      <ExpenditureFormModal
        visible={formModal.visible}
        hqId={hqId}
        branchId={branchId}
        expenditureId={formModal.expenditureId}
        onClose={onCloseFormModal}
        onDone={onDone}
      />
      <ExpenditureDeleteModal
        visible={deleteModal.visible}
        hqId={hqId}
        branchId={branchId}
        expenditureId={deleteModal.expenditureId}
        onClose={onCloseDeleteModal}
        onDone={onDone}
      />
      <ExpenditureExportModal
        visible={exportModal.visible}
        hqId={hqId}
        branchId={branchId}
        onClose={onCloseExportModal}
        onDone={onDone}
      />
    </div>
  )
}
