import { Products } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { ProductSearch } from 'gadjet-v2-types/dist/type/search'

// ActionType
export const SEARCH_PRODUCTS = 'product/SEARCH_PRODUCTS' as const
export const SET_PRODUCT_DEFAULT = 'product/SET_DEFAULT' as const
export const SET_PRODUCT_LOADING = 'product/SET_PRODUCT_LOADING' as const
export const SET_PRODUCT_SEARCH_RESULT = 'product/SET_PRODUCT_SEARCH_RESULT' as const
export const SET_PRODUCT_SEARCH = 'product/SET_PRODUCT_SEARCH' as const
export const SET_PRODUCT_PAGINATION = 'product/SET_PRODUCT_PAGINATION' as const
export const SET_PRODUCT_SUMMARY = 'product/SET_PRODUCT_SUMMARY' as const
export const SET_PRODUCT_PAGE_CONFIG = 'product/SET_PRODUCT_PAGE_CONFIG' as const

// Action
export const searchProducts = (hqId: number, branchId: number) => ({
  type: SEARCH_PRODUCTS,
  payload: { hqId, branchId },
})
export const setProductDefault = (hqId: number, branchId: number) => ({
  type: SET_PRODUCT_DEFAULT,
  payload: { hqId, branchId },
})
export const setProductLoading = (loading: boolean) => ({
  type: SET_PRODUCT_LOADING,
  payload: { loading },
})
export const setProductSearchResult = (result: Partial<ProductState['searchResult']>) => ({
  type: SET_PRODUCT_SEARCH_RESULT,
  payload: { result },
})
export const setProductSearch = (search: Partial<ProductSearch>) => ({
  type: SET_PRODUCT_SEARCH,
  payload: { search },
})
export const setProductPagination = (pagination: Partial<Pagination>) => ({
  type: SET_PRODUCT_PAGINATION,
  payload: { pagination },
})

export type ProductAction =
  | ReturnType<typeof searchProducts>
  | ReturnType<typeof setProductDefault>
  | ReturnType<typeof setProductLoading>
  | ReturnType<typeof setProductSearchResult>
  | ReturnType<typeof setProductSearch>
  | ReturnType<typeof setProductPagination>

type ProductState = {
  loading: boolean
  searchResult: { branchId: number; total: number; products: Products[] }
  search: ProductSearch
  pagination: Pagination
}

const productState: ProductState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, products: [] },
  search: { query: '' },
  pagination: { current: 1, pageSize: 10 },
}

const productReducer = (state = productState, action: ProductAction): ProductState => {
  switch (action.type) {
    case SET_PRODUCT_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_PRODUCT_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_PRODUCT_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_PRODUCT_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    default:
      return state
  }
}

export default productReducer
