import { Row } from 'antd'
import { SpaceTypes } from 'gadjet-v2-types/dist/model'
import { SpaceSummary } from 'gadjet-v2-types/dist/type'
import { useMemo } from 'react'

import ContentWrapper from '@components/atoms/ContentWrapper'
import SummaryItem from '@components/molecules/SummaryItem'

type Props = {
  summary: SpaceSummary
  spaceTypes: SpaceTypes[]
}
export default function BranchSpaceSummary({ summary, spaceTypes }: Props): JSX.Element {
  const getRateString = (rate?: number) => {
    if (!rate) return '-'
    return `${Math.round(rate * 100)}%`
  }

  const items = useMemo(
    () => [
      { key: 'total', label: '전체 공실률', value: getRateString(summary.total) },
      ...spaceTypes
        .filter(({ virtualFlag }) => !virtualFlag)
        .map(({ spaceTypeId, name }) => {
          return {
            key: `${spaceTypeId}`,
            label: name,
            value: getRateString(summary[`${spaceTypeId}`]),
          }
        }),
    ],
    [summary, spaceTypes]
  )

  return (
    <ContentWrapper>
      <Row>
        {items.map(({ key, label, value }) => (
          <SummaryItem key={key} label={label} value={value} span={4} />
        ))}
      </Row>
    </ContentWrapper>
  )
}
