import { Styles } from '@types'
import { Alert, Form, Input, Modal, notification } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { useForm } from 'antd/lib/form/Form'
import { Branches } from 'gadjet-v2-types/dist/model'
import { PopbillJoinForm } from 'gadjet-v2-types/dist/popbill/auth'
import { useEffect, useMemo, useState } from 'react'

import formRule from '@utils/formRule'
import getArrayFirst from '@utils/getArrayFirst'

import BranchAPI from '@apis/branch'

import HiddenItems from '@components/atoms/Form/HiddenItems'
import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  branch: Branches
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function JoinPopbillModal({ hqId, branchId, branch, visible, onClose, onDone }: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [agree, setAgree] = useState(false)
  const [form] = useForm<PopbillJoinForm>()

  const onOk = async () => {
    const values = await form.validateFields()
    setLoading(true)
    const { data } = await BranchAPI.joinPopbill({ hqId, branchId }, { form: values })
    const { isSuccess, message } = data
    setLoading(false)
    if (!isSuccess) {
      notification.error({ message })
      return
    }
    notification.success({ message })
    onDone()
    onClose()
  }

  const onReset = () => {
    form.resetFields()
    form.setFieldsValue(initialValues)

    setAgree(false)
    setLoading(false)
  }

  useEffect(() => {
    if (visible) onReset()
  }, [visible])

  const initialValues = useMemo((): PopbillJoinForm => {
    const { business } = branch

    const manager = getArrayFirst(business?.managers)

    return {
      ID: '',
      PWD: '',
      LinkID: 'GADJET',
      CorpNum: business?.businessNumber || '',
      CeoName: business?.director || '',
      CorpName: business?.name || '',
      Addr: business?.address || '',
      BizType: business?.businessType || '',
      BizClass: business?.businessClass || '',
      ContactName: manager?.name || '',
      ContactEmail: manager?.email || '',
      ContactTEL: manager?.tel || '',
    }
  }, [branch])

  return (
    <Modal
      visible={visible}
      onOk={onOk}
      onCancel={onClose}
      okButtonProps={{ loading, disabled: !agree }}
      okText="회원가입"
      cancelText="취소"
      title="팝빌 회원가입"
    >
      <Loading loading={loading}>
        <>
          <Alert message="세금계산서, 현금영수증 발행을 위해 팝빌 회원가입이 필요합니다." type="info" showIcon />
          <Form form={form} layout="vertical" style={styles.form} initialValues={initialValues}>
            <HiddenItems names={['LinkID']} />

            <Form.Item
              label="아이디"
              name="ID"
              rules={[{ required: true, min: 6, max: 50, pattern: /^[a-z0-9]/g }]}
              help="6~50자의 영어 소문자, 숫자만 사용 가능합니다."
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="비밀번호"
              name="PWD"
              rules={[{ required: true, min: 8, max: 20 }]}
              help="8~20자의 비밀번호를 입력하세요."
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label="사업자번호"
              name="CorpNum"
              rules={[formRule.required, formRule.businessNumber]}
              help="-을 제외한 10자리 숫자를 입력하세요."
            >
              <Input />
            </Form.Item>
            <Form.Item label="대표자" name="CeoName" rules={[formRule.required]}>
              <Input />
            </Form.Item>
            <Form.Item label="상호" name="CorpName" rules={[formRule.required]}>
              <Input />
            </Form.Item>
            <Form.Item label="사업자주소" name="Addr" rules={[formRule.required]}>
              <Input />
            </Form.Item>
            <Form.Item label="업태" name="BizType" rules={[formRule.required]}>
              <Input />
            </Form.Item>
            <Form.Item label="종목" name="BizClass" rules={[formRule.required]}>
              <Input />
            </Form.Item>
            <Form.Item label="담당자 이름" name="ContactName" rules={[formRule.required]}>
              <Input />
            </Form.Item>
            <Form.Item label="담당자 이메일" name="ContactEmail" rules={[formRule.required, formRule.email]}>
              <Input />
            </Form.Item>
            <Form.Item label="담당자 연락처" name="ContactTEL" rules={[formRule.required]}>
              <Input />
            </Form.Item>
            <Form.Item>
              <div style={styles.checkboxWrapper}>
                <Checkbox checked={agree} onClick={() => setAgree(!agree)}>
                  <a href="https://www.popbill.com/Policy/Privacy" target="_blank" rel="noreferrer">
                    개인정보처리방침
                  </a>
                  과
                  <a href="https://www.popbill.com/Policy/Agreement" target="_blank" rel="noreferrer">
                    이용약관
                  </a>
                  에 동의합니다.
                </Checkbox>
              </div>
            </Form.Item>
          </Form>
        </>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  form: { padding: '30px 0' },
  checkboxWrapper: { display: 'flex', justifyContent: 'center' },
}
