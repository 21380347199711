import { TablePaginationConfig, TableProps } from 'antd'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { useState } from 'react'

import { onChangeTable } from '@utils/antd-table'

const usePagination = <M extends unknown>(_pagination?: Partial<Pagination>) => {
  const [state, setState] = useState<Pagination>({ current: 1, pageSize: 10, sort: [], ..._pagination })

  const onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<M> | SorterResult<M>[]
  ) => {
    const { current, pageSize, sort } = onChangeTable(pagination, filters, sorter)
    setState({ current, pageSize, sort })
  }

  return [state, onChange] as [Pagination, TableProps<M>['onChange']]
}

export default usePagination
