import { Styles } from '@types'
import { Input, Select, Tag } from 'antd'
import { ContractStatus } from 'gadjet-v2-types/dist/type'
import { contractStatus } from 'gadjet-v2-types/dist/type/label'
import { ContractSearch } from 'gadjet-v2-types/dist/type/search'
import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'

import SpaceTypeAPI from '@apis/branch/spaceType'
import branchQueryKey from '@apis/queryKey/branch'

import SearchBox from '@components/molecules/SearchBox'
import SearchItem from '@components/molecules/SearchItem'

type Props = {
  hqId: number
  branchId: number
  search: ContractSearch
  isBranchSwitched: boolean
  onChangeSearch: (search: Partial<ContractSearch>) => void
  onSearch: () => void
}

export default function BranchContractSearchBox({
  hqId,
  branchId,
  isBranchSwitched,
  search,
  onChangeSearch,
  onSearch,
}: Props): JSX.Element {
  const onChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => onChangeSearch({ query: e.target.value })

  const onChangeSpaceType = (_spaceTypes: number[]) => onChangeSearch({ spaceTypes: _spaceTypes })

  const onChangeStatus = (_status: ContractStatus[]) => onChangeSearch({ status: _status })

  const { isLoading, data } = useQuery(
    branchQueryKey.getBranchSpaceTypes(branchId),
    () => SpaceTypeAPI.getSpaceTypes({ hqId, branchId }),
    { refetchOnWindowFocus: false }
  )

  const spaceTypes = useMemo(() => {
    if (!data) return []
    return data.data.map(({ name, spaceTypeId }) => ({
      label: name,
      value: spaceTypeId,
    }))
  }, [data])

  const contractStatusOptions = useMemo(
    (): { key: ContractStatus; label: string }[] => [
      { key: 'before-started', label: contractStatus['before-started'] },
      { key: 'expired', label: contractStatus.expired },
      { key: 'extended', label: contractStatus.extended },
      { key: 'started', label: contractStatus.started },
      { key: 'suspended', label: contractStatus.suspended },
    ],
    []
  )

  useEffect(() => {
    if (isBranchSwitched) {
      onChangeSpaceType([])
    }
  }, [branchId])

  return (
    <SearchBox onSearch={onSearch}>
      <SearchItem label="공간유형" span={8}>
        <Select
          mode="multiple"
          onChange={onChangeSpaceType}
          size="large"
          value={search.spaceTypes}
          style={styles.select}
          tagRender={({ label }) => <Tag>{label}</Tag>}
          loading={isLoading}
          options={spaceTypes}
        />
      </SearchItem>

      <SearchItem label="상태" span={6}>
        <Select
          mode="multiple"
          onChange={onChangeStatus}
          size="large"
          value={search.status}
          style={styles.select}
          tagRender={({ label }) => <Tag>{label}</Tag>}
        >
          {contractStatusOptions.map((cs) => (
            <Select.Option key={cs.key} value={cs.key}>
              {cs.label}
            </Select.Option>
          ))}
        </Select>
      </SearchItem>

      <SearchItem label="검색어" span={8}>
        <Input size="large" placeholder="검색어를 입력하세요." value={search.query} onChange={onChangeQuery} />
      </SearchItem>
    </SearchBox>
  )
}

const styles: Styles = {
  select: { width: '100%' },
}
