import { BREAKPOINTS } from '@const'
import dayjs from 'dayjs'
import styled from 'styled-components'

import PromotionBanner from '../../atoms/PromotionBanner'
import PlanCard from '../../molecules/PlanCard'
import * as S from '../../styles'

type Props = {
  promotion: Promotion
  gadjetPlans: GadjetPlan[]
  selectedPlan: GadjetPlan
  onClickSelectPlan: (plan: GadjetPlan) => void
}

export default function GadjetPlans({ gadjetPlans, promotion, selectedPlan, onClickSelectPlan }: Props): JSX.Element {
  return (
    <Wrapper>
      <Title>
        지금 가젯 도입하면
        <br />
        지점 별 계약 100개까지
        <MobileBr /> 추가 비용 없이!
      </Title>
      <SubTitle>
        100개가 넘어도 합리적으로,
        <MediaBr /> 이용한 만큼만 결제해요.
      </SubTitle>
      {promotion.isVisible && (
        <PromotionBanner
          dDay={promotion.endDate.diff(dayjs(), 'day')}
          content={
            <PromotionContent>
              <span>🎁</span>
              <span>&nbsp;무료 60일 이용 후 {promotion.durationMonth}개월&nbsp;</span>
              <Emphasis> {promotion.rate * 100}% 할인 중!</Emphasis>
            </PromotionContent>
          }
          endDate={promotion.endDate.format('M월 D일')}
        />
      )}
      <InnerWrapper>
        <PlanList>
          {gadjetPlans.map((plan) => (
            <PlanCard
              {...plan}
              isYearPrepay={plan.key === 'yearPrepay'}
              promotion={promotion}
              isSelected={plan.key === selectedPlan.key}
              onClick={() => onClickSelectPlan(plan)}
            />
          ))}
        </PlanList>
        <NoticeList>
          <S.NoticeItem>위 금액은 지점 1개당 기준입니다.</S.NoticeItem>
          <S.NoticeItem>모든 요금은 부가세(10%) 별도입니다.</S.NoticeItem>
          <S.NoticeItem>10개 이상의 지점을 운영하실 경우 별도 문의해 주세요.</S.NoticeItem>
        </NoticeList>
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 140px;
  @media ${BREAKPOINTS.lg} {
    padding: 0px 16px 100px 16px;
  }
`

const Title = styled.h2`
  width: max-content;
  font-weight: 700;
  font-size: 42px;
  line-height: 140%;
  text-align: center;
  color: #2e2e2e;
  margin-bottom: 12px;
  @media ${BREAKPOINTS.lg} {
    font-weight: 700;
    font-size: 32px;
  }
`

const MobileBr = styled.br`
  display: none;
  @media ${BREAKPOINTS.sm} {
    display: block;
  }
`

const MediaBr = styled.br`
  display: none;
  @media ${BREAKPOINTS.lg} {
    display: block;
  }
`

const SubTitle = styled.h4`
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #7a7b7c;
  margin-bottom: 44px;
  @media ${BREAKPOINTS.lg} {
    font-size: 18px;
    margin-bottom: 40px;
  }
  @media ${BREAKPOINTS.sm} {
    margin-bottom: 20px;
  }
`

const InnerWrapper = styled.div`
  max-width: 860px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 27px;
  @media ${BREAKPOINTS.lg} {
    gap: 24px;
  }
`
const PlanList = styled.ul`
  display: flex;
  gap: 25px;
  list-style: none;
  margin: 0;
  padding: 0;

  @media ${BREAKPOINTS.md} {
    overflow-x: scroll;
    padding: 100px;
    margin: -100px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media ${BREAKPOINTS.sm} {
    padding: 90px;
    gap: 16px;
  }
`
const NoticeList = styled.ul`
  margin: 0;
  padding: 0 0 0 10px;
  @media ${BREAKPOINTS.lg} {
    align-self: center;
  }
`
const Emphasis = styled.span`
  color: #f43f24;
`
const PromotionContent = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  @media ${BREAKPOINTS.md} {
    flex-direction: column;
  }
`
