import Picture from '@pages/Landing/FeaturePages/atoms/Picture'
import * as S from '@pages/Landing/FeaturePages/styles'

export default function IssueInvoice(): JSX.Element {
  return (
    <S.SectionInnerWrapper flexDirection="row" width="1000px">
      <S.Container
        defaultStyles={{
          flexDirection: 'column',
          width: '100%',
          background: 'linear-gradient(341.78deg, #F3F4F5 10.65%, #E4F6EC 87.62%)',
          height: '500px',
          position: 'relative',
        }}
        mediaStyles={{ height: '480px' }}
      >
        <S.DescriptionWrapper defaultStyle={{ textAlign: 'center', padding: '40px 0 0 0' }}>
          <S.BoxTitle>간편 인증</S.BoxTitle>
          <S.Description>
            귀찮은 공인인증서는
            <br />
            필요 없어요.
          </S.Description>
        </S.DescriptionWrapper>
        <S.ImageWrapper defaultStyle={{ alignItems: 'flex-end' }}>
          <Picture
            srcPNG="/images/landing/features/invoice/locker.png"
            srcWEBP="/images/landing/features/invoice/locker.webp"
            alt="locker"
            width={240}
            height={190}
            defaultStyle={{ margin: '0 0 84px 0' }}
          />
        </S.ImageWrapper>
      </S.Container>
      <S.Container
        defaultStyles={{
          flexDirection: 'column',
          width: '100%',
          background: '#F2F0FC',
          height: '500px',
          position: 'relative',
        }}
        mediaStyles={{ height: '480px' }}
      >
        <S.DescriptionWrapper defaultStyle={{ textAlign: 'center', padding: '40px 0 0 0' }}>
          <S.BoxTitle>증빙</S.BoxTitle>
          <S.Description>
            세금계산서도 현금영수증도
            <br />
            이미 등록된 계약 정보로
            <br />
            클릭 한 번이면 발행 끝.
          </S.Description>
        </S.DescriptionWrapper>
        <S.ImageWrapper defaultStyle={{ alignItems: 'flex-end' }}>
          <Picture
            srcPNG="/images/landing/features/invoice/reciept.png"
            srcWEBP="/images/landing/features/invoice/reciept.webp"
            width={222}
            height={92}
            alt="reciept"
            defaultStyle={{ margin: '0 0 112px 0' }}
          />
        </S.ImageWrapper>
      </S.Container>
    </S.SectionInnerWrapper>
  )
}
