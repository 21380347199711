import { notification } from 'antd'

import RentalAPI from '@apis/branch/rental'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  rentalId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function RentalDeleteModal({ hqId, branchId, rentalId, visible, onClose, onDone }: Props): JSX.Element {
  const onConfirm = async () => {
    if (!rentalId) return
    await RentalAPI.deleteRental({ hqId, branchId, rentalId })
    notification.success({ message: '삭제되었습니다.' })
  }

  return (
    <ConfirmModal
      title="대관/대여 정보 삭제"
      description="대관/대여 항목을 삭제할 경우 관련 이용 내역도 삭제되어 이후 확인이 불가합니다."
      okText="삭제"
      onConfirm={onConfirm}
      visible={visible}
      onClose={onClose}
      onDone={onDone}
    />
  )
}
