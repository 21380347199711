import { Form, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { useState } from 'react'

import bufferToFileDownload from '@utils/download'

import ProductAPI from '@apis/branch/product'

import LocalDatePicker from '@components/atoms/LocalDatePicker'
import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  visible: boolean
  onClose: () => void
}

export default function ProductSaleExportModal({ hqId, branchId, visible, onClose }: Props): JSX.Element {
  const [form] = useForm<{ datetime: [string, string] }>()
  const [loading, setLoading] = useState(false)

  const onOk = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)
      const { data } = await ProductAPI.exportProductSales({ hqId, branchId }, values)
      bufferToFileDownload('상품_판매내역.xlsx', Buffer.from(data))
      setLoading(false)
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const initialValues = {
    datetime: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('month').format('YYYY-MM-DD')],
  }

  return (
    <Modal title="다운로드" onOk={onOk} okText="다운로드" visible={visible} confirmLoading={loading} onCancel={onClose}>
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item
            label="판매기간"
            name="datetime"
            getValueProps={([f, t]) => ({ value: [dayjs(f), dayjs(t)] })}
            getValueFromEvent={(_, [f, t]) => [f, t]}
            required
          >
            <LocalDatePicker.RangePicker allowClear={false} />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}
