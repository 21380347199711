export default function TenantIcon(): JSX.Element {
  return (
    <img
      src="/images/landing/home/previews/tenant-working-man.png"
      alt="tenant-emoji"
      width={38}
      height={38}
      style={{ position: 'absolute', top: '40px', right: '40px' }}
    />
  )
}
