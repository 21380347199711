import { BREAKPOINTS, CHANNEL_TALK_URL, TERMS_OF_SERVICE_URL } from '@const'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { featureMenuList } from '../Header'

type MenuListType = { label: string; key: string; path?: string; target?: string }[]

export default function Footer(): JSX.Element {
  const mainMenuList: MenuListType = [
    // { key: 'pricing', label: '가격정책', path: '/pricing' },
    { key: 'business', label: '가젯 비즈니스', path: '/business' },
    {
      key: 'guide',
      label: '가젯 가이드',
      path: 'https://gadjet.notion.site/78b2e1111fcf43208f420b1815d7a479',
      target: '_blank',
    },
  ]

  const subMenuList: MenuListType = [
    {
      key: 'notice',
      label: '공지사항',
      path: 'https://gadjet.notion.site/84498c568b1b45e59a2a9167e50c7587',
      target: '_blank',
    },
    { key: 'inquiry', label: '1:1 문의', path: CHANNEL_TALK_URL, target: '_blank' },
    { key: 'blog', label: '블로그', path: 'https://g-labs.tistory.com/', target: '_blank' },
    {
      key: 'mobileApp',
      label: '가젯 모바일앱 - 입주사용',
      path: 'https://www.notion.so/840cdcfbf6664b7daee8a22a53266b38#e3acdda94aec463baeb74118e25ff3ae',
      target: '_blank',
    },
  ]

  return (
    <FooterWrapper>
      <Wrapper>
        <CompanyName>주식회사 지랩스</CompanyName>
        <InnerSection>
          <CompanyDetails>
            <Address>
              대표 백기민 | 개인정보관리책임자 남호석 <br />
              사업자등록번호 378-87-00388 | 통신판매업신고번호 제2018-서울강남-02569호
              <br />
              서울특별시 강남구 선릉로 578, 7층(삼성동) | support@gadjet.io | 02-587-4025
              <br />
              Copyright ⓒ g-labs Inc. All Rights Reserved.
            </Address>
            <span>
              <Link to={{ pathname: TERMS_OF_SERVICE_URL }} target="_blank">
                <PolicyLabel>이용약관</PolicyLabel>
              </Link>
              {` | `}
              <Link
                to={{ pathname: 'https://gadjet.notion.site/22-05-23-c8524f3fc1c741faab9a55a680a55bc2' }}
                target="_blank"
              >
                <PolicyLabel>개인정보처리방침</PolicyLabel>
              </Link>
            </span>
            <span>
              주식회사 지랩스는 결제정보의 중개서비스 또는 통신판매중개시스템의 제공자로서, 통신판매의 당사자가 아니며
              공간의 이용, 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다.
            </span>
          </CompanyDetails>

          <MenuGroup>
            <MainMenuGroup>
              <MenuList>
                {mainMenuList.map(({ label, key, path, target }) => (
                  <li key={key}>
                    <MenuItem to={{ pathname: path }} target={target}>
                      {label}
                    </MenuItem>
                  </li>
                ))}
              </MenuList>
              <MenuList>
                <li>주요기능</li>
                {featureMenuList.map(({ label, key, path }) => (
                  <li key={key}>
                    <MenuItem to={{ pathname: path }} $isFeatureItem>
                      {label}
                    </MenuItem>
                  </li>
                ))}
              </MenuList>
            </MainMenuGroup>
            <MenuList>
              {subMenuList.map(({ label, key, path, target }) => (
                <li key={key}>
                  <MenuItem to={{ pathname: path }} target={target}>
                    {label}
                  </MenuItem>
                </li>
              ))}
            </MenuList>
          </MenuGroup>
        </InnerSection>
      </Wrapper>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 100px;
  width: 100%;
  background: #2e2e2e;
  border-top: 1px solid #ffffff;

  @media ${BREAKPOINTS.lg} {
    align-items: flex-start;
    padding: 60px 40px;
  }

  @media ${BREAKPOINTS.sm} {
    align-items: flex-start;
    padding: 60px 20px;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
`

const InnerSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  @media ${BREAKPOINTS.lg} {
    flex-direction: column-reverse;
    gap: 24px;
  }
`

const CompanyName = styled.span`
  font-weight: 300;
  font-size: 13px;
  line-height: 150%;
  color: #ffffff;
  padding-bottom: 12px;
  @media ${BREAKPOINTS.lg} {
    padding-bottom: 24px;
  }
`
const CompanyDetails = styled.span`
  max-width: 480px;
  width: 100%;
  color: #ffffff;
  opacity: 0.8;
  font-weight: 200;
  font-size: 10px;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  letter-spacing: 0.5px;
`

const Address = styled.address`
  margin-bottom: 0;
`

const MenuGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 50px;
  padding: 0px;
  @media ${BREAKPOINTS.lg} {
    width: 100%;
    gap: 0px;
  }
`

const MainMenuGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 50px;
  padding: 0px;
  @media ${BREAKPOINTS.lg} {
    width: 100%;
    gap: 0px;
  }
  @media ${BREAKPOINTS.sm} {
    flex-direction: column;
    gap: 8px;
  }
`

const MenuList = styled.ul`
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;

  font-weight: 300;
  font-size: 12px;
  line-height: 160%;
  color: #ffffff;
  @media ${BREAKPOINTS.lg} {
    width: 50%;
  }
`
const MenuItem = styled(Link)`
  list-style: none;
  width: max-content;
  color: #ffffff;
  opacity: ${({ $isFeatureItem }: { $isFeatureItem?: boolean }) => $isFeatureItem && 0.6};
  :hover {
    color: #ffffff;
  }
`
const PolicyLabel = styled.span`
  color: #ffffff;
`
