import { ContractExtendStatus } from 'gadjet-v2-types/dist/type'
import { contractExtendStatus } from 'gadjet-v2-types/dist/type/label'

import TypeTag from '@components/molecules/TypeTag'

export default function ExtendStatus({ contract }: ContractColumnProps): JSX.Element {
  const { extendStatus } = contract
  return (
    <div>
      <TypeTag<ContractExtendStatus>
        label={contractExtendStatus[extendStatus]}
        value={extendStatus}
        switchCase={{
          values: [['none'], ['asked'], ['approval'], ['refusal']],
          types: ['default', 'ongoing', 'success', 'fail'],
        }}
      />
    </div>
  )
}
