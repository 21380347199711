const spaceLabelBg = (colorInfo: string) => {
  const color = colorInfo
  switch (color) {
    case '#3ebd73':
      return '#e8ffed'
    case '#f16d37':
      return '#ffeee4'
    case '#135dac':
      return '#e8f3ff'
    case '#ffc528':
      return '#fff7e0'
    case '#be42f8':
      return '#f9ebff'

    case '#7ecb42':
      return '#f1ffe7'
    case '#f58b8a':
      return '#fff0f0'
    case '#60bede':
      return '#effbff'
    case '#ffdf00':
      return '#fffad8'
    case '#ec75ff':
      return '#fdf1ff'

    case '#38ab9c':
      return '#edfcfa'
    case '#ff90cc':
      return '#fff1f9'
    case '#63b4ff':
      return '#ebf5ff'
    case '#f09300':
      return '#fff5e5'
    case '#c61fe1':
      return '#fdf1ff'

    case '#289148':
      return '#eefdf2'
    case '#d26441':
      return '#fcefeb'
    case '#406ef2':
      return '#eff3ff'
    case '#cda96d':
      return '#fff7Ea'
    case '#9644ff':
      return '#faf2ff'

    case '#8fd9a9':
      return '#f6fff9'
    case '#c27d56':
      return '#fbf0e6'
    case '#655de8':
      return '#f5f4ff'
    case '#977255':
      return '#f6f0eb'
    case '#ca87ff':
      return '#f9f4ff'

    default:
      return '#e8ffed'
  }
}

export default spaceLabelBg
