import useModal from '@hooks/useModal'
import { useContext } from 'react'

import { AdminGlobalContext } from '@components/contexts/AdminGlobal'
import AddBranchFormModal from '@components/organisms/AddBranch/Modals/FormModal'

export default function BranchPage(): JSX.Element {
  const [formModal, onVisibleFormModal, onCloseFormModal] = useModal<{ branchId?: number | undefined }>({
    visible: true,
    branchId: undefined,
  })

  const { hqId } = useContext(AdminGlobalContext)
  return (
    <>
      <AddBranchFormModal onClose={onCloseFormModal} visible={formModal.visible} hqId={hqId} />
    </>
  )
}
