import { notification } from 'antd'

import ContractAPI from '@apis/branch/contract'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  contractId: number
  onClose?: () => void
  onDone?: () => void
}

export default function ConfirmResignModal({ hqId, branchId, contractId, onClose, onDone }: Props): JSX.Element {
  const onConfirm = async () => {
    if (!contractId) return
    await ContractAPI.updateContractSignatureStatus({ hqId, branchId, contractId }, { signatureStatus: 'waiting' })
    notification.success({ message: '서명을 요청했습니다.' })
  }

  return (
    <ConfirmModal
      title={
        <>
          수정사항이 저장되었습니다. <br /> 계약서 서명을 재요청하시겠어요?
        </>
      }
      description="입주사의 모바일 앱으로 전송됩니다"
      okText="서명 요청 보내기"
      onConfirm={onConfirm}
      visible
      onClose={onClose}
      onDone={onDone}
      showIcon={false}
      isConfirmResign
    />
  )
}
