import { Styles } from '@types'
import { Col } from 'antd'

type Props = { span?: number; label: string; value: string | number | JSX.Element }

export default function ModalAlertItem({ span, label, value }: Props): JSX.Element {
  const Empty = <span style={styles.empty}>-</span>
  return (
    <Col span={span}>
      <div style={styles.label}>{label}</div>
      {typeof value === 'number' || typeof value === 'string' ? (
        <div style={styles.value}>{value || Empty}</div>
      ) : (
        value || Empty
      )}
    </Col>
  )
}

const styles: Styles = {
  label: { opacity: '0.6', fontSize: '14px', marginBottom: '6px' },
  value: { fontSize: '16px' },
  empty: { opacity: 0.6 },
}
