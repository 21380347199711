import { useRouteMatch, Route, Switch } from 'react-router-dom'

import BranchBillPage from '@pages/Branch/BillPage'
import PaymentPage from '@pages/Branch/PaymentPage'
import ReceiptPage from '@pages/Branch/ReceiptPage'
import NotFoundPage from '@pages/Error/NotFoundPage'

export default function BranchBillRouter(): JSX.Element {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${url}/list`}>
        <BranchBillPage />
      </Route>
      <Route path={`${url}/receipt`}>
        <ReceiptPage />
      </Route>
      <Route path={`${url}/payment`}>
        <PaymentPage />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  )
}
