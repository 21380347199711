import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { ProductSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ProductAPI from '@apis/branch/product'

import {
  setProductPagination,
  setProductSearch,
  searchProducts,
  setProductDefault,
  setProductLoading,
} from '@reducers/branch/product.reducer'
import { RootState } from '@reducers/index'

import BranchProductListTable from '@components/organisms/Branch/Product/ListTable'
import ProductDeleteModal from '@components/organisms/Branch/Product/Modals/ProductDeleteModal'
import ProductFormModal from '@components/organisms/Branch/Product/Modals/ProductFormModal'
import BranchProductSearchBox from '@components/organisms/Branch/Product/SearchBox'
import BranchProductTitle from '@components/organisms/Branch/Product/Title'

export type BranchProductAction = 'add' | 'update' | 'delete'

export default function ProductPage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const { loading, search, pagination, searchResult } = useSelector((state: RootState) => state.product)

  const [formModal, onVisibleFormModal, onCloseFormModal] = useModal<{ productId?: number }>({
    visible: false,
    productId: undefined,
  })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal<{ productId?: number }>({
    visible: false,
    productId: undefined,
  })

  const onChangeSearch = (_search: Partial<ProductSearch>) => dispatch(setProductSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setProductPagination(_pagination))
    dispatch(searchProducts(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setProductPagination({ current: 1 }))
    dispatch(searchProducts(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setProductDefault(hqId, branchId)), [hqId, branchId])
  const onDone = () => onResetAndSearch()

  const onAction = (action: BranchProductAction, productId?: number) => {
    if (action === 'add') onVisibleFormModal({ productId: undefined })
    if (action === 'update') onVisibleFormModal({ productId })
    if (action === 'delete') onVisibleDeleteModal({ productId })
  }

  const refreshPage = () => {
    dispatch(setProductPagination(pagination))
    dispatch(searchProducts(hqId, branchId))
  }

  const onChangeActiveStatus = async (isActive: boolean, productId: number) => {
    dispatch(setProductLoading(true))
    await ProductAPI.updateProduct({ hqId, branchId, productId }, { product: { isActive } })
    refreshPage()
  }

  useEffect(() => {
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchProductSearchBox search={search} onChangeSearch={onChangeSearch} onSearch={onSearch} />

      <BranchProductTitle onAction={onAction} />

      <BranchProductListTable
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        products={searchResult.products}
        total={searchResult.total}
        onAction={onAction}
        onChangeActiveStatus={onChangeActiveStatus}
      />

      <ProductFormModal
        hqId={hqId}
        branchId={branchId}
        productId={formModal.productId}
        visible={formModal.visible}
        onClose={onCloseFormModal}
        onDone={onDone}
      />

      <ProductDeleteModal
        hqId={hqId}
        branchId={branchId}
        productId={deleteModal.productId}
        visible={deleteModal.visible}
        onClose={onCloseDeleteModal}
        onDone={onDone}
      />
    </div>
  )
}
