import { Styles } from '@types'
import { Form, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useMemo, useState } from 'react'

import bufferToFileDownload from '@utils/download'
import formRule from '@utils/formRule'

import BillAPI from '@apis/branch/bill'

import LocalDatePicker from '@components/atoms/LocalDatePicker'

type Props = {
  hqId: number
  branchId: number
  visible: boolean
  onClose: () => void
  onDone?: () => void
}

export default function BillExportModal({
  hqId,
  branchId,
  visible,
  onClose,
  onDone = () => undefined,
}: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [form] = useForm<{ dateRange: [Dayjs, Dayjs] }>()

  const onOk = async () => {
    const { dateRange } = await form.validateFields()

    setLoading(true)

    const { data } = await BillAPI.exportBiils(
      { hqId, branchId },
      { startDate: [dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')] }
    )
    bufferToFileDownload('청구서.xlsx', Buffer.from(data))

    setLoading(false)
    onDone()
  }

  const initialValues = useMemo(
    () => ({
      criteria: 'startDate',
      dateRange: [dayjs().startOf('month'), dayjs().endOf('month')],
    }),
    []
  )

  const onReset = () => {
    form.resetFields()
    setLoading(false)
  }

  useEffect(() => {
    if (visible) onReset()
  }, [visible])

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      onOk={onOk}
      title="청구서 다운로드"
      okText="다운로드"
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item label="제공기간 시작일" name="dateRange" rules={[formRule.required]}>
          <LocalDatePicker.RangePicker size="large" style={styles.datePicker} allowClear={false} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const styles: Styles = {
  datePicker: { width: '100%' },
}
