import { createContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

type AdminGlobalContextState = {
  hqId: number
  branchId: number
  drawer: boolean
  setDrawer: (drawer: boolean) => void
  isUnread: boolean
  setUnread: (isUnread: boolean) => void
}

const AdminGlobalContext = createContext<AdminGlobalContextState>({
  hqId: 0,
  branchId: 0,
  drawer: false,
  setDrawer: (drawer: boolean) => {},
  isUnread: false,
  setUnread: (isUnread: boolean) => {},
})

type Props = { children: React.ReactNode }
const AdminGlobalProvider = ({ children }: Props): JSX.Element => {
  const { hqId, branchId } = useParams<{ hqId: string | undefined; branchId: string | undefined }>()
  const [state, setState] = useState({ hqId: 0, branchId: 0, drawer: false, isUnread: false })

  const setUnread = (isUnread: boolean) => setState({ ...state, isUnread })
  const setDrawer = (drawer: boolean) => setState({ ...state, drawer })

  useEffect(() => {
    setState({ ...state, hqId: hqId ? Number(hqId) : 0, branchId: branchId ? Number(branchId) : 0 })
  }, [hqId, branchId])
  // branchid 바꿔주는 부분
  return (
    <AdminGlobalContext.Provider value={{ ...state, setUnread, setDrawer }}>{children}</AdminGlobalContext.Provider>
  )
}

export { AdminGlobalContext, AdminGlobalProvider }
