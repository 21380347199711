import { Styles } from '@types'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

type Props = {
  hqId: number
  branchId: number
}

export default function NoRentals({ hqId, branchId }: Props): JSX.Element {
  const { push } = useHistory()
  return (
    <div style={styles.wrapper}>
      <div style={styles.text}>등록된 대관/대여 항목이 없습니다.</div>
      <Button onClick={() => push(`/hq/${hqId}/branch/${branchId}/rental/list`, { action: 'add' })}>등록하기</Button>
    </div>
  )
}

const styles: Styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  text: { color: '#7A7B7C', fontWeight: 400, fontSize: '14px', marginTop: '10px', marginBottom: '11px' },
}
