import { ReactComponent as Info } from '@assets/Icons/helper.svg'
import { Styles } from '@types'
import { Form, FormInstance, Input, Tooltip } from 'antd'
import { Contracts } from 'gadjet-v2-types/dist/model'
import styled from 'styled-components'

import formRule from '@utils/formRule'
import mask from '@utils/mask'

type Props = { form: FormInstance<Contracts>; isUpdate: boolean }

export default function EtcTab({ form, isUpdate }: Props): JSX.Element {
  const toolTip = (
    <p>
      세금계산서에 포함되는 메모입니다.
      <Tooltip placement="bottom" title="수정 시 청구서와 세금계산서의 비고란도 함께 수정됩니다.">
        <InfoIcon />
      </Tooltip>
    </p>
  )
  return (
    <>
      <Form.Item
        label="계약 크레딧"
        name="creditAmount"
        rules={[formRule.required, formRule.positiveNumber]}
        {...mask.krw}
      >
        <Input addonAfter="크레딧" />
      </Form.Item>
      <Form.Item label="비고" name="note" extra={toolTip}>
        <Input.TextArea style={styles.textarea} showCount maxLength={100} />
      </Form.Item>
      <Form.Item label="공개 메모" name="publicMemo" extra="계약서에 포함되는 메모입니다.">
        <Input.TextArea style={styles.textarea} />
      </Form.Item>
      <Form.Item label="비공개 메모" name="privateMemo" extra="입주사에게 공개되지 않습니다.">
        <Input.TextArea style={styles.textarea} />
      </Form.Item>
    </>
  )
}

const styles: Styles = {
  textarea: { height: '100px' },
}

const InfoIcon = styled(Info)`
  cursor: pointer;
  position: absolute;
`
