import { Button } from 'antd'
import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  onClick: () => void
  label: string
  icon: ReactNode
}

export default function BlockIconButton({ onClick, label, icon }: Props): JSX.Element {
  return (
    <BlockButton onClick={onClick} block size="large">
      <div>
        {icon}
        <span>{label}</span>
      </div>
    </BlockButton>
  )
}

const BlockButton = styled(Button)`
  height: 52px;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #0d0d0d;
  color: #0d0d0d;
  border-radius: 8px;
  :hover {
    background-color: #f4f5f7;
  }
`
