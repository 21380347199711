import { Additions } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { AdditionSearch } from 'gadjet-v2-types/dist/type/search'

// ActionType
export const SEARCH_ADDITIONS = 'addition/SEARCH_ADDITIONS' as const
export const SET_ADDITION_DEFAULT = 'addition/SET_DEFAULT' as const
export const SET_ADDITION_LOADING = 'addition/SET_ADDITION_LOADING' as const
export const SET_ADDITION_SEARCH_RESULT = 'addition/SET_ADDITION_SEARCH_RESULT' as const
export const SET_ADDITION_SEARCH = 'addition/SET_ADDITION_SEARCH' as const
export const SET_ADDITION_PAGINATION = 'addition/SET_ADDITION_PAGINATION' as const
export const SET_ADDITION_SUMMARY = 'addition/SET_ADDITION_SUMMARY' as const
export const SET_ADDITION_PAGE_CONFIG = 'addition/SET_ADDITION_PAGE_CONFIG' as const

// Action
export const searchAdditions = (hqId: number, branchId: number) => ({
  type: SEARCH_ADDITIONS,
  payload: { hqId, branchId },
})
export const setAdditionDefault = (hqId: number, branchId: number) => ({
  type: SET_ADDITION_DEFAULT,
  payload: { hqId, branchId },
})
export const setAdditionLoading = (loading: boolean) => ({
  type: SET_ADDITION_LOADING,
  payload: { loading },
})
export const setAdditionSearchResult = (result: Partial<AdditionState['searchResult']>) => ({
  type: SET_ADDITION_SEARCH_RESULT,
  payload: { result },
})
export const setAdditionSearch = (search: Partial<AdditionSearch>) => ({
  type: SET_ADDITION_SEARCH,
  payload: { search },
})
export const setAdditionPagination = (pagination: Partial<Pagination>) => ({
  type: SET_ADDITION_PAGINATION,
  payload: { pagination },
})

export type AdditionAction =
  | ReturnType<typeof searchAdditions>
  | ReturnType<typeof setAdditionDefault>
  | ReturnType<typeof setAdditionLoading>
  | ReturnType<typeof setAdditionSearchResult>
  | ReturnType<typeof setAdditionSearch>
  | ReturnType<typeof setAdditionPagination>

type AdditionState = {
  loading: boolean
  searchResult: { branchId: number; total: number; additions: Additions[] }
  search: AdditionSearch
  pagination: Pagination
}

const additionState: AdditionState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, additions: [] },
  search: { query: '' },
  pagination: { current: 1, pageSize: 10 },
}

const additionReducer = (state = additionState, action: AdditionAction): AdditionState => {
  switch (action.type) {
    case SET_ADDITION_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_ADDITION_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_ADDITION_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_ADDITION_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    default:
      return state
  }
}

export default additionReducer
