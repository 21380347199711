import { BREAKPOINTS } from '@const'
import { Modal } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useState } from 'react'
import styled from 'styled-components'

import ContactAPI, { ContactSales } from '@apis/contact'

import ContactForm from '@pages/Landing/organisms/ContactForm'

import toast from '@components/atoms/Toast'

type ContactFormModalProps = {
  visible: boolean
  onCloseModal: () => void
}

export default function ContactFormModal({ visible, onCloseModal }: ContactFormModalProps) {
  const [form] = useForm<ContactSales>()
  const [loading, setLoading] = useState<boolean>(false)
  const [isEtcChecked, setIsEtcChecked] = useState<boolean>(false)

  const [etcFeature, setEtcFeature] = useState<string>('')

  const onClick = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()
      const interestedFeatureName = form.getFieldValue('interestedFeatureName')
      const interestedFeatureWithEtc = [...interestedFeatureName.filter((item: string) => item !== 'etc'), etcFeature]
      const data = await ContactAPI.contactSales(
        !isEtcChecked
          ? values
          : {
              ...values,
              interestedFeatureName: interestedFeatureWithEtc,
            }
      )
      if (data) {
        toast.success('문의가 등록되었습니다')
        setLoading(false)
        onCloseModal()
        form.resetFields()
      }
    } catch (error) {
      // console.log(error)
    }
  }
  return (
    <StyledModal
      maskClosable={false}
      visible={visible}
      onCancel={onCloseModal}
      confirmLoading={loading}
      title={<Header>상담 및 도입 문의</Header>}
      footer={<div />}
    >
      <InnerWrapper>
        <ContactForm
          form={form}
          isEtcChecked={isEtcChecked}
          onSubmit={onClick}
          setIsEtcChecked={setIsEtcChecked}
          setEtcFeature={setEtcFeature}
        />
      </InnerWrapper>
    </StyledModal>
  )
}
const InnerWrapper = styled.div`
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
`
const StyledModal = styled(Modal)`
  .ant-modal-header {
    background-color: #fdfdfd;
    padding: 16px 24px 0 24px;
  }
  .ant-modal-content {
    background-color: #fdfdfd;
  }
  .ant-modal-footer {
    padding: 12px 0;
    @media ${BREAKPOINTS.sm} {
      padding: 2px 0;
    }
  }
`

const Header = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  color: #000b43;
  padding-top: 32px;
  padding-bottom: 8px;

  @media ${BREAKPOINTS.sm} {
    padding-top: 8px;
    padding-bottom: 0;
  }
`
