import { AccessLogs } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { AccessLogSearch } from 'gadjet-v2-types/dist/type/search'

import { getDefaultRange } from '@utils/datetime'

// ActionType
export const SEARCH_ACCESS_LOGS = 'access-log/SEARCH_ACCESS_LOGS' as const
export const SET_ACCESS_LOG_DEFAULT = 'access-log/SET_DEFAULT' as const
export const SET_ACCESS_LOG_LOADING = 'access-log/SET_ACCESS_LOG_LOADING' as const
export const SET_ACCESS_LOG_SEARCH_RESULT = 'access-log/SET_ACCESS_LOG_SEARCH_RESULT' as const
export const SET_ACCESS_LOG_SEARCH = 'access-log/SET_ACCESS_LOG_SEARCH' as const
export const SET_ACCESS_LOG_PAGINATION = 'access-log/SET_ACCESS_LOG_PAGINATION' as const
export const SET_ACCESS_LOG_SUMMARY = 'access-log/SET_ACCESS_LOG_SUMMARY' as const
export const SET_ACCESS_LOG_PAGE_CONFIG = 'access-log/SET_ACCESS_LOG_PAGE_CONFIG' as const

// Action
export const searchAccessLogs = (hqId: number, branchId: number) => ({
  type: SEARCH_ACCESS_LOGS,
  payload: { hqId, branchId },
})
export const setAccessLogDefault = (hqId: number, branchId: number) => ({
  type: SET_ACCESS_LOG_DEFAULT,
  payload: { hqId, branchId },
})
export const setAccessLogLoading = (loading: boolean) => ({
  type: SET_ACCESS_LOG_LOADING,
  payload: { loading },
})
export const setAccessLogSearchResult = (result: Partial<AccessLogState['searchResult']>) => ({
  type: SET_ACCESS_LOG_SEARCH_RESULT,
  payload: { result },
})
export const setAccessLogSearch = (search: Partial<AccessLogSearch>) => ({
  type: SET_ACCESS_LOG_SEARCH,
  payload: { search },
})
export const setAccessLogPagination = (pagination: Partial<Pagination>) => ({
  type: SET_ACCESS_LOG_PAGINATION,
  payload: { pagination },
})

export type AccessLogAction =
  | ReturnType<typeof searchAccessLogs>
  | ReturnType<typeof setAccessLogDefault>
  | ReturnType<typeof setAccessLogLoading>
  | ReturnType<typeof setAccessLogSearchResult>
  | ReturnType<typeof setAccessLogSearch>
  | ReturnType<typeof setAccessLogPagination>

type AccessLogState = {
  loading: boolean
  searchResult: { branchId: number; total: number; accessLogs: AccessLogs[] }
  search: AccessLogSearch
  pagination: Pagination
}

const accessLogState: AccessLogState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, accessLogs: [] },
  search: { query: '', datetime: getDefaultRange('week', 'datetime') },
  pagination: { current: 1, pageSize: 10 },
}

const accessLogReducer = (state = accessLogState, action: AccessLogAction): AccessLogState => {
  switch (action.type) {
    case SET_ACCESS_LOG_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_ACCESS_LOG_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_ACCESS_LOG_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_ACCESS_LOG_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    default:
      return state
  }
}

export default accessLogReducer
