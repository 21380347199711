import { LogoutOutlined, NotificationOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Badge, Button, Col, Row } from 'antd'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'

import { logout } from '@reducers/auth.reducer'

import { AdminGlobalContext } from '@components/contexts/AdminGlobal'

export default function LayoutHeader(): JSX.Element {
  const dispatch = useDispatch()

  const { isUnread, drawer, setDrawer } = useContext(AdminGlobalContext)

  const onToggleDrawer = () => setDrawer(!drawer)
  const onLogout = () => dispatch(logout())

  return (
    <Row align="middle" style={styles.row}>
      <Col flex={1} />

      <Button type="text" onClick={onToggleDrawer} size="large">
        <Badge count={isUnread ? ' ' : null} size="small">
          <NotificationOutlined />
        </Badge>
      </Button>
      <Button type="text" onClick={onLogout} size="large">
        <LogoutOutlined />
      </Button>
    </Row>
  )
}

const styles: Styles = {
  row: { height: '100%' },
}
