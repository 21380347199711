import { MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Table, TableColumnType, TablePaginationConfig, Tag } from 'antd'
import { Notices } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getHqNotices } from '@reducers/hq/notice.reducer'
import { RootState } from '@reducers/index'

import { DashboardNoticeAction } from '@pages/Hq/NoticePage'

import TableTotalRows from '@components/atoms/TableTotalRows'
import TableWrapper from '@components/atoms/TableWrapper'
import TypeTag from '@components/molecules/TypeTag'

type Props = {
  hqId: number
  onAction: (action: DashboardNoticeAction, noticeId: number) => void
}

export default function HqNoticeListTable({ hqId, onAction }: Props): JSX.Element {
  const dispatch = useDispatch()
  const { loading, notices } = useSelector((state: RootState) => state.hqNotice)
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })

  const getDatas = () => dispatch(getHqNotices(hqId, pagination))
  const onChange = ({ current = 1, pageSize = 10 }: TablePaginationConfig) =>
    setPagination({ ...pagination, current, pageSize })

  useEffect(() => {
    getDatas()
  }, [pagination])

  const columns: TableColumnType<Omit<Notices, 'content'>>[] = [
    {
      key: 'title',
      dataIndex: 'title',
      title: '제목',
      render: (title) => <div style={{ cursor: 'pointer' }}>{title}</div>,
      onCell: ({ noticeId }) => ({
        onClick: () => onAction('detail', noticeId),
      }),
    },
    {
      key: 'branchId',
      dataIndex: 'branchId',
      title: '타겟',
      width: '200px',
      render: (_, { hq, branch }) => {
        return (
          <>
            {hq && <Tag>{hq.name}</Tag>}
            {branch && <Tag>{branch.name}</Tag>}
          </>
        )
      },
    },
    {
      key: 'writeDate',
      dataIndex: 'writeDate',
      title: '작성일',
      width: '200px',
      render: (writeDate) => `${writeDate}`,
    },
    {
      key: 'openFlag',
      dataIndex: 'openFlag',
      title: '공개여부',
      width: '100px',
      align: 'center',
      render: (openFlag) => <TypeTag type={openFlag ? 'success' : 'fail'} label={openFlag ? '공개' : '비공개'} />,
    },
    {
      key: 'noticeId',
      dataIndex: 'noticeId',
      title: '',
      width: '80px',
      render: (noticeId) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="update" onClick={() => onAction('update', noticeId)}>
                수정
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => onAction('delete', noticeId)}>
                삭제
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]

  return (
    <TableWrapper>
      <Table
        columns={columns}
        rowKey={({ noticeId }) => `${noticeId}`}
        dataSource={notices}
        loading={loading}
        pagination={{
          ...pagination,
          position: ['bottomCenter'],
          showTotal: (total) => <TableTotalRows total={total} />,
        }}
        onChange={onChange}
      />
    </TableWrapper>
  )
}
