import { BREAKPOINTS } from '@const'
import styled from 'styled-components'

import ContractFeature from '@pages/Landing/HomePage/organisms/ContractFeature'
import FreeTrial from '@pages/Landing/HomePage/organisms/FreeTrial'

import BillFeature from './organisms/BillFeature'
import Intro from './organisms/Intro'
import KeyFeatures from './organisms/KeyFeatures'
import UserReviews from './organisms/UserReviews'

export default function HomePage(): JSX.Element {
  return (
    <Background>
      <Intro />
      <ContractFeature />
      <BillFeature />
      <KeyFeatures />
      <UserReviews />
      <FreeTrial />
    </Background>
  )
}

const Background = styled.div`
  width: 100%;
  background-image: url('/images/landing/home/background/gadjet-bg-home-1920.png');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @media ${BREAKPOINTS.sm} {
    background-image: url('/images/landing/home/background/gadjet-bg-home-414.png');
  }

  @media ${BREAKPOINTS.md} {
    background-image: url('/images/landing/home/background/gadjet-bg-home-768.png');
  }
`
