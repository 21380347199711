import { Button, Col, Form, Input, notification, Radio, Row, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Branches, BranchContractDocuments } from 'gadjet-v2-types/dist/model'
import { BranchBuildingContractType, ContractCreditPolicy } from 'gadjet-v2-types/dist/type'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import addressFinder from '@utils/addressFinder'
import mask from '@utils/mask'

import BranchAPI from '@apis/branch'

import HiddenItems from '@components/atoms/Form/HiddenItems'
import UploadButton from '@components/molecules/UploadButton'

type Props = {
  hqId: number
  branchId: number
  branch?: Branches
  onRefresh: () => void
  onChangeBuildingContractType: (buildingContractType: BranchBuildingContractType) => void
}

export default function BranchContractDocumentForm({
  hqId,
  branchId,
  branch,
  onRefresh,
  onChangeBuildingContractType,
}: Props): JSX.Element {
  const [form] = useForm<BranchContractDocuments>()
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    const values = await form.validateFields()
    setLoading(true)
    await BranchAPI.updateBranchContractDocument({ hqId, branchId }, { contractDocument: values })
    setLoading(false)
    notification.success({ message: '저장되었습니다.' })
    onRefresh()
  }

  const onUploadDone = (id: number, key: 'signatureImageId' | 'termsFileId' | 'policyFileId' | 'etcFileId') =>
    form.setFieldsValue({ [key]: id })

  const findAddress = () => {
    addressFinder.open(({ address, zonecode }) => {
      form.setFieldsValue({ address, zipcode: zonecode })
    })
  }

  const resetForm = () => {
    form.resetFields()
    if (branch?.contractDocument) form.setFieldsValue(branch?.contractDocument)
  }

  useEffect(() => {
    resetForm()
  }, [branch])

  const initialValues: Partial<BranchContractDocuments> = {
    zipcode: '',
    address: '',
    addressDetail: '',
    area: '',
    buildingContractType: 'none',
    creditPolicy: 'weekly',
    deposit: '',
    managementFee: '',
    rent: '',
    signatureImageId: 0,
    policyFileId: 0,
    termsFileId: 0,
    etcFileId: 0,
  }

  const buildingContractTypeOptions: { label: string; value: BranchBuildingContractType }[] = [
    { label: '없음', value: 'none' },
    { label: '임대', value: 'rent' },
    { label: '전대', value: 'sublet' },
  ]

  const creditPolicyOptions: { label: string; value: ContractCreditPolicy }[] = [
    { label: '주 단위', value: 'weekly' },
    { label: '월 단위', value: 'monthly' },
  ]

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <HiddenItems names={['signatureImageId', 'policyFileId', 'termsFileId', 'etcFileId']} />

      <Form.Item label="우편번호" name="zipcode">
        <Input onClick={findAddress} readOnly />
      </Form.Item>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label="주소" name="address">
            <Input onClick={findAddress} readOnly />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="상세주소" name="addressDetail">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label="건물 계약" name="buildingContractType">
            <RadioGroup
              options={buildingContractTypeOptions}
              optionType="button"
              onChange={(e) => onChangeBuildingContractType(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="총 면적" name="area" help="예) 265m² / 300평">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="건물 보증금" name="deposit" {...mask.krw}>
        <Input />
      </Form.Item>
      <Form.Item label="건물 임대료" name="rent" {...mask.krw}>
        <Input />
      </Form.Item>
      <Form.Item label="건물 관리비" name="managementFee" {...mask.krw}>
        <Input />
      </Form.Item>

      <Form.Item label="계약 크레딧 정책" name="creditPolicy">
        <Radio.Group options={creditPolicyOptions} optionType="button" />
      </Form.Item>

      <Form.Item shouldUpdate label="파일">
        {({ getFieldValue }) => {
          const signatureImage = getFieldValue('signatureImage')
          const termsFile = getFieldValue('termsFile')
          const policyFile = getFieldValue('policyFile')
          const etcFile = getFieldValue('etcFile')
          return (
            <Space direction="vertical" style={{ width: '100%' }}>
              <UploadButton.Image
                category="signature"
                file={signatureImage}
                label="서명"
                accept="image/*"
                onUploadDone={({ imageId }) => onUploadDone(imageId, 'signatureImageId')}
              />
              <UploadButton.File
                category="branch-terms"
                file={termsFile}
                label="이용약관"
                accept=".pdf"
                onUploadDone={({ fileId }) => onUploadDone(fileId, 'termsFileId')}
              />
              <UploadButton.File
                category="branch-policy"
                file={policyFile}
                label="개인정보처리방침"
                accept=".pdf"
                onUploadDone={({ fileId }) => onUploadDone(fileId, 'policyFileId')}
              />
              <UploadButton.File
                category="branch-etc"
                file={etcFile}
                label="기타"
                accept=".pdf"
                onUploadDone={({ fileId }) => onUploadDone(fileId, 'etcFileId')}
              />
            </Space>
          )
        }}
      </Form.Item>

      <Button type="primary" size="large" onClick={onSubmit} loading={loading}>
        저장하기
      </Button>
    </Form>
  )
}

const RadioGroup = styled(Radio.Group)`
  display: flex;

  .ant-radio-button-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`
