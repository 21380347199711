import { FormInstance, Row, Col } from 'antd'
import { Branches } from 'gadjet-v2-types/dist/model'
import { BranchBuildingContractType } from 'gadjet-v2-types/dist/type'
import { useState } from 'react'

import BranchContractDocumentForm from '@components/organisms/AddBranch/Forms/BranchContractDocumentForm'
import BranchRenteeForm from '@components/organisms/AddBranch/Forms/BranchRenteeForm'
import BranchSubletForm from '@components/organisms/AddBranch/Forms/BranchSubletForm'

type Props = {
  form: FormInstance<Branches>
}
export default function BranchContractDocumentTab({ form }: Props) {
  const [buildingContractType, SetBuildingContractType] = useState<BranchBuildingContractType>('none')

  return (
    <Row gutter={[16, 16]}>
      {/* <Col span={20}>
        <Alert type="info" showIcon message="건물 정보는 계약서 생성 시 이용됩니다." />
      </Col> */}
      <Col span={12}>
        <BranchContractDocumentForm form={form} onChangeBuildingContractType={SetBuildingContractType} />
      </Col>
      <Col span={12}>
        {buildingContractType === 'rent' && <BranchRenteeForm form={form} />}
        {buildingContractType === 'sublet' && <BranchSubletForm form={form} />}
      </Col>
    </Row>
  )
}
