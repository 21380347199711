/* eslint-disable react/jsx-props-no-spreading */
import { Styles } from '@types'
import { Button } from 'antd'
import { ButtonProps, ButtonSize, ButtonType } from 'antd/lib/button'

export type ActionButton = {
  key: string
  label: string | JSX.Element
  onClick: () => void
  type?: ButtonType
  buttonProps?: ButtonProps
}

type Props = {
  actions: ActionButton[]
  size?: ButtonSize
}

export default function ActionButtons({ actions }: Props): JSX.Element {
  return (
    <div style={styles.wrapper}>
      {actions.map(({ key, label, onClick, type, buttonProps }) => (
        <Button key={key} onClick={onClick} type={type} {...buttonProps}>
          {label}
        </Button>
      ))}
    </div>
  )
}

const styles: Styles = {
  wrapper: { padding: '16px 0', display: 'flex', gap: '8px' },
}
