import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import ServiceAPI from '@apis/branch/service'

import {
  ServiceAction,
  setServiceLoading,
  setServiceSearchResult,
  SEARCH_SERVICES,
  SET_SERVICE_DEFAULT,
  SET_SERVICE_PAGINATION,
  SET_SERVICE_SEARCH,
  SET_SERVICE_PAGE_CONFIG,
  setServicePagination,
  searchServices,
} from '@reducers/branch/service.reducer'
import { RootState } from '@reducers/index'
import { setStorageService } from '@reducers/storage.reducer'

function* searchServiceSaga(action: ServiceAction): Generator {
  if (action.type !== SEARCH_SERVICES) return
  const { hqId, branchId } = action.payload
  yield* put(setServiceLoading(true))

  const search = yield* select((state: RootState) => state.service.search)
  const pagination = yield* select((state: RootState) => state.service.pagination)

  const {
    data: { services, total },
  } = yield* call(ServiceAPI.getServices, { hqId, branchId }, { ...search, ...pagination })

  yield* put(setServiceSearchResult({ branchId, services, total }))

  yield* put(setServiceLoading(false))
}

function* savePageConfigSaga(action: ServiceAction): Generator {
  const state: Parameters<typeof setStorageService>[0] = {}

  if (action.type === SET_SERVICE_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageService(state))
}

function* setDefaultPageConfigSaga(action: ServiceAction): Generator {
  if (action.type !== SET_SERVICE_DEFAULT) return
  const { pageSize } = yield* select((state: RootState) => state.storage.serviceConfig)
  yield* all([put(setServicePagination({ pageSize }))])

  const { hqId, branchId } = action.payload
  yield* put(searchServices(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_SERVICES, searchServiceSaga),
    takeEvery(SET_SERVICE_SEARCH, savePageConfigSaga),
    takeEvery(SET_SERVICE_PAGINATION, savePageConfigSaga),
    takeEvery(SET_SERVICE_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_SERVICE_DEFAULT, setDefaultPageConfigSaga),
  ])
}
