import { Styles } from '@types'
import { Input, Select, Tag } from 'antd'
import { ContractStatus } from 'gadjet-v2-types/dist/type'
import { contractStatus } from 'gadjet-v2-types/dist/type/label'
import { TenantSearch } from 'gadjet-v2-types/dist/type/search'
import { useMemo } from 'react'

import SearchBox from '@components/molecules/SearchBox'
import SearchItem from '@components/molecules/SearchItem'

type Props = {
  search: TenantSearch
  onChangeSearch: (search: Partial<TenantSearch>) => void
  onSearch: () => void
}

export default function BranchTenantSearchBox({ search, onChangeSearch, onSearch }: Props): JSX.Element {
  const onChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => onChangeSearch({ query: e.target.value })

  const onChangeStatus = (_status: ContractStatus[]) => onChangeSearch({ contractStatus: _status })

  const contractStatusOptions = useMemo(
    (): { key: ContractStatus; label: string }[] => [
      { key: 'before-started', label: contractStatus['before-started'] },
      { key: 'started', label: contractStatus.started },
      { key: 'extended', label: contractStatus.extended },
      { key: 'expired', label: contractStatus.expired },
      { key: 'suspended', label: contractStatus.suspended },
    ],
    []
  )

  return (
    <SearchBox onSearch={onSearch}>
      <SearchItem label="검색어" span={8}>
        <Input size="large" placeholder="검색어를 입력하세요." value={search.query} onChange={onChangeQuery} />
      </SearchItem>
      <SearchItem label="계약상태" span={6}>
        <Select
          mode="multiple"
          onChange={onChangeStatus}
          size="large"
          value={search.contractStatus}
          style={styles.select}
          tagRender={({ label }) => <Tag>{label}</Tag>}
        >
          {contractStatusOptions.map((cs) => (
            <Select.Option key={cs.key} value={cs.key}>
              {cs.label}
            </Select.Option>
          ))}
        </Select>
      </SearchItem>
    </SearchBox>
  )
}

const styles: Styles = {
  select: { width: '100%' },
}
