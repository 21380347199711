import { Services } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { ServiceSearch } from 'gadjet-v2-types/dist/type/search'

// ActionType
export const SEARCH_SERVICES = 'service/SEARCH_SERVICES' as const
export const SET_SERVICE_DEFAULT = 'service/SET_DEFAULT' as const
export const SET_SERVICE_LOADING = 'service/SET_SERVICE_LOADING' as const
export const SET_SERVICE_SEARCH_RESULT = 'service/SET_SERVICE_SEARCH_RESULT' as const
export const SET_SERVICE_SEARCH = 'service/SET_SERVICE_SEARCH' as const
export const SET_SERVICE_PAGINATION = 'service/SET_SERVICE_PAGINATION' as const
export const SET_SERVICE_SUMMARY = 'service/SET_SERVICE_SUMMARY' as const
export const SET_SERVICE_PAGE_CONFIG = 'service/SET_SERVICE_PAGE_CONFIG' as const

// Action
export const searchServices = (hqId: number, branchId: number) => ({
  type: SEARCH_SERVICES,
  payload: { hqId, branchId },
})
export const setServiceDefault = (hqId: number, branchId: number) => ({
  type: SET_SERVICE_DEFAULT,
  payload: { hqId, branchId },
})
export const setServiceLoading = (loading: boolean) => ({
  type: SET_SERVICE_LOADING,
  payload: { loading },
})
export const setServiceSearchResult = (result: Partial<ServiceState['searchResult']>) => ({
  type: SET_SERVICE_SEARCH_RESULT,
  payload: { result },
})
export const setServiceSearch = (search: Partial<ServiceSearch>) => ({
  type: SET_SERVICE_SEARCH,
  payload: { search },
})
export const setServicePagination = (pagination: Partial<Pagination>) => ({
  type: SET_SERVICE_PAGINATION,
  payload: { pagination },
})

export type ServiceAction =
  | ReturnType<typeof searchServices>
  | ReturnType<typeof setServiceDefault>
  | ReturnType<typeof setServiceLoading>
  | ReturnType<typeof setServiceSearchResult>
  | ReturnType<typeof setServiceSearch>
  | ReturnType<typeof setServicePagination>

type ServiceState = {
  loading: boolean
  searchResult: { branchId: number; total: number; services: Services[] }
  search: ServiceSearch
  pagination: Pagination
}

const serviceState: ServiceState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, services: [] },
  search: { query: '' },
  pagination: { current: 1, pageSize: 10 },
}

const serviceReducer = (state = serviceState, action: ServiceAction): ServiceState => {
  switch (action.type) {
    case SET_SERVICE_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_SERVICE_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_SERVICE_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_SERVICE_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    default:
      return state
  }
}

export default serviceReducer
