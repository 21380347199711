import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { initialize } from '@utils/firebase'

import { getOption } from '@reducers/option.reducer'

export default function AsyncLoader(): JSX.Element {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOption())
    initialize()
  }, [])

  return <div />
}
