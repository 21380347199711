import { notification } from 'antd'

import ContractAPI from '@apis/branch/contract'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  contractId: number
  onClose?: () => void
  onDone?: () => void
}

export default function ContractDeleteModal({ hqId, branchId, contractId, onClose, onDone }: Props): JSX.Element {
  const onConfirm = async () => {
    if (!contractId) return
    await ContractAPI.deleteContract({ hqId, branchId, contractId })
    notification.success({ message: '계약이 삭제되었습니다.' })
  }

  return (
    <ConfirmModal
      title="계약 삭제"
      description="계약을 삭제하면 복구할 수 없습니다."
      okText="삭제"
      visible
      onConfirm={onConfirm}
      onClose={onClose}
      onDone={onDone}
    />
  )
}
