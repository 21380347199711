import { BREAKPOINTS, FREE_TRIAL_URL } from '@const'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import ReviewCard from '../../atoms/ReviewCard'
import SectionTitle from '../../atoms/SectionTitle'

export default function UserReviews(): JSX.Element {
  const userReviews: {
    key: string
    content: ReactNode
    reviewer?: ReactNode
    images: { src: string; alt: string }
    highlight: { color: string; width: string; top: string; left: string }
  }[] = [
    {
      key: 'microwave',
      content: (
        <span>
          시간과 장소에 구애받지 않고
          <br />
          비대면으로 전자계약을 진행할
          <br />수 있어 고객분들도 만족스러
          <br />
          워하세요.
        </span>
      ),
      reviewer: (
        <span>
          마이크로웨이브,
          <br />
          이*린
        </span>
      ),
      images: { src: '/images/landing/home/reviews/microwave.png', alt: 'review-microwave' },
      highlight: { color: '#CEC8FF', width: '165px', top: '47px', left: '-3px' },
    },
    {
      key: 'workspace',
      content: (
        <span>
          앱으로 간편하게 회의실 예약
          <br />을 할 수 있어 편리하다는 입주
          <br />
          사분들의 피드백이 많아요.
        </span>
      ),
      reviewer: (
        <span>
          워크스페이스,
          <br />
          차*무
        </span>
      ),
      images: { src: '/images/landing/home/reviews/workspace.png', alt: 'review-workspace' },
      highlight: { color: '#B1E9C8', width: '131px', top: '15px', left: '-2px' },
    },
    {
      key: 'garage',
      content: (
        <span>
          각 지점이 어떻게 돌아가는지
          <br />
          현황을 대시보드에서 한눈에
          <br />
          보고 관리할 수 있다는 점이 무<br />척 편합니다.
        </span>
      ),
      reviewer: (
        <span>
          가라지,
          <br />
          정*수
        </span>
      ),
      images: { src: '/images/landing/home/reviews/garage.png', alt: 'review-garage' },
      highlight: { color: '#CEC8FF', width: '163px', top: '47px', left: '-1px' },
    },
    {
      key: 'workandco',
      content: (
        <span>
          매달 청구서와 세금계산서 발<br />
          행에 일주일씩 소요되던 시간
          <br />이 가젯을 사용하고 하루로 줄<br />
          었어요.
        </span>
      ),
      reviewer: (
        <span>
          웍앤코,
          <br />
          윤*찬
        </span>
      ),
      images: { src: '/images/landing/home/reviews/workandco.png', alt: 'review-workandco' },
      highlight: { color: '#B1E9C8', width: '164px', top: '14px', left: '36px' },
    },
    {
      key: 'workbox',
      content: (
        <span>
          별도의 프로그램 설치 없이 언<br />제 어디서든 인터넷만 있으면
          <br /> 이용할 수 있는 가벼운 웹 기반
          <br />
          서비스라 좋아요.
        </span>
      ),
      reviewer: (
        <span>
          워크박스,
          <br />
          유*주
        </span>
      ),
      images: { src: '/images/landing/home/reviews/workbox.png', alt: 'review-workbox' },
      highlight: { color: '#CEC8FF', width: '114px', top: '79px', left: '116px' },
    },
  ]

  const gadjetJoin: {
    content: ReactNode
    path: string
  } = {
    content: (
      <Text>
        가장 효율적인
        <br />
        공간 관리,
        <br />
        이제 여러분도
        <br />
        경험해 보세요
      </Text>
    ),
    path: FREE_TRIAL_URL,
  }

  return (
    <Wrapper>
      <SectionTitle
        text={
          <span>
            가젯과 먼저 함께하고 있는 분들의 <br />
            이야기를 들어보세요
          </span>
        }
      />

      <ReviewList>
        {userReviews.map(({ content, reviewer, images, key, highlight }) => (
          <ReviewCard
            key={key}
            content={content}
            reviewer={reviewer}
            image={{ src: images.src, alt: images.alt }}
            highlight={highlight}
          />
        ))}

        <GadjetCard>
          {gadjetJoin.content}
          <Link to={{ pathname: gadjetJoin.path }} target="_blank">
            <FreeTrialButton>
              <FreeTrialText>60일 무료로 경험하기</FreeTrialText>
              <img
                src="/images/landing/home/reviews/point-right.png"
                alt="finger-to-right-emoji"
                width={34}
                height={34}
              />
            </FreeTrialButton>
          </Link>
        </GadjetCard>
      </ReviewList>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 200px;
  @media ${BREAKPOINTS.lg} {
    padding-bottom: 100px;
  }
`

const ReviewList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 940px;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  @media ${BREAKPOINTS.lg} {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    max-width: none;
    overflow-x: scroll;
    padding: 100px;
    margin: -100px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media ${BREAKPOINTS.sm} {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    max-width: none;
    overflow-x: scroll;
    padding: 100px 36px;
    margin: -100px -36px;
  }

  @media ${BREAKPOINTS.xs} {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    max-width: none;
    overflow-x: scroll;
    padding: 100px 16px;
    margin: -100px -16px;
  }
`
const Text = styled.span`
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  color: #ffffff;
`
const GadjetCard = styled.li`
  width: 300px;
  min-width: 300px;
  height: 340px;
  background: #2e2e2e;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 32px 44px 32px;
  margin: 0;
  transition: all 0.15s ease-in-out;
`

const FreeTrialButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px 10px 18px;
  gap: 14px;
  background: rgba(255, 255, 255, 0.97);
  border-radius: 12px;
  word-break: keep-all;
  white-space: nowrap;
  font-weight: 600;
  font-size: 19px;
  line-height: 140%;
  letter-spacing: -0.02em;
  opacity: 0.9;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  border: none;
  :hover {
    opacity: 1;
  }
`
const FreeTrialText = styled.span`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(99.27deg, #4aab5f 32.66%, #a874fc 92.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`
