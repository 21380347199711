import { COLORS } from '@const'
import { Styles } from '@types'
import { Alert, Col, Divider, Form } from 'antd'
import { RentalReservations, Rentals } from 'gadjet-v2-types/dist/model'
import { UsedCreditAmount } from 'gadjet-v2-types/dist/type'
import { useMemo } from 'react'

import { krw } from '@utils/format'

import Loading from '@components/molecules/Loading'
import UseCreditInput from '@components/molecules/UseCreditInput'

type Props = {
  rental?: Rentals
  tenantCredit: UsedCreditAmount
  loading?: boolean
  getPrice: (rentalReservations: RentalReservations) => number
  hidden?: boolean
}

export default function RentalReservationUseCredit({
  rental,
  tenantCredit,
  loading,
  getPrice,
  hidden,
}: Props): JSX.Element {
  const available = useMemo(() => {
    const creditTypes = rental?.availableCreditType || []
    return {
      contract: creditTypes.some((ct) => ct === 'contract'),
      buy: creditTypes.some((ct) => ct === 'buy'),
      mileage: creditTypes.some((ct) => ct === 'mileage'),
    }
  }, [rental])

  return (
    <Form.Item<RentalReservations> shouldUpdate noStyle>
      {({ getFieldsValue }) => {
        const values = getFieldsValue()
        const { tenantId } = values
        if (!rental) return <></>

        const { buy = 0, contract = 0, mileage = 0 } = values.usedCreditAmount || {}
        const totalCreditAmount = buy + contract + mileage

        const price = getPrice(values)

        return (
          <Form.Item hidden={hidden || !tenantId}>
            <Loading loading={loading}>
              <>
                <div style={styles.wrapper}>
                  <UseCreditInput
                    creditType="contract"
                    currentCredit={tenantCredit.contract}
                    disabled={!available.contract}
                    label="계약"
                  />

                  <UseCreditInput
                    creditType="mileage"
                    currentCredit={tenantCredit.mileage}
                    disabled={!available.mileage}
                    label="마일리지"
                  />

                  <UseCreditInput
                    creditType="buy"
                    currentCredit={tenantCredit.buy}
                    disabled={!available.buy}
                    label="구매"
                  />

                  <Divider style={styles.divider} />
                  <div style={styles.priceWrapper}>
                    <span style={styles.price}>{krw(price, true)}</span>
                    <span>크레딧</span>
                  </div>
                </div>

                {totalCreditAmount !== price && (
                  <Col span={24}>
                    <Alert
                      banner
                      showIcon
                      type="error"
                      message={
                        totalCreditAmount !== price ? `${krw(price, true)}크레딧이 필요합니다.` : '크레딧이 부족합니다.'
                      }
                    />
                  </Col>
                )}
              </>
            </Loading>
          </Form.Item>
        )
      }}
    </Form.Item>
  )
}

const styles: Styles = {
  wrapper: {
    border: '1px solid #F2F3F4',
    borderRadius: '6px',
    padding: '20px',
    backgroundColor: '#ffffff',
    marginBottom: '10px',
  },
  divider: { margin: '12px 0px' },
  priceWrapper: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '4px' },
  price: { fontSize: '16px', fontWeight: '600', color: COLORS.primary },
}
