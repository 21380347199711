import { Styles } from '@types'
import { Button, Form, Image, Input, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Hqs, Images } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'

import HqAPI from '@apis/hq'

import HiddenItems from '@components/atoms/Form/HiddenItems'
import UploadButton from '@components/molecules/UploadButton'

type Props = { hqId: number; hq?: Hqs; onRefresh: () => void }

export default function HqForm({ hqId, hq, onRefresh }: Props): JSX.Element {
  const [form] = useForm<Hqs>()
  const [loading, setLoading] = useState(false)

  const [logo, setLogo] = useState('')

  const onSubmit = async () => {
    const values = await form.validateFields()
    setLoading(true)
    await HqAPI.updateHq({ hqId }, { hq: values })
    setLoading(false)
    notification.success({ message: '저장되었습니다.' })
    onRefresh()
  }

  const onUploadDone = (image: Images) => {
    form.setFieldsValue({ logoImageId: image.imageId })
    setLogo(image.uri)
  }

  const initialValues: Partial<Hqs> = {
    email: '',
    name: '',
  }

  useEffect(() => {
    if (!hq) return
    form.setFieldsValue(hq)
    if (hq.image) setLogo(hq.image.uri)
  }, [hq])

  return (
    <Form form={form} layout="vertical" initialValues={initialValues} style={styles.form}>
      <HiddenItems names={['logoImageId']} />

      <Form.Item label="로고">
        <Image src={logo} alt="logo" />
        <UploadButton.Image category="hq" label="로고" accept="image/*" hideDownload onUploadDone={onUploadDone} />
      </Form.Item>
      <Form.Item label="이름" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="이메일" name="email">
        <Input />
      </Form.Item>

      <Button loading={loading} onClick={onSubmit} type="primary" size="large">
        저장하기
      </Button>
    </Form>
  )
}

const styles: Styles = {
  form: {},
}
