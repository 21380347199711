/* eslint-disable react/no-array-index-key */
import { Styles } from '@types'
import { Alert, Space, Table, TableColumnType, Tag } from 'antd'
import { useMemo } from 'react'

import ModalAlert from '@components/molecules/ModalAlert'

import columns from '../columns'

// const optionGuide = () => ['하단의 값 중 하나 입력']

type Props = { visible: boolean; onClose: () => void }
export default function ContractUploadGuide({ visible, onClose }: Props): JSX.Element {
  const tableColumns: TableColumnType<typeof columns[number]>[] = useMemo(
    () => [
      {
        key: 'title',
        dataIndex: 'title',
        title: '항목',
        render: (title, { required }) => (
          <div style={{ width: '110px' }}>
            {title}
            {required && <span style={{ color: 'red' }}>*</span>}
          </div>
        ),
      },
      {
        key: 'guide',
        dataIndex: 'guide',
        title: '가이드',
        render: (guide: typeof columns[number]['guide']) => (
          <ul>
            {guide.map((str, index) => (
              <li key={index}>{str}</li>
            ))}
          </ul>
        ),
      },
      {
        key: 'options',
        dataIndex: 'options',
        title: '선택지',
        render: (options: typeof columns[number]['options'] = []) => (
          <Space style={{ width: '150px', flexWrap: 'wrap' }}>
            {options.map(({ label, value }) => (
              <Tag key={value}>{label}</Tag>
            ))}
          </Space>
        ),
      },
    ],
    []
  )

  return (
    <ModalAlert title="템플릿 가이드" visible={visible} onCancel={onClose} width="1000px">
      <div>
        <Alert
          showIcon
          type="info"
          message="선택지가 있는 항목은 선택지 중 하나를 입력해야 합니다."
          style={styles.alert}
        />
        <Table
          dataSource={columns}
          columns={tableColumns}
          pagination={{ pageSize: 500, hideOnSinglePage: true }}
          style={styles.table}
          size="small"
        />
      </div>
    </ModalAlert>
  )
}

const styles: Styles = {
  alert: { marginBottom: '10px' },
  table: { wordBreak: 'keep-all', whiteSpace: 'pre-wrap' },
}
