import { Row, Col, FormInstance, Form, Input, Button } from 'antd'
import { Branches } from 'gadjet-v2-types/dist/model'

import addressFinder from '@utils/addressFinder'
import formRule from '@utils/formRule'
import mask from '@utils/mask'

type Props = {
  form: FormInstance<Branches>
}

export default function BranchTab({ form }: Props) {
  const findAddress = () => {
    addressFinder.open(({ address, zonecode }) => {
      form.setFieldsValue({ address, zipcode: zonecode })
    })
  }

  return (
    <>
      <Form.Item label="지점이름" name="name" rules={[formRule.required]}>
        <Input />
      </Form.Item>
      <Form.Item label="지점 연락처" name="contact" {...mask.contact} rules={[formRule.required]}>
        <Input />
      </Form.Item>
      <Form.Item label="지점 이메일" name="email" rules={[formRule.required]}>
        <Input />
      </Form.Item>

      <Form.Item label="우편번호" name="zipcode" rules={[formRule.required]}>
        <Input onClick={findAddress} readOnly />
      </Form.Item>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label="주소" name="address" rules={[formRule.required]}>
            <Input onClick={findAddress} readOnly />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="상세주소" name="addressDetail">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="팝빌 아이디"
        extra="지점추가 후 팝빌 회원가입 후 현금영수증을 발행할 수 있습니다."
        name="popbillId"
      >
        <Button disabled>팝빌 회원가입</Button>
      </Form.Item>
    </>
  )
}
