import styled from 'styled-components'

import { krw } from '@utils/format'

import DiscountBadge from '../../atoms/DiscountBadge'

type Props = {
  promotion: Promotion
  isSelected?: boolean
  isYearPrepay: boolean
  onClick: () => void
}

export default function PlanCard({
  label,
  monthlyPrice,
  details,
  discountRate,
  isMostPopular,
  isSelected,
  isYearPrepay,
  promotion,
  onClick,
}: Props & GadjetPlan): JSX.Element {
  const yearlyPrice = monthlyPrice * 12
  const promotionMonthlyPrice = monthlyPrice * promotion.rate
  const promotionYearlyPrice = promotionMonthlyPrice * promotion.durationMonth + monthlyPrice * 10
  const promotionYearPrepayYearlyPrice = promotionMonthlyPrice * promotion.durationMonth + monthlyPrice * 10
  return (
    <li>
      <Container isSelected={isSelected} onClick={onClick}>
        {isMostPopular && <PopularBadge>Most Popular</PopularBadge>}
        <Label isSelected={isSelected}>
          {label}
          {!promotion.isVisible && <DiscountBadge discountRate={discountRate} />}
        </Label>
        <InnerWrapper>
          <PriceGroup>
            {promotion.isVisible && (
              <OriginalPromotionPrice>
                <DiscountBadge discountRate={discountRate} promotion={promotion} isVisibleDurationMonth />
                <span>
                  <Crossed>{krw(!isYearPrepay ? monthlyPrice : yearlyPrice, true)}</Crossed>
                  <DateUnit> /{!isYearPrepay ? '월' : '연'}</DateUnit>
                </span>
              </OriginalPromotionPrice>
            )}
            <DisplayPrice>
              <ChargedPrice isSelected={isSelected}>
                {promotion.isVisible &&
                  krw(!isYearPrepay ? promotionMonthlyPrice : promotionYearPrepayYearlyPrice, true)}
                {!promotion.isVisible && krw(!isYearPrepay ? monthlyPrice : yearlyPrice, true)}{' '}
                <DateUnit>/{!isYearPrepay ? '월' : '연'}</DateUnit>
              </ChargedPrice>
              <ComparedPrice isSelected={isSelected}>
                {promotion.isVisible && krw(!isYearPrepay ? promotionYearlyPrice : promotionMonthlyPrice, true)}
                {!promotion.isVisible && krw(!isYearPrepay ? yearlyPrice : monthlyPrice, true)}{' '}
                <DateUnit>/{!isYearPrepay ? '연' : '월'}</DateUnit>
              </ComparedPrice>
            </DisplayPrice>
          </PriceGroup>
          <Divider />
          <DetailList isSelected={isSelected}>
            {details.map(({ key, content }) => (
              <DetailItem key={key}>{content}</DetailItem>
            ))}
          </DetailList>
        </InnerWrapper>
      </Container>
    </li>
  )
}

type StyleProps = {
  isMostPopular?: boolean
  isSelected?: boolean
}

const Container = styled.button`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;

  width: 270px;
  height: max-content;
  position: relative;

  background: ${({ isSelected }: StyleProps) => (isSelected ? '#FBFFFB' : '#ffffff')};
  border-radius: 24px;

  border: ${({ isSelected }: StyleProps) => (isSelected ? '2px solid #22AF5D' : '2px solid #ffffff')};

  box-shadow: ${({ isSelected }: StyleProps) =>
    isSelected ? '0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14)' : 'none'};
  cursor: pointer;
  :hover {
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  }
`

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
`

const PopularBadge = styled.span`
  background: #22af5d;
  border-radius: 8px;
  padding: 8px 12px;
  position: absolute;
  top: -15px;

  height: 30px;

  font-family: 'Rajdhani';
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
`

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 28px;
  gap: 6px;

  width: max-content;
  height: 40px;

  background: #f2f3f4;
  border-radius: 12px;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ isSelected }: StyleProps) => (isSelected ? '#2e2e2e' : '#7a7b7c')};
`

const PriceGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const OriginalPromotionPrice = styled.div`
  display: flex;
  gap: 6px;
  font-family: 'Akshar';
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;

  color: #9a9c9c;
`
const Crossed = styled.span`
  font-family: 'Akshar';
  text-decoration: line-through;
`

const DisplayPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`
const ChargedPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;

  height: 32px;

  font-family: 'Akshar';
  font-weight: 600;
  font-size: 36px;
  line-height: 30px;
  color: ${({ isSelected }: StyleProps) => (isSelected ? '#22AF5D' : '#595a5b')};
`

const ComparedPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;

  height: 20px;

  font-family: 'Akshar';
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: ${({ isMostPopular }: StyleProps) => (isMostPopular ? '#2E2E2E' : '#595a5b')};
`

const DateUnit = styled.span`
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #e9eaeb;
`
const DetailList = styled.ul`
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: ${({ isSelected }: StyleProps) => (isSelected ? '#2e2e2e' : '#7a7b7c')};
  list-style: disc;
  margin: 0;
  padding: 0 0 0 24px;
  text-align: left;
`
const DetailItem = styled.li`
  white-space: pre-wrap;

  ::marker {
    font-size: 1rem;
  }
`
