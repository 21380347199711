import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { CashSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setCashPagination, setCashSearch, searchCash, setCashDefault } from '@reducers/branch/cash.reducer'
import { RootState } from '@reducers/index'

import BranchCashListTable from '@components/organisms/Branch/Cash/ListTable'
import CashDeleteModal from '@components/organisms/Branch/Cash/Modals/CashDeleteModal'
import CashExportModal from '@components/organisms/Branch/Cash/Modals/CashExportModal'
import CashFormModal from '@components/organisms/Branch/Cash/Modals/CashFormModal'
import BranchCashSearchBox from '@components/organisms/Branch/Cash/SearchBox'
import BranchCashSummary from '@components/organisms/Branch/Cash/Summary'
import BranchCashTitle from '@components/organisms/Branch/Cash/Title'

export type BranchCashAction = 'add' | 'update' | 'delete' | 'export'

export default function CashPage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const { loading, search, pagination, searchResult, summary } = useSelector((state: RootState) => state.cash)

  const [formModal, onVisibleFormModal, onCloseFormModal] = useModal<{ cashId?: number }>({
    visible: false,
    cashId: undefined,
  })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal<{ cashId?: number }>({
    visible: false,
    cashId: undefined,
  })
  const [exportModal, onVisibleExportModal, onCloseExportModal] = useModal({ visible: false })

  const onChangeSearch = (_search: Partial<CashSearch>) => dispatch(setCashSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setCashPagination(_pagination))
    dispatch(searchCash(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setCashPagination({ current: 1 }))
    dispatch(searchCash(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setCashDefault(hqId, branchId)), [hqId, branchId])
  const onDone = () => onResetAndSearch()

  const onAction = (action: BranchCashAction, cashId?: number) => {
    if (action === 'add') onVisibleFormModal({ cashId: undefined })
    if (action === 'update') onVisibleFormModal({ cashId })
    if (action === 'delete') onVisibleDeleteModal({ cashId })
    if (action === 'export') onVisibleExportModal()
  }

  useEffect(() => {
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchCashSearchBox search={search} onChangeSearch={onChangeSearch} onSearch={onSearch} />

      <BranchCashSummary summary={summary} />

      <BranchCashTitle onAction={onAction} />

      <BranchCashListTable
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        cash={searchResult.cash}
        total={searchResult.total}
        onAction={onAction}
      />

      <CashFormModal
        hqId={hqId}
        branchId={branchId}
        cashId={formModal.cashId}
        visible={formModal.visible}
        onClose={onCloseFormModal}
        onDone={onDone}
      />
      <CashExportModal
        hqId={hqId}
        branchId={branchId}
        visible={exportModal.visible}
        onClose={onCloseExportModal}
        onDone={onDone}
      />
      <CashDeleteModal
        hqId={hqId}
        branchId={branchId}
        cashId={deleteModal.cashId}
        visible={deleteModal.visible}
        onClose={onCloseDeleteModal}
        onDone={onDone}
      />
    </div>
  )
}
