import useModal from '@hooks/useModal'
import { Button, Row } from 'antd'
import { tenantType } from 'gadjet-v2-types/dist/type/label'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@reducers/index'

import ModalAlert from '@components/molecules/ModalAlert'
import ModalAlertItem from '@components/molecules/ModalAlert/Item'

export default function Tenant({ contract }: ContractColumnProps): JSX.Element {
  const { tenant } = contract
  const gics = useSelector((state: RootState) => state.option.gics)
  const [modal, onVisible, onClose] = useModal({ visible: false })

  const gicsLabel = useMemo(() => {
    const findGics = gics.find((g) => g.code === tenant.gicsCode)
    return findGics?.ko || '-'
  }, [gics, tenant])

  const isConnect = useMemo(() => contract.tenantId, [contract])

  return (
    <>
      <Button onClick={() => onVisible()} type={isConnect ? 'primary' : 'dashed'}>
        {tenant.name}
      </Button>
      <ModalAlert title="입주사" visible={modal.visible} onCancel={onClose}>
        <Row gutter={[20, 40]}>
          <ModalAlertItem label="이름" value={tenant.name} span={8} />
          <ModalAlertItem label="연락처" value={tenant.contact} span={8} />
          <ModalAlertItem label="이메일" value={tenant.email} span={8} />
          <ModalAlertItem label="사업자번호" value={tenant.businessNumber} span={8} />
          <ModalAlertItem label="법인번호" value={tenant.corporationNumber} span={8} />
          <ModalAlertItem label="외국인번호" value={tenant.foreignerNumber} span={8} />
          <ModalAlertItem label="타입" value={tenantType[tenant.type]} span={8} />
          <ModalAlertItem label="산업분류" value={gicsLabel} span={8} />
        </Row>
      </ModalAlert>
    </>
  )
}
