import { Styles } from '@types'
import { Col, Row } from 'antd'
import { SpaceTypes } from 'gadjet-v2-types/dist/model'

import SpaceItem from './SpaceItem'

type Props = {
  spaceType: SpaceTypes
}

export default function SpaceTypeItem({ spaceType }: Props): JSX.Element {
  const { name } = spaceType

  const spaces = spaceType.spaces || []
  return (
    <>
      <div style={styles.name}>{name}</div>
      <Row gutter={[20, 20]} style={styles.row}>
        {spaces.map((s) => (
          <Col span={8} key={s.spaceId}>
            <SpaceItem space={s} />
          </Col>
        ))}
      </Row>
    </>
  )
}
const styles: Styles = {
  name: { fontSize: '20px', fontWeight: 'bold' },
  row: { padding: '10px' },
}
