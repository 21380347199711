import { Input } from 'antd'
import { BranchRoleSearch } from 'gadjet-v2-types/dist/type/search'

import SearchBox from '@components/molecules/SearchBox'
import SearchItem from '@components/molecules/SearchItem'

type Props = {
  search: BranchRoleSearch
  onChangeSearch: (search: Partial<BranchRoleSearch>) => void
  onSearch: () => void
}

export default function BranchRoleSearchBox({ search, onChangeSearch, onSearch }: Props): JSX.Element {
  return (
    <SearchBox onSearch={onSearch}>
      <SearchItem label="매니저" span={8}>
        <Input />
      </SearchItem>
    </SearchBox>
  )
}
