import { COLORS } from '@const'
import { Styles } from '@types'
import { Col, Form, Input, Row, Switch, Button, Dropdown, Drawer, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { Images, Rentals } from 'gadjet-v2-types/dist/model'
import React, { useEffect, useMemo, useState } from 'react'
import { ColorResult } from 'react-color'
import { useSelector } from 'react-redux'

import formRule from '@utils/formRule'

import RentalAPI from '@apis/branch/rental'

import { RootState } from '@reducers/index'

import ColorPicker from '@components/atoms/ColorPicker'
import HiddenItems from '@components/atoms/Form/HiddenItems'
import LocalDatePicker from '@components/atoms/LocalDatePicker'
import ImageUploader from '@components/molecules/ImageUploader'
import Loading from '@components/molecules/Loading'
import MultiSelectButtons from '@components/molecules/MultiSelectButtons'

type Props = {
  hqId: number
  branchId: number
  rentalId?: number

  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function RentalFormModal({ hqId, branchId, rentalId, visible, onClose, onDone }: Props): JSX.Element {
  const [form] = useForm<Rentals>()
  const option = useSelector((state: RootState) => state.option)
  const [loading, setLoading] = useState(false)
  const [imageUri, setImageUri] = useState('')
  const [color, setColor] = useState<string>('#3ebd73')

  const initialValues = useMemo(
    (): Partial<Rentals> => ({
      name: '',
      description: '',
      price: 5000,
      availableCreditType: ['buy', 'contract', 'mileage'],
      startTime: '08:00',
      endTime: '17:30',
      colorInfo: '#3EBD73',
    }),
    []
  )

  const onUploadDone = ({ imageId, uri }: Images) => {
    form.setFieldsValue({ imageId })
    setImageUri(uri)
  }

  const onChangeComplete = ({ hex }: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({ colorInfo: hex })
    setColor(hex)
  }

  const onOk = async () => {
    try {
      const values = await form.validateFields()

      setLoading(true)

      if (rentalId) await RentalAPI.updateRental({ hqId, branchId, rentalId }, { rental: values })
      else await RentalAPI.addRental({ hqId, branchId }, { rental: values })

      setLoading(false)
      onClose()
      onDone()
    } catch (err) {
      setLoading(false)
    }
  }

  const onChangeAllTime = () => form.setFieldsValue({ startTime: '00:00', endTime: '23:30' })

  const getRental = async () => {
    if (!rentalId) return
    setLoading(true)
    const { data } = await RentalAPI.getRentalDetail({ hqId, branchId, rentalId })

    setImageUri(data.image?.uri || '')
    setColor(data.colorInfo || '#3ebd73')

    form.setFieldsValue(data)
    setLoading(false)
  }

  const reset = () => {
    form.resetFields()
    setImageUri('')
  }

  useEffect(() => {
    if (visible) {
      reset()
      if (rentalId) getRental()
    }
  }, [visible, rentalId])

  return (
    <Drawer
      title="대관/대여 관리"
      placement="right"
      onClose={onClose}
      visible={visible}
      maskStyle={styles.maskStyle}
      footerStyle={styles.footerStyle}
      maskClosable={false}
      width="480px"
      footer={
        <Space>
          <Button onClick={onClose}>취소</Button>
          <Button type="primary" onClick={onOk}>
            저장하기
          </Button>
        </Space>
      }
    >
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <HiddenItems names={['imageId', 'startTime', 'endTime', 'colorInfo']} />

          <Form.Item label="이름" name="name" required rules={[formRule.required]}>
            <Input
              placeholder="이름을 입력하세요."
              suffix={
                <Dropdown
                  placement="bottomRight"
                  overlay={<ColorPicker onChangeComplete={onChangeComplete} color={color} />}
                >
                  <Button shape="circle" size="small" type="ghost" style={{ backgroundColor: color }}>
                    <div />
                  </Button>
                </Dropdown>
              }
            />
          </Form.Item>

          <Row gutter={20}>
            <Col>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue, setFieldsValue }) => {
                  const start = getFieldValue('startTime')
                  const end = getFieldValue('endTime')

                  const realEndTime = dayjs(end, 'HH:mm').add(30, 'minute').format('HH:mm')
                  const extraLabel = `${start}~${realEndTime === '00:00' ? '24:00' : realEndTime}`

                  const isAllTime = start === '00:00' && end === '23:30'

                  return (
                    <Form.Item
                      label="예약가능시간"
                      required
                      extra={<div style={styles.timeExtra}>실제이용시간: {extraLabel}</div>}
                    >
                      <LocalDatePicker.RangePicker
                        format="HH:mm"
                        picker="time"
                        minuteStep={30}
                        allowClear={false}
                        inputReadOnly
                        value={[dayjs(start, 'HH:mm'), dayjs(end, 'HH:mm')]}
                        onChange={(_, [_start, _end]) => setFieldsValue({ startTime: _start, endTime: _end })}
                      />
                      <Button
                        style={{ marginLeft: -1 }}
                        onClick={onChangeAllTime}
                        type={isAllTime ? 'primary' : 'default'}
                      >
                        24시간
                      </Button>
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="주말이용" name="weekendFlag" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="크레딧/30분" name="price" required rules={[formRule.required, formRule.positiveNumber]}>
            <Input suffix="크레딧" min={0} style={styles.inputNumber} type="number" />
          </Form.Item>
          <Col>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue, setFieldsValue }) => {
                const selected: string[] = getFieldValue('availableCreditType')
                const isAll = selected.length === option.creditTypes.length

                const onClickSelect = (isActive: boolean, _select: string) => {
                  if (isActive) {
                    setFieldsValue({
                      availableCreditType: selected.filter((selectedType: string) => selectedType !== _select),
                    })
                  } else {
                    setFieldsValue({ availableCreditType: [...selected, _select] })
                    if (isAll) {
                      setFieldsValue({ availableCreditType: [_select] })
                    }
                  }
                }

                const onClickSelectAll = () => {
                  if (!isAll) {
                    setFieldsValue({ availableCreditType: option.creditTypes })
                  } else {
                    setFieldsValue({ availableCreditType: [] })
                  }
                }

                return (
                  <Form.Item label="결제 크레딧" name="availableCreditType" required rules={[formRule.required]}>
                    <MultiSelectButtons
                      isAll={isAll}
                      onClickSelectAll={onClickSelectAll}
                      onClickSelect={onClickSelect}
                      options={option.creditTypes}
                      selected={selected}
                    />
                  </Form.Item>
                )
              }}
            </Form.Item>
          </Col>

          <Form.Item label="설명" name="description">
            <Input.TextArea style={styles.textArea} />
          </Form.Item>
          <Form.Item label="사진">
            <ImageUploader
              accept="image/*"
              category="rental"
              onUploadDone={onUploadDone}
              imageUri={imageUri}
              alt="썸네일 이미지"
            />
          </Form.Item>
          <Form.Item label="순서" name="order" required>
            <Input type="number" min="1" defaultValue={1} />
          </Form.Item>
        </Form>
      </Loading>
    </Drawer>
  )
}

const styles: Styles = {
  image: { width: '200px', height: '200px', marginBottom: '10px' },
  textArea: { height: '100px' },
  timeExtra: { color: COLORS.primary, opacity: 0.6, fontSize: 12 },
  inputNumber: { width: '100%' },
  maskStyle: { backgroundColor: '#00000033' },
  footerStyle: { marginBottom: '40px' },
}
