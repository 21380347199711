import { Styles } from '@types'
import { Alert, Button, Col, Form, Input, notification, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { BranchBusinesses, Branches } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'

import mask from '@utils/mask'

import BranchAPI from '@apis/branch'

const editable = false

type Props = { hqId: number; branchId: number; branch?: Branches; onRefresh: () => void }

export default function BranchBusinessForm({ hqId, branchId, branch, onRefresh }: Props): JSX.Element {
  const [form] = useForm<BranchBusinesses>()
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    const values = await form.validateFields()
    setLoading(true)
    await BranchAPI.updateBranchBusiness({ hqId, branchId }, { business: values })
    setLoading(false)
    notification.success({ message: '저장되었습니다.' })
    onRefresh()
  }

  const getManagers = (managers: BranchBusinesses['managers']): BranchBusinesses['managers'] => {
    const getManager = () => ({ name: '', department: '', tel: '', contact: '', email: '' })
    if (!managers) return [getManager(), getManager()]
    return [managers[0] || getManager(), managers[1] || getManager()]
  }

  const resetForm = () => {
    form.resetFields()
    if (branch?.business) {
      const { managers } = branch.business
      form.setFieldsValue({ ...branch.business, managers: getManagers(managers) })
    }
  }

  useEffect(() => {
    resetForm()
  }, [branch])

  const initialValues: Partial<BranchBusinesses> = {
    director: '',
    name: '',
    address: '',
    identityNumber: '',
    businessNumber: '',
    corporationNumber: '',
    businessType: '',
    businessClass: '',
    managers: [
      { name: '', department: '', tel: '', contact: '', email: '' },
      { name: '', department: '', tel: '', contact: '', email: '' },
    ],
  }

  return (
    <Form form={form} layout="vertical" initialValues={initialValues} style={styles.form}>
      <Form.Item>
        <Alert type="info" showIcon message="담당자 외 사업자 정보가 변경되었을 경우 support@gadjet.io로 알려주세요." />
      </Form.Item>
      <Form.Item label="사업자 이름" name="name">
        <Input disabled={editable} />
      </Form.Item>
      <Form.Item label="대표자" name="director">
        <Input disabled={editable} />
      </Form.Item>
      <Form.Item label="주소" name="address">
        <Input disabled={editable} />
      </Form.Item>
      <Row gutter={10}>
        <Col span={18}>
          <Form.Item label="사업자번호" name="businessNumber" {...mask.businessLicense}>
            <Input disabled={editable} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="종 사업장" name="identityNumber">
            <Input placeholder="0000" disabled={editable} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="법인번호" name="corporationNumber" {...mask.personalLicense}>
        <Input disabled={editable} />
      </Form.Item>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label="업태" name="businessType">
            <Input disabled={editable} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="업종" name="businessClass">
            <Input disabled={editable} />
          </Form.Item>
        </Col>
      </Row>

      <Form.List name="managers">
        {(fields) =>
          fields.map((field) => (
            <Row key={field.key} gutter={10}>
              <Col span={12}>
                <Form.Item isListField name={[field.name, 'name']} label="담당자 이름">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item isListField name={[field.name, 'email']} label="담당자 이메일">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          ))
        }
      </Form.List>

      <Button type="primary" size="large" onClick={onSubmit} loading={loading}>
        저장하기
      </Button>
    </Form>
  )
}

const styles: Styles = {
  form: {
    maxWidth: '600px',
  },
}
