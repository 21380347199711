import { ReactComponent as MessageLinesIcon } from '@assets/Icons/message-lines.svg'
import { Styles } from '@types'
import { RentalReservations } from 'gadjet-v2-types/dist/model'
import styled from 'styled-components'

import DotBadge from '@components/atoms/DotBadge'

type Props = {
  onClickEvent: (reservation: RentalReservations) => void
  reservation: RentalReservations
}

export default function AgendaEventItem({ reservation, onClickEvent }: Props): JSX.Element {
  return (
    <AgendaEventWrapper onClick={() => onClickEvent(reservation)}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'flex-start',
        }}
      >
        <div style={styles.time}>
          <DotBadge color={reservation.rental?.colorInfo} marginRight="10px" />

          <div>
            {reservation.startTime} - {reservation.endTime}
          </div>
        </div>

        <div style={styles.tanantName}>{reservation.tenant?.name || '관리자 예약'}</div>
      </div>

      {reservation.memo && (
        <div style={styles.memo}>
          <MessageLinesIcon fill="#7A7B7C" /> {reservation.memo}
        </div>
      )}
    </AgendaEventWrapper>
  )
}

const AgendaEventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px;
  &:hover {
    background-color: #f4f5f7;
    border-radius: 6px;
    cursor: pointer;
  }
`

const styles: Styles = {
  time: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: '15px',
    paddingLeft: '6px',
    whiteSpace: 'nowrap',
  },
  tanantName: { wordBreak: 'break-all' },
  memo: { color: '#7A7B7C', paddingLeft: '18px', wordBreak: 'break-all' },
}
