import { Button } from 'antd'
import { ReactNode } from 'react'
import styled from 'styled-components'

import Loading from '@components/molecules/Loading'

type Props = {
  title: string
  footer?: ReactNode
  children: ReactNode
  loading?: boolean
  submit: {
    label: string
    onClick: () => void
    isSubmitDisabled: boolean
  }
}

export default function LoginFormTemplate({ title, footer, children, submit, loading }: Props): JSX.Element {
  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        <div>
          <Loading loading={loading}>
            <>
              {children}
              <LoginButton
                type="primary"
                size="large"
                block
                onClick={submit.onClick}
                loading={loading}
                disabled={submit.isSubmitDisabled}
              >
                {submit.label}
              </LoginButton>

              <FooterWrapper>{footer}</FooterWrapper>
            </>
          </Loading>
        </div>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  padding: 100px 0px;
  justify-content: center;
`
const Container = styled.div`
  width: 530px;
  height: max-content;
  padding: 60px 72px;
  box-shadow: 0px 8px 22px -6px rgba(13, 13, 13, 0.12), 0px 14px 64px -4px rgba(13, 13, 13, 0.12);
  border-radius: 24px;
  background-color: #ffffff;
  opacity: 0.97;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  color: #232530;
  padding-bottom: 50px;
`
const FooterWrapper = styled.div`
  max-width: 400px;
`
const LoginButton = styled(Button)`
  width: 100%;
  font-size: 15px;
  height: 52px;
  border-radius: 8px;
  background: #2e2e2e;
  border: none;
  :hover {
    background: #0d0d0d;
  }
  :focus {
    background: #0d0d0d;
  }
`
