import { ReactComponent as PlusIcon } from '@assets/Icons/plus.svg'
import { Col } from 'antd'
import { Images } from 'gadjet-v2-types/dist/model'
import { ImageCategory } from 'gadjet-v2-types/dist/type'
import { useRef, useState } from 'react'
import styled from 'styled-components'

import { imageUpload } from '@utils/upload'

import Loading from '../Loading'

type Props = {
  onUploadDone: (image: Images) => void
  category: ImageCategory
  imageUri: string
  accept: string
  alt: string
}

const ImageUploader = ({ accept, category, onUploadDone, imageUri, alt }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isHover, setIsHover] = useState<boolean>(false)

  const inputFile = useRef<HTMLInputElement>(null)

  const onChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const { files } = e.target
    if (!files || !files[0]) return
    setIsLoading(true)
    const result = await imageUpload(files[0], category)
    onUploadDone(result)
    setIsLoading(false)
  }

  return (
    <Col>
      <Wrapper
        onClick={() => inputFile.current?.click()}
        isHover={isHover}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {imageUri && <ThumbnailImage src={imageUri} alt={alt} />}
        {isLoading ? (
          <LoadingWrapper>
            <Loading size="small" />
          </LoadingWrapper>
        ) : (
          !imageUri && <PlusIcon width="24" height="24" stroke={isHover ? '#3ebd73' : '#c1c1c1'} />
        )}
      </Wrapper>
      <input accept={accept} value="" ref={inputFile} type="file" hidden onChange={onChange} />
    </Col>
  )
}

export default ImageUploader

const Wrapper = styled.div`
  width: 88px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ isHover }: { isHover: boolean }) => (isHover ? '1px solid #3ebd73' : '1px solid #e9eaeb')};
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
`

const ThumbnailImage = styled.img`
  width: 88px;
  height: 88px;
  object-fit: cover;
`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 88px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
`
