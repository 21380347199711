import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import ServiceAPI from '@apis/branch/service'

import {
  ServiceSaleAction,
  setServiceSaleLoading,
  setServiceSaleSearchResult,
  SEARCH_SERVICE_SALES,
  SET_SERVICE_SALE_DEFAULT,
  SET_SERVICE_SALE_PAGINATION,
  SET_SERVICE_SALE_SEARCH,
  SET_SERVICE_SALE_PAGE_CONFIG,
  setServiceSalePagination,
  searchServiceSales,
  setServiceSaleSearch,
  setServiceSaleService,
} from '@reducers/branch/service-sale.reducer'
import { RootState } from '@reducers/index'
import { setStorageServiceSale } from '@reducers/storage.reducer'

function* searchServiceSaleSaga(action: ServiceSaleAction): Generator {
  if (action.type !== SEARCH_SERVICE_SALES) return
  const { hqId, branchId } = action.payload
  yield* put(setServiceSaleLoading(true))

  const search = yield* select((state: RootState) => state.serviceSale.search)
  const pagination = yield* select((state: RootState) => state.serviceSale.pagination)

  const {
    data: { serviceSales, total },
  } = yield* call(ServiceAPI.getServiceSales, { hqId, branchId }, { ...search, ...pagination })

  yield* put(setServiceSaleSearchResult({ branchId, serviceSales, total }))

  yield* put(setServiceSaleLoading(false))
}

function* getServices(hqId: number, branchId: number): Generator {
  yield* put(setServiceSaleService({ loading: true }))

  const {
    data: { services },
  } = yield* call(ServiceAPI.getServices, { hqId, branchId }, { current: 1, pageSize: 500, query: '' })

  yield* put(setServiceSaleService({ list: services, loading: false }))
}

function* savePageConfigSaga(action: ServiceSaleAction): Generator {
  const state: Parameters<typeof setStorageServiceSale>[0] = {}

  if (action.type === SET_SERVICE_SALE_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageServiceSale(state))
}

function* setDefaultPageConfigSaga(action: ServiceSaleAction): Generator {
  if (action.type !== SET_SERVICE_SALE_DEFAULT) return
  const { hqId, branchId } = action.payload
  const { pageSize } = yield* select((state: RootState) => state.storage.serviceSaleConfig)
  yield* all([put(setServiceSaleSearch({})), put(setServiceSalePagination({ pageSize }))])
  yield* getServices(hqId, branchId)
  yield* put(searchServiceSales(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_SERVICE_SALES, searchServiceSaleSaga),
    takeEvery(SET_SERVICE_SALE_SEARCH, savePageConfigSaga),
    takeEvery(SET_SERVICE_SALE_PAGINATION, savePageConfigSaga),
    takeEvery(SET_SERVICE_SALE_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_SERVICE_SALE_DEFAULT, setDefaultPageConfigSaga),
  ])
}
