import { DownloadOutlined } from '@ant-design/icons'

import { BranchProductSaleAction } from '@pages/Branch/ProductSalePage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchProductSaleAction, productSaleId?: number) => void
}

export default function BranchProductSaleTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [
    { label: '다운로드', buttonType: 'dashed', icon: <DownloadOutlined />, onClick: () => onAction('export') },
  ]

  return <PageTitle title="상품 판매내역" actions={actions} />
}
