import { Form, FormInstance, Input } from 'antd'
import { Contracts } from 'gadjet-v2-types/dist/model'

import formRule from '@utils/formRule'
import mask from '@utils/mask'

type Props = { form: FormInstance<Contracts>; isUpdate: boolean }

export default function DirectorTab({ form, isUpdate }: Props): JSX.Element {
  return (
    <>
      <Form.Item label="대표자 이름" name={['director', 'name']} rules={[formRule.required]}>
        <Input />
      </Form.Item>
      <Form.Item label="대표자 연락처" name={['director', 'contact']} {...mask.contact}>
        <Input />
      </Form.Item>
      <Form.Item label="대표자 이메일" name={['director', 'email']} rules={[formRule.email]}>
        <Input />
      </Form.Item>
      <Form.Item label="대표자 주민등록번호" name={['director', 'residentNumber']} {...mask.personalLicense}>
        <Input />
      </Form.Item>
      <Form.Item label="대표자 법인등록번호" name={['director', 'corporationNumber']} {...mask.personalLicense}>
        <Input />
      </Form.Item>
    </>
  )
}
