import { RentalReservations } from 'gadjet-v2-types/dist/model'
import { RentalReservationSearch } from 'gadjet-v2-types/dist/type/search'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import AgendaEventByDate from '@components/molecules/AgendaEventByDate'
import NoReservations from '@components/molecules/NoItems/NoReservations'

import CalendarRentals from './CalendarRentals'

type Props = {
  rentalReservations: RentalReservations[]
  hqId: number
  branchId: number
  search: RentalReservationSearch
  onClickEvent?: (reservation: RentalReservations) => void
  onChangeSearch: (search: Partial<RentalReservationSearch>) => void
  onSearch: () => void
}

export default function CalendarAgenda({
  rentalReservations,
  hqId,
  branchId,
  search,
  onClickEvent = () => undefined,
  onChangeSearch,
  onSearch,
}: Props): JSX.Element {
  const [isEmptyReservations, setIsEmptyReservations] = useState(false)
  const agendaEventsComponent: JSX.Element[] = useMemo(() => {
    const agendaEvents = _(rentalReservations)
      .groupBy((x) => x.date)
      .map((value, key) => ({ date: key, reservations: value }))
      .value()
      .sort((a, b) => {
        if (a.date > b.date) {
          return 1
        }
        if (a.date < b.date) {
          return -1
        }

        return 0
      })

    setIsEmptyReservations(agendaEvents.length === 0)

    const lastEventItem = agendaEvents[agendaEvents.length - 1]

    return agendaEvents.map((agendaEvent) => (
      <AgendaEventByDate agendaEvent={agendaEvent} lastEventItem={lastEventItem} onClickEvent={onClickEvent} />
    ))
  }, [rentalReservations])

  return (
    <AgendaWrapper>
      <AgendaReservations>
        <div style={{ height: '100%', width: '100%', overflow: 'scroll' }}>
          {isEmptyReservations && <NoReservations />}
          {agendaEventsComponent}
        </div>
      </AgendaReservations>

      <CalendarRentals
        hqId={hqId}
        branchId={branchId}
        search={search}
        onChangeSearch={onChangeSearch}
        onSearch={onSearch}
      />
    </AgendaWrapper>
  )
}

const AgendaWrapper = styled.div`
  height: 80vh;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 2%;
`

const AgendaReservations = styled.div`
  background-color: white;
  border-radius: 16px;
  height: calc(98% - 120px);
  min-height: auto;
  padding: 20px 0px;
`
