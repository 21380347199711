import * as ROLE from 'gadjet-v2-types/dist/api/branch/role'

import axios from '@utils/axios'

const RoleAPI = {
  // 권한 조회
  getRoles: async (
    { hqId, branchId }: ROLE.GET_LIST_PARAMS,
    query: ROLE.GET_LIST_QUERY
  ): Promise<{ data: ROLE.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/roles`, { params: query }),

  // 권한 상세조회
  getRoleDetail: async ({ hqId, branchId, branchRoleId }: ROLE.GET_DETAIL_PARAMS) =>
    axios.get<ROLE.GET_DETAIL_RESPONSE>(`/hqs/${hqId}/branches/${branchId}/roles/${branchRoleId}`),

  // 권한 추가
  addRoles: async (
    { hqId, branchId }: ROLE.ADD_PARAMS,
    { role }: ROLE.ADD_BODY
  ): Promise<{ data: ROLE.ADD_RESPONSE }> => axios.post(`/hqs/${hqId}/branches/${branchId}/roles`, { role }),

  // 권한 수정
  updateRole: async (
    { hqId, branchId, branchRoleId }: ROLE.UPDATE_PARAMS,
    { role }: ROLE.UPDATE_BODY
  ): Promise<{ data: ROLE.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/roles/${branchRoleId}`, { role }),

  // 권한 삭제
  deleteRole: async ({ hqId, branchId, branchRoleId }: ROLE.DELETE_PARAMS): Promise<{ data: ROLE.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/roles/${branchRoleId}`),

  // 권한 초대
  inviteRole: async ({ hqId, branchId }: ROLE.INVITE_PARAMS, { email, branchRole }: ROLE.INVITE_BODY) =>
    axios.post<ROLE.INVITE_RESPONSE>(`/hqs/${hqId}/branches/${branchId}/roles/invite`, { email, branchRole }),
}

export default RoleAPI
