import { Pagination } from 'gadjet-v2-types/dist/type'
import { TenantSearch } from 'gadjet-v2-types/dist/type/search'

const branchQueryKey = {
  getBranchSpaceTypes: (branchId: number) => ['branch', branchId, 'space-types'],
  getBranchRentals: (branchId: number) => ['branch', branchId, 'rentals'],
  getBranchRentalReservations: (branchId: number) => ['branch', branchId, 'rental-reservations'],
  getBranchTenants: (branchId: number, search: TenantSearch & Pagination) => ['branch', branchId, 'tenant', search],
}

export default branchQueryKey
