import * as BRANCH from 'gadjet-v2-types/dist/api/hq/branch'

import axios from '@utils/axios'

const HqBranchAPI = {
  // 운영사 지점 조회
  getHqBranches: async ({ hqId }: BRANCH.GET_LIST_PARAMS): Promise<{ data: BRANCH.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches`),

  // 운영사 지점 추가
  addHqBranches: async (
    { hqId }: BRANCH.ADD_PARAMS,
    { branch, managerId }: BRANCH.ADD_BODY
  ): Promise<{ data: BRANCH.ADD_RESPONSE }> => axios.post(`/hqs/${hqId}/branches`, { branch, managerId }),
}

export default HqBranchAPI
