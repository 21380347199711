export const calculatePricesFromConsumerPrice = (consumerPrice: number) => {
  // Calculate VAT and round off to the first decimal
  const vat = Math.round((consumerPrice / 11) * 10) / 10

  // Calculate NET price as Consumer Price - VAT to ensure precision
  let netPrice = Math.round((consumerPrice - vat) * 10) / 10

  // Ensure consumerPrice = NET price + VAT
  const calculatedConsumerPrice = netPrice + vat

  // Adjust netPrice if there is a small rounding difference
  if (calculatedConsumerPrice !== consumerPrice) {
    netPrice = consumerPrice - vat // Direct adjustment to ensure match
    netPrice = Math.round(netPrice * 10) / 10 // Re-round to the first decimal
  }

  return {
    netPrice,
    vat,
    consumerPrice,
  }
}

export const calculatePricesFromNetPrice = (netPrice: number) => {
  // Calculate VAT and round off to the first decimal
  const vat = Math.round(netPrice * 0.1 * 10) / 10

  // Calculate Consumer Price as NET price + VAT to ensure precision
  let consumerPrice = Math.round((netPrice + vat) * 10) / 10

  // Ensure netPrice = Consumer Price - VAT
  const calculatedNetPrice = consumerPrice - vat

  // Adjust consumerPrice if there is a small rounding difference
  if (calculatedNetPrice !== netPrice) {
    consumerPrice = netPrice + vat // Direct adjustment to ensure match
    consumerPrice = Math.round(consumerPrice * 10) / 10 // Re-round to the first decimal
  }

  return {
    netPrice,
    vat,
    consumerPrice,
  }
}

export const netPriceToVAT = (netPrice: number) => {
  const prices = calculatePricesFromNetPrice(netPrice)
  return prices.vat
}
