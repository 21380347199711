import { Input, InputProps, Modal } from 'antd'
import { useState } from 'react'
import ReactDOM from 'react-dom'

type Props = {
  title: string
  inputProps?: InputProps
  onConfirm: (value: string) => void
  onCancel: () => void
  afterClose: () => void
}
export default function Prompt({ title, inputProps, onConfirm, onCancel, afterClose }: Props): JSX.Element {
  const [visible, setVisible] = useState(true)
  const [value, setValue] = useState(String(inputProps?.value) || '')

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)

  const onOk = () => {
    onConfirm(value)
    setVisible(false)
  }
  const onClose = () => {
    onCancel()
    setVisible(false)
  }

  return (
    <Modal title={title} visible={visible} onOk={onOk} onCancel={onClose} afterClose={afterClose}>
      <Input size="large" onChange={onChangeValue} onPressEnter={onOk} {...inputProps} value={value} />
    </Modal>
  )
}

export const prompt = (title: string, inputProps?: InputProps): Promise<{ isConfirm: boolean; value: string }> =>
  new Promise((resolve) => {
    const id = `${Date.now()}-prompt`
    const rootElement = document.querySelector('#root')
    const target = document.createElement('div')
    target.id = id

    rootElement?.appendChild(target)

    const removeTarget = () => target.remove()
    const onConfirm = (value: string) => resolve({ isConfirm: true, value })
    const onCancel = () => resolve({ isConfirm: false, value: '' })

    ReactDOM.render(
      <Prompt
        title={title}
        inputProps={inputProps}
        onConfirm={onConfirm}
        onCancel={onCancel}
        afterClose={removeTarget}
      />,
      document.getElementById(id)
    )
  })
