import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Styles } from '@types'
import { Button, Divider, Form, Input, Modal, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import ContractAPI from '@apis/branch/contract'

import { setContractDefault } from '@reducers/branch/contract.reducer'

export default function Memo({ contract }: ContractColumnProps): JSX.Element {
  const { hqId, branchId } = useMenu()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [form] = useForm<Partial<Contracts>>()
  const { publicMemo, privateMemo, contractId } = contract

  const [modal, onVisible, onClose] = useModal({ visible: false })

  const onOk = async () => {
    const { publicMemo, privateMemo } = await form.validateFields()
    setLoading(true)
    try {
      await ContractAPI.updateContractMemo({ hqId, branchId, contractId }, { contracts: { publicMemo, privateMemo } })
      notification.success({ message: '저장되었습니다.' })
      dispatch(setContractDefault(hqId, branchId))
      setLoading(false)
      onClose()
    } catch (error) {
      setLoading(false)
    }
  }

  const onOpenModal = () => {
    form.resetFields()
    onVisible({ visible: false })
  }

  return (
    <Form form={form} initialValues={{ privateMemo, publicMemo }}>
      <Button onClick={onOpenModal}>메모보기</Button>
      <Modal title="메모" visible={modal.visible} onCancel={onClose} onOk={onOk} okText="저장" confirmLoading={loading}>
        <>
          <Label>공개 메모 (계약서에 포함됩니다.)</Label>
          <Form.Item name="publicMemo">
            <div style={styles.public}>{publicMemo}</div>
          </Form.Item>
          <Divider />
          <Label>비공개 메모 (입주사가 확인할 수 없습니다.)</Label>
          <Form.Item name="privateMemo">
            <StyledTextArea style={styles.textArea} />
          </Form.Item>
        </>
      </Modal>
    </Form>
  )
}
const styles: Styles = {
  textArea: { height: 100 },
  public: { padding: '5px', borderRadius: '5px', backgroundColor: '#eee', minHeight: '100px', whiteSpace: 'pre-line' },
}
const Label = styled.div`
  font-size: 12px;
  opacity: 0.6;
  margin-bottom: 4px;
`
const StyledTextArea = styled(Input.TextArea)`
  resize: none;
`
