import { Checkbox, Row } from 'antd'
import { useMemo } from 'react'
import styled from 'styled-components'

import spaceLabelBg from '@utils/spaceLabelBg'

type Props = {
  onChange: (_rentalIds: any[]) => void
  rentals: {
    label: string
    value: number
    color: string
  }[]
  showChevronDown: boolean
}

export default function RentalSelectBox({ onChange, rentals, showChevronDown }: Props): JSX.Element {
  const defaultRentalValues: number[] = rentals.map((rental) => rental.value)

  const CheckBoxItems: JSX.Element[] = useMemo(() => {
    return rentals.map((rental) => (
      <CheckboxWrapper>
        <CustomCheckbox color={rental.color} value={rental.value}>
          {rental.label}
        </CustomCheckbox>
      </CheckboxWrapper>
    ))
  }, [rentals])

  return (
    <Wrapper showChevronDown={showChevronDown}>
      <Checkbox.Group onChange={onChange} defaultValue={defaultRentalValues}>
        <Row>{CheckBoxItems}</Row>
      </Checkbox.Group>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 95%;
  display: flex;
  overflow: ${({ showChevronDown }: { showChevronDown: boolean }) => (showChevronDown ? 'auto' : 'hidden')};
  flex-wrap: wrap;
  flex-direction: column;
`

const CheckboxWrapper = styled.div`
  border: 1px solid #ebebeb;
  width: max-content;
  border-radius: 8px;
  height: max-content;
  margin: 5px;
  padding: 3.5px 0px 3.5px 7px;
  &:hover {
    background-color: #f4f5f7;
  }
`

const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${({ color }: { color: string }) => color || '#3ebd73'};
  }

  .ant-checkbox .ant-checkbox-inner {
    border-color: ${({ color }: { color: string }) => color || '#3ebd73'};
    background-color: ${({ color }: { color: string }) => spaceLabelBg(color)};
  }
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: ${({ color }: { color: string }) => color || '#3ebd73'};
    border-color: ${({ color }: { color: string }) => color};
  }
`
