import { Styles } from '@types'
import { Button, Table, TableColumnType, TablePaginationConfig } from 'antd'
import { ServiceSales } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'

import { krw } from '@utils/format'

import { BranchServiceSaleAction } from '@pages/Branch/ServiceSalePage'

import TableTotalRows from '@components/atoms/TableTotalRows'
import TableWrapper from '@components/atoms/TableWrapper'

type Props = {
  loading: boolean
  total: number
  pagination: Pagination
  serviceSales: ServiceSales[]
  onChangePagination: (pagination: Pagination) => void
  onAction: (action: BranchServiceSaleAction, serviceSaleId: number) => void
}
export default function BranchServiceSaleListTable({
  loading,
  total,
  pagination,
  serviceSales,
  onChangePagination,
  onAction,
}: Props): JSX.Element {
  const onChange = (_pagination: TablePaginationConfig) => onChangePagination({ ...pagination, ..._pagination })

  const columns: TableColumnType<ServiceSales>[] = [
    {
      key: 'tenantId',
      dataIndex: 'tenantId',
      title: '입주사',
      render: (_, { tenant }) => tenant?.name,
    },
    {
      key: 'serviceId',
      dataIndex: 'serviceId',
      title: '이름',
      render: (_, { service, name }) => service?.name || name,
    },
    { key: 'amount', dataIndex: 'amount', title: '수량', render: (amount) => `${amount}개` },
    {
      key: 'price',
      dataIndex: 'price',
      title: '금액',
      align: 'right',
      render: (price) => `${krw(price, true)} 크레딧`,
    },
    { key: 'userId', dataIndex: 'userId', title: '구매자', render: (_, { user }) => user?.name },
    { key: 'datetime', dataIndex: 'datetime', title: '구매일시' },
    {
      key: 'refundFlag',
      dataIndex: 'refundFlag',
      title: '환불',
      render: (refundFlag, { serviceSaleId }) => {
        if (refundFlag) return <Button disabled>환불완료</Button>
        return <Button onClick={() => onAction('refund', serviceSaleId)}>환불하기</Button>
      },
    },
  ]

  return (
    <TableWrapper>
      <Table<ServiceSales>
        loading={loading}
        style={styles.table}
        scroll={{ x: true }}
        rowKey={({ serviceSaleId }) => `${serviceSaleId}`}
        dataSource={serviceSales}
        columns={columns}
        onChange={onChange}
        pagination={{
          total,
          position: ['bottomCenter'],
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          showTotal: (total) => <TableTotalRows total={total} />,
        }}
      />
    </TableWrapper>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
}
