import { Button, Modal } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import styled from 'styled-components'

type Props = {
  name: string
  blockPeriod: number
  path: string
  image: string
  width: string
  height: string
}

export default function WebPopUp({ name, blockPeriod, path, image, width, height }: Props): JSX.Element {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true)
  const onClickCloseForAWeek = () => {
    setIsModalVisible(false)
    const expiry: string | null = dayjs().add(blockPeriod, 'day').format('YYYY/MM/DD HH:mm:ss')
    localStorage.setItem(name, expiry)
  }

  const onClickClose = () => {
    setIsModalVisible(false)
  }

  return (
    <CustomModal
      closable={false}
      visible={isModalVisible}
      onOk={onClickClose}
      onCancel={onClickCloseForAWeek}
      mask={false}
      focusTriggerAfterClose={false}
      maskClosable={false}
      width="max-content"
      bodyStyle={{ padding: 0, margin: 0 }}
      okButtonProps={{ type: 'default' }}
      footer={[
        <Button onClick={onClickCloseForAWeek}>{`${blockPeriod}일간 보지 않기`}</Button>,
        <Button onClick={onClickClose}>닫기</Button>,
      ]}
    >
      <a href={path} target="_blank" rel="noreferrer">
        <Image src={image} alt={name} width={width} height={height} />
      </a>
    </CustomModal>
  )
}

const Image = styled.img`
  border-radius: 16px 16px 0px 0px;
`
const CustomModal = styled(Modal)`
  .ant-modal-footer {
    padding: 10px 24px 12px 10px;
  }
`
