import { Services, ServiceSales } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { ServiceSaleSearch } from 'gadjet-v2-types/dist/type/search'

import { getDefaultRange } from '@utils/datetime'

// ActionType
export const SEARCH_SERVICE_SALES = 'serviceSale/SEARCH_SERVICE_SALES' as const
export const SET_SERVICE_SALE_DEFAULT = 'serviceSale/SET_DEFAULT' as const
export const SET_SERVICE_SALE_LOADING = 'serviceSale/SET_SERVICE_SALE_LOADING' as const
export const SET_SERVICE_SALE_SEARCH_RESULT = 'serviceSale/SET_SERVICE_SALE_SEARCH_RESULT' as const
export const SET_SERVICE_SALE_SEARCH = 'serviceSale/SET_SERVICE_SALE_SEARCH' as const
export const SET_SERVICE_SALE_PAGINATION = 'serviceSale/SET_SERVICE_SALE_PAGINATION' as const
export const SET_SERVICE_SALE_SUMMARY = 'serviceSale/SET_SERVICE_SALE_SUMMARY' as const
export const SET_SERVICE_SALE_PAGE_CONFIG = 'serviceSale/SET_SERVICE_SALE_PAGE_CONFIG' as const
export const SET_SERVICE_SALE_SERVICE = 'serviceSale/SET_SERVICE_SALE_SERVICE' as const

// Action
export const searchServiceSales = (hqId: number, branchId: number) => ({
  type: SEARCH_SERVICE_SALES,
  payload: { hqId, branchId },
})
export const setServiceSaleDefault = (hqId: number, branchId: number) => ({
  type: SET_SERVICE_SALE_DEFAULT,
  payload: { hqId, branchId },
})
export const setServiceSaleLoading = (loading: boolean) => ({
  type: SET_SERVICE_SALE_LOADING,
  payload: { loading },
})
export const setServiceSaleSearchResult = (result: Partial<ServiceSaleState['searchResult']>) => ({
  type: SET_SERVICE_SALE_SEARCH_RESULT,
  payload: { result },
})
export const setServiceSaleSearch = (search: Partial<ServiceSaleSearch>) => ({
  type: SET_SERVICE_SALE_SEARCH,
  payload: { search },
})
export const setServiceSalePagination = (pagination: Partial<Pagination>) => ({
  type: SET_SERVICE_SALE_PAGINATION,
  payload: { pagination },
})
export const setServiceSaleService = (service: Partial<ServiceSaleState['service']>) => ({
  type: SET_SERVICE_SALE_SERVICE,
  payload: { service },
})

export type ServiceSaleAction =
  | ReturnType<typeof searchServiceSales>
  | ReturnType<typeof setServiceSaleDefault>
  | ReturnType<typeof setServiceSaleLoading>
  | ReturnType<typeof setServiceSaleSearchResult>
  | ReturnType<typeof setServiceSaleSearch>
  | ReturnType<typeof setServiceSalePagination>
  | ReturnType<typeof setServiceSaleService>

type ServiceSaleState = {
  service: { list: Services[]; loading: boolean }
  loading: boolean
  searchResult: { branchId: number; total: number; serviceSales: ServiceSales[] }
  search: ServiceSaleSearch
  pagination: Pagination
}

const serviceSaleState: ServiceSaleState = {
  service: { list: [], loading: false },
  loading: true,
  searchResult: { branchId: 0, total: 0, serviceSales: [] },
  search: { query: '', datetime: getDefaultRange('month', 'datetime'), serviceIds: [] },
  pagination: { current: 1, pageSize: 10 },
}

const serviceSaleReducer = (state = serviceSaleState, action: ServiceSaleAction): ServiceSaleState => {
  switch (action.type) {
    case SET_SERVICE_SALE_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_SERVICE_SALE_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_SERVICE_SALE_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_SERVICE_SALE_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    case SET_SERVICE_SALE_SERVICE:
      return { ...state, service: { ...state.service, ...action.payload.service } }
    default:
      return state
  }
}

export default serviceSaleReducer
