import { Input } from 'antd'
import { RentalSearch } from 'gadjet-v2-types/dist/type/search'

import SearchBox from '@components/molecules/SearchBox'
import SearchItem from '@components/molecules/SearchItem'

type Props = {
  search: RentalSearch
  onChangeSearch: (search: Partial<RentalSearch>) => void
  onSearch: () => void
}

export default function BranchRentalSearchBox({ search, onChangeSearch, onSearch }: Props): JSX.Element {
  const onChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => onChangeSearch({ query: e.target.value })

  return (
    <SearchBox onSearch={onSearch}>
      <SearchItem label="검색어" span={6}>
        <Input size="large" placeholder="검색어를 입력하세요." value={search.query} onChange={onChangeQuery} />
      </SearchItem>
    </SearchBox>
  )
}
