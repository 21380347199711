import { DownloadOutlined } from '@ant-design/icons'

import { BranchExpenditureAction } from '@pages/Branch/ExpenditurePage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchExpenditureAction) => void
}

export default function BranchExpenditureTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [
    { label: '다운로드', buttonType: 'dashed', icon: <DownloadOutlined />, onClick: () => onAction('export') },
    { label: '지출 추가', buttonType: 'primary', onClick: () => onAction('add') },
  ]

  return <PageTitle title="지출" actions={actions} />
}
