import { BranchServiceAction } from '@pages/Branch/ServicePage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchServiceAction) => void
}

export default function BranchServiceTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [{ label: '서비스 추가', buttonType: 'primary', onClick: () => onAction('add') }]

  return <PageTitle title="서비스" actions={actions} />
}
