import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import ReceiptAPI from '@apis/branch/receipt'

import {
  ReceiptAction,
  setReceiptLoading,
  setReceiptSearchResult,
  SEARCH_RECEIPTS,
  SET_RECEIPT_DEFAULT,
  SET_RECEIPT_PAGINATION,
  SET_RECEIPT_SEARCH,
  SET_RECEIPT_PAGE_CONFIG,
  setReceiptPagination,
  searchReceipts,
  setReceiptSummary,
} from '@reducers/branch/receipt.reducer'
import { RootState } from '@reducers/index'
import { setStorageReceipt } from '@reducers/storage.reducer'

function* searchReceiptSaga(action: ReceiptAction): Generator {
  if (action.type !== SEARCH_RECEIPTS) return
  const { hqId, branchId } = action.payload
  yield* put(setReceiptLoading(true))

  const search = yield* select((state: RootState) => state.receipt.search)
  const pagination = yield* select((state: RootState) => state.receipt.pagination)

  const {
    data: { receipts, total },
  } = yield* call(ReceiptAPI.getReceipts, { hqId, branchId }, { ...search, ...pagination })

  const { data: summary } = yield* call(ReceiptAPI.getReceiptSummary, { hqId, branchId }, { ...search })

  yield* put(setReceiptSearchResult({ branchId, receipts, total }))
  yield* put(setReceiptSummary(summary))

  yield* put(setReceiptLoading(false))
}

function* savePageConfigSaga(action: ReceiptAction): Generator {
  const state: Parameters<typeof setStorageReceipt>[0] = {}

  if (action.type === SET_RECEIPT_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageReceipt(state))
}

function* setDefaultPageConfigSaga(action: ReceiptAction): Generator {
  if (action.type !== SET_RECEIPT_DEFAULT) return
  const { hqId, branchId } = action.payload
  const { pageSize } = yield* select((state: RootState) => state.storage.receiptConfig)

  yield* put(setReceiptPagination({ pageSize }))

  yield* put(searchReceipts(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_RECEIPTS, searchReceiptSaga),
    takeEvery(SET_RECEIPT_SEARCH, savePageConfigSaga),
    takeEvery(SET_RECEIPT_PAGINATION, savePageConfigSaga),
    takeEvery(SET_RECEIPT_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_RECEIPT_DEFAULT, setDefaultPageConfigSaga),
  ])
}
