import { Receipts } from 'gadjet-v2-types/dist/model'
import { ReceiptSummary, Pagination } from 'gadjet-v2-types/dist/type'
import { ReceiptSearch } from 'gadjet-v2-types/dist/type/search'

import { getDefaultRange } from '@utils/datetime'

// ActionType
export const SEARCH_RECEIPTS = 'receipt/SEARCH_RECEIPTS' as const
export const SET_RECEIPT_DEFAULT = 'receipt/SET_DEFAULT' as const
export const SET_RECEIPT_LOADING = 'receipt/SET_RECEIPT_LOADING' as const
export const SET_RECEIPT_SEARCH_RESULT = 'receipt/SET_RECEIPT_SEARCH_RESULT' as const
export const SET_RECEIPT_SEARCH = 'receipt/SET_RECEIPT_SEARCH' as const
export const SET_RECEIPT_PAGINATION = 'receipt/SET_RECEIPT_PAGINATION' as const
export const SET_RECEIPT_SUMMARY = 'receipt/SET_RECEIPT_SUMMARY' as const
export const SET_RECEIPT_PAGE_CONFIG = 'receipt/SET_RECEIPT_PAGE_CONFIG' as const

// Action
export const searchReceipts = (hqId: number, branchId: number) => ({
  type: SEARCH_RECEIPTS,
  payload: { hqId, branchId },
})
export const setReceiptDefault = (hqId: number, branchId: number) => ({
  type: SET_RECEIPT_DEFAULT,
  payload: { hqId, branchId },
})
export const setReceiptLoading = (loading: boolean) => ({
  type: SET_RECEIPT_LOADING,
  payload: { loading },
})
export const setReceiptSearchResult = (result: Partial<ReceiptState['searchResult']>) => ({
  type: SET_RECEIPT_SEARCH_RESULT,
  payload: { result },
})
export const setReceiptSearch = (search: Partial<ReceiptSearch>) => ({
  type: SET_RECEIPT_SEARCH,
  payload: { search },
})
export const setReceiptPagination = (pagination: Partial<Pagination>) => ({
  type: SET_RECEIPT_PAGINATION,
  payload: { pagination },
})
export const setReceiptSummary = (summary: ReceiptSummary) => ({
  type: SET_RECEIPT_SUMMARY,
  payload: { summary },
})

export type ReceiptAction =
  | ReturnType<typeof searchReceipts>
  | ReturnType<typeof setReceiptDefault>
  | ReturnType<typeof setReceiptLoading>
  | ReturnType<typeof setReceiptSearchResult>
  | ReturnType<typeof setReceiptSearch>
  | ReturnType<typeof setReceiptPagination>
  | ReturnType<typeof setReceiptSummary>

type ReceiptState = {
  loading: boolean
  searchResult: { branchId: number; total: number; receipts: Receipts[] }
  search: ReceiptSearch
  pagination: Pagination
  summary: ReceiptSummary
}

const receiptState: ReceiptState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, receipts: [] },
  search: { query: '', requestDatetime: getDefaultRange('month', 'datetime') },
  pagination: { current: 1, pageSize: 10 },
  summary: { total: 0, cashReceipt: 0, taxInvoice: 0 },
}

const receiptReducer = (state = receiptState, action: ReceiptAction): ReceiptState => {
  switch (action.type) {
    case SET_RECEIPT_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_RECEIPT_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_RECEIPT_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_RECEIPT_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    case SET_RECEIPT_SUMMARY:
      return { ...state, summary: { ...state.summary, ...action.payload.summary } }
    default:
      return state
  }
}

export default receiptReducer
