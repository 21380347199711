import { ReactComponent as ChevronRightIcon } from '@assets/Icons/chevron-right.svg'
import { BREAKPOINTS } from '@const'
import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  icon: { src: string; alt: string }
  text: string | ReactNode
  path: string
}

export default function KeyFeatureButton({ icon, text, path }: Props): JSX.Element {
  return (
    <Button href={path}>
      <InnerWrapper>
        <Icon src={icon.src} alt={icon.alt} />
        <Text>{text}</Text>
      </InnerWrapper>
      <ChevronRightIcon width="24" height="24" stroke="#2E2E2E" />
    </Button>
  )
}

const Button = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 28px;
  width: 380px;
  height: 130px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
  border-radius: 24px;
  transition: all 0.3s ease-in-out;
  :hover {
    background: #ffffff;
    opacity: 0.8;
    box-shadow: none;
  }
  @media ${BREAKPOINTS.lg} {
    width: 323px;
    height: 100px;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`
const Icon = styled.img`
  width: 56px;
  height: 56px;
  @media ${BREAKPOINTS.lg} {
    width: 40px;
    height: 40px;
  }
`

const Text = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #2e2e2e;
  @media ${BREAKPOINTS.lg} {
    font-size: 20px;
  }
`
