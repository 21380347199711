import useMenu from '@hooks/useMenu'
import { Styles } from '@types'
import { Space } from 'antd'
import dayjs from 'dayjs'
import { BranchDashboardDayEvent, BranchDashboardEventSummary } from 'gadjet-v2-types/dist/type'
import { useEffect, useMemo, useState } from 'react'

import DashboardAPI from '@apis/branch/dashboard'

import DashboardMonthPicker from '@components/organisms/Branch/Dashboard/DashboardMonthPicker'
import DashboardSummary from '@components/organisms/Branch/Dashboard/DashboardSummary'

import DashboardInformation from './organisms/DashboardInformation'

export default function DashboardPage(): JSX.Element {
  const { hqId, branchId } = useMenu()

  const [month, setMonth] = useState(dayjs().format('YYYY-MM'))
  const [eventState, setEventState] = useState<{
    loading: boolean
    events: { date: string; event: BranchDashboardDayEvent }[]
  }>({
    loading: false,
    events: [],
  })
  const [summaryState, setSummaryState] = useState<{ loading: boolean; summary: BranchDashboardEventSummary }>({
    loading: false,
    summary: {
      billingCount: 0,
      contractEndCount: 0,
      contractStartCount: 0,
      expectSalePrice: 0,
      expenditurePrice: 0,
      rentalReservationCount: 0,
      paymentPrice: 0,
      unpaidPrice: 0,
    },
  })

  const dateRange: [string, string] = useMemo(
    () => [
      dayjs(month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'),
      dayjs(month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD'),
    ],
    [month]
  )

  const getEvents = async () => {
    setEventState({ ...eventState, loading: true })
    const { data } = await DashboardAPI.getDashboardEvents({ hqId, branchId }, { date: dateRange })
    setEventState({ loading: false, events: data })
  }

  const getEventSummary = async () => {
    setSummaryState({ ...summaryState, loading: true })
    const { data } = await DashboardAPI.getDashboardEventSummary({ hqId, branchId }, { date: dateRange })
    setSummaryState({ loading: false, summary: data })
  }

  useEffect(() => {
    getEvents()
    getEventSummary()
  }, [dateRange])

  return (
    <Space size={20} direction="vertical" style={styles.space}>
      <DashboardMonthPicker month={month} onChange={setMonth} />
      <DashboardSummary summary={summaryState.summary} loading={summaryState.loading} dateRange={dateRange} />
      <DashboardInformation />
    </Space>
  )
}

const styles: Styles = {
  space: { width: '100%' },
}
