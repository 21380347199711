import { FREE_TRIAL_URL, PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '@const'
import { Checkbox, Form, InputRef, Row } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useForm } from 'antd/lib/form/Form'
import { Managers } from 'gadjet-v2-types/dist/model'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { setToken } from '@utils/axios'
import { GoogleUserProfile } from '@utils/firebase'
import formRule from '@utils/formRule'

import AuthAPI from '@apis/auth'

import AuthFormTemplate from '@templates/AuthFormTemplate'

import AuthFormItem from '@components/atoms/AuthFormItem'
import AuthInput from '@components/atoms/AuthInput'
import AuthInputPassword from '@components/atoms/AuthInputPassword'
import HiddenItems from '@components/atoms/Form/HiddenItems'
import SendCodeButton from '@components/atoms/SendCodeButton'
import toast from '@components/atoms/Toast'

export default function JoinPage(): JSX.Element {
  const { location } = useHistory<GoogleUserProfile | undefined>()
  const profile = useMemo(() => location.state, [location])

  const [form] = useForm<Managers>()

  const input = {
    password: useRef<InputRef>(null),
    name: useRef<InputRef>(null),
    code: useRef<InputRef>(null),
  }

  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [verification, setVerification] = useState(false)

  const [codeStatus, setCodeStatus] = useState<'none' | 'sending' | 'sent'>('none')

  const [validAge, setValidAge] = useState(false)
  const [agreePolicy, setAgreePolicy] = useState(false)

  const sendCode = async () => {
    const email = form.getFieldValue('email')

    const { data: mailVerify } = await AuthAPI.verifyEmail({}, { email })
    if (!mailVerify) {
      toast.error('동일한 이메일 주소로 가입되어 있습니다.')
      return
    }
    const { data } = await AuthAPI.sendEmailCode({}, { email })
    setCode(data)
    setCodeStatus('sending')
    toast.success('코드가 발송되었습니다.')
    input.code.current?.focus()
    setCodeStatus('sent')
  }

  const onChangeCode: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!code || code !== e.target.value) return

    setVerification(true)
    toast.success('코드인증 완료')
    input.password.current?.focus()
  }

  const onChangeValidAge = (e: CheckboxChangeEvent) => {
    setValidAge(e.target.checked)
  }

  const onChangeAgreePolicy = (e: CheckboxChangeEvent) => {
    setAgreePolicy(e.target.checked)
  }

  const onJoin = async () => {
    const values = await form.validateFields()
    if (agreePolicy && validAge) {
      setLoading(true)
      const { data } = await AuthAPI.join({}, { manager: values })
      const { token } = data
      setToken(token)
      setLoading(false)
      window.location.href = '/code'
    } else {
      toast.error('필수항목을 확인해주세요.')
    }
  }

  const setSocialProfile = () => {
    const manager: Partial<Managers> = {
      email: profile?.email || '',
      joinType: profile ? 'google' : 'local',
      password: '',
      name: profile?.name || '',
      socialId: profile?.id,
    }
    form.setFieldsValue(manager)

    if (!profile) return
    setVerification(true)
    setCodeStatus('sent')
  }

  useEffect(() => {
    setSocialProfile()
  }, [profile])

  return (
    <AuthFormTemplate title="관리자 회원가입" submit={{ label: '회원가입', onClick: onJoin }} loading={loading}>
      <StartInfoBox>
        가젯을 도입, 이용 중인 관리자를 위한 페이지입니다.
        <br /> 아직 가젯 도입 전이라면{' '}
        <Link href={FREE_TRIAL_URL} target="_blank">
          <b>
            <u>무료체험/도입하기</u>
          </b>
        </Link>
        를 눌러주세요.
      </StartInfoBox>
      <Form form={form} layout="vertical" requiredMark={false}>
        <HiddenItems names={['joinType', 'socialId']} />
        <Form.Item shouldUpdate noStyle>
          {({ getFieldError }) => (
            <AuthFormItem name="email" label="이메일 아이디" rules={[formRule.required, formRule.email]}>
              <AuthInput
                autoFocus
                onPressEnter={() => sendCode()}
                disabled={codeStatus !== 'none'}
                suffix={
                  profile ? undefined : (
                    <SendCodeButton
                      type="primary"
                      size="small"
                      onClick={sendCode}
                      disabled={verification || getFieldError(['email']).length > 0}
                    />
                  )
                }
              />
            </AuthFormItem>
          )}
        </Form.Item>
        {!profile && (
          <>
            <AuthFormItem
              label="이메일 인증코드"
              hidden={!!profile}
              extra={
                <Helper>
                  <img src="/images/love-letter-emoji.png" alt="love-letter-emoji" width={20} height={20} />
                  <span>
                    받은메일함에서 <b>[gadjet] 인증코드</b>를 확인하세요
                  </span>
                </Helper>
              }
            >
              <AuthInput ref={input.code} onChange={onChangeCode} disabled={verification} />
            </AuthFormItem>
          </>
        )}
        {!profile && (
          <AuthFormItem
            name="password"
            label="비밀번호"
            rules={[formRule.required, formRule.password]}
            hidden={!!profile}
          >
            <AuthInputPassword ref={input.password} type="password" onPressEnter={() => input.name.current?.focus()} />
          </AuthFormItem>
        )}
        <AuthFormItem name="name" label="이름">
          <AuthInput ref={input.name} onPressEnter={onJoin} />
        </AuthFormItem>
        <CheckboxGroup>
          <Row>
            <CheckboxItem onChange={onChangeValidAge}>(필수) 만 14세 이상입니다.</CheckboxItem>
          </Row>
          <Row>
            <CheckboxItem onChange={onChangeAgreePolicy}>
              (필수){' '}
              <Link href={TERMS_OF_SERVICE_URL} target="_blank">
                서비스 이용약관
              </Link>
              {`과 `}
              <Link href={PRIVACY_POLICY_URL} target="_blank">
                개인정보 처리방침
              </Link>
              에 동의합니다.
            </CheckboxItem>
          </Row>
        </CheckboxGroup>
      </Form>
    </AuthFormTemplate>
  )
}

const Link = styled.a`
  color: #2e2e2e;
  :hover {
    color: #0d0d0d;
  }
`
const CheckboxItem = styled(Checkbox)`
  font-size: 13px;
  line-height: 24px;
  color: #595a5b;
  font-weight: 400 !important;
`
const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  margin-bottom: 20px;
`
const Helper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #777d83;
  padding-top: 8px;
`
const StartInfoBox = styled.div`
  padding: 19px 16px;
  margin: 16px 0px 20px 0px;
  background: #f2f3f4;
  border-radius: 8px;

  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2e2e2e;
`
