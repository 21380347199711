import { BREAKPOINTS } from '@const'
import styled from 'styled-components'

import BalloonBlack from '../../atoms/BalloonBlack'
import BalloonGreen from '../../atoms/BalloonGreen'
import BookDemoButton from '../../atoms/BookDemoButton'
import PreviewBox from '../../atoms/PreviewBox'
import SectionTitle from '../../atoms/SectionTitle'
import TenantPreviewBox from '../../atoms/TenantPreviewBox'

export default function BillFeature(): JSX.Element {
  return (
    <Wrapper>
      <SectionTitle
        text={
          <span>
            가젯으로 편해질 공유공간 관리,
            <br />
            상상해 보세요!
          </span>
        }
      />
      <TextWrapper>
        <BalloonGroup>
          <BalloonBlack
            background={{ src: '/images/landing/home/balloons/balloon-black-bill.png', width: 564, height: 167 }}
            icon={{ src: '/images/landing/home/balloons/knocked-out-face.png', alt: 'knocked-out-face' }}
            mobileBackground="/images/landing/home/balloons/balloon-black-mobile.png"
            text={
              <span>
                <Black>
                  청구서, 결제 여부,
                  <MediaBr /> 증빙 발행...
                </Black>
                <br />
                매달 정리하고 확인하느라 <Black>시간이 부족해요</Black>
              </span>
            }
          />
          <BalloonGreen
            background={{ src: '/images/landing/home/balloons/balloon-green-bill.png', width: 409, height: 167 }}
            mobileBackground="/images/landing/home/balloons/balloon-green-mobile.png"
            icon={{ src: '/images/landing/home/balloons/smiling-with-hearts.png', alt: 'smiling-with-hearts' }}
            text={
              <span>
                이제 걱정 마세요.
                <br />
                <Green>가젯이 먼저 챙겨드릴게요!</Green>
              </span>
            }
          />
        </BalloonGroup>
      </TextWrapper>
      <BoxGroup>
        <PreviewBox
          width="1000px"
          mediaWidth="904px"
          background="#ffffff"
          label="청구서 발송"
          content={{
            text: (
              <span>
                매달 한 건 한 건 신경 쓸
                <MediaBr /> 필요 없이 <Br />
                지정한 날짜에 자동으로 발송돼요.
              </span>
            ),
            align: 'center',
          }}
          toggleBox={{
            title: '청구서 자동화',
            label: '청구서 자동 발송',
            tootipText: (
              <span>
                청구서 결제일에 문자, 이메일로
                <br />
                청구서를 자동으로 발송합니다
              </span>
            ),
            styles: { width: '170px', right: '95px', top: '198px' },
          }}
          image={{
            src: '/images/landing/home/previews/bill-send.png',
            alt: 'bill-notification',
            width: 842,
            height: 362,
          }}
          mobileImage={{
            src: '/images/landing/home/previews/bill-send-m.png',
            alt: 'bill-notification',
            width: 343,
            height: 309,
          }}
        />
        <Space>
          <TenantPreviewBox
            width="400px"
            mediaWidth="350px"
            label="온라인 결제"
            content={
              <span>
                결제는 간편하고 빠르게
                <br />
                앱, PC에서 해요.
              </span>
            }
            image={{
              src: '/images/landing/home/previews/bill-mobile.png',
              alt: 'mobile-payment',
              width: 330,
              height: 360,
            }}
          />
          <PreviewBox
            width="580px"
            mediaWidth="534px"
            background="#ffffff"
            label="증빙 발행"
            content={{
              text: (
                <span>
                  세금계산서는 가젯이 알아서,
                  <br />
                  현금영수증도 클릭 한 번이면 발행 끝!
                </span>
              ),
              align: 'start',
            }}
            toggleBox={{
              title: '청구서 자동화',
              label: '증빙 자동 발행',
              tootipText: (
                <span>
                  매 달 9일에 직전월 매출에 대한
                  <br />
                  세금계산서를 자동으로 발행합니다
                </span>
              ),
              styles: { width: '160px', right: '25px', top: '185px' },
            }}
            image={{
              src: '/images/landing/home/previews/bill-receipts.png',
              alt: 'bill-automation',
              width: 580,
              height: 357,
            }}
            mobileImage={{
              src: '/images/landing/home/previews/bill-receipts-m.png',
              alt: 'bill-automation',
              width: 343,
              height: 371,
            }}
          />
        </Space>
        <BookDemoButton text="자동 발송 청구서 더 자세히 알고싶다면," />
      </BoxGroup>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 200px;
  @media ${BREAKPOINTS.lg} {
    padding-bottom: 100px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`

const BalloonGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  margin-bottom: 60px;
  @media ${BREAKPOINTS.lg} {
    width: 100%;
    align-items: center;
    gap: 12px;
  }
`

const Black = styled.span`
  color: #2e2e2e;
`

const Green = styled.span`
  color: #22af5d;
`

const BoxGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

const Space = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media ${BREAKPOINTS.lg} {
    flex-direction: column;
  }
`

const MediaBr = styled.br`
  display: none;
  @media ${BREAKPOINTS.lg} {
    display: block;
  }
`
const Br = styled.br`
  @media ${BREAKPOINTS.lg} {
    display: none;
  }
`
