type FileType = 'pdf' | 'xlsx'

export const bufferToFileDownload = (filename: string, buffer: Buffer, type: FileType = 'pdf') => {
  const blobOptions: BlobPropertyBag = { type: 'application/pdf' }
  if (type === 'pdf') blobOptions.type = 'application/pdf'
  if (type === 'xlsx') blobOptions.type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

  const url = URL.createObjectURL(new Blob([buffer], blobOptions))

  const a: HTMLAnchorElement = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.download = filename
  document.body.appendChild(a)
  a.click()
}

export const urlToFileDownload = (filename: string, url: string) => {
  const a: HTMLAnchorElement = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.download = filename
  document.body.appendChild(a)
  a.click()
}

export default bufferToFileDownload
