import Picture from '@pages/Landing/FeaturePages/atoms/Picture'
import * as S from '@pages/Landing/FeaturePages/styles'

export default function ManageBranch(): JSX.Element {
  return (
    <S.SectionInnerWrapper>
      <S.Container
        defaultStyles={{
          flexDirection: 'row-reverse',
          alignItems: 'center',
          height: '440px',
          background: 'linear-gradient(291.54deg, #E9F6EF 12.83%, #F2F0FC 76.3%)',
        }}
        mediaStyles={{ flexDirection: 'column', height: '470px' }}
      >
        <S.DescriptionWrapper defaultStyle={{ padding: '0 0 0 60px' }} mediaStyle={{ padding: '40px 0 0 30px' }}>
          <S.BoxTitle>지점 관리</S.BoxTitle>
          <S.Description>
            지점이 늘어날수록
            <br />
            더 복잡해지는 관리,
            <br />
            가젯에서는 심플해요.
          </S.Description>
        </S.DescriptionWrapper>
        <S.ImageWrapper defaultStyle={{ alignItems: 'center', width: '100%' }}>
          <Picture
            srcPNG="/images/landing/features/space/branch-menu.png"
            srcWEBP="/images/landing/features/space/branch-menu.webp"
            alt="branch-menu"
            width={272}
            height={320}
            mediaScale={0.83}
            mediaStyle={{ margin: '3px 0 19px 0' }}
          />
        </S.ImageWrapper>
      </S.Container>

      <S.Container
        defaultStyles={{
          flexDirection: 'row',
          alignItems: 'center',
          height: '480px',
          background: '#EFFAF4',
        }}
        mediaStyles={{ flexDirection: 'column', height: 'auto' }}
      >
        <S.DescriptionWrapper defaultStyle={{ padding: '0 0 0 80px' }} mediaStyle={{ padding: '40px 0 0 30px' }}>
          <S.BoxTitle>지점 통합 대시보드</S.BoxTitle>
          <S.Description>
            운영사 대시보드에서
            <br />
            전 지점의 입주사, 계약을
            <br />
            통합 관리할 수 있어요.
          </S.Description>
        </S.DescriptionWrapper>
        <Picture
          srcPNG="/images/landing/features/space/hq-dashboard.png"
          srcWEBP="/images/landing/features/space/hq-dashboard.webp"
          alt="hq-dashboard"
          width={550}
          height={480}
          defaultStyle={{ alignSelf: 'flex-end' }}
          mediaScale={0.65}
        />
      </S.Container>
    </S.SectionInnerWrapper>
  )
}
