import * as PRODUCT from 'gadjet-v2-types/dist/api/branch/product'

import axios from '@utils/axios'

const ProductAPI = {
  // 상품 조회
  getProducts: async (
    { hqId, branchId }: PRODUCT.GET_LIST_PARAMS,
    query: PRODUCT.GET_LIST_QUERY
  ): Promise<{ data: PRODUCT.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/products`, { params: query }),

  // 상품 상세조회
  getProductDetail: async ({
    hqId,
    branchId,
    productId,
  }: PRODUCT.GET_DETAIL_PARAMS): Promise<{ data: PRODUCT.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/products/${productId}`),

  // 상품 추가
  addProduct: async (
    { hqId, branchId }: PRODUCT.ADD_PARAMS,
    { product }: PRODUCT.ADD_BODY
  ): Promise<{ data: PRODUCT.ADD_RESPONSE }> => axios.post(`/hqs/${hqId}/branches/${branchId}/products`, { product }),

  // 상품 수정
  updateProduct: async (
    { hqId, branchId, productId }: PRODUCT.UPDATE_PARAMS,
    { product }: PRODUCT.UPDATE_BODY
  ): Promise<{ data: PRODUCT.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/products/${productId}`, { product }),

  // 상품 삭제
  deleteProduct: async ({
    hqId,
    branchId,
    productId,
  }: PRODUCT.DELETE_PARAMS): Promise<{ data: PRODUCT.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/products/${productId}`),

  // 상품 판매내역 조회
  getProductSales: async (
    { hqId, branchId }: PRODUCT.GET_SALES_PARAMS,
    query: PRODUCT.GET_SALES_QUERY
  ): Promise<{ data: PRODUCT.GET_SALES_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/product-sales`, { params: query }),

  // 상품 판매내역 요약
  getProductSaleSummary: async (
    { hqId, branchId }: PRODUCT.GET_SALES_SUMMARY_PARAMS,
    query: PRODUCT.GET_SALES_SUMMARY_QUERY
  ): Promise<{ data: PRODUCT.GET_SALES_SUMMARY_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/product-sales/summary`, { params: query }),

  // 상품 판매내역 환불
  refundProductSale: async ({
    hqId,
    branchId,
    productSaleId,
  }: PRODUCT.REFUND_SALES_PARAMS): Promise<{ data: PRODUCT.REFUND_SALES_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/product-sales/${productSaleId}`),

  // 상품 판매내역 다운로드
  exportProductSales: async (
    { hqId, branchId }: PRODUCT.EXPORT_SALES_PARAMS,
    query: PRODUCT.EXPORT_SALES_QUERY
  ): Promise<{ data: PRODUCT.EXPORT_SALES_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/product-sales/export`, { params: query }),
}

export default ProductAPI
