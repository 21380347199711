import { PickerLocale } from 'antd/lib/date-picker/generatePicker'
import { Locale } from 'antd/lib/locale-provider'

const pickerLocale: PickerLocale = {
  lang: {
    locale: 'ko_KR',
    placeholder: '날짜 선택',
    today: '오늘',
    now: '지금',
    backToToday: '오늘',
    ok: '확인',
    clear: '초기화',
    month: '월',
    year: '년',
    timeSelect: '시간 선택',
    dateSelect: '날짜 선택',
    monthSelect: '월 선택',
    yearSelect: '년 선택',
    decadeSelect: '년 선택(10년)',
    yearFormat: 'YYYY',
    dateFormat: 'YYYY-MM-DD',
    dayFormat: 'DD',
    dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
    monthFormat: 'MM',
    monthBeforeYear: true,
    previousMonth: '이전 월',
    nextMonth: '다음 월',
    previousYear: '이전 년',
    nextYear: '다음 년',
    previousDecade: '이전 년(10년)',
    nextDecade: '다음 년(10년)',
    previousCentury: '이전 세기',
    nextCentury: '다음 세기',
    shortWeekDays: ['일', '월', '화', '수', '목', '금', '토'],
    rangePlaceholder: ['시작', '종료'],
    rangeMonthPlaceholder: ['시작', '종료'],
    rangeWeekPlaceholder: ['시작', '종료'],
    rangeYearPlaceholder: ['시작', '종료'],
  },
  dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  dateFormat: 'YYYY-MM-DD',
  monthFormat: 'YYYY-MM',
  weekFormat: 'AA',
  timePickerLocale: {
    placeholder: '시간 선택',
    rangePlaceholder: ['시작', '종료'],
  },
}
const locale: Locale = { locale: 'ko_KR', DatePicker: pickerLocale }

export default locale
