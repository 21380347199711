import useMenu from '@hooks/useMenu'
import { Col, Row } from 'antd'

import EndContracts from '@components/organisms/Hq/Dashboard/EndContracts'
import RecentContracts from '@components/organisms/Hq/Dashboard/RecentContracts'
import SearchContracts from '@components/organisms/Hq/Dashboard/SearchContracts'
import Spaces from '@components/organisms/Hq/Dashboard/Spaces'
import StartContracts from '@components/organisms/Hq/Dashboard/StartContracts'
import UnpaidBills from '@components/organisms/Hq/Dashboard/UnpaidBills'

export default function DashboardPage(): JSX.Element {
  const { hqId } = useMenu()

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <SearchContracts hqId={hqId} />
        </Col>
        <Col span={12}>
          <UnpaidBills hqId={hqId} />
        </Col>
        <Col span={8}>
          <RecentContracts hqId={hqId} />
        </Col>
        <Col span={8}>
          <StartContracts hqId={hqId} />
        </Col>
        <Col span={8}>
          <EndContracts hqId={hqId} />
        </Col>
        <Col span={24}>
          <Spaces hqId={hqId} />
        </Col>
      </Row>
    </>
  )
}
