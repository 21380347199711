import { Col, Divider, Form, Input, Modal, notification, Radio, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Cashbill } from 'gadjet-v2-types/dist/popbill/cash-bill'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import mask from '@utils/mask'

import BillAPI from '@apis/branch/bill'
import ReceiptAPI from '@apis/branch/receipt'

import { RootState } from '@reducers/index'

import HiddenItems from '@components/atoms/Form/HiddenItems'
import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  visible: boolean
  billId: number
  onClose: () => void
  onDone: () => void
}

export default function CashReceiptModal({ hqId, branchId, billId, visible, onClose, onDone }: Props): JSX.Element {
  const business = useSelector((state: RootState) => state.branch.business)
  const [form] = useForm<Cashbill>()
  const [loading, setLoading] = useState(false)

  const onOk = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)
      const { data } = await ReceiptAPI.issueCashReceipt({ hqId, branchId }, { billId, cashReceipt: values })

      if (!data.isSuccess) {
        notification.error({ message: data.message })
      }

      if (data.isSuccess) {
        notification.success({ message: data.message })
        onDone()
        onClose()
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const getBill = async () => {
    form.resetFields()
    setLoading(true)
    const { data } = await BillAPI.getBill({ hqId, branchId, billId })
    const { spaces, additions, surcharges } = data
    const { tenant, director, receiver } = data?.contract || {}

    let itemName = ''
    const items = [...spaces, ...additions, ...surcharges]
    if (items.length === 1) itemName = items[0].name
    if (items.length > 1) itemName = `${items[0].name} 외 ${items.length - 1}건`

    let supplyCost = 0
    let tax = 0
    items.forEach(({ price, vat }) => {
      supplyCost += price
      tax += vat
    })
    const totalAmount = supplyCost + tax

    const invoiceReceiver = receiver?.invoice
    const email = invoiceReceiver && invoiceReceiver.length > 0 ? invoiceReceiver[0].email : ''

    form.setFieldsValue({
      tradeType: '승인거래',
      tradeUsage: '소득공제용',
      identityNum: tenant?.businessNumber || '',
      taxationType: '과세',

      totalAmount: String(totalAmount),
      supplyCost: String(supplyCost),
      tax: String(tax),
      serviceFee: '0',

      franchiseCorpNum: business?.businessNumber,
      franchiseCorpName: business?.name,
      franchiseCEOName: business?.director,
      franchiseAddr: business?.address,
      franchiseTEL: '',

      customerName: director?.name,
      itemName,
      orderNumber: '',
      email,
    })

    setLoading(false)
  }

  useEffect(() => {
    if (visible && billId) getBill()
  }, [visible, billId])

  return (
    <Modal visible={visible} onCancel={onClose} title="현금영수증" width="1000px" onOk={onOk} okText="현금영수증 발행">
      <Loading loading={loading}>
        <Form form={form} layout="vertical">
          <HiddenItems names={['tradeType']} />
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item label="가맹점 사업자번호" required name="franchiseCorpNum" {...mask.businessLicense}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="가맹점 상호" name="franchiseCorpName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="가맹점 대표자" name="franchiseCEOName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item label="가맹점 주소" name="franchiseAddr">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item label="거래구분" name="tradeUsage" required>
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="소득공제용">소득공제용</Radio.Button>
                  <Radio.Button value="지출증빙용">지출증빙용</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="과세형태" name="taxationType" required>
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="과세">과세</Radio.Button>
                  <Radio.Button value="비과세">비과세</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const tradeUsage = getFieldValue('tradeUsage')
                  let help = '주민등록번호 / 휴대폰 / 현금영수증 카드번호'
                  if (tradeUsage === '지출증빙용') help = '사업자번호 / 주민등록번호 / 휴대폰 / 현금영수증 카드번호'
                  return (
                    <Form.Item label="식별번호" name="identityNum" required help={help} {...mask.identityNum}>
                      <Input />
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item label="거래금액" name="totalAmount" required {...mask.krw}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="공급가액" name="supplyCost" required {...mask.krw}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="부가세" name="tax" required {...mask.krw}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="봉사료" name="serviceFee" {...mask.krw}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item label="주문자 이름" name="customerName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="상품" name="itemName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="이메일" name="email">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Loading>
    </Modal>
  )
}
