import { CreditType } from 'gadjet-v2-types/dist/type'
import { creditType } from 'gadjet-v2-types/dist/type/label'
import styled from 'styled-components'

import ChipButton from '@components/atoms/ChipButton'

type Props = {
  isAll: boolean
  options: CreditType[]
  selected: string[]
  onClickSelectAll: () => void
  onClickSelect: (isActive: boolean, _select: string) => void
}

export default function MultiSelectButtons({
  isAll,
  onClickSelectAll,
  options,
  selected,
  onClickSelect,
}: Props): JSX.Element {
  return (
    <Wrapper>
      <ChipButton label="전체" isActive={isAll} onClick={onClickSelectAll} />
      {options.map((option) => {
        const isActive = selected.includes(option) && !isAll
        return (
          <div key={option}>
            <ChipButton
              label={creditType[option]}
              isActive={isActive}
              onClick={() => onClickSelect(isActive, option)}
            />
          </div>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`
