import { Form, Input, Modal, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useState } from 'react'

import formRule from '@utils/formRule'

import ReceiptAPI from '@apis/branch/receipt'

import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  receiptId: number
  onClose: () => void
  onDone: () => void
  visible: boolean
}

export default function ResendReceiptModal({
  onClose,
  onDone,
  hqId,
  branchId,
  receiptId,
  visible,
}: Props): JSX.Element {
  const [form] = useForm<{ email: string }>()
  const [loading, setLoading] = useState(false)

  const onOk = async () => {
    try {
      const { email } = await form.validateFields()
      setLoading(true)
      await ReceiptAPI.resendTaxInvoiceOrCashReceipt({ hqId, branchId, receiptId }, { email })
      notification.success({ message: '세금계산서/현금영수증이 재전송되었습니다.' })
      setLoading(false)
      onClose()
      onDone()
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Modal
      title="세금계산서/현금영수증 재전송"
      onCancel={onClose}
      onOk={onOk}
      okText="보내기"
      okButtonProps={{ loading }}
      visible={visible}
      maskClosable={false}
    >
      <Loading loading={loading}>
        <Form form={form} layout="vertical">
          <Form.Item label="이메일" name="email" required rules={[formRule.required]}>
            <Input />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}
