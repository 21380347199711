import { Divider, Form, InputRef, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { setToken } from '@utils/axios'
import { googleLogin, GoogleUserProfile } from '@utils/firebase'
import formRule from '@utils/formRule'

import AuthAPI from '@apis/auth'

import LoginFormTemplate from '@templates/LoginFormTemplate'

import AuthFormItem from '@components/atoms/AuthFormItem'
import AuthInput from '@components/atoms/AuthInput'
import AuthInputPassword from '@components/atoms/AuthInputPassword'
import BlockIconButton from '@components/atoms/BlockIconButton'
import BlockTextButton from '@components/atoms/BlockTextButton'
import toast from '@components/atoms/Toast'
import MobileAppPopUp from '@components/molecules/PopupModals/MobileAppPopUp'

export default function LoginPage(): JSX.Element {
  const [form] = useForm<{ email: string; password: string }>()
  const password = useRef<InputRef>(null)

  const { push } = useHistory()

  const [loading, setLoading] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false)
  const [loginNotice, setloginNotice] = useState<boolean>(true)

  useEffect(() => {
    const expiry: string | null = localStorage.getItem('loginNotice')
    if (expiry) {
      setloginNotice(false)
    } else {
      setloginNotice(true)
    }
  }, [])

  const onClickLogin = async () => {
    const values = await form.validateFields()

    const { data } = await AuthAPI.login({}, { manager: values })
    const { token, manager } = data
    setLoading(false)

    if (!manager) {
      toast.error('이메일, 비밀번호를 확인하세요.')
      return
    }
    onSuccessLogin(token)
  }

  const onClickGoogleLogin = async () => {
    const result = await googleLogin()
    setLoading(true)
    const profile = result.additionalUserInfo?.profile as GoogleUserProfile

    const { data } = await AuthAPI.socialLogin({}, { manager: { socialId: profile.id } })
    const { token, manager } = data

    if (!manager) {
      push('/join', profile)
      return
    }

    onSuccessLogin(token)
    setLoading(false)
  }

  const onSuccessLogin = (token: string) => {
    setToken(token)
    window.location.href = '/'
  }

  const onClickJoin = () => push('/join')
  const onClickForgetPassword = () => push('/forget-password')

  return (
    <Background>
      {loginNotice && <MobileAppPopUp />}
      <LoginFormTemplate
        title="관리자 로그인"
        submit={{ label: '로그인', onClick: onClickLogin, isSubmitDisabled }}
        loading={loading}
        footer={
          <>
            <AuthButtonGroup
              style={{ justifyContent: 'space-between', display: 'flex' }}
              align="center"
              split={<Divider type="vertical" />}
            >
              <BlockTextButton
                onClick={onClickForgetPassword}
                label="비밀번호를 잊으셨어요?"
                width="172px"
                height="44px"
              />
              <BlockTextButton onClick={onClickJoin} label="관리자 회원가입" width="172px" height="44px" />
            </AuthButtonGroup>

            <BlockIconButton
              label="Google로 로그인"
              onClick={onClickGoogleLogin}
              icon={<GoogleIcon src="/images/google-logo.png" alt="google-logo" />}
            />
          </>
        }
      >
        <Form form={form} layout="vertical" requiredMark={false}>
          <AuthFormItem name="email" label="이메일" rules={[formRule.required, formRule.email]}>
            <AuthInput size="large" autoFocus onPressEnter={() => password.current?.focus()} />
          </AuthFormItem>
          <AuthFormItem name="password" label="비밀번호" rules={[formRule.required, formRule.password]}>
            <AuthInputPassword size="large" ref={password} type="password" onPressEnter={onClickLogin} />
          </AuthFormItem>
          <Form.Item shouldUpdate noStyle>
            {({ isFieldsTouched, getFieldsError }) => {
              const isSubmitDisabled =
                !isFieldsTouched(true) || getFieldsError().filter(({ errors }) => errors.length).length > 0
              setIsSubmitDisabled(isSubmitDisabled)
            }}
          </Form.Item>
        </Form>
      </LoginFormTemplate>
    </Background>
  )
}

const Background = styled.div`
  background: linear-gradient(0deg, rgba(172, 205, 175, 0.2), rgba(172, 205, 175, 0.2)), #ffffff;
`

const GoogleIcon = styled.img`
  position: absolute;
  left: 20px;
`

const AuthButtonGroup = styled(Space)`
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
`
