import { createStore, applyMiddleware, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'

import rootSaga from '@sagas/index'

import reducer, { RootAction, RootState } from '@reducers/index'

const saga = createSagaMiddleware()

export const store: Store<RootState, RootAction> = createStore(reducer, composeWithDevTools(applyMiddleware(saga)))
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const persistor = persistStore(store as any)

saga.run(rootSaga)
