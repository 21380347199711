import { Card } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { krw } from '@utils/format'

import { getHqGraphExpectPayments } from '@reducers/hq/graph.reducer'
import { RootState } from '@reducers/index'

import GraphLine from '@components/molecules/Graph/Line'
import Loading from '@components/molecules/Loading'

type Props = { hqId: number }

export default function GraphExpectPayments({ hqId }: Props): JSX.Element {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const expectPayments = useSelector((state: RootState) => state.hqGraph.expectPayments)

  const getData = async () => {
    setLoading(true)
    dispatch(
      getHqGraphExpectPayments(hqId, () => {
        setLoading(false)
      })
    )
  }

  useEffect(() => {
    if (hqId) getData()
  }, [hqId])

  const graphData = useMemo(
    () =>
      Object.entries(expectPayments).map(([id, datas]) => ({
        id,
        data: datas.map(({ month, price }) => ({ x: month, y: price })),
      })),
    [expectPayments]
  )

  return (
    <Card title="예상결제" size="small">
      <Loading loading={loading}>
        <GraphLine
          height="400px"
          data={graphData}
          axis={{
            left: { legend: '백만원', format: (v) => Number(v) / 1000000 },
            bottom: { legend: '월' },
          }}
          labelFormat={({ data }) => krw(String(data.y))}
        />
      </Loading>
    </Card>
  )
}
