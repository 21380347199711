import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { ProductSaleSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  setProductSalePagination,
  setProductSaleSearch,
  searchProductSales,
  setProductSaleDefault,
} from '@reducers/branch/product-sale.reducer'
import { RootState } from '@reducers/index'

import BranchProductSaleListTable from '@components/organisms/Branch/ProductSale/ListTable'
import ProductSaleExportModal from '@components/organisms/Branch/ProductSale/Modals/ProductSaleExportModal'
import ProductSaleRefundModal from '@components/organisms/Branch/ProductSale/Modals/ProductSaleRefundModal'
import BranchProductSaleSearchBox from '@components/organisms/Branch/ProductSale/SearchBox'
import BranchProductSaleTitle from '@components/organisms/Branch/ProductSale/Title'

export type BranchProductSaleAction = 'refund' | 'export'

export default function ProductSalePage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const { loading, search, pagination, searchResult, product } = useSelector((state: RootState) => state.productSale)

  const [refundModal, onVisibleRefundModal, onCloseRefundModal] = useModal<{ productSaleId: number }>({
    visible: false,
    productSaleId: 0,
  })
  const [exportModal, onVisibleExportModal, onCloseExportModal] = useModal({
    visible: false,
  })

  const onChangeSearch = (_search: Partial<ProductSaleSearch>) => dispatch(setProductSaleSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setProductSalePagination(_pagination))
    dispatch(searchProductSales(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setProductSalePagination({ current: 1 }))
    dispatch(searchProductSales(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setProductSaleDefault(hqId, branchId)), [hqId, branchId])
  const onDone = () => onResetAndSearch()

  const onAction = (action: BranchProductSaleAction, productSaleId?: number) => {
    if (action === 'refund') onVisibleRefundModal({ productSaleId })
    if (action === 'export') onVisibleExportModal()
  }

  useEffect(() => {
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchProductSaleSearchBox
        branchId={branchId}
        search={search}
        product={product}
        onChangeSearch={onChangeSearch}
        onSearch={onSearch}
      />

      <BranchProductSaleTitle onAction={onAction} />

      <BranchProductSaleListTable
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        productSales={searchResult.productSales}
        total={searchResult.total}
        onAction={onAction}
      />

      <ProductSaleRefundModal
        hqId={hqId}
        branchId={branchId}
        productSaleId={refundModal.productSaleId}
        visible={refundModal.visible}
        onClose={onCloseRefundModal}
        onDone={onDone}
      />

      <ProductSaleExportModal
        hqId={hqId}
        branchId={branchId}
        visible={exportModal.visible}
        onClose={onCloseExportModal}
      />
    </div>
  )
}
