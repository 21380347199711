import { ReactComponent as Info } from '@assets/Icons/helper.svg'
import { Button, Tooltip } from 'antd'
import { ReactNode } from 'react'
import styled from 'styled-components'

import Loading from '@components/molecules/Loading'

type Props = {
  title: string | ReactNode
  tooltip?: ReactNode
  footer?: ReactNode
  children: ReactNode
  loading?: boolean
  submit: {
    label: string
    onClick: () => void
    isDisabled?: boolean
  }
}

export default function AuthFormTemplate({ title, tooltip, footer, children, submit, loading }: Props): JSX.Element {
  return (
    <Wrapper>
      <Container>
        <Title>
          {title}
          {tooltip && (
            <Tooltip
              title={tooltip}
              placement="top"
              color="#232530"
              overlayInnerStyle={{ padding: '12px', width: '294px', borderRadius: '8px' }}
            >
              <InfoIcon />
            </Tooltip>
          )}
        </Title>
        <div>
          <Loading loading={loading}>
            <>
              {children}

              <SubmitButton
                type="primary"
                size="large"
                block
                onClick={submit.onClick}
                loading={loading}
                disabled={submit.isDisabled}
              >
                {submit.label}
              </SubmitButton>

              <FooterWrapper>{footer}</FooterWrapper>
            </>
          </Loading>
        </div>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  padding: 100px 0px;
  justify-content: center;
  background: linear-gradient(0deg, rgba(172, 205, 175, 0.2), rgba(172, 205, 175, 0.2)), #ffffff;
  background-repeat: repeat-y;
  background-size: cover;
`
const Container = styled.div`
  width: 530px;
  height: max-content;
  padding: 60px 72px;
  box-shadow: 0px 8px 22px -6px rgba(13, 13, 13, 0.12), 0px 14px 64px -4px rgba(13, 13, 13, 0.12);
  border-radius: 24px;
  background: #ffffff;
`

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-weight: 700;
  font-size: 34px;
  line-height: 41px;
  color: #232530;
  height: max-content;
`
const FooterWrapper = styled.div`
  max-width: 400px;
`
const SubmitButton = styled(Button)`
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  height: 52px;
  border-radius: 8px;
  background: #2e2e2e;
  border: none;
  :hover {
    background: #0d0d0d;
  }
  :focus {
    background: #0d0d0d;
  }
`
const InfoIcon = styled(Info)`
  cursor: pointer;
`
