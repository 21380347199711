import { useRouteMatch, Route, Switch } from 'react-router-dom'

import BranchRentalPage from '@pages/Branch/RentalPage'
import BranchRentalReservationPage from '@pages/Branch/RentalReservationPage'
import NotFoundPage from '@pages/Error/NotFoundPage'

export default function BranchRentalRouter(): JSX.Element {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${url}/list`}>
        <BranchRentalPage />
      </Route>
      <Route path={`${url}/reservation`}>
        <BranchRentalReservationPage />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  )
}
