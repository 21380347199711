import { Styles } from '@types'
import { Form, FormInstance, Input } from 'antd'
import { useEffect, useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { imageUpload } from '@utils/upload'

type Props = {
  name: string
  form: FormInstance
}

export default function TextEditor({ name, form }: Props): JSX.Element {
  const { getFieldValue, setFieldsValue } = form
  const quill = useRef<ReactQuill>(null)
  const inputFile = useRef<HTMLInputElement>(null)

  const onClickToolbarImage = () => {
    inputFile.current?.click()
  }

  const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files) return
    const file = e?.target?.files[0]
    if (!file) return
    const { uri } = await imageUpload(file, 'notice')
    onAttachImage(uri)
  }

  const onAttachImage = (uri: string) => {
    const editor = quill.current?.getEditor()
    if (!editor) return
    const selection = editor.getSelection()
    if (!selection) return
    editor.insertEmbed(selection.index, 'image', uri)
  }

  useEffect(() => {
    if (!quill.current) return
    const editor = quill.current.getEditor()
    const toolbar = editor.getModule('toolbar')
    toolbar.addHandler('image', onClickToolbarImage)
  }, [quill.current])

  return (
    <>
      <ReactQuill
        ref={quill}
        style={styles.quill}
        theme="snow"
        value={getFieldValue(name)}
        onChange={(content) => setFieldsValue({ [name]: content })}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ align: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ color: [] }, { background: [] }],
            ['link', 'image'],
            ['clean'],
          ],
        }}
      />
      <input hidden type="file" ref={inputFile} accept="images/*" multiple={false} onChange={onChangeFile} />
      <Form.Item name={name} hidden>
        <Input />
      </Form.Item>
    </>
  )
}

const styles: Styles = {
  quill: { height: '500px' },
}
