import { BREAKPOINTS } from '@const'
import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  text: string | ReactNode
}

export default function SectionTitle({ text }: Props): JSX.Element {
  return <Title>{text}</Title>
}

const Title = styled.h2`
  font-weight: 700;
  font-size: 45px;
  line-height: 140%;
  text-align: center;
  color: #2e2e2e;
  padding-bottom: 50px;
  @media ${BREAKPOINTS.lg} {
    font-size: 26px;
    padding-bottom: 32px;
    margin-bottom: 0;
  }
`
