import { Route, Switch } from 'react-router-dom'

import ForgetPasswordPage from '@pages/ForgetPasswordPage'
import JoinPage from '@pages/JoinPage'
import BusinessPage from '@pages/Landing/BusinessPage'
import ContactPage from '@pages/Landing/ContactPage'
import ContractFeaturePage from '@pages/Landing/FeaturePages/pages/ContractFeaturePage'
import InvoiceFeaturePage from '@pages/Landing/FeaturePages/pages/InvoiceFeaturePage'
import SpaceFeaturePage from '@pages/Landing/FeaturePages/pages/SpaceFeaturePage'
import StoreFeaturePage from '@pages/Landing/FeaturePages/pages/StoreFeaturePage'
import HomePage from '@pages/Landing/HomePage'
import LandingTemplate from '@pages/Landing/Index'
import PricingPage from '@pages/Landing/PricingPage'
import Header from '@pages/Landing/organisms/Header'
import LoginPage from '@pages/LoginPage'
import ResetPasswordPage from '@pages/ResetPasswordPage'

export default function UnauthorizedRoutes(): JSX.Element {
  return (
    <Switch>
      <Route path={['/join', '/login', '/forget-password', '/reset-password']}>
        <Header />
        <Switch>
          <Route exact path="/join">
            <JoinPage />
          </Route>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/forget-password">
            <ForgetPasswordPage />
          </Route>
          <Route exact path="/reset-password">
            <ResetPasswordPage />
          </Route>
        </Switch>
      </Route>
      <Route
        path={[
          '/',
          '/features/contracts',
          '/features/invoices',
          '/features/spaces',
          '/features/stores',
          '/pricing',
          '/contact',
          '/business',
        ]}
      >
        <LandingTemplate>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route exact path="/contact">
              <ContactPage />
            </Route>
            <Route exact path="/business">
              <BusinessPage />
            </Route>
            <Route exact path="/pricing">
              <PricingPage />
            </Route>
            <Route exact path="/features/contracts">
              <ContractFeaturePage />
            </Route>
            <Route exact path="/features/invoices">
              <InvoiceFeaturePage />
            </Route>
            <Route exact path="/features/spaces">
              <SpaceFeaturePage />
            </Route>
            <Route exact path="/features/stores">
              <StoreFeaturePage />
            </Route>
          </Switch>
        </LandingTemplate>
      </Route>
    </Switch>
  )
}
