import { MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'

export type ContractRowAction =
  | 'form-memo'
  | 'form-deposit'
  | 'update'
  | 'extend'
  | 'suspend'
  | 'delete'
  | 'invite'
  | 'signature-status'
  | 'deposit'

export default function More({ contract, onRowAction }: ContractColumnProps): JSX.Element {
  const menu = (
    <Menu>
      <Menu.Item key="invite" onClick={() => onRowAction('invite', contract)}>
        입주사 초대
      </Menu.Item>
      <Menu.Item key="signature-status" onClick={() => onRowAction('signature-status', contract)}>
        서명 요청
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="memo" onClick={() => onRowAction('form-memo', contract)}>
        메모
      </Menu.Item>
      <Menu.Item key="deposit" onClick={() => onRowAction('form-deposit', contract)}>
        보증금
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="update" onClick={() => onRowAction('update', contract)}>
        수정
      </Menu.Item>
      <Menu.Item key="extend" onClick={() => onRowAction('extend', contract)}>
        연장동의
      </Menu.Item>
      <Menu.Item key="suspend" onClick={() => onRowAction('suspend', contract)}>
        종료
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="delete" onClick={() => onRowAction('delete', contract)}>
        삭제
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Button icon={<MoreOutlined />} size="large" type="text" />
    </Dropdown>
  )
}
