import { Row } from 'antd'
import { ExpenditureSummary } from 'gadjet-v2-types/dist/type'
import { useMemo } from 'react'

import { krw } from '@utils/format'

import ContentWrapper from '@components/atoms/ContentWrapper'
import SummaryItem from '@components/molecules/SummaryItem'

type Props = {
  summary: ExpenditureSummary
}

export default function BranchExpenditureSummary({ summary }: Props): JSX.Element {
  const items = useMemo(
    () => [
      { key: 'total', label: '총 지출', value: krw(summary.total) },
      { key: 'card', label: '카드', value: krw(summary.card) },
      { key: 'transfer', label: '계좌이체', value: krw(summary.transfer) },
      { key: 'cash', label: '현금', value: krw(summary.cash) },
      { key: 'commission', label: '결제수수료', value: krw(summary.commission) },
    ],
    [summary]
  )

  return (
    <ContentWrapper>
      <Row>
        {items.map(({ key, label, value }) => (
          <SummaryItem key={key} label={label} value={value} span={4} />
        ))}
      </Row>
    </ContentWrapper>
  )
}
