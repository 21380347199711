/* eslint-disable react/jsx-props-no-spreading, react/destructuring-assignment */
import { Button, Modal, ModalProps } from 'antd'

type Props = ModalProps & { children: JSX.Element }

export default function ModalAlert(props: Props): JSX.Element {
  return (
    <Modal
      width="600px"
      footer={[
        <Button key="close" onClick={props.onCancel}>
          닫기
        </Button>,
      ]}
      {...props}
    >
      {props.children}
    </Modal>
  )
}
