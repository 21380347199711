import { DownloadOutlined } from '@ant-design/icons'

import { BranchTenantAction } from '@pages/Branch/TenantPage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchTenantAction) => void
}

export default function BranchTenantTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [
    { label: '다운로드', buttonType: 'dashed', icon: <DownloadOutlined />, onClick: () => onAction('export') },
  ]

  return <PageTitle title="입주사" actions={actions} />
}
