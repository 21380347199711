import { notification } from 'antd'

import EventAPI from '@apis/branch/event'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  eventId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function EventDeleteModal({ hqId, branchId, eventId, visible, onClose, onDone }: Props): JSX.Element {
  const onConfirm = async () => {
    if (!eventId) return
    await EventAPI.deleteEvent({ hqId, branchId, eventId })
    notification.success({ message: '이벤트가 삭제되었습니다.' })
  }

  return (
    <ConfirmModal
      title="이벤트 삭제"
      description="이벤트를 삭제하시기 전에 모든 참가자가 신청을 취소했는지 확인하세요. 이벤트를 삭제하면 관련 내역도 삭제됩니다."
      okText="삭제"
      visible={visible}
      onConfirm={onConfirm}
      onClose={onClose}
      onDone={onDone}
    />
  )
}
