import { Card } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { krw } from '@utils/format'

import { getHqGraphPayments } from '@reducers/hq/graph.reducer'
import { RootState } from '@reducers/index'

import GraphLine from '@components/molecules/Graph/Line'
import Loading from '@components/molecules/Loading'

type Props = { hqId: number }
export default function HqGraphPayments({ hqId }: Props): JSX.Element {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { payments, expectPayments, expenditure } = useSelector((state: RootState) => state.hqGraph.payments)

  const getData = () => {
    setLoading(true)
    dispatch(
      getHqGraphPayments(hqId, () => {
        setLoading(false)
      })
    )
  }

  useEffect(() => {
    if (hqId) getData()
  }, [hqId])

  const graphData = useMemo(
    () => [
      {
        id: '매출',
        data: payments.map(({ month, price }) => ({ x: month, y: price })),
      },
      {
        id: '예상매출',
        data: expectPayments.map(({ month, price }) => ({ x: month, y: price })),
      },
      {
        id: '지출',
        data: expenditure.map(({ month, price }) => ({ x: month, y: price })),
      },
      {
        id: '매출-지출',
        data: payments.map(({ month, price }, index) => ({ x: month, y: price - expenditure[index].price })),
      },
    ],
    [payments, expectPayments, expenditure]
  )

  return (
    <Card title="매출" size="small">
      <Loading loading={loading}>
        <GraphLine
          height="400px"
          data={graphData}
          axis={{
            left: { legend: '백만원', format: (v) => Number(v) / 1000000 },
            bottom: { legend: '월' },
          }}
          labelFormat={({ data }) => krw(String(data.y))}
        />
      </Loading>
    </Card>
  )
}
