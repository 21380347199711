import { Styles } from '@types'
import { Button, Table, TableColumnType, Typography } from 'antd'
import { Floors } from 'gadjet-v2-types/dist/model'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FloorAPI from '@apis/branch/floor'

import { getBranch } from '@reducers/branch/branch.reducer'
import { RootState } from '@reducers/index'

import Loading from '@components/molecules/Loading'
import ModalAlert from '@components/molecules/ModalAlert'

type Props = {
  hqId: number
  branchId: number
  visible: boolean
  onClose: () => void
}

export default function FloorListModal({ hqId, branchId, visible, onClose }: Props): JSX.Element {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const floors = useSelector((state: RootState) => state.branch.floors || [])

  const onAddFloor = async () => {
    setLoading(true)
    await FloorAPI.addFloor({ hqId, branchId }, { floor: { name: '' } })
    getBrach()
  }

  const updateFloor = async (floorId: number, floor: Floors) => {
    setLoading(true)
    await FloorAPI.updateFloor({ hqId, branchId, floorId }, { floor })
    getBrach()
  }

  const getBrach = () => {
    dispatch(
      getBranch(hqId, branchId, () => {
        setLoading(false)
      })
    )
  }

  const columns: TableColumnType<Floors>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: '이름',
      render: (name: string, s) => (
        <Typography.Text
          editable={{
            onChange: (_name) => updateFloor(s.floorId, { ...s, name: _name }),
          }}
        >
          {name}
        </Typography.Text>
      ),
    },
  ]

  return (
    <ModalAlert title="층/구역" visible={visible} onCancel={onClose}>
      <Loading loading={loading}>
        <Table
          title={() => (
            <div style={styles.title}>
              <Button onClick={onAddFloor} size="small">
                층/구역 추가
              </Button>
            </div>
          )}
          dataSource={floors}
          columns={columns}
        />
      </Loading>
    </ModalAlert>
  )
}

const styles: Styles = {
  title: { display: 'flex', justifyContent: 'flex-end' },
}
