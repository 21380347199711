import { BIZ_BREAKPOINTS, GADJET_BIZ_COLORS } from '@const'
import { useForm } from 'antd/lib/form/Form'
import { useState } from 'react'
import styled from 'styled-components'

import ContactAPI, { ContactSales } from '@apis/contact'

import toast from '@components/atoms/Toast'

import ContactForm from '../organisms/ContactForm'

export default function ContactPage() {
  const [form] = useForm<ContactSales>()
  const [isEtcChecked, setIsEtcChecked] = useState<boolean>(false)
  const [etcFeature, setEtcFeature] = useState<string>('')

  const onClick = async () => {
    try {
      const values = await form.validateFields()
      const interestedFeatureName = form.getFieldValue('interestedFeatureName')
      const interestedFeatureWithEtc = [...interestedFeatureName.filter((item: string) => item !== 'etc'), etcFeature]
      const data = await ContactAPI.contactSales(
        !isEtcChecked
          ? values
          : {
              ...values,
              interestedFeatureName: interestedFeatureWithEtc,
            }
      )
      if (data) {
        toast.success('문의가 등록되었습니다')
        form.resetFields()
        window.scrollTo({ top: 0 })
      }
    } catch (error) {
      // console.log(error)
    }
  }
  return (
    <Wrapper>
      <Header>
        <h1>영업팀에 문의하세요.</h1>
        <p>
          가젯 비즈니스 영엄팀은 더 효율적인 부동산 경영에 함께하기 위해 준비하고 있습니다. <br />
          문의를 남겨주시면 가젯 비즈니스 담당자가 곧 연락드리겠습니다.
        </p>
      </Header>
      <MainWrapper>
        <FormWrapper>
          <ContactForm
            form={form}
            isEtcChecked={isEtcChecked}
            onSubmit={onClick}
            setIsEtcChecked={setIsEtcChecked}
            setEtcFeature={setEtcFeature}
          />
        </FormWrapper>
      </MainWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const Header = styled.div`
  width: 100%;
  background: #fff;

  padding: 160px 100px 60px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid ${GADJET_BIZ_COLORS.primary};

  @media ${BIZ_BREAKPOINTS.lg} {
    padding: 106px 16px 60px 16px;
  }
  h1 {
    text-align: center;
    color: ${GADJET_BIZ_COLORS.primary};
    margin-bottom: 26px;
    font-weight: 700;
    font-size: 45px;
    line-height: 140%;
    @media ${BIZ_BREAKPOINTS.lg} {
      font-size: 30px;
      letter-spacing: -0.6px;
    }
  }

  p {
    text-align: center;
    color: ${GADJET_BIZ_COLORS.primary};
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    @media ${BIZ_BREAKPOINTS.lg} {
      text-align: start;
      font-size: 15px;
    }
  }
`
const MainWrapper = styled.div`
  width: 100%;
  background: #f1f3f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 100px 120px 100px;

  @media ${BIZ_BREAKPOINTS.lg} {
    padding: 60px 16px 60px 16px;
  }
`

const FormWrapper = styled.div`
  width: 100%;
  max-width: 400px;
`
