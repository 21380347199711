import { Styles } from '@types'
import { Alert, Form, Input, notification, Select, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Payments } from 'gadjet-v2-types/dist/model'
import { useEffect, useMemo, useState } from 'react'

import formRule from '@utils/formRule'

import PaymentAPI from '@apis/branch/payment'

import ConfirmModal from '@components/molecules/ConfirmModal'
import bankCodes from '@components/organisms/Branch/Contract/Modals/UploadModal/columns/bankCodes'

type Account = { number: string; bankCode: string; holder: string }

type Props = {
  hqId: number
  branchId: number
  paymentId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function PaymentRefundModal({
  hqId,
  branchId,
  paymentId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const [form] = useForm<Account>()
  const [payment, setPayment] = useState<Payments>()
  const [loading, setLoading] = useState(false)

  const isVBank = useMemo(() => !!payment && payment.type === 'direct', [payment])
  const initialValues = { number: '', bankCode: '', holder: '' }

  const onConfirm = async (): Promise<boolean> => {
    if (!paymentId) return false
    try {
      const account = await form.validateFields()
      const { data } = await PaymentAPI.refundPayment({ hqId, branchId, paymentId }, { account })
      const { isSuccess, message } = data

      if (isSuccess) notification.success({ message })
      if (!isSuccess) notification.error({ message })

      return isSuccess
    } catch (err) {
      return false
    }
  }

  const getPayment = async () => {
    if (!paymentId) return
    setLoading(true)
    const { data } = await PaymentAPI.getPaymentDetail({ hqId, branchId, paymentId })
    setPayment(data)
    setLoading(false)
  }

  useEffect(() => {
    if (visible) {
      getPayment()
      form.resetFields()
    }
  }, [visible, paymentId])

  const Description = () => {
    if (!isVBank) return null
    return (
      <div>
        <Alert
          type="warning"
          message={
            <div>
              결제금액 중 입금 수수료와 환불 수수료를 제외한 금액이 환불됩니다.
              <br />
              <Typography.Text type="secondary">입금 수수료: 250원 (부가세 별도)</Typography.Text>
              <br />
              <Typography.Text type="secondary">환불 수수료: 250원 (부가세 별도)</Typography.Text>
            </div>
          }
        />

        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={initialValues}
          style={style.form}
        >
          <Form.Item name="bankCode" label="은행" rules={[formRule.required]}>
            <Select options={bankCodes} />
          </Form.Item>
          <Form.Item name="holder" label="예금주" rules={[formRule.required]}>
            <Input />
          </Form.Item>
          <Form.Item name="number" label="계좌번호" rules={[formRule.required, formRule.number]}>
            <Input />
          </Form.Item>
        </Form>
      </div>
    )
  }

  return (
    <ConfirmModal
      visible={visible}
      title="환불하기"
      description={isVBank && <Description />}
      okText="환불"
      onDone={onDone}
      onClose={onClose}
      onConfirm={onConfirm}
      loading={loading}
    />
  )
}

const style: Styles = {
  form: { marginTop: '40px' },
}
