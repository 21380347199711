import * as HQ from 'gadjet-v2-types/dist/api/hq'

import axios from '@utils/axios'

const HqAPI = {
  // 운영사 조회
  getHqDetail: async ({ hqId }: HQ.GET_DETAIL_PARAMS): Promise<{ data: HQ.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}`),

  // 운영사 수정
  updateHq: async ({ hqId }: HQ.UPDATE_PARAMS, { hq }: HQ.UPDATE_BODY): Promise<{ data: HQ.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}`, { hq }),
}

export default HqAPI
