import { Button, ButtonProps } from 'antd'
import styled from 'styled-components'

export default function SendCodeButton(props: ButtonProps): JSX.Element {
  return <CustomButton {...props}>코드발송</CustomButton>
}

const CustomButton = styled(Button)`
  width: 69px;
  height: 32px;
  background: #2e2e2e;
  border: none;
  border-radius: 8px;
  font-weight: 300;
  font-size: 13px;
  :hover {
    background: #0d0d0d;
  }
  :focus {
    background: #0d0d0d;
  }
`
