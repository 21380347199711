import { Styles } from '@types'
import { Col } from 'antd'
import { ReactNode } from 'react'
import styled from 'styled-components'

import HelpTooltip from '@components/atoms/HelpTooltip'

type Props = {
  label: string
  span?: number
  helpText?: ReactNode
  children: JSX.Element
}

export default function SearchItem({ label, span, helpText, children }: Props): JSX.Element {
  return (
    <Col style={styles.col} span={span}>
      <LabelWrapper>
        <div style={styles.label}>{label}</div>
        {helpText && <HelpTooltip title={helpText} placement="topLeft" />}
      </LabelWrapper>

      <div style={styles.childrenWrapper}>{children}</div>
    </Col>
  )
}

const styles: Styles = {
  col: {},
  label: { opacity: 0.6, fontSize: '12px' },
  childrenWrapper: {},
}

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`
