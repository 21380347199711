import { Styles } from '@types'
import { Button } from 'antd'
import { ContractDepositStatus } from 'gadjet-v2-types/dist/type'
import { contractDepositStatus } from 'gadjet-v2-types/dist/type/label'

import TypeTag from '@components/molecules/TypeTag'

export default function Deposit({ contract, onRowAction }: ContractColumnProps): JSX.Element {
  const { contractDeposit } = contract

  if (!contractDeposit) return <div />

  const tag = (
    <TypeTag<ContractDepositStatus>
      label={contractDepositStatus[contractDeposit.status]}
      value={contractDeposit.status}
      switchCase={{
        values: [['paid', 'returned'], ['unpaid']],
        types: ['success', 'fail'],
      }}
      style={style.tag}
    />
  )

  return <Button onClick={() => onRowAction('deposit', contract)}>{tag}</Button>
}

const style: Styles = {
  tag: { margin: '0' },
}
