import { Styles } from '@types'
import { Modal, Form, Input, notification, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { Expenditures } from 'gadjet-v2-types/dist/model'
import { expenditureType } from 'gadjet-v2-types/dist/type/label'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import formRule from '@utils/formRule'

import ExpenditureAPI from '@apis/branch/expenditure'

import { RootState } from '@reducers/index'

import HiddenItems from '@components/atoms/Form/HiddenItems'
import InputPrice from '@components/atoms/InputPrice'
import LocalDatePicker from '@components/atoms/LocalDatePicker'
import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  expenditureId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function ExpenditureFormModal({
  hqId,
  branchId,
  expenditureId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const [form] = useForm<Expenditures>()
  const [loading, setLoading] = useState(false)
  const manager = useSelector((state: RootState) => state.auth.manager)
  const option = useSelector((state: RootState) => state.option)

  const onOk = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      if (expenditureId)
        await ExpenditureAPI.updateExpenditure({ hqId, branchId, expenditureId }, { expenditure: values })
      else await ExpenditureAPI.addExpenditure({ hqId, branchId }, { expenditure: values })

      notification.success({ message: '저장되었습니다.' })

      setLoading(false)
      onDone()
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const getExpenditure = async () => {
    if (!expenditureId) return
    setLoading(true)
    const { data } = await ExpenditureAPI.getExpenditureDetail({ hqId, branchId, expenditureId })
    form.setFieldsValue(data)
    setLoading(false)
  }

  const reset = () => {
    form.resetFields()
  }

  useEffect(() => {
    if (visible) {
      reset()
      getExpenditure()
    }
  }, [visible])

  const initialValues: Partial<Expenditures> = {
    managerId: manager?.managerId,
    managerName: manager?.name,
    date: dayjs().format('YYYY-MM-DD'),
    memo: '',
    type: 'card',
  }

  return (
    <Modal
      title="지출"
      okText="저장"
      onOk={onOk}
      confirmLoading={loading}
      visible={visible}
      onCancel={onClose}
      maskClosable={false}
    >
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item
            label="날짜"
            name="date"
            getValueProps={(d) => ({ value: dayjs(d) })}
            getValueFromEvent={(_, d) => d}
            required
            rules={[formRule.required]}
          >
            <LocalDatePicker />
          </Form.Item>
          <Form.Item label="금액" name="price" required rules={[formRule.required]}>
            <InputPrice addonAfter="원" />
          </Form.Item>
          <Form.Item label="타입" name="type" required rules={[formRule.required]}>
            <Select>
              {option.expenditureTypes.map((type) => (
                <Select.Option value={type} key={type}>
                  {expenditureType[type]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="메모" name="memo">
            <Input.TextArea style={styles.textarea} />
          </Form.Item>
          <HiddenItems names={['managerId', 'managerName']} />
        </Form>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  textarea: { height: '200px' },
}
