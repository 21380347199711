import { Managers } from 'gadjet-v2-types/dist/model'

// ActionType
export const GET_AUTH = 'auth/GET_AUTH' as const
export const GET_AUTH_SUCCESS = 'auth/GET_AUTH_SUCCESS' as const
export const GET_AUTH_FAIL = 'auth/GET_AUTH_FAIL' as const
export const LOGOUT = 'auth/LOGOUT' as const
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS' as const

// Action
export const getAuth = () => ({
  type: GET_AUTH,
  payload: {},
})
export const getAuthSuccess = (manager: Managers | null) => ({
  type: GET_AUTH_SUCCESS,
  payload: { manager },
})
export const getAuthFail = () => ({
  type: GET_AUTH_FAIL,
  payload: {},
})
export const logout = () => ({
  type: LOGOUT,
  payload: {},
})
export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
  payload: {},
})

export type AuthAction =
  | ReturnType<typeof getAuth>
  | ReturnType<typeof getAuthSuccess>
  | ReturnType<typeof getAuthFail>
  | ReturnType<typeof logout>
  | ReturnType<typeof logoutSuccess>

type AuthState = {
  loading: boolean
  manager: null | Managers
}

const authState: AuthState = {
  loading: true,
  manager: null,
}

const authReducer = (state = authState, action: AuthAction): AuthState => {
  switch (action.type) {
    case GET_AUTH:
      return { ...state, loading: true }
    case GET_AUTH_SUCCESS:
      return { ...state, loading: false, manager: action.payload.manager }
    case GET_AUTH_FAIL:
      return { ...state, loading: false }
    case LOGOUT:
      return { ...state, loading: true }
    case LOGOUT_SUCCESS:
      return { ...state, loading: false, manager: null }
    default:
      return state
  }
}

export default authReducer
