import { CloudDownloadOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Button } from 'antd'
import { ContractSignatureStatus } from 'gadjet-v2-types/dist/type'
import { contractSignatureStatus } from 'gadjet-v2-types/dist/type/label'

import TypeTag from '@components/molecules/TypeTag'

export default function SignatureStatus({ contract }: ContractColumnProps): JSX.Element {
  const { documentFile, signatureStatus } = contract

  const onClick = () => {
    if (!documentFile) return
    window.open(documentFile.uri, '_blank')
  }

  return (
    <Button
      onClick={onClick}
      disabled={!documentFile}
      icon={documentFile ? <CloudDownloadOutlined style={styles.icon} /> : undefined}
    >
      <TypeTag<ContractSignatureStatus>
        label={contractSignatureStatus[signatureStatus]}
        value={signatureStatus}
        switchCase={{
          values: [['none'], ['signed'], ['waiting']],
          types: ['default', 'success', 'ongoing'],
        }}
        style={styles.tag}
      />
    </Button>
  )
}

const styles: Styles = {
  tag: { margin: 0 },
  icon: { marginRight: '10px' },
}
