import { Styles } from '@types'
import { Input } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { NoticeSearch } from 'gadjet-v2-types/dist/type/search'
import { RangeValue } from 'rc-picker/lib/interface'
import { useMemo } from 'react'

import LocalDatePicker from '@components/atoms/LocalDatePicker'
import SearchBox from '@components/molecules/SearchBox'
import SearchItem from '@components/molecules/SearchItem'

type Props = {
  search: NoticeSearch
  onChangeSearch: (search: Partial<NoticeSearch>) => void
  onSearch: () => void
}

export default function BranchNoticeSearchBox({ search, onChangeSearch, onSearch }: Props): JSX.Element {
  const onChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => onChangeSearch({ query: e.target.value })

  const onChangeDateRange = (_value: RangeValue<Dayjs>) => {
    const start = _value?.[0]?.format('YYYY-MM-DD') || search.writeDate[0]
    const end = _value?.[1]?.format('YYYY-MM-DD') || search.writeDate[1]
    onChangeSearch({ writeDate: [start, end] })
  }

  const rangeValue = useMemo((): [Dayjs, Dayjs] => {
    const [start, end] = search.writeDate
    return [dayjs(start), dayjs(end)]
  }, [search])
  return (
    <SearchBox onSearch={onSearch}>
      <SearchItem label="날짜" span={9}>
        <LocalDatePicker.RangePicker
          size="large"
          style={styles.datePicker}
          value={rangeValue}
          onChange={onChangeDateRange}
        />
      </SearchItem>
      <SearchItem label="검색어" span={6}>
        <Input size="large" placeholder="검색어를 입력하세요." value={search.query} onChange={onChangeQuery} />
      </SearchItem>
    </SearchBox>
  )
}

const styles: Styles = {
  datePicker: { width: '100%' },
}
