import PaymentAPI from '@apis/branch/payment'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  paymentId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function PaymentDeleteModal({
  hqId,
  branchId,
  paymentId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    if (!paymentId) return
    await PaymentAPI.deletePayment({ hqId, branchId, paymentId })
  }

  return (
    <ConfirmModal
      visible={visible}
      title="결제내역이 삭제됩니다."
      okText="삭제"
      onDone={onDone}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  )
}
