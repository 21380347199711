import { DownloadOutlined } from '@ant-design/icons'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

export type BranchContractActionType = 'deposit-export' | 'contract-export' | 'upload' | 'automation-config' | 'add'

type Props = {
  onAction: (type: BranchContractActionType) => void
}

export default function BranchContractTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [
    {
      label: '보증금 다운로드',
      buttonType: 'dashed',
      icon: <DownloadOutlined />,
      onClick: () => onAction('deposit-export'),
    },
    {
      label: '계약 다운로드',
      buttonType: 'dashed',
      icon: <DownloadOutlined />,
      onClick: () => onAction('contract-export'),
    },
    { label: '업로드', buttonType: 'dashed', onClick: () => onAction('upload') },
    // { label: '자동화 설정', buttonType: 'default', onClick: () => onAction('automation-config') },
    { label: '계약 추가', buttonType: 'primary', onClick: () => onAction('add') },
  ]
  return <PageTitle title="계약" actions={actions} />
}
