import * as NOTICE from 'gadjet-v2-types/dist/api/branch/notice'

import axios from '@utils/axios'

const NoticeAPI = {
  // 공지사항 조회
  getNotices: async (
    { branchId, hqId }: NOTICE.GET_LIST_PARAMS,
    query: NOTICE.GET_LIST_QUERY
  ): Promise<{ data: NOTICE.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/notices`, { params: query }),

  // 공지사항 상세 조회
  getNoticeDetail: async ({
    hqId,
    branchId,
    noticeId,
  }: NOTICE.GET_DETAIL_PARAMS): Promise<{ data: NOTICE.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/notices/${noticeId}`),

  // 공지사항 추가
  addNotice: async (
    { hqId, branchId }: NOTICE.ADD_PARAMS,
    { notice }: NOTICE.ADD_BODY
  ): Promise<{ data: NOTICE.ADD_RESPONSE }> => axios.post(`/hqs/${hqId}/branches/${branchId}/notices`, { notice }),

  // 공지사항 수정
  updateNotice: async (
    { hqId, branchId, noticeId }: NOTICE.UPDATE_PARAMS,
    { notice }: NOTICE.UPDATE_BODY
  ): Promise<{ data: NOTICE.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/notices/${noticeId}`, { notice }),

  // 공지사항 삭제
  deleteNotice: async ({ hqId, branchId, noticeId }: NOTICE.DELETE_PARAMS): Promise<{ data: NOTICE.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/notices/${noticeId}`),
}

export default NoticeAPI
