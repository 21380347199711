import { BREAKPOINTS } from '@const'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'

import CollapsibleItem from '@pages/Landing/PricingPage/atoms/CollapsibleItem'

export default function FAQ(): JSX.Element {
  const faq: { tab: string; key: string; faqList: { question: string; answer: string | ReactNode }[] }[] = [
    {
      tab: '서비스 도입',
      key: 'subscription',
      faqList: [
        {
          question: '가젯은 무료 사용기간을 제공하나요?',
          answer:
            '운영사가 계정을 만든 날로부터 60일까지 사용료를 청구하지 않습니다. 61일째 되는 날 등록된 결제정보로 자동 결제가 진행됩니다. 만약 결제를 원하지 않으실 경우 결제 정보를 삭제해 주세요.',
        },
        {
          question: '지점이란 무엇인가요?',
          answer: (
            <AnswerList>
              <li>
                운영사가 관리하는 단위를 지칭합니다. 보편적으로 계약서 상에 기재하는 건물 혹은 공간의 주소를 기준으로
                지점으로 정의합니다. 다만 운영사가 제공하는 서비스의 종류와 형태에 따라 그 단위는 달라질 수 있습니다.
              </li>
              <li>
                가젯의 서비스 이용료와 계약 관리 비용은 지점당 청구됩니다. 기본 서비스 이용료에는 월 100건의 계약 관리
                비용이 포함되어 있습니다.
              </li>
            </AnswerList>
          ),
        },
        {
          question: '가젯 설정 및 이용 교육을 지원받을 수 있나요?',
          answer:
            '가젯팀은 운영사의 원활한 도입을 위해 준비하고 있습니다. 초기 운영팀이 도입 최초 30일동안 집중해 운영을 지원합니다. 이후 60일까지 운영사의 운영 주기 및 상황에 맞추어 공유공간 운영 전략 컨설팅을 제공합니다.',
        },
        {
          question: '서비스의 해지와 환불은 어떻게 진행되나요?',
          answer: (
            <AnswerList>
              <li>모든 플랜은 유료 사용 기간 시작 7일 이내 해지 시 전액 환불 됩니다.</li>
              <li>유료 사용 기간 시작 7일 이후 서비스 해지를 원하시는 경우 각 플랜의 정책에 따릅니다.</li>
            </AnswerList>
          ),
        },
      ],
    },
    {
      tab: '비용/정산/결제',
      key: 'payment',
      faqList: [
        {
          question: '계약 관리 비용은 어떻게 책정되나요?',
          answer:
            '기본 서비스 이용료에 100건의 계약 관리 비용이 포함되어있습니다. 계약 관리 비용은 101건 이상 부터해당 월에 지점에 활성화된 계약 수를 기준으로 청구됩니다.',
        },
        {
          question: '서비스 무료 이용기간에 결제 정보를 입력해야하는 이유는 무엇인가요?',
          answer:
            "가젯은 운영사의 청구, 결제 및 증빙 업무를 더 빠르게 할 수 있도록 '청구' 및 '증빙' 기능을 제공하고 있습니다. 운영사가 입력한 결제정보(팝빌ID, 계좌 정보, 카드 정보 등)를 통해 이용자의 결제 후 정산 진행, 환불 등의 업무를 지원합니다.",
        },
        {
          question: '결제 수수료는 어떻게 계산되나요?',
          answer: (
            <AnswerList>
              <li>
                결제 수수료는 결제 플랫폼으로 지불되는 비용입니다. 결제 수단에 따라 최소 1% ~ 최대 5% 사이에서 부여되며,
                결제액 대비 비율로 수수료가 발생됩니다.
              </li>
              <li>
                정산 진행 시에는 총 결제액에서 결제 수수료를 제하여 정산되며, 결제 수수료는 결제 수단 제공사에
                지불됩니다.
              </li>
            </AnswerList>
          ),
        },
        {
          question: '증빙 발행 수수료는 어떻게 되나요?',
          answer:
            '운영사는 팝빌에 회원가입하고 그 ID로 증빙을 발행할 수 있습니다. 현금영수증과 세금계산서의 발행 수수료는 가젯이 부담합니다.',
        },
      ],
    },
    {
      tab: '이용 지원',
      key: 'support',
      faqList: [
        {
          question: '모바일앱을 사용할 수 있나요?',
          answer:
            '입주사 혹은 이용자는 가젯의 모든 서비스를 모바일 앱으로 이용할 수 있습니다. 가젯 모바일 앱은 주요 모바일 앱 스토어에서 다운로드 받을 수 있으며, 운영사 및 입주사 관리자의 초대 코드가 있어야 앱을 활성화할 수 있습니다.',
        },
        {
          question: '가젯 이용 중 도움이 필요한 경우엔 어떻게 해야하나요?',
          answer:
            '가젯 우측 하단에 붙어있는 메신저를 통해 평일 업무시간 중 실시간으로 전문 고객지원팀이 응대해드립니다.',
        },
        {
          question: '별도로 필요한 기능이 있을 경우엔 추가도 가능할까요?',
          answer: (
            <AnswerList>
              <li>
                즉각적인 기능 개발이 필요하신 경우 Enterprise 플랜을 통해 문의해주시면 더 상세한 논의를 하실 수
                있습니다.
              </li>
              <li>
                기능 추가에 대한 제안일 경우 가젯 우측 하단 메신저로 고객지원팀에 알려주시면 적극 검토하여 가능한
                반영하겠습니다.
              </li>
            </AnswerList>
          ),
        },
      ],
    },
  ]

  const [selectedTab, setSelectedTab] = useState(faq[0].tab)
  const [currentList, setCurrentList] = useState(faq[0].faqList)

  return (
    <Wrapper>
      <Title>자주 묻는 질문</Title>
      <InnerWrapper>
        <TabGroup>
          {faq.map((item) => (
            <Tab
              isSelected={item.tab === selectedTab}
              key={item.key}
              onClick={() => {
                setCurrentList(item.faqList)
                setSelectedTab(item.tab)
              }}
            >
              {item.tab}
            </Tab>
          ))}
        </TabGroup>
        <CollapsibleList>
          {currentList.map(({ answer, question }) => (
            <CollapsibleItem key={question} title={question} content={answer} />
          ))}
        </CollapsibleList>
      </InnerWrapper>
    </Wrapper>
  )
}

type StyleProps = {
  isSelected: boolean
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 120px;
`

const Title = styled.h2`
  width: max-content;
  font-weight: 700;
  font-size: 35px;
  line-height: 140%;
  text-align: center;
  color: #2e2e2e;

  margin-bottom: 40px;
  @media ${BREAKPOINTS.sm} {
    font-size: 25px;
    margin-bottom: 28px;
  }
`

const InnerWrapper = styled.div`
  width: 860px;
  @media ${BREAKPOINTS.lg} {
    width: 100%;
    padding: 0px 80px;
  }
  @media ${BREAKPOINTS.sm} {
    min-width: 343px;
    padding: 0px 16px;
  }
`

const TabGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #2e2e2e;
`

const Tab = styled.button`
  font-weight: ${({ isSelected }: StyleProps) => (isSelected ? 700 : 500)};
  font-size: 18px;
  line-height: 140%;
  color: ${({ isSelected }: StyleProps) => (isSelected ? '#2E2E2E' : '#8d9297')};
  border: none;
  background: transparent;
  padding: 0px;
  cursor: pointer;
  :hover {
    transition: all 0.3s ease-in-out;
    color: #2e2e2e;
  }
`
const AnswerList = styled.ul`
  list-style: disc;
  margin: 0px;
  padding: 0px 0px 0px 20px;
`
const CollapsibleList = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style: none;
`
