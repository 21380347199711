import * as SERVICE from 'gadjet-v2-types/dist/api/branch/service'

import axios from '@utils/axios'

const ServiceAPI = {
  // 서비스 조회
  getServices: async (
    { hqId, branchId }: SERVICE.GET_LIST_PARAMS,
    query: SERVICE.GET_LIST_QUERY
  ): Promise<{ data: SERVICE.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/services`, { params: query }),

  // 서비스 상세조회
  getServiceDetail: async ({
    hqId,
    branchId,
    serviceId,
  }: SERVICE.GET_DETAIL_PARAMS): Promise<{ data: SERVICE.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/services/${serviceId}`),

  // 서비스 추가
  addService: async (
    { hqId, branchId }: SERVICE.ADD_PARAMS,
    { service }: SERVICE.ADD_BODY
  ): Promise<{ data: SERVICE.ADD_RESPONSE }> => axios.post(`/hqs/${hqId}/branches/${branchId}/services`, { service }),

  // 서비스 수정
  updateService: async (
    { hqId, branchId, serviceId }: SERVICE.UPDATE_PARAMS,
    { service }: SERVICE.UPDATE_BODY
  ): Promise<{ data: SERVICE.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/services/${serviceId}`, { service }),

  // 서비스 삭제
  deleteService: async ({
    hqId,
    branchId,
    serviceId,
  }: SERVICE.DELETE_PARAMS): Promise<{ data: SERVICE.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/services/${serviceId}`),

  // 서비스 판매내역 조회
  getServiceSales: async (
    { hqId, branchId }: SERVICE.GET_SALES_PARAMS,
    query: SERVICE.GET_SALES_QUERY
  ): Promise<{ data: SERVICE.GET_SALES_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/service-sales`, { params: query }),

  // 서비스 판매내역 요약
  getServiceSaleSummary: async (
    { hqId, branchId }: SERVICE.GET_SALES_SUMMARY_PARAMS,
    query: SERVICE.GET_SALES_SUMMARY_QUERY
  ): Promise<{ data: SERVICE.GET_SALES_SUMMARY_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/service-sales/summary`, { params: query }),

  // 서비스 판매내역 환불
  refundServiceSale: async ({
    hqId,
    branchId,
    serviceSaleId,
  }: SERVICE.REFUND_SALES_PARAMS): Promise<{ data: SERVICE.REFUND_SALES_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/service-sales/${serviceSaleId}`),

  // 서비스 판매내역 다운로드
  exportServiceSales: async (
    { hqId, branchId }: SERVICE.EXPORT_SALES_PARAMS,
    query: SERVICE.EXPORT_SALES_QUERY
  ): Promise<{ data: SERVICE.EXPORT_SALES_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/service-sales/export`, { params: query }),
}

export default ServiceAPI
