import { BIZ_BREAKPOINTS, GADJET_BIZ_COLORS } from '@const'
import styled from 'styled-components'

import Header from './atoms/Header'
import CompanyLogoGroup from './organisms/CompanyLogoGroup'
import ContactLinkGroup from './organisms/ContactLinkGroup'
import FeatureTabGroup from './organisms/FeatureTabGroup'
import RecommendationTable from './organisms/RecommendationTable'

export default function BusinessPage() {
  return (
    <Wrapper>
      <Header />

      <Section>
        <SectionTitle>
          더 효율적인 부동산 업무를 위한 솔루션
          <br />
          가젯 비즈니스 솔루션을 선택하세요
        </SectionTitle>
        <FeatureTabGroup />
      </Section>

      <Section background={GADJET_BIZ_COLORS.background} noSidePadding>
        <SectionTitle>비즈니스 특성에 따른 추천 조합</SectionTitle>
        <RecommendationTable />
      </Section>

      <Section background={GADJET_BIZ_COLORS.primary}>
        <SectionTitle darkMode>
          부동산 관리를 고민하고 있다면
          <br />
          가젯 영업팀과 상담하세요
        </SectionTitle>
        <ContactLinkGroup />
      </Section>

      <Section background={GADJET_BIZ_COLORS.background} noSidePadding>
        <SectionTitle>이미 많은 부동산 기업이 가젯을 도입했습니다</SectionTitle>
        <CompanyLogoGroup />
      </Section>
    </Wrapper>
  )
}

type StyleSectionProps = {
  background?: string
  noSidePadding?: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  overflow-x: hidden;
`

const Section = styled.section<StyleSectionProps>`
  background: ${({ background }) => background || '#fff'};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ noSidePadding }) => (!noSidePadding ? '120px 16px' : '120px 0px')};

  @media ${BIZ_BREAKPOINTS.lg} {
    padding: ${({ noSidePadding }) => (!noSidePadding ? '70px 16px' : '70px 0px')};
    h2 {
      padding: ${({ noSidePadding }) => (!noSidePadding ? '0' : '0 16px')};
    }
  }
`

const SectionTitle = styled.h2`
  color: ${({ darkMode }: { darkMode?: boolean }) => (!darkMode ? GADJET_BIZ_COLORS.textBlack : '#fff')};
  text-align: center;
  font-size: 38px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 50px;
  @media ${BIZ_BREAKPOINTS.lg} {
    font-size: 24px;
    margin-bottom: 28px;
  }
`
