import { BranchAccessLogAction } from '@pages/Branch/AccessLogPage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchAccessLogAction) => void
}

export default function BranchAccessLogTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [
    // { label: '', buttonType: 'primary', onClick: () => onAction('add') }
  ]

  return <PageTitle title="접근기록" actions={actions} />
}
