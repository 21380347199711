import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { PaymentSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  setPaymentPagination,
  setPaymentSearch,
  searchPayments,
  setPaymentDefault,
} from '@reducers/branch/payment.reducer'
import { RootState } from '@reducers/index'

import BranchPaymentListTable from '@components/organisms/Branch/Payment/ListTable'
import PaymentConfirmModal from '@components/organisms/Branch/Payment/Modals/PaymentConfirmModal'
import PaymentDeleteModal from '@components/organisms/Branch/Payment/Modals/PaymentDeleteModal'
import PaymentExportModal from '@components/organisms/Branch/Payment/Modals/PaymentExportModal'
import PaymentFormModal from '@components/organisms/Branch/Payment/Modals/PaymentFormModal'
import PaymentRefundModal from '@components/organisms/Branch/Payment/Modals/PaymentRefundModal'
import BranchPaymentSearchBox from '@components/organisms/Branch/Payment/SearchBox'
import BranchPaymentSummary from '@components/organisms/Branch/Payment/Summary'
import BranchPaymentTitle from '@components/organisms/Branch/Payment/Title'

export type BranchPaymentAction = 'delete' | 'update' | 'confirm' | 'refund' | 'export'

export default function PaymentPage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const location = useLocation<{ dateRange?: [string, string] }>()

  const { loading, search, pagination, searchResult, summary } = useSelector((state: RootState) => state.payment)

  const [formModal, onVisibleFormModal, onCloseFormModal] = useModal<{ paymentId?: number }>({
    visible: false,
    paymentId: 0,
  })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal<{ paymentId?: number }>({
    visible: false,
    paymentId: 0,
  })
  const [confirmModal, onVisibleConfirmModal, onCloseConfirmModal] = useModal<{ paymentId?: number }>({
    visible: false,
    paymentId: 0,
  })
  const [refundModal, onVisibleRefundModal, onCloseRefundModal] = useModal<{ paymentId?: number }>({
    visible: false,
    paymentId: 0,
  })
  const [exportModal, onVisibleExportModal, onCloseExportModal] = useModal({
    visible: false,
  })

  const onChangeSearch = (_search: Partial<PaymentSearch>) => dispatch(setPaymentSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setPaymentPagination(_pagination))
    dispatch(searchPayments(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setPaymentPagination({ current: 1 }))
    dispatch(searchPayments(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setPaymentDefault(hqId, branchId)), [hqId, branchId])
  const onDone = () => onResetAndSearch()

  const onAction = (action: BranchPaymentAction, paymentId?: number) => {
    if (action === 'update') onVisibleFormModal({ paymentId })
    if (action === 'confirm') onVisibleConfirmModal({ paymentId })
    if (action === 'refund') onVisibleRefundModal({ paymentId })
    if (action === 'delete') onVisibleDeleteModal({ paymentId })
    if (action === 'export') onVisibleExportModal()
  }

  useEffect(() => {
    if (location?.state?.dateRange) {
      onChangeSearch({ date: location?.state?.dateRange })
      onSearch()
    }
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div style={{ minWidth: '900px' }}>
      <BranchPaymentSearchBox search={search} onChangeSearch={onChangeSearch} onSearch={onSearch} />

      <BranchPaymentSummary summary={summary} />

      <BranchPaymentTitle onAction={onAction} />

      <BranchPaymentListTable
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        payments={searchResult.payments}
        total={searchResult.total}
        onAction={onAction}
      />

      <PaymentFormModal
        hqId={hqId}
        branchId={branchId}
        paymentId={formModal.paymentId}
        visible={formModal.visible}
        onClose={onCloseFormModal}
        onDone={onDone}
      />
      <PaymentDeleteModal
        hqId={hqId}
        branchId={branchId}
        paymentId={deleteModal.paymentId}
        visible={deleteModal.visible}
        onClose={onCloseDeleteModal}
        onDone={onDone}
      />
      <PaymentConfirmModal
        hqId={hqId}
        branchId={branchId}
        paymentId={confirmModal.paymentId}
        visible={confirmModal.visible}
        onClose={onCloseConfirmModal}
        onDone={onDone}
      />
      <PaymentRefundModal
        hqId={hqId}
        branchId={branchId}
        paymentId={refundModal.paymentId}
        visible={refundModal.visible}
        onClose={onCloseRefundModal}
        onDone={onDone}
      />
      <PaymentExportModal
        hqId={hqId}
        branchId={branchId}
        search={search}
        visible={exportModal.visible}
        onClose={onCloseExportModal}
        onDone={onDone}
      />
    </div>
  )
}
