import { Styles } from '@types'
import { Divider, Result, Table, TableColumnType } from 'antd'
import { Bills } from 'gadjet-v2-types/dist/model'
import { BillPrice } from 'gadjet-v2-types/dist/type'
import { useEffect, useState } from 'react'

import { krw } from '@utils/format'

import BillAPI from '@apis/branch/bill'

import Loading from '@components/molecules/Loading'
import ModalAlert from '@components/molecules/ModalAlert'

type Props = { hqId: number; branchId: number; billId: number; visible: boolean; onClose: () => void }

export default function BillPriceModal({ hqId, branchId, billId, visible, onClose }: Props): JSX.Element {
  const [bill, setBill] = useState<Bills | null>(null)
  const [loading, setLoading] = useState(false)

  const getBill = async () => {
    setLoading(true)
    const { data } = await BillAPI.getBill({ hqId, branchId, billId })
    setBill(data)
    setLoading(false)
  }

  useEffect(() => {
    if (visible) getBill()
  }, [visible])

  const columns: TableColumnType<BillPrice>[] = [
    { key: 'name', dataIndex: 'name', title: '이름', width: '30%' },
    { key: 'price', dataIndex: 'price', title: '금액', width: '35%', render: (price) => krw(price), align: 'right' },
    { key: 'vat', dataIndex: 'vat', title: '부가세', width: '35%', render: (vat) => krw(vat), align: 'right' },
  ]
  const { price = 0, spaces = [], additions = [], surcharges = [] } = bill || {}
  return (
    <ModalAlert title="청구내역" visible={visible} onCancel={onClose}>
      <Loading loading={loading}>
        <>
          {bill === null && <Result status="warning" title="청구서가 없습니다." />}
          <Table
            style={styles.table}
            size="small"
            dataSource={spaces}
            columns={columns}
            title={() => '공간'}
            pagination={false}
          />
          <Table
            style={styles.table}
            size="small"
            dataSource={additions}
            columns={columns}
            title={() => '부가서비스'}
            pagination={false}
          />
          <Table
            style={styles.table}
            size="small"
            dataSource={surcharges}
            columns={columns}
            title={() => '추가비용'}
            pagination={false}
          />
          <Divider />
          <div style={styles.total}>{krw(price)}</div>
        </>
      </Loading>
    </ModalAlert>
  )
}

const styles: Styles = {
  table: { marginBottom: '10px' },
  total: { textAlign: 'right', fontSize: '32px', fontWeight: 'bold' },
}
