import { SearchOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Button, Col, Row } from 'antd'
import { useEffect, useRef } from 'react'

import ContentWrapper from '@components/atoms/ContentWrapper'

type Props = {
  onSearch: () => void
  children: JSX.Element | JSX.Element[]
  searching?: boolean
}

export default function SearchBox({ onSearch, children, searching }: Props): JSX.Element {
  const div = useRef<HTMLDivElement>(null)

  useEffect((): (() => void) => {
    if (!div.current) return () => {}
    const inputs = Array.from(div.current?.querySelectorAll('input'))
    const onPressEnter = ({ key }: KeyboardEvent) => {
      if (key === 'Enter') onSearch()
    }

    inputs.forEach((input) => input.addEventListener('keypress', onPressEnter))

    const removeEvents = () => inputs.forEach((input) => input.removeEventListener('keypress', onPressEnter))

    return removeEvents
  }, [div, children])

  return (
    <ContentWrapper loading={searching}>
      <Row gutter={10}>
        <Col ref={div} span={22}>
          <Row gutter={[10, 10]}>{children}</Row>
        </Col>
        <Col style={styles.actionContainer} span={2}>
          <Button
            type="primary"
            style={styles.searchButton}
            onClick={onSearch}
            icon={<SearchOutlined style={styles.searchIcon} />}
            size="large"
          />
        </Col>
      </Row>
    </ContentWrapper>
  )
}

const styles: Styles = {
  actionContainer: { paddingLeft: '5px', borderLeft: '1px solid #eee' },
  searchButton: { height: '100%', width: '100%' },
  searchIcon: { fontSize: '20px' },
}
