import * as ADDITION from 'gadjet-v2-types/dist/api/branch/addition'

import axios from '@utils/axios'

const AdditionAPI = {
  // 부가서비스 조회
  getAdditions: async (
    { hqId, branchId }: ADDITION.GET_LIST_PARAMS,
    query: ADDITION.GET_LIST_QUERY
  ): Promise<{ data: ADDITION.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/additions`, { params: query }),

  // 부가서비스 상세 조회
  getAdditionDetail: async ({
    hqId,
    branchId,
    additionId,
  }: ADDITION.GET_DETAIL_PARAMS): Promise<{ data: ADDITION.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/additions/${additionId}`),

  // 부가서비스 추가
  addAddition: async (
    { hqId, branchId }: ADDITION.ADD_PARAMS,
    { addition }: ADDITION.ADD_BODY
  ): Promise<{ data: ADDITION.ADD_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/additions`, { addition }),

  // 부가서비스 수정
  updateAddition: async (
    { hqId, branchId, additionId }: ADDITION.UPDATE_PARAMS,
    { addition }: ADDITION.UPDATE_BODY
  ): Promise<{ data: ADDITION.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/additions/${additionId}`, { addition }),

  // 부가서비스 제거
  deleteAddition: async ({
    hqId,
    branchId,
    additionId,
  }: ADDITION.DELETE_PARAMS): Promise<{ data: ADDITION.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/additions/${additionId}`),

  // 부가서비스 계약 조회
  getAdditionContracts: async (
    { hqId, branchId, additionId }: ADDITION.GET_CONTRACTS_PARAMS,
    query: ADDITION.GET_CONTRACTS_QUERY
  ): Promise<{ data: ADDITION.GET_CONTRACTS_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/additions/${additionId}/contracts`, { params: query }),
}

export default AdditionAPI
