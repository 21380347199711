import { Serie } from '@nivo/line'
import { Styles } from '@types'
import { Card, Select } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getHqGraphContracts } from '@reducers/hq/graph.reducer'
import { RootState } from '@reducers/index'

import GraphLine from '@components/molecules/Graph/Line'
import Loading from '@components/molecules/Loading'

type Props = { hqId: number }

export default function GraphSpaceTypes({ hqId }: Props): JSX.Element {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [activeKey, setActiveKey] = useState('')
  const branchOptions = useSelector((state: RootState) => [
    { label: '전 지점', value: '전 지점' },
    ...state.hqBranch.branches.map(({ name }) => ({ label: name, value: name })),
  ])

  const contracts = useSelector((state: RootState) => {
    const s: { [key: string]: Serie[] } = {}
    Object.entries(state.hqGraph.contracts).forEach(([key, datas]) => {
      s[key] = [
        {
          id: '시작',
          data: datas.map(({ month, start }) => ({ x: month, y: start })),
        },
        {
          id: '종료',
          data: datas.map(({ month, end }) => ({ x: month, y: end })),
        },
      ]
    })
    return s
  })

  const getData = () => {
    setLoading(true)
    dispatch(
      getHqGraphContracts(hqId, () => {
        setLoading(false)
      })
    )
  }

  useEffect(() => {
    if (hqId) getData()
  }, [hqId])

  useEffect(() => {
    if (branchOptions.length > 0 && activeKey === '') setActiveKey(branchOptions[0].value)
  }, [branchOptions])

  const graphData = useMemo(
    () =>
      contracts[activeKey] || [
        { id: '시작', data: [] },
        { id: '종료', data: [] },
      ],
    [activeKey, branchOptions]
  )

  return (
    <Card
      title="계약"
      size="small"
      extra={
        <Select value={activeKey} onSelect={setActiveKey} options={branchOptions} style={styles.select} size="small" />
      }
    >
      <Loading loading={loading}>
        <GraphLine
          height="400px"
          data={graphData}
          axis={{
            left: { legend: '건' },
            bottom: { legend: '월' },
          }}
          labelFormat={({ data }) => `${data.y}건`}
        />
      </Loading>
    </Card>
  )
}

const styles: Styles = {
  select: { width: '200px' },
}
