import { Button, Form, Input } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useEffect, useState } from 'react'

import mask from '@utils/mask'

export default function TestPage(): JSX.Element {
  const [form] = useForm()
  const [s, setS] = useState('')

  const submit = async () => {
    const value = await form.validateFields()

    setS(JSON.stringify(value, null, 2))
  }

  useEffect(() => {
    form.setFieldsValue({ string: '3788700388', number: 3788700388, string2: '3788700388', number2: 3788700388 })
  }, [])

  return (
    <div>
      <Form form={form} onValuesChange={submit}>
        <Form.Item name="string" {...mask.businessLicense}>
          <Input maxLength={12} />
        </Form.Item>
        <Form.Item name="string2" {...mask.contact}>
          <Input maxLength={13} />
        </Form.Item>
        <Form.Item name="number" {...mask.personalLicense}>
          <Input maxLength={14} />
        </Form.Item>
        <Form.Item name="number2" {...mask.krw}>
          <Input />
        </Form.Item>
        <Button onClick={submit}>SUBMIT</Button>
      </Form>
      <pre style={{ padding: '30px' }}>{s}</pre>
    </div>
  )
}
