import { ReactComponent as HelperCircleFilledIcon } from '@assets/Icons/helper-circle-filled.svg'
import { ReactComponent as TickCircleFilledIcon } from '@assets/Icons/tick-circle-filled.svg'
import { message } from 'antd'
import styled from 'styled-components'

// https://ant.design/components/message/

const toast = {
  error: (content: string) => {
    message.error({
      content: (
        <ContentWrapper>
          <HelperCircleFilledIcon width="16" height="16" fill="#F43F24" />
          {content}
        </ContentWrapper>
      ),
      icon: <div />,
    })
  },
  success: (content: string) => {
    message.success({
      content: (
        <ContentWrapper>
          <TickCircleFilledIcon width="16" height="16" fill="#3EBD73" />
          {content}
        </ContentWrapper>
      ),
      icon: <div />,
    })
  },
}

export default toast

const ContentWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`
