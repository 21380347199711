import { Button, Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import ManagerRoleAPI from '@apis/manager/role'

import { logout } from '@reducers/auth.reducer'
import { RootState } from '@reducers/index'

import AuthFormTemplate from '@templates/AuthFormTemplate'

import AuthFormItem from '@components/atoms/AuthFormItem'
import AuthInput from '@components/atoms/AuthInput'
import toast from '@components/atoms/Toast'

export default function CodePage(): JSX.Element {
  const dispatch = useDispatch()
  const [form] = useForm<{ code: string }>()
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')

  const manager = useSelector((state: RootState) => state.auth.manager)

  const onChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value)
  }

  const onClickLogout = async () => {
    dispatch(logout())
  }

  const onPressDone = async () => {
    if (!manager) return
    const values = await form.validateFields()
    const { code } = values

    const { data } = await ManagerRoleAPI.acceptInvite({ managerId: manager.managerId }, { code })

    if (!data) {
      toast.error('코드를 확인하세요.')
      return
    }
    setLoading(false)
    window.location.reload()
  }

  const initialValues = { code: '' }

  return (
    <AuthFormTemplate
      title="초대코드 입력"
      tooltip={
        <Tooltip>
          <li>
            <Bold>운영사 초대코드</Bold> : ‘무료체험/도입하기’를 통해
            <br />
            운영사, 지점 생성 후 메일에서 확인
          </li>
          <li>
            <Bold>매니저 초대코드</Bold> : 운영사 계정 가입, 로그인 후<br />
            지점 목록에서 [권한-매니저 초대]
          </li>
        </Tooltip>
      }
      submit={{
        label: '완료',
        onClick: onPressDone,
        isDisabled: code.length !== 6,
      }}
      loading={loading}
      footer={
        <LogOutButton onClick={onClickLogout} size="large" block type="text">
          로그아웃
        </LogOutButton>
      }
    >
      <CustomForm form={form} layout="vertical" initialValues={initialValues}>
        <AuthFormItem
          name="code"
          label="6자리 숫자 코드"
          extra={
            <Helper>
              <img src="/images/love-letter-emoji.png" alt="love-letter-emoji" width={20} height={20} />
              <span>
                받은메일함에서 <b>[gadjet] 초대코드</b>를 확인하세요
              </span>
            </Helper>
          }
        >
          <AuthInput size="large" autoFocus onPressEnter={onPressDone} onChange={onChangeCode} />
        </AuthFormItem>
      </CustomForm>
    </AuthFormTemplate>
  )
}

const Helper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #777d83;
  padding-top: 8px;
`
const LogOutButton = styled(Button)`
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  height: 52px;
  border-radius: 8px;
  border: none;
  color: #7a7b7c;
  margin-top: 8px;
`
const CustomForm = styled(Form)`
  padding: 28px 0px 0px 0px;
`
const Tooltip = styled.ul`
  padding: 0px 0px 0px 20px;
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`

const Bold = styled.span`
  font-weight: 700;
`
