import { BranchNotifications } from 'gadjet-v2-types/dist/model'

// ActionType
export const GET_NOTIFICATIONS = 'notification/GET_NOTIFICATIONS' as const
export const GET_NOTIFICATIONS_SUCCESS = 'notification/GET_NOTIFICATIONS_SUCCESS' as const
export const SET_NOTIFICATION_DRAWER = 'notification/SET_NOTIFICATION_DRAWER' as const
export const READ_NOTIFICATIONS = 'notification/READ_NOTIFICATIONS' as const
export const GET_READ_AT_NOTIFICATIONS = 'notification/GET_READ_AT_NOTIFICATIONS' as const
export const SET_NOTIFICATIONS = 'notification/SET_NOTIFICATIONS' as const

// Action
export const getNotifications = (hqId: number, branchId: number) => ({
  type: GET_NOTIFICATIONS,
  payload: { hqId, branchId },
})
export const getNotificationsSuccess = (branchNotifications: BranchNotifications[]) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: { branchNotifications },
})
export const setNotificationDrawer = (drawer: boolean) => ({
  type: SET_NOTIFICATION_DRAWER,
  payload: { drawer },
})
export const readNotifications = (branchId: number) => ({
  type: READ_NOTIFICATIONS,
  payload: { branchId },
})
export const getReadtAtNotifications = (branchId: number) => ({
  type: GET_READ_AT_NOTIFICATIONS,
  payload: { branchId },
})
export const setNotifications = (state: Partial<NotificationState>) => ({
  type: SET_NOTIFICATIONS,
  payload: { state },
})

export type NotificationAction =
  | ReturnType<typeof getNotifications>
  | ReturnType<typeof getNotificationsSuccess>
  | ReturnType<typeof setNotificationDrawer>
  | ReturnType<typeof readNotifications>
  | ReturnType<typeof getReadtAtNotifications>
  | ReturnType<typeof setNotifications>

type NotificationState = {
  drawer: boolean
  loading: boolean
  readAt: string
  notifications: BranchNotifications[]
}

const notificationState: NotificationState = {
  drawer: false,
  loading: false,
  readAt: '2000-01-01 00:00:00',
  notifications: [],
}

const notificationReducer = (state = notificationState, action: NotificationAction): NotificationState => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return { ...state, loading: true }
    case GET_NOTIFICATIONS_SUCCESS:
      return { ...state, notifications: action.payload.branchNotifications, loading: false }
    case SET_NOTIFICATION_DRAWER:
      return { ...state, drawer: action.payload.drawer }
    case READ_NOTIFICATIONS:
      return { ...state }
    case GET_READ_AT_NOTIFICATIONS:
      return { ...state }
    case SET_NOTIFICATIONS:
      return { ...state, ...action.payload.state }
    default:
      return state
  }
}

export default notificationReducer
