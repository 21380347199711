import { Styles } from '@types'
import { Modal, Table, TableColumnType } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@reducers/index'

import { ContractColumn } from '../../ListTable/Columns'

type Props = {
  columns: ContractColumn[]
  visible: boolean
  onClose: () => void
  onApply: (activeColumns: Array<keyof Contracts>) => void
}

export default function TableColumnModal({ columns, visible, onClose, onApply }: Props): JSX.Element {
  const defaultActiveColumns = useSelector((state: RootState) => state.contract.tableConfig.activeColumns)
  const [activeColumns, setActiveColumns] = useState(defaultActiveColumns)

  const onToggleColumns = (key: keyof Contracts, index: number) => {
    if (index === -1) setActiveColumns([...activeColumns, key])
    else setActiveColumns([...activeColumns.slice(0, index), ...activeColumns.slice(index + 1)])
  }
  const onOk = () => {
    onApply(activeColumns)
    onClose()
  }

  useEffect(() => {
    if (!visible) setActiveColumns(defaultActiveColumns)
  }, [visible, defaultActiveColumns])

  const tableColumns: TableColumnType<ContractColumn>[] = [
    { key: 'title', dataIndex: 'title', title: '항목' },
    {
      key: 'description',
      dataIndex: 'description',
      title: '설명',
      width: '300px',
    },
    {
      key: 'key',
      dataIndex: 'key',
      title: '활성화',
      width: '80px',
      align: 'center',
      render: (key) => {
        const index = activeColumns.findIndex((activeKey) => activeKey === key)
        const isActive = index !== -1
        return <Checkbox checked={isActive} onClick={() => onToggleColumns(key, index)} />
      },
    },
  ]

  return (
    <Modal
      visible={visible}
      title="항목편집"
      onCancel={onClose}
      onOk={onOk}
      okText="저장"
      cancelText="취소"
      width="600px"
      style={styles.table}
    >
      <Table
        dataSource={columns}
        columns={tableColumns}
        pagination={{ current: 1, pageSize: 500, hideOnSinglePage: true }}
      />
    </Modal>
  )
}

const styles: Styles = {
  modalBody: { padding: '0' },
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
}
