import useMenu from '@hooks/useMenu'
import { Button } from 'antd'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { krw } from '@utils/format'

import { searchBills, setBillSearch } from '@reducers/branch/bill.reducer'

export default function Fee({ contract, onRowAction }: ContractColumnProps): JSX.Element {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { hqId, branchId } = useMenu()

  const fee = useMemo(() => {
    const { contractSpaces, contractAdditions, billingType } = contract

    const items = [...(contractSpaces || []), ...(contractAdditions || [])]

    const price = items.reduce((a, b) => a + b.price, 0)

    if (billingType === 'monthly') return `매 월 ${krw(price)}`
    if (billingType === 'prepayment') return krw(price)

    return ''
  }, [contract])

  const onClickButton = () => {
    const { tenant, startDate, suspendDate } = contract

    dispatch(setBillSearch({ query: tenant.name, startDate: [startDate, suspendDate] }))
    dispatch(searchBills(hqId, branchId))

    push(`/hq/${hqId}/branch/${branchId}/bill/list`)
  }

  return (
    <div>
      <Button onClick={onClickButton}>{fee}</Button>
    </div>
  )
}
