import { Point } from '@nivo/line'
import { Styles } from '@types'

type Props = {
  points: Point[]
  labelFormat?: (point: Point) => string
}

export default function GraphTooltip({ points, labelFormat }: Props): JSX.Element {
  return (
    <div style={styles.container}>
      {points.map((point) => (
        <div style={styles.wrapper} key={point.id}>
          <div style={{ ...styles.color, backgroundColor: point.serieColor }} />
          <div>{point.serieId}</div>
          <div style={{ flex: 1 }} />
          <div style={styles.label}>{labelFormat ? labelFormat(point) : point.data.y}</div>
        </div>
      ))}
    </div>
  )
}

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    boxShadow: '2px 2px 4px rgba(0,0,0,0.2)',
    borderRadius: '4px',
    padding: '4px',
  },
  wrapper: {
    padding: '2px 4px',
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
  color: { height: '16px', width: '16px' },
  label: { fontSize: '14px' },
}
