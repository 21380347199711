import { ReactComponent as ChevronDownXsIcon } from '@assets/Icons/chevron-down-xs.svg'
import { ReactComponent as ChevronUpXsIcon } from '@assets/Icons/chevron-up-xs.svg'
import { ReactComponent as InfoIcon } from '@assets/Icons/info.svg'
import { useState } from 'react'
import styled from 'styled-components'

export default function DashboardInformation(): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const data = [
    [
      {
        label: '예상매출',
        content: '이용기간의 시작일이 당월에 포함된 청구서를 기준으로 된 집계한 금액입니다',
      },
      {
        label: '미납',
        content: `이용기간의 시작일이 당월에 포함된 청구서의 금액 중 아직 결제되지 않은 금액입니다\n
      (가젯에 입력된 내역만 반영됩니다)`,
      },
      {
        label: '계약 시작',
        content: '이용기간의 시작일이 당월에 포함된 신규/연장 계약의 수입니다',
      },
      {
        label: '청구',
        content: '결제 기간의 시작일이 당월에 포함된 청구서의 수입니다',
      },
    ],
    [
      {
        label: '결제',
        content: '당월에 결제된 금액을 집계한 금액입니다 (가젯에 입력된 내역만 반영됩니다)',
      },
      {
        label: '지출',
        content: `당월에 지출된 금액을 집계한 금액입니다 (가젯에 기록된 내역만 반영됩니다)`,
      },
      {
        label: '계약 종료',
        content: '당월에 종료된 계약의 수입니다',
      },
      {
        label: '대관/대여',
        content: '당월에 발생한 대관/대여 예약의 수입니다',
      },
    ],
  ]

  const onClick = () => {
    setIsOpen(!isOpen)
  }
  return (
    <Wrapper>
      <InfoButton onClick={onClick}>
        <InfoIcon /> 항목 정보 {isOpen ? <ChevronUpXsIcon stroke="#7A7B7C" /> : <ChevronDownXsIcon stroke="#7A7B7C" />}
      </InfoButton>
      <InfoBox isOpen={isOpen}>
        {data.map((item) => (
          <div style={{ width: '499px' }}>
            {item.map((el) => (
              <ItemWrapper key={el.label}>
                <Label>{el.label}</Label>
                <Content>{el.content}</Content>
              </ItemWrapper>
            ))}
          </div>
        ))}
      </InfoBox>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const InfoButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 8px;
  gap: 4px;
  border: none;
  font-size: 13px;
  line-height: 20px;
  color: #777d83;
  background-color: transparent;
  cursor: pointer;
  :hover {
    background: #edf0f4;
    border-radius: 8px;
  }
`
const InfoBox = styled.div`
  display: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? 'flex' : 'none')};
  flex-direction: row;
  width: 1180px;
  gap: 80px;
  padding: 16px 24px;
  background: #fdfdfe;
  border-radius: 16px;
  transition: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? '0.5s cubic-bezier(0, 1, 0, 1)' : '1s ease-in-out')};
  @media (max-width: 1423px) {
    width: 880px;
    flex-direction: column;
    gap: 0;
  }
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const Label = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 28px;
  padding: 4px 8px;
  background: #f2f3f4;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  color: #232530;
`
const Content = styled.span`
  width: 465px;
  height: 36px;
  font-size: 13px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #777d83;
`
