import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { SpaceSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SpaceAPI from '@apis/branch/space'

import {
  searchSpaces,
  setSpaceDefault,
  setSpaceLoading,
  setSpacePagination,
  setSpaceSearch,
} from '@reducers/branch/space.reducer'
import { RootState } from '@reducers/index'

import FloorListModal from '@components/organisms/Branch/Floor/Modals/ListModal'
import BranchSpaceListTable from '@components/organisms/Branch/Space/ListTable'
import SpaceContractModal from '@components/organisms/Branch/Space/Modals/SpaceContractModal'
import SpaceDeleteModal from '@components/organisms/Branch/Space/Modals/SpaceDeleteModal'
import SpaceExportModal from '@components/organisms/Branch/Space/Modals/SpaceExportModal'
import SpaceFormModal from '@components/organisms/Branch/Space/Modals/SpaceFormModal'
import SpaceUploadModal from '@components/organisms/Branch/Space/Modals/SpaceUploadModal'
import BranchSpaceSearchBox from '@components/organisms/Branch/Space/SearchBox'
import BranchSpaceSummary from '@components/organisms/Branch/Space/Summary'
import BranchSpaceTitle from '@components/organisms/Branch/Space/Title'
import SpaceTypeListModal from '@components/organisms/Branch/SpaceType/Modals/ListModal'

export type BranchSpaceActionType =
  | 'add'
  | 'update'
  | 'delete'
  | 'upload'
  | 'export'
  | 'contract'
  | 'space-type'
  | 'floor'

export default function SpacePage(): JSX.Element {
  const { hqId, branchId } = useMenu()
  const dispatch = useDispatch()
  const spaceTypes = useSelector((state: RootState) => state.branch.spaceTypes || [])
  const { loading, search, pagination, summary, searchResult } = useSelector((state: RootState) => state.space)

  const [formModal, onVisibleFormModal, onCloseFormModal] = useModal<{ spaceId?: number }>({
    visible: false,
    spaceId: undefined,
  })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal<{ spaceId?: number }>({
    visible: false,
    spaceId: undefined,
  })
  const [uploadModal, onVisibleUploadModal, onCloseUploadModal] = useModal({ visible: false })
  const [exportModal, onVisibleExportModal, onCloseExportModal] = useModal({ visible: false })
  const [contractModal, onVisibleContractModal, onCloseContractModal] = useModal<{ spaceId?: number }>({
    visible: false,
    spaceId: undefined,
  })
  const [spaceTypeModal, onVisibleSpaceTypeModal, onCloseSpaceTypeModal] = useModal({ visible: false })
  const [floorModal, onVisibleFloorModal, onCloseFloorModal] = useModal({ visible: false })

  const onChangeSearch = (_search: Partial<SpaceSearch>) => dispatch(setSpaceSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setSpacePagination(_pagination))
    dispatch(searchSpaces(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setSpacePagination({ current: 1 }))
    dispatch(searchSpaces(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setSpaceDefault(hqId, branchId)), [hqId, branchId])
  const onDone = () => onResetAndSearch()

  const onAction = (action: BranchSpaceActionType, spaceId?: number) => {
    if (action === 'add') onVisibleFormModal({ spaceId: undefined })
    if (action === 'update') onVisibleFormModal({ spaceId })
    if (action === 'delete') onVisibleDeleteModal({ spaceId })
    if (action === 'upload') onVisibleUploadModal()
    if (action === 'export') onVisibleExportModal()
    if (action === 'contract') onVisibleContractModal({ spaceId })
    if (action === 'space-type') onVisibleSpaceTypeModal()
    if (action === 'floor') onVisibleFloorModal()
  }

  const refreshPage = () => {
    dispatch(setSpacePagination(pagination))
    dispatch(searchSpaces(hqId, branchId))
  }

  const onChangeActiveStatus = async (isActive: boolean, spaceId: number) => {
    dispatch(setSpaceLoading(true))
    await SpaceAPI.updateSpace({ hqId, branchId, spaceId }, { space: { isActive } })
    refreshPage()
  }

  useEffect(() => {
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [hqId, branchId])

  return (
    <div>
      <BranchSpaceSearchBox search={search} onChangeSearch={onChangeSearch} onSearch={onSearch} />
      <BranchSpaceSummary summary={summary} spaceTypes={spaceTypes} />

      <BranchSpaceTitle onAction={onAction} />

      <BranchSpaceListTable
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        spaces={searchResult.spaces}
        total={searchResult.total}
        onAction={onAction}
        onChangeActiveStatus={onChangeActiveStatus}
      />

      <SpaceFormModal
        hqId={hqId}
        branchId={branchId}
        spaceId={formModal.spaceId}
        visible={formModal.visible}
        onClose={onCloseFormModal}
        onDone={onDone}
      />
      <SpaceDeleteModal
        hqId={hqId}
        branchId={branchId}
        spaceId={deleteModal.spaceId}
        visible={deleteModal.visible}
        onClose={onCloseDeleteModal}
        onDone={onDone}
      />
      <SpaceUploadModal
        hqId={hqId}
        branchId={branchId}
        visible={uploadModal.visible}
        onClose={onCloseUploadModal}
        onDone={onDone}
      />
      <SpaceExportModal
        hqId={hqId}
        branchId={branchId}
        visible={exportModal.visible}
        onClose={onCloseExportModal}
        onDone={onDone}
      />

      <SpaceContractModal
        hqId={hqId}
        branchId={branchId}
        spaceId={contractModal.spaceId}
        visible={contractModal.visible}
        onClose={onCloseContractModal}
      />

      <SpaceTypeListModal
        hqId={hqId}
        branchId={branchId}
        visible={spaceTypeModal.visible}
        onClose={onCloseSpaceTypeModal}
      />
      <FloorListModal hqId={hqId} branchId={branchId} visible={floorModal.visible} onClose={onCloseFloorModal} />
    </div>
  )
}
