import { Styles } from '@types'
import { Form, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Buffer } from 'buffer'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

import bufferToFileDownload from '@utils/download'
import formRule from '@utils/formRule'

import ReceiptAPI from '@apis/branch/receipt'

import LocalDatePicker from '@components/atoms/LocalDatePicker'

type Props = {
  hqId: number
  branchId: number
  initialDateRange: [string, string]
  query: string
  visible: boolean
  onClose: () => void
  onDone?: () => void
}

export default function ReceiptExportModal({
  hqId,
  branchId,
  initialDateRange,
  query,
  visible,
  onClose,
  onDone = () => undefined,
}: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [form] = useForm<{ dateRange: [Dayjs, Dayjs] }>()

  const onOk = async () => {
    const { dateRange } = await form.validateFields()

    setLoading(true)
    const { data } = await ReceiptAPI.exportReceipts(
      { hqId, branchId },
      { requestDatetime: [dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')], query }
    )

    bufferToFileDownload(`${data.fileName}.xlsx`, Buffer.from(data.data))

    setLoading(false)
    onDone()
  }

  const initialValues = { dateRange: [dayjs(initialDateRange[0]), dayjs(initialDateRange[1])] }

  const onReset = () => {
    form.resetFields()
    setLoading(false)
  }

  useEffect(() => {
    if (visible) onReset()
  }, [visible])

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      onOk={onOk}
      title="증빙 다운로드"
      okText="다운로드"
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item label="기간" name="dateRange" rules={[formRule.required]}>
          <LocalDatePicker.RangePicker size="large" style={styles.datePicker} allowClear={false} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const styles: Styles = {
  datePicker: { width: '100%' },
}
