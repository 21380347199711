import { DeleteOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Button, Empty, Form, PageHeader, Table } from 'antd'
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList'
import { ColumnType } from 'antd/lib/table'
import { useMemo } from 'react'

export type FormListTableColumn = {
  label: string
  render: (field: FormListFieldData, index: number) => JSX.Element
  width?: string
}

type Props = {
  title: string
  subTitle?: string
  name: string | string[]
  columns: FormListTableColumn[]
  emptyText?: string
  footer?: () => JSX.Element
  defaultValue?: unknown
}

export default function FormListTable({
  title,
  subTitle,
  name,
  columns,
  emptyText,
  footer,
  defaultValue,
}: Props): JSX.Element {
  const tableColumns: (operation: FormListOperation) => ColumnType<FormListFieldData>[] = useMemo(
    () => (operation: FormListOperation) =>
      columns
        .map<ColumnType<FormListFieldData>>(({ label, render, width }) => ({
          title: label,
          render: (_, field, index) => render(field, index),
          width,
        }))
        .concat([
          {
            align: 'center',
            width: '10%',
            render: (_, __, index) => (
              <Form.Item noStyle>
                <Button
                  type="text"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => operation.remove(index)}
                  size="large"
                />
              </Form.Item>
            ),
          },
        ]),
    [columns]
  )

  const tableTitle = useMemo(
    () => (operation: FormListOperation) =>
      (
        <PageHeader
          style={styles.pageHeader}
          title={title}
          subTitle={subTitle}
          extra={
            <Button size="small" onClick={() => operation.add(defaultValue)}>
              추가
            </Button>
          }
        />
      ),
    [title]
  )

  return (
    <Form.List name={name}>
      {(fields, operation) => (
        <Table
          style={styles.table}
          title={() => tableTitle(operation)}
          dataSource={fields}
          columns={tableColumns(operation)}
          size="small"
          pagination={false}
          locale={{
            emptyText: <Empty description={emptyText || '데이터가 없습니다.'} />,
          }}
          footer={footer}
        />
      )}
    </Form.List>
  )
}

const styles: Styles = { pageHeader: { padding: '0' }, table: { marginBottom: '24px' } }
