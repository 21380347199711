import { Styles } from '@types'
import { CSSProperties } from 'react'

import Loading from '@components/molecules/Loading'

type Props = { style?: CSSProperties; children: JSX.Element; loading?: boolean }
export default function TableWrapper({ style, children, loading = false }: Props): JSX.Element {
  return (
    <div className="content-wrapper" style={{ ...styles.div, ...style }}>
      {children}

      {loading && (
        <div style={styles.loading}>
          <Loading />
        </div>
      )}
    </div>
  )
}

const styles: Styles = {
  div: {
    backgroundColor: 'white',
    overflow: 'hidden',
    borderRadius: '16px',
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
}
