import { Button, Form, Input, notification, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Branches, BranchSettlements } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import BranchAPI from '@apis/branch'

import { RootState } from '@reducers/index'

type Props = { hqId: number; branchId: number; branch?: Branches; onRefresh: () => void }

export default function BranchSettlementForm({ hqId, branchId, branch, onRefresh }: Props): JSX.Element {
  const [form] = useForm<BranchSettlements>()
  const [loading, setLoading] = useState(false)
  const banks = useSelector((state: RootState) => state.option.banks)

  const onSubmit = async () => {
    const values = await form.validateFields()
    setLoading(true)
    await BranchAPI.updateBranchSettlement({ hqId, branchId }, { settlement: values })
    setLoading(false)
    notification.success({ message: '저장되었습니다.' })
    onRefresh()
  }

  const onSelectBank = (bankCode: string) => {
    const bank = banks.find((b) => b.bankCode === bankCode)
    form.setFieldsValue({ bankName: bank?.name || '' })
  }

  const resetForm = () => {
    form.resetFields()
    if (branch?.settlement) form.setFieldsValue(branch?.settlement)
  }

  useEffect(() => {
    resetForm()
  }, [branch])

  const initialValues: Partial<BranchSettlements> = {
    bankCode: '',
    bankName: '',
    account: '',
    holder: '',
  }

  const bankOptions = banks.map(({ bankCode, name }) => ({ label: name, value: bankCode }))

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <Form.Item label="은행" name="bankCode">
        <Select<string> options={bankOptions} onSelect={onSelectBank} />
      </Form.Item>

      <Form.Item label="계좌번호" name="account">
        <Input />
      </Form.Item>
      <Form.Item label="예금주" name="holder">
        <Input />
      </Form.Item>

      <Button type="primary" size="large" onClick={onSubmit} loading={loading}>
        저장하기
      </Button>
    </Form>
  )
}
