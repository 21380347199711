import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import PaymentAPI from '@apis/branch/payment'

import {
  PaymentAction,
  setPaymentLoading,
  setPaymentSearchResult,
  SEARCH_PAYMENTS,
  SET_PAYMENT_DEFAULT,
  SET_PAYMENT_PAGINATION,
  SET_PAYMENT_SEARCH,
  SET_PAYMENT_PAGE_CONFIG,
  setPaymentPagination,
  searchPayments,
  setPaymentSearch,
  setPaymentSummary,
} from '@reducers/branch/payment.reducer'
import { RootState } from '@reducers/index'
import { setStoragePayment } from '@reducers/storage.reducer'

function* searchPaymentSaga(action: PaymentAction): Generator {
  if (action.type !== SEARCH_PAYMENTS) return
  const { hqId, branchId } = action.payload
  yield* put(setPaymentLoading(true))

  const search = yield* select((state: RootState) => state.payment.search)
  const pagination = yield* select((state: RootState) => state.payment.pagination)

  const {
    data: { payments, total },
  } = yield* call(PaymentAPI.getPayments, { hqId, branchId }, { ...search, ...pagination })
  const { data: summary } = yield* call(PaymentAPI.getPaymentSummary, { hqId, branchId }, { ...search })

  yield* put(setPaymentSummary(summary))
  yield* put(setPaymentSearchResult({ branchId, payments, total }))

  yield* put(setPaymentLoading(false))
}

function* savePageConfigSaga(action: PaymentAction): Generator {
  const state: Parameters<typeof setStoragePayment>[0] = {}

  if (action.type === SET_PAYMENT_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStoragePayment(state))
}

function* setDefaultPageConfigSaga(action: PaymentAction): Generator {
  if (action.type !== SET_PAYMENT_DEFAULT) return
  const { pageSize } = yield* select((state: RootState) => state.storage.paymentConfig)
  yield* all([put(setPaymentSearch({})), put(setPaymentPagination({ pageSize }))])

  const { hqId, branchId } = action.payload
  yield* put(searchPayments(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_PAYMENTS, searchPaymentSaga),
    takeEvery(SET_PAYMENT_SEARCH, savePageConfigSaga),
    takeEvery(SET_PAYMENT_PAGINATION, savePageConfigSaga),
    takeEvery(SET_PAYMENT_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_PAYMENT_DEFAULT, setDefaultPageConfigSaga),
  ])
}
