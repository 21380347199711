import { Styles } from '@types'
import { Modal, Form, Input, notification, Divider, Switch, Select, Col, Row, Image, InputNumber } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { Events, Images } from 'gadjet-v2-types/dist/model'
import { creditType } from 'gadjet-v2-types/dist/type/label'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import addressFinder from '@utils/addressFinder'
import formRule from '@utils/formRule'

import EventAPI from '@apis/branch/event'

import { RootState } from '@reducers/index'

import HiddenItems from '@components/atoms/Form/HiddenItems'
import LocalDatePicker from '@components/atoms/LocalDatePicker'
import Loading from '@components/molecules/Loading'
import UploadButton from '@components/molecules/UploadButton'

type Props = {
  hqId: number
  branchId: number
  eventId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function EventFormModal({ hqId, branchId, eventId, visible, onClose, onDone }: Props): JSX.Element {
  const [form] = useForm<Events>()
  const [loading, setLoading] = useState(false)
  const option = useSelector((state: RootState) => state.option)
  const [imageUri, setImageUri] = useState('')

  const onVisibleFinder = () => {
    addressFinder.open(({ address }) => {
      form.setFieldsValue({ address })
    })
  }

  const onUploadDone = ({ imageId, uri }: Images) => {
    form.setFieldsValue({ bannerImageId: imageId })
    setImageUri(uri)
  }

  const onOk = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      if (eventId) await EventAPI.updateEvent({ hqId, branchId, eventId }, { event: values })
      else await EventAPI.addEvent({ hqId, branchId }, { event: values })

      notification.success({ message: '저장되었습니다.' })

      setLoading(false)
      onDone()
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const getEvent = async () => {
    if (!eventId) return
    setLoading(true)
    const { data } = await EventAPI.getEventDetail({ hqId, branchId, eventId })
    form.setFieldsValue(data)
    setImageUri(data?.bannerImage?.uri || '')
    setLoading(false)
  }

  const reset = () => {
    form.resetFields()
  }

  useEffect(() => {
    if (visible) {
      reset()
      getEvent()
    }
  }, [visible])

  const initialValues: Partial<Events> = {
    title: '',

    onlineFlag: false,
    place: '',
    address: '',
    addressDetail: '',
    lat: '',
    lng: '',

    openDate: dayjs().format('YYYY-MM-DD'),
    applyStartDate: dayjs().format('YYYY-MM-DD'),
    applyEndDate: dayjs().add(1, 'week').format('YYYY-MM-DD'),
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().add(1, 'week').format('YYYY-MM-DD'),

    availableCreditType: ['buy', 'contract', 'mileage'],

    price: 0,
    maxAttendeeCount: 0,

    content: '',
  }

  return (
    <Modal
      title="이벤트"
      okText="저장"
      onOk={onOk}
      confirmLoading={loading}
      visible={visible}
      onCancel={onClose}
      maskClosable={false}
    >
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <HiddenItems names={['bannerImageId']} />

          <Form.Item label="사진" required>
            <Image src={imageUri} style={{ width: '200px', height: '200px', marginBottom: '10px' }} />
            <UploadButton.Image
              file={null}
              accept="image/*"
              label=""
              category="rental"
              onUploadDone={onUploadDone}
              hideDownload
            />
          </Form.Item>

          <Form.Item name="title" label="제목" required rules={[formRule.required]}>
            <Input />
          </Form.Item>

          <Divider />

          <Form.Item name="onlineFlag" label="온라인" valuePropName="checked">
            <Switch />
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const isOnlineEvent = getFieldValue('onlineFlag')
              const eventFormRule = isOnlineEvent ? [] : [formRule.required]

              return (
                <Form.Item noStyle hidden={isOnlineEvent}>
                  <Row gutter={20}>
                    <Col span={14}>
                      <Form.Item name="address" label="주소" required rules={eventFormRule}>
                        <Input onClick={() => onVisibleFinder()} readOnly placeholder="클릭하여 주소 검색" />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item name="addressDetail" label="상세주소">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item name="place" label="장소이름" required rules={eventFormRule}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="lng" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name="lat" hidden>
                    <Input />
                  </Form.Item>
                </Form.Item>
              )
            }}
          </Form.Item>

          <Divider />

          <Form.Item
            name="openDate"
            label="이벤트 공개일"
            getValueProps={(d) => ({ value: dayjs(d) })}
            getValueFromEvent={(_, s) => s}
            required
            rules={[formRule.required]}
          >
            <LocalDatePicker />
          </Form.Item>

          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="applyStartDate"
                label="신청 시작"
                getValueProps={(d) => ({ value: dayjs(d) })}
                getValueFromEvent={(_, s) => s}
                required
                rules={[formRule.required]}
              >
                <LocalDatePicker />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="applyEndDate"
                label="신청 종료"
                getValueProps={(d) => ({ value: dayjs(d) })}
                getValueFromEvent={(_, s) => s}
                required
                rules={[formRule.required]}
              >
                <LocalDatePicker />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="startDate"
                label="이벤트 시작"
                getValueProps={(d) => ({ value: dayjs(d) })}
                getValueFromEvent={(_, s) => s}
                required
                rules={[formRule.required]}
              >
                <LocalDatePicker />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="endDate"
                label="이벤트 종료"
                getValueProps={(d) => ({ value: dayjs(d) })}
                getValueFromEvent={(_, s) => s}
                required
                rules={[formRule.required]}
              >
                <LocalDatePicker />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          {/* NOTE: 추후 크레딧 정책 정리 후 참가비, 사용가능 크레딧 hidden 해제 */}
          <Form.Item
            name="price"
            label="참가비"
            extra="무료일 경우 0을 입력하세요."
            required
            rules={[formRule.required]}
            hidden
          >
            <InputNumber addonAfter="크레딧" min={0} style={styles.inputNumber} />
          </Form.Item>

          <Form.Item label="사용가능 크레딧" name="availableCreditType" hidden>
            <Select mode="multiple">
              {option.creditTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {creditType[type]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="maxAttendeeCount" label="최대 참가자" required rules={[formRule.required]}>
            <InputNumber addonAfter="명" min={0} style={styles.inputNumber} />
          </Form.Item>

          <Divider />

          <Form.Item name="content" label="내용" required rules={[formRule.required]}>
            <Input.TextArea style={styles.textarea} />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  textarea: { height: '200px', resize: 'none' },
  inputNumber: { width: '100%' },
}
