import { BREAKPOINTS, FREE_TRIAL_URL } from '@const'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export default function Intro(): JSX.Element {
  return (
    <Wrapper>
      <IntroBox>
        <InnerWrapper>
          <TextWrapper>
            <Title>
              가장 쉬운
              <br />
              공유공간 관리,
              <br />
              가젯
            </Title>
            <SubTitle>
              공간 운영에 대한 고민은 줄이고
              <br />더 좋은 공간 경험 제공에 집중하세요
            </SubTitle>
          </TextWrapper>
          <Link to={{ pathname: FREE_TRIAL_URL }} target="_blank">
            <FreeTrial>가젯 무료로 시작하기</FreeTrial>
          </Link>
        </InnerWrapper>
        <Image src="/images/landing/home/symbols.png" alt="gadjet-symbols" />
        <MobileImage src="/images/landing/home/symbols-m.png" alt="gadjet-mobile-symbols" />
      </IntroBox>
      <ArrowsDown src="/images/landing/home/multiple-arrows-down.svg" alt="arrows-down" />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  padding: 150px 0px 80px 0px;
  overflow: hidden;
  @media ${BREAKPOINTS.lg} {
    padding: 117px 0px 220px 0px;
  }
`

const IntroBox = styled.div`
  width: 1000px;
  height: 580px;

  background: #ffffff;
  opacity: 0.8;
  border-radius: 40px;
  padding: 80px 80px 70px 80px;
  position: relative;

  @media ${BREAKPOINTS.lg} {
    width: 335px;
    height: 470px;
    padding: 68px 40px;
    border-radius: 28px;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 313px;
  height: 430px;
  @media ${BREAKPOINTS.lg} {
    width: 256px;
    height: 314px;
    align-items: center;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Title = styled.h1`
  font-weight: 800;
  font-size: 50px;
  line-height: 140%;
  color: #2e2e2e;
  @media ${BREAKPOINTS.lg} {
    font-size: 40px;
  }
`
const SubTitle = styled.span`
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  color: #7a7b7c;
  @media ${BREAKPOINTS.lg} {
    font-size: 18px;
  }
`
const FreeTrial = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 14px 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  background: #0d0d0d;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  border-radius: 12px;
  :hover {
    background: rgba(13, 13, 13, 0.8);
    transition: all 0.3s ease-in-out;
  }
  @media ${BREAKPOINTS.lg} {
    position: absolute;
    z-index: 1;
    left: 73px;
    bottom: 88px;
  }
`
const Image = styled.img`
  width: 591px;
  height: 501px;
  position: absolute;
  top: 65px;
  right: -72px;

  @media ${BREAKPOINTS.lg} {
    display: none;
  }
`
const MobileImage = styled.img`
  display: none;
  @media ${BREAKPOINTS.lg} {
    display: block;
    width: 406px;
    height: 637px;
    position: absolute;
    top: -30px;
    left: -10px;
  }
`
const ArrowsDown = styled.img`
  width: 40px;
  height: 40px;
  @media ${BREAKPOINTS.lg} {
    display: none;
  }
`
