import Picture from '@pages/Landing/FeaturePages/atoms/Picture'
import * as S from '@pages/Landing/FeaturePages/styles'

export default function EasyPromotion(): JSX.Element {
  return (
    <S.Container
      defaultStyles={{
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '480px',
        background: 'linear-gradient(236.84deg, #eaf8f0 30.24%, #f2f0fc 92.36%)',
      }}
      mediaStyles={{ position: 'relative' }}
    >
      <S.DescriptionWrapper defaultStyle={{ textAlign: 'center', padding: '40px 0 0 0' }}>
        <S.BoxTitle>계약 관리</S.BoxTitle>
        <S.Description>
          더 유연한 프로모션 운영을 위해 <br />
          가젯이 알아서 계산해요.
        </S.Description>
      </S.DescriptionWrapper>
      <S.ImageWrapper
        defaultStyle={{ alignItems: 'flex-end', width: '100%' }}
        mediaStyle={{ justifyContent: 'flex-end' }}
      >
        <Picture
          srcPNG="/images/landing/features/contract/add-space.png"
          srcWEBP="/images/landing/features/contract/add-space.webp"
          alt="add-space"
          width={680}
          height={272}
          defaultStyle={{
            margin: '46px 0 0 0',
            filter:
              'drop-shadow(0px 8px 12px rgba(24, 39, 75, 0.06)) drop-shadow(0px 12px 42px rgba(24, 39, 75, 0.12))',
          }}
          mediaStyle={{ position: 'absolute', bottom: '0px', right: '-32.5px' }}
        />
      </S.ImageWrapper>
    </S.Container>
  )
}
