import { COLORS } from '@const'
import { Styles } from '@types'
import { Menu } from 'antd'
import { Hqs } from 'gadjet-v2-types/dist/model'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  hq: Hqs | null
  isActive?: boolean
}

export default function HqHomeButton({ hq, isActive }: Props): JSX.Element {
  const wrapperStyle = useMemo(() => {
    if (isActive) return { ...styles.wrapper, ...styles.wrapperActive }
    return styles.wrapper
  }, [isActive])

  if (!hq) return <></>

  return (
    <Menu>
      <Link to={`/hq/${hq.hqId}/dashboard`}>
        <div style={wrapperStyle}>홈</div>
      </Link>
    </Menu>
  )
}

const styles: Styles = {
  wrapper: {
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    color: 'black',
  },
  wrapperActive: { backgroundColor: COLORS.active, color: COLORS.primary },
  logo: { maxWidth: '70%', maxHeight: '34px' },
  name: { fontSize: '24px', color: 'black' },
}
