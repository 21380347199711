import { Layout } from 'antd'

import Footer from '@pages/Landing/organisms/Footer'
import Header from '@pages/Landing/organisms/Header'

type Props = {
  children: JSX.Element
}

export default function LandingTemplate({ children }: Props): JSX.Element {
  return (
    <Layout>
      <Header />
      <Layout.Content>{children}</Layout.Content>
      <Footer />
    </Layout>
  )
}
