import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  image: { src: string; alt: string }
  content: ReactNode
  reviewer: ReactNode
  highlight: { color: string; width: string; top: string; left: string }
}

export default function ReviewCard({ image, content, reviewer, highlight }: Props): JSX.Element {
  return (
    <Wrapper>
      <ReviewContent>
        {content}
        <Highlight {...highlight} />
      </ReviewContent>
      <ReviewerWrapper>
        <Image src={image.src} alt={image.alt} width={86} height={86} />
        {reviewer && <Reviewer>- {reviewer} 님</Reviewer>}
      </ReviewerWrapper>
    </Wrapper>
  )
}

type StyleProps = {
  width: string
  color: string
  top: string
  left: string
}

const Wrapper = styled.li`
  width: 300px;
  min-width: 300px;
  height: 340px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 32px;
  list-style: none;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
`
const ReviewContent = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: -0.2px;
  color: #595a5b;
  position: relative;
  z-index: 0;
`

const ReviewerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Reviewer = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: right;
  color: #9ea4aa;
`

const Image = styled.img`
  border-radius: 50%;
`
const Highlight = styled.span`
  top: ${({ top }: StyleProps) => top};
  left: ${({ left }: StyleProps) => left};
  width: ${({ width }: StyleProps) => width};
  height: 16px;
  z-index: -1;
  position: absolute;
  background: ${({ color }: StyleProps) => color};
`
