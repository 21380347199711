import { Form, FormInstance, Input, Space } from 'antd'
import { Branches, Images } from 'gadjet-v2-types/dist/model'

import mask from '@utils/mask'

import UploadButton from '@components/molecules/UploadButton'

type Props = {
  form: FormInstance<Branches>
}

export default function BranchRenteeForm({ form }: Props): JSX.Element {
  const onUploadSignatureImage = ({ imageId }: Images) => {
    const values = form.getFieldValue(['rentee'])
    form.setFieldsValue({ rentee: { ...values, signatureImageId: imageId } })
  }
  return (
    <>
      <Form.Item label="임대인 이름" name={['rentee', 'name']}>
        <Input />
      </Form.Item>
      <Form.Item label="임대인 사업자번호" name={['rentee', 'businessNumber']} {...mask.businessLicense}>
        <Input />
      </Form.Item>
      <Form.Item label="임대인 법인번호" name={['rentee', 'corporationNumber']} {...mask.personalLicense}>
        <Input />
      </Form.Item>
      <Form.Item label="임대인 주소" name={['rentee', 'address']}>
        <Input />
      </Form.Item>
      <Form.Item label="임대인 연락처" name={['rentee', 'contact']} {...mask.contact}>
        <Input />
      </Form.Item>

      <Form.Item shouldUpdate label="파일">
        <Space direction="vertical" style={{ width: '100%' }}>
          <UploadButton.Image
            category="signature"
            label="임대인 서명"
            accept="image/*"
            file={null}
            onUploadDone={onUploadSignatureImage}
          />
        </Space>
      </Form.Item>
      <Form.Item label="서명" name={['rentee', 'signatureImageId']} hidden>
        <Input />
      </Form.Item>
    </>
  )
}
