/* eslint-disable react/no-array-index-key */
import { Styles } from '@types'
import { Space, Table, TableColumnType, Tag } from 'antd'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@reducers/index'

import ModalAlert from '@components/molecules/ModalAlert'

import columns from '../columns'

type Props = { visible: boolean; onClose: () => void }

export default function SpaceUploadGuideModal({ visible, onClose }: Props): JSX.Element {
  const spaceTypes = useSelector((state: RootState) => state.branch.spaceTypes) || []

  const tableColumns: TableColumnType<ReturnType<typeof columns>[number]>[] = useMemo(
    () => [
      {
        key: 'title',
        dataIndex: 'title',
        title: '항목',
        render: (title, { required }) => (
          <div style={{ width: '110px' }}>
            {title}
            {required && <span style={{ color: 'red' }}>*</span>}
          </div>
        ),
      },
      {
        key: 'guide',
        dataIndex: 'guide',
        title: '가이드',
        render: (_, { guide }) => (
          <ul>
            {guide.map((str, index) => (
              <li key={index}>{str}</li>
            ))}
          </ul>
        ),
      },
      {
        key: 'options',
        dataIndex: 'options',
        title: '선택지',
        render: (_, { options = [] }) => (
          <Space style={{ width: '150px', flexWrap: 'wrap' }}>
            {options.map(({ label, value }) => (
              <Tag key={value}>{label}</Tag>
            ))}
          </Space>
        ),
      },
    ],
    []
  )

  return (
    <ModalAlert title="템플릿 가이드" visible={visible} onCancel={onClose} width="1000px">
      <Table
        size="small"
        dataSource={columns(spaceTypes)}
        columns={tableColumns}
        pagination={{ pageSize: 500, hideOnSinglePage: true }}
        style={styles.table}
      />
    </ModalAlert>
  )
}

const styles: Styles = {
  alert: { marginBottom: '10px' },
  table: { wordBreak: 'keep-all', whiteSpace: 'pre-wrap' },
}
