import { BranchRentalReservationAction } from '@pages/Branch/RentalReservationPage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchRentalReservationAction) => void
}

export default function BranchRentalReservationTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [{ label: '추가', buttonType: 'default', onClick: () => onAction('add') }]

  return <PageTitle title="예약내역" actions={actions} noItemOnTop />
}
