import { Input, Modal, Form, Col, Row, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { Notices } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'

import NoticeAPI from '@apis/branch/notice'
import HqNoticeAPI from '@apis/hq/notice'

import LocalDatePicker from '@components/atoms/LocalDatePicker'
import Loading from '@components/molecules/Loading'
import TextEditor from '@components/molecules/TextEditor'

type Props = {
  hqId: number
  branchId?: number
  noticeId?: number
  visible: boolean
  onClose: () => void
  onDone?: () => void
}

export default function NoticeFormModal({ hqId, branchId, noticeId, visible, onClose, onDone }: Props): JSX.Element {
  const [form] = useForm<Notices>()
  const [loading, setLoading] = useState(false)

  const getNotice = async () => {
    if (!noticeId) return
    const call = branchId
      ? NoticeAPI.getNoticeDetail({ hqId, branchId, noticeId })
      : HqNoticeAPI.getNoticeDetail({ hqId, noticeId })

    const { data } = await call
    form.setFieldsValue(data)
  }

  const onOk = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)

      // branch
      if (branchId) {
        if (noticeId) await NoticeAPI.updateNotice({ hqId, branchId, noticeId }, { notice: values })
        else await NoticeAPI.addNotice({ hqId, branchId }, { notice: values })
      }
      // hq
      if (!branchId) {
        if (noticeId) await HqNoticeAPI.updateNotice({ hqId, noticeId }, { notice: values })
        else await HqNoticeAPI.addNotice({ hqId }, { notice: values })
      }

      setLoading(false)
      if (onDone) onDone()
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const reset = () => {
    form.resetFields()
    if (noticeId) getNotice()
  }

  useEffect(() => {
    if (visible) reset()
  }, [visible])

  const initialValues = { title: '', content: '', writeDate: dayjs().format('YYYY-MM-DD'), openFlag: false }

  return (
    <Modal
      title="공지사항"
      visible={visible}
      okText="저장"
      onOk={onOk}
      confirmLoading={loading}
      width="800px"
      onCancel={onClose}
      maskClosable={false}
    >
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label="제목" name="title" required>
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="작성일"
                name="writeDate"
                required
                getValueProps={(str) => ({ value: dayjs(str) })}
                getValueFromEvent={(_, str) => str}
              >
                <LocalDatePicker />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="공개" name="openFlag" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="내용" name="content" required>
            <TextEditor name="content" form={form} />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}
