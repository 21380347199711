import { Rentals } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { RentalSearch } from 'gadjet-v2-types/dist/type/search'

// ActionType
export const SEARCH_RENTALS = 'rental/SEARCH_RENTALS' as const
export const SET_RENTAL_DEFAULT = 'rental/SET_DEFAULT' as const
export const SET_RENTAL_LOADING = 'rental/SET_RENTAL_LOADING' as const
export const SET_RENTAL_SEARCH_RESULT = 'rental/SET_RENTAL_SEARCH_RESULT' as const
export const SET_RENTAL_SEARCH = 'rental/SET_RENTAL_SEARCH' as const
export const SET_RENTAL_PAGINATION = 'rental/SET_RENTAL_PAGINATION' as const
export const SET_RENTAL_PAGE_CONFIG = 'rental/SET_RENTAL_PAGE_CONFIG' as const

// Action
export const searchRentals = (hqId: number, branchId: number) => ({
  type: SEARCH_RENTALS,
  payload: { hqId, branchId },
})
export const setRentalDefault = (hqId: number, branchId: number) => ({
  type: SET_RENTAL_DEFAULT,
  payload: { hqId, branchId },
})
export const setRentalLoading = (loading: boolean) => ({
  type: SET_RENTAL_LOADING,
  payload: { loading },
})
export const setRentalSearchResult = (result: Partial<RentalState['searchResult']>) => ({
  type: SET_RENTAL_SEARCH_RESULT,
  payload: { result },
})
export const setRentalSearch = (search: Partial<RentalSearch>) => ({
  type: SET_RENTAL_SEARCH,
  payload: { search },
})
export const setRentalPagination = (pagination: Partial<Pagination>) => ({
  type: SET_RENTAL_PAGINATION,
  payload: { pagination },
})

export type RentalAction =
  | ReturnType<typeof searchRentals>
  | ReturnType<typeof setRentalDefault>
  | ReturnType<typeof setRentalLoading>
  | ReturnType<typeof setRentalSearchResult>
  | ReturnType<typeof setRentalSearch>
  | ReturnType<typeof setRentalPagination>

type RentalState = {
  loading: boolean
  searchResult: { branchId: number; total: number; rentals: Rentals[] }
  search: RentalSearch
  pagination: Pagination
}

const rentalState: RentalState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, rentals: [] },
  search: { query: '' },
  pagination: { current: 1, pageSize: 1000 },
}

const rentalReducer = (state = rentalState, action: RentalAction): RentalState => {
  switch (action.type) {
    case SET_RENTAL_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_RENTAL_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_RENTAL_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_RENTAL_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    default:
      return state
  }
}

export default rentalReducer
