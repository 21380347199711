import { Modal } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'

export default function MobileAppPopUp(): JSX.Element {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true)

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    const expiry: string | null = dayjs().add(7, 'day').format('YYYY/MM/DD')
    localStorage.setItem('loginNotice', expiry)
  }

  const images = [
    {
      url: '/images/popup/gadjet-modal_login-notice02.png',
      target: 'login-notice',
      link: 'https://gadjet.notion.site/840cdcfbf6664b7daee8a22a53266b38',
      width: '370px',
      height: '500px',
    },
  ]

  const component = images.map((image) => (
    <a key={image.target} href={image.link} target="_blank" rel="noreferrer">
      <img
        src={image.url}
        alt={image.target}
        aria-hidden="true"
        style={style}
        width={image.width}
        height={image.height}
      />
    </a>
  ))
  return (
    <Modal
      closable={false}
      visible={isModalVisible}
      mask
      onOk={handleOk}
      onCancel={handleCancel}
      okText="닫기"
      cancelText="다시보지 않기"
      focusTriggerAfterClose={false}
      maskClosable={false}
      width="max-content"
      bodyStyle={{ padding: 0, margin: 0, borderRadius: '16px 16px 0px 0px', overflow: 'hidden' }}
      okButtonProps={{ type: 'default' }}
      centered
    >
      {component}
    </Modal>
  )
}

const style = { margin: 0, cursor: 'pointer' }
