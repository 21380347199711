import { BREAKPOINTS } from '@const'
import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  icon: { src: string; alt: string }
  text: string | ReactNode
  background: { src: string; width: number; height: number }
  mobileBackground: string
}

export default function BalloonBlack({ icon, text, background, mobileBackground }: Props): JSX.Element {
  return (
    <Balloon>
      <InnerWrapper>
        <Icon src={icon.src} alt={icon.alt} />
        <Text>{text}</Text>
      </InnerWrapper>
      <Background src={background.src} width={background.width} height={background.height} alt="text-balloon-black" />
      <MobileBackground src={mobileBackground} alt="text-balloon-black" width={330} height={181} />
    </Balloon>
  )
}

const Balloon = styled.div`
  position: relative;
`

const Background = styled.img`
  filter: drop-shadow(0px 6px 12px rgba(24, 39, 75, 0.12)) drop-shadow(0px 8px 24px rgba(24, 39, 75, 0.08));
  @media ${BREAKPOINTS.lg} {
    display: none;
  }
`

const MobileBackground = styled.img`
  display: none;

  @media ${BREAKPOINTS.lg} {
    filter: drop-shadow(0px 6px 12px rgba(24, 39, 75, 0.12)) drop-shadow(0px 8px 24px rgba(24, 39, 75, 0.08));
    display: block;
  }
`

const Icon = styled.img`
  width: 90px;
  height: 90px;
  filter: drop-shadow(0px 6px 12px -6px rgba(24, 39, 75, 0.12)) drop-shadow(0px 8px 24px -4px rgba(24, 39, 75, 0.08));
  @media ${BREAKPOINTS.lg} {
    width: 64px;
    height: 64px;
  }
`

const Text = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #9ea4aa;
  @media ${BREAKPOINTS.lg} {
    font-size: 20px;
  }
`

const InnerWrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin: 22px 30px 0px 22px;
  @media ${BREAKPOINTS.lg} {
    margin: 20px;
  }
`
