// ActionType
export const GET_HQ_CONFIG = 'hq/config/GET_HQ_CONFIG' as const
export const SET_HQ_CONFIG = 'hq/config/SET_HQ_CONFIG' as const
export const SET_HQ_CONFIG_LOADING = 'hq/config/SET_HQ_CONFIG_LOADING' as const

// Action
export const getHqConfig = (hqId: number, cb?: () => void) => ({
  type: GET_HQ_CONFIG,
  payload: { hqId },
})
export const setHqConfig = (config: unknown) => ({
  type: SET_HQ_CONFIG,
  payload: { config },
})
export const setHqConfigLoading = (loading: boolean) => ({
  type: SET_HQ_CONFIG_LOADING,
  payload: { loading },
})

export type HqConfigAction =
  | ReturnType<typeof getHqConfig>
  | ReturnType<typeof setHqConfig>
  | ReturnType<typeof setHqConfigLoading>

type HqConfigState = {
  config: unknown
  loading: boolean
}

const hqConfigState: HqConfigState = {
  config: {},
  loading: false,
}

const hqConfigReducer = (state = hqConfigState, action: HqConfigAction): HqConfigState => {
  switch (action.type) {
    case SET_HQ_CONFIG:
      return { ...state, config: action.payload.config }
    case SET_HQ_CONFIG_LOADING:
      return { ...state, loading: action.payload.loading }
    default:
      return state
  }
}

export default hqConfigReducer
