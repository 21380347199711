import { Alert, AlertProps } from 'antd'
import styled from 'styled-components'

export default function AntAlert(props: AlertProps): JSX.Element {
  return <CustomAlert {...props} />
}

const CustomAlert = styled(Alert)`
  border-radius: 8px;
  padding: 10px 12px 10px 12px;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #2e2e2e;
  .ant-alert-warning {
    background-color: #fff9e9;
    border: 1px solid #ffe293;
  }
`
