import Picture from '@pages/Landing/FeaturePages/atoms/Picture'
import * as S from '@pages/Landing/FeaturePages/styles'

export default function ManageSpace(): JSX.Element {
  return (
    <S.SectionInnerWrapper>
      <S.Container
        defaultStyles={{
          flexDirection: 'row-reverse',
          alignItems: 'center',
          height: '460px',
          position: 'relative',
          background: '#F2F0FC',
        }}
        mediaStyles={{ flexDirection: 'column' }}
      >
        <S.DescriptionWrapper defaultStyle={{ padding: '0 0 0 60px' }} mediaStyle={{ padding: '40px 0 0 30px' }}>
          <S.BoxTitle>공간 관리</S.BoxTitle>
          <S.Description>
            우리 공간을 운영하는
            <br />
            실제 방식대로
            <br />딱 맞춰 관리해요.
          </S.Description>
        </S.DescriptionWrapper>
        <S.ImageWrapper defaultStyle={{ alignItems: 'center', width: '100%' }}>
          <Picture
            srcPNG="/images/landing/features/space/space-setting.png"
            srcWEBP="/images/landing/features/space/space-setting.webp"
            alt="space-setting"
            width={326}
            height={394}
            mediaScale={0.93}
          />
        </S.ImageWrapper>
      </S.Container>

      <S.Container
        defaultStyles={{
          flexDirection: 'column',
          alignItems: 'center',
          background: 'linear-gradient(95.81deg, #E4F6EC 5.02%, #F3F4F5 99.87%)',
          height: '480px',
          position: 'relative',
        }}
      >
        <S.DescriptionWrapper defaultStyle={{ padding: '40px 0 0 80px' }} mediaStyle={{ padding: '40px 0 0 30px' }}>
          <S.BoxTitle>공실률 확인</S.BoxTitle>
          <S.Description>
            공간의 공실률도
            <br />
            실시간으로 확인할 수 있어요.
          </S.Description>
        </S.DescriptionWrapper>
        <S.ImageWrapper defaultStyle={{ alignItems: 'center', width: '100%' }}>
          <Picture
            srcPNG="/images/landing/features/space/empty-space.png"
            srcWEBP="/images/landing/features/space/empty-space.webp"
            alt="empty-space"
            width={840}
            height={410}
            mediaScale={0.75}
            defaultStyle={{ position: 'absolute', bottom: 0 }}
            mediaStyle={{ margin: '24px 0 0 0' }}
          />
        </S.ImageWrapper>
      </S.Container>
    </S.SectionInnerWrapper>
  )
}
