import { ReceiptStatus } from 'gadjet-v2-types/dist/type'

import TypeTag from '@components/molecules/TypeTag'

type Props = {
  label: string
  value?: ReceiptStatus
}

export default function ReceiptStatusTag({ label, value }: Props): JSX.Element {
  return (
    <TypeTag<ReceiptStatus>
      label={label}
      value={value}
      switchCase={{
        types: ['default', 'ongoing', 'success', 'fail'],
        values: [
          ['none', 'before-receipt'],
          ['cash-receipt-request', 'tax-invoice-request'],
          ['tax-invoice-done', 'cash-receipt-done', 'card', 'seperately-receipt'],
          ['cash-receipt-cancel', 'tax-invoice-updated', 'tax-invoice-fail', 'cash-receipt-fail'],
        ],
      }}
    />
  )
}
