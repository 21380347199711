import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { useLocation } from 'react-router-dom'

const GTM_CONFIG = {
  gtmId: 'GTM-KWNJGTL',
}

const GA_TRACKING_ID = 'G-S78SYVVMHY'

const google = {
  gtmInitialize: () => {
    TagManager.initialize(GTM_CONFIG)
  },
  useGAPageTracking: () => {
    const location = useLocation()
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
      ReactGA.initialize(GA_TRACKING_ID)
      setInitialized(true)
    }, [])

    useEffect(() => {
      if (initialized) {
        ReactGA.pageview(location.pathname + location.search)
      }
    }, [initialized, location])
  },
}

export default google
