import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { useDispatch } from 'react-redux'

import { getHqNotices } from '@reducers/hq/notice.reducer'

import ActionButtons, { ActionButton } from '@components/molecules/ActionButtons'
import HqNoticeListTable from '@components/organisms/Hq/Notice/ListTable'
import NoticeDeleteModal from '@components/organisms/Notice/Modals/DeleteModal'
import NoticeDetailModal from '@components/organisms/Notice/Modals/DetailModal'
import NoticeFormModal from '@components/organisms/Notice/Modals/FormModal'

export type DashboardNoticeAction = 'update' | 'delete' | 'add' | 'detail'

export default function NoticePage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId } = useMenu()

  const [formModal, onVisibleFormModal, onCloseFormModal] = useModal<{ noticeId?: number }>({
    visible: false,
    noticeId: 0,
  })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal({ visible: false, noticeId: 0 })
  const [detailModal, onVisibleDetailModal, onCloseDetailModal] = useModal({ visible: false, noticeId: 0 })

  const onDone = () => {
    dispatch(getHqNotices(hqId, { current: 1, pageSize: 20 }))
  }

  const onAction = (action: DashboardNoticeAction, noticeId?: number) => {
    if (action === 'add') onVisibleFormModal({ noticeId: undefined })
    if (action === 'update') onVisibleFormModal({ noticeId })
    if (action === 'delete') onVisibleDeleteModal({ noticeId })
    if (action === 'detail') onVisibleDetailModal({ noticeId })
  }

  const actions: ActionButton[] = [
    { key: 'add', label: '공지사항 추가', type: 'primary', onClick: () => onAction('add') },
  ]

  return (
    <div>
      <ActionButtons actions={actions} />
      <HqNoticeListTable hqId={hqId} onAction={onAction} />

      <NoticeDetailModal
        hqId={hqId}
        noticeId={detailModal.noticeId}
        visible={detailModal.visible}
        onClose={onCloseDetailModal}
      />

      <NoticeFormModal
        hqId={hqId}
        noticeId={formModal.noticeId}
        visible={formModal.visible}
        onClose={onCloseFormModal}
        onDone={onDone}
      />

      <NoticeDeleteModal
        hqId={hqId}
        noticeId={deleteModal.noticeId}
        visible={deleteModal.visible}
        onClose={onCloseDeleteModal}
        onDone={onDone}
      />
    </div>
  )
}
