import { Styles } from '@types'
import { Divider, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getHqDashboardSpaceTypes } from '@reducers/hq/dashboard.reducer'
import { RootState } from '@reducers/index'

import DashboardCard from '@components/molecules/Dashboard/Card'
import Loading from '@components/molecules/Loading'

import SpaceTypeItem from './SpaceTypeItem'

type Props = { hqId: number }

export default function DashboardSpaces({ hqId }: Props): JSX.Element {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const branchOptions = useSelector((state: RootState) =>
    state.hqBranch.branches.map(({ name, branchId }) => ({ label: name, value: branchId }))
  )
  const spaceTypes = useSelector((state: RootState) => state.hqDashboard.spaceTypes)
  const [branchId, setBranchId] = useState(0)

  const getDatas = async () => {
    setLoading(true)
    dispatch(
      getHqDashboardSpaceTypes(hqId, branchId, () => {
        setLoading(false)
      })
    )
  }

  useEffect(() => {
    if (hqId && branchId) getDatas()
  }, [hqId, branchId])

  useEffect(() => {
    if (branchId === 0 && branchOptions.length > 0) setBranchId(branchOptions[0].value)
  }, [branchOptions])

  return (
    <DashboardCard
      title="공간 현황"
      loading={loading}
      bodyStyle={styles.body}
      extra={
        <Select<number>
          value={branchId}
          options={branchOptions}
          size="small"
          style={styles.select}
          onChange={setBranchId}
        />
      }
    >
      <Loading loading={loading}>
        {spaceTypes.map((st) => (
          <div key={st.spaceTypeId}>
            <SpaceTypeItem spaceType={st} />
            <Divider />
          </div>
        ))}
      </Loading>
    </DashboardCard>
  )
}

const styles: Styles = {
  body: { height: undefined },
  select: { width: '120px' },
}
