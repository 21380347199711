import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { NoticeSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setNoticePagination, setNoticeSearch, searchNotices, setNoticeDefault } from '@reducers/branch/notice.reducer'
import { RootState } from '@reducers/index'

import BranchNoticeListTable from '@components/organisms/Branch/Notice/ListTable'
import BranchNoticeSearchBox from '@components/organisms/Branch/Notice/SearchBox'
import BranchNoticeTitle from '@components/organisms/Branch/Notice/Title'
import NoticeDeleteModal from '@components/organisms/Notice/Modals/DeleteModal'
import NoticeFormModal from '@components/organisms/Notice/Modals/FormModal'

export type BranchNoticeAction = 'add' | 'update' | 'delete'

export default function NoticePage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const { loading, search, pagination, searchResult } = useSelector((state: RootState) => state.notice)

  const [formModal, onVisibleFormModal, onCloseFormModal] = useModal<{ noticeId?: number }>({
    visible: false,
    noticeId: undefined,
  })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal<{ noticeId?: number }>({
    visible: false,
    noticeId: undefined,
  })

  const onChangeSearch = (_search: Partial<NoticeSearch>) => dispatch(setNoticeSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setNoticePagination(_pagination))
    dispatch(searchNotices(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setNoticePagination({ current: 1 }))
    dispatch(searchNotices(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setNoticeDefault(hqId, branchId)), [hqId, branchId])
  const onDone = () => onResetAndSearch()

  const onAction = (action: BranchNoticeAction, noticeId?: number) => {
    if (action === 'add') onVisibleFormModal({ noticeId: undefined })
    if (action === 'update') onVisibleFormModal({ noticeId })
    if (action === 'delete') onVisibleDeleteModal({ noticeId })
  }

  useEffect(() => {
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchNoticeSearchBox search={search} onChangeSearch={onChangeSearch} onSearch={onSearch} />

      <BranchNoticeTitle onAction={onAction} />

      <BranchNoticeListTable
        hqId={hqId}
        branchId={branchId}
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        notices={searchResult.notices}
        total={searchResult.total}
        onAction={onAction}
      />

      <NoticeFormModal
        hqId={hqId}
        branchId={branchId}
        noticeId={formModal.noticeId}
        visible={formModal.visible}
        onClose={onCloseFormModal}
        onDone={onDone}
      />

      <NoticeDeleteModal
        hqId={hqId}
        branchId={branchId}
        noticeId={deleteModal.noticeId}
        visible={deleteModal.visible}
        onClose={onCloseDeleteModal}
        onDone={onDone}
      />
    </div>
  )
}
