import useMenu from '@hooks/useMenu'
import { Col, Row } from 'antd'

import HqGraphContracts from '@components/organisms/Hq/Graph/Contracts'
import HqGraphExpectPayments from '@components/organisms/Hq/Graph/ExpectPayments'
import HqGraphPayments from '@components/organisms/Hq/Graph/Payments'

// import HqGraphSpaceTypes from '@components/organisms/Hq/Graph/SpaceTypes'

export default function GraphPage(): JSX.Element {
  const { hqId } = useMenu()
  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <HqGraphPayments hqId={hqId} />
      </Col>
      <Col span={24}>
        <HqGraphExpectPayments hqId={hqId} />
      </Col>
      <Col span={24}>
        <HqGraphContracts hqId={hqId} />
      </Col>
      {/* <Col span={24}>
        <HqGraphSpaceTypes hqId={hqId} />
      </Col> */}
    </Row>
  )
}
