import { ReactComponent as Info } from '@assets/Icons/info-dark.svg'
import { Tooltip, TooltipProps } from 'antd'

export default function HelpTooltip(props: TooltipProps): JSX.Element {
  return (
    <Tooltip {...props} overlayInnerStyle={{ padding: '8px 12px', fontWeight: 300, borderRadius: '6px' }}>
      <Info />
    </Tooltip>
  )
}
