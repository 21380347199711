import { all } from 'redux-saga/effects'

import auth from './auth.saga'
import accessLog from './branch/access-log.saga'
import addition from './branch/addition.saga'
import bill from './branch/bill.saga'
import branch from './branch/branch.saga'
import cash from './branch/cash.saga'
import contract from './branch/contract.saga'
import event from './branch/event.saga'
import expenditure from './branch/expenditure.saga'
import menu from './branch/menu.saga'
import notice from './branch/notice.saga'
import notification from './branch/notification.saga'
import payment from './branch/payment.saga'
import productSales from './branch/product-sale.saga'
import product from './branch/product.saga'
import receipt from './branch/receipt.saga'
import rentalReservation from './branch/rental-reservation.saga'
import rental from './branch/rental.saga'
import serviceSales from './branch/service-sale.saga'
import service from './branch/service.saga'
import space from './branch/space.saga'
import tenant from './branch/tenant.saga'
import hqBranch from './hq/branch.saga'
import hqConfig from './hq/config.saga'
import hqDashboard from './hq/dashboard.saga'
import hqGraph from './hq/graph.saga'
import hq from './hq/hq.saga'
import hqNotice from './hq/notice.saga'
import hqRole from './hq/role.saga'
import option from './option.saga'

export default function* rootSaga(): Generator {
  yield all([
    auth(),
    contract(),
    bill(),
    receipt(),
    space(),
    branch(),
    hq(),
    rental(),
    rentalReservation(),
    tenant(),
    notice(),
    product(),
    productSales(),
    service(),
    serviceSales(),
    expenditure(),
    payment(),
    event(),
    addition(),
    cash(),
    accessLog(),
    menu(),
    option(),
    notification(),
    hqBranch(),
    hqConfig(),
    hqDashboard(),
    hqGraph(),
    hqNotice(),
    hqRole(),
  ])
}
