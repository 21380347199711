import { Col, Row } from 'antd'
import { Branches } from 'gadjet-v2-types/dist/model'

import BranchBusinessForm from '../../Forms/BranchBusinessForm'

type Props = { hqId: number; branchId: number; branch?: Branches; onRefresh: () => void }

export default function BranchBusinessTab({ hqId, branchId, branch, onRefresh }: Props): JSX.Element {
  return (
    <Row gutter={20}>
      <Col span={10}>
        <BranchBusinessForm hqId={hqId} branchId={branchId} branch={branch} onRefresh={onRefresh} />
      </Col>
    </Row>
  )
}
