import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import ExpenditureAPI from '@apis/branch/expenditure'

import {
  ExpenditureAction,
  setExpenditureLoading,
  setExpenditureSearchResult,
  SEARCH_EXPENDITURE,
  SET_EXPENDITURE_DEFAULT,
  SET_EXPENDITURE_PAGINATION,
  SET_EXPENDITURE_SEARCH,
  SET_EXPENDITURE_PAGE_CONFIG,
  setExpenditurePagination,
  searchExpenditure,
  setExpenditureSearch,
  setExpenditureSummary,
} from '@reducers/branch/expenditure.reducer'
import { RootState } from '@reducers/index'
import { setStorageExpenditure } from '@reducers/storage.reducer'

function* searchExpenditureSaga(action: ExpenditureAction): Generator {
  if (action.type !== SEARCH_EXPENDITURE) return
  const { hqId, branchId } = action.payload
  yield* put(setExpenditureLoading(true))

  const search = yield* select((state: RootState) => state.expenditure.search)
  const pagination = yield* select((state: RootState) => state.expenditure.pagination)

  const {
    data: { expenditure, total },
  } = yield* call(ExpenditureAPI.getExpenditure, { hqId, branchId }, { ...search, ...pagination })

  const { data: summary } = yield* call(ExpenditureAPI.getExpenditureSummary, { hqId, branchId }, { ...search })

  yield* put(setExpenditureSummary(summary))
  yield* put(setExpenditureSearchResult({ branchId, expenditure, total }))

  yield* put(setExpenditureLoading(false))
}

function* savePageConfigSaga(action: ExpenditureAction): Generator {
  const state: Parameters<typeof setStorageExpenditure>[0] = {}

  if (action.type === SET_EXPENDITURE_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageExpenditure(state))
}

function* setDefaultPageConfigSaga(action: ExpenditureAction): Generator {
  if (action.type !== SET_EXPENDITURE_DEFAULT) return
  const { pageSize } = yield* select((state: RootState) => state.storage.expenditureConfig)
  yield* all([put(setExpenditureSearch({})), put(setExpenditurePagination({ pageSize }))])

  const { hqId, branchId } = action.payload
  yield* put(searchExpenditure(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_EXPENDITURE, searchExpenditureSaga),
    takeEvery(SET_EXPENDITURE_SEARCH, savePageConfigSaga),
    takeEvery(SET_EXPENDITURE_PAGINATION, savePageConfigSaga),
    takeEvery(SET_EXPENDITURE_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_EXPENDITURE_DEFAULT, setDefaultPageConfigSaga),
  ])
}
