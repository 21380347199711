import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import ProductAPI from '@apis/branch/product'

import {
  ProductSaleAction,
  setProductSaleLoading,
  setProductSaleSearchResult,
  SEARCH_PRODUCT_SALES,
  SET_PRODUCT_SALE_DEFAULT,
  SET_PRODUCT_SALE_PAGINATION,
  SET_PRODUCT_SALE_SEARCH,
  SET_PRODUCT_SALE_PAGE_CONFIG,
  setProductSalePagination,
  searchProductSales,
  setProductSaleSearch,
  setProductSaleProduct,
} from '@reducers/branch/product-sale.reducer'
import { RootState } from '@reducers/index'
import { setStorageProductSale } from '@reducers/storage.reducer'

function* searchProductSaleSaga(action: ProductSaleAction): Generator {
  if (action.type !== SEARCH_PRODUCT_SALES) return
  const { hqId, branchId } = action.payload
  yield* put(setProductSaleLoading(true))

  const search = yield* select((state: RootState) => state.productSale.search)
  const pagination = yield* select((state: RootState) => state.productSale.pagination)

  const {
    data: { productSales, total },
  } = yield* call(ProductAPI.getProductSales, { hqId, branchId }, { ...search, ...pagination })

  yield* put(setProductSaleSearchResult({ branchId, productSales, total }))

  yield* put(setProductSaleLoading(false))
}

function* getProducts(hqId: number, branchId: number): Generator {
  yield* put(setProductSaleProduct({ loading: true }))
  const {
    data: { products },
  } = yield* call(ProductAPI.getProducts, { hqId, branchId }, { current: 1, pageSize: 500, query: '' })
  yield* put(setProductSaleProduct({ list: products, loading: false }))
}

function* savePageConfigSaga(action: ProductSaleAction): Generator {
  const state: Parameters<typeof setStorageProductSale>[0] = {}

  if (action.type === SET_PRODUCT_SALE_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageProductSale(state))
}

function* setDefaultPageConfigSaga(action: ProductSaleAction): Generator {
  if (action.type !== SET_PRODUCT_SALE_DEFAULT) return
  const { hqId, branchId } = action.payload
  const { pageSize } = yield* select((state: RootState) => state.storage.productSaleConfig)
  yield* all([put(setProductSaleSearch({})), put(setProductSalePagination({ pageSize }))])
  yield* getProducts(hqId, branchId)
  yield* put(searchProductSales(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_PRODUCT_SALES, searchProductSaleSaga),
    takeEvery(SET_PRODUCT_SALE_SEARCH, savePageConfigSaga),
    takeEvery(SET_PRODUCT_SALE_PAGINATION, savePageConfigSaga),
    takeEvery(SET_PRODUCT_SALE_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_PRODUCT_SALE_DEFAULT, setDefaultPageConfigSaga),
  ])
}
