import { notification } from 'antd'

import NoticeAPI from '@apis/branch/notice'
import HqNoticeAPI from '@apis/hq/notice'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId?: number
  noticeId?: number
  visible: boolean
  onClose: () => void
  onDone?: () => void
}

export default function NoticeDeleteModal({ hqId, branchId, noticeId, visible, onClose, onDone }: Props): JSX.Element {
  const onConfirm = async () => {
    if (!noticeId) return

    const call = branchId
      ? NoticeAPI.deleteNotice({ hqId, branchId, noticeId })
      : HqNoticeAPI.deleteNotice({ hqId, noticeId })

    await call

    notification.success({ message: '공지사항이 삭제되었습니다.' })
    if (onDone) onDone()
  }

  return (
    <ConfirmModal
      title="공지사항 삭제"
      description="공지사항을 삭제합니다."
      okText="삭제"
      visible={visible}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  )
}
