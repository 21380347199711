import { Form, Modal, notification, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { BranchAutomations } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BranchAPI from '@apis/branch'

import { getBranch } from '@reducers/branch/branch.reducer'
import { RootState } from '@reducers/index'

import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  visible: boolean
  onClose: () => void
}

export default function BillAutomationConfigModal({ hqId, branchId, visible, onClose }: Props): JSX.Element {
  const dispatch = useDispatch()
  const [form] = useForm<BranchAutomations>()
  const [loading, setLoading] = useState(false)

  const automation = useSelector((state: RootState) => state.branch.automation) || {}

  const onOk = async () => {
    const values = await form.validateFields()
    setLoading(true)
    await BranchAPI.updateBranchAutomation({ hqId, branchId }, { automation: values })
    notification.success({ message: '저장되었습니다.' })
    setLoading(false)
    getData()
    onClose()
  }

  const getData = async () => {
    dispatch(getBranch(hqId, branchId))
  }

  useEffect(() => {
    if (visible) form.setFieldsValue(automation)
  }, [visible])

  return (
    <Modal visible={visible} onCancel={onClose} title="청구서 자동화 설정" okText="저장" onOk={onOk}>
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={automation}>
          <Form.Item
            name="billing"
            label="청구서 자동 발송"
            extra="청구서 결제일에 문자, 이메일로 청구서를 발송합니다."
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="receipt"
            label="증빙 자동 발행"
            extra={
              <span>
                매 달 9일 이전 달의 청구서에 대해 세금계산서를 발행합니다.
                <br />
                단, 9일이 평일이 아닐 경우(공휴일, 주말 등) 직전 영업일에 발행됩니다.
              </span>
            }
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}
