export default [
  { value: '001', label: '한국은행', hide: false },
  { value: '002', label: '산업은행', hide: false },
  { value: '003', label: '기업은행', hide: false },
  { value: '004', label: 'KB국민은행', hide: false },
  { value: '005', label: '외환은행', hide: false },
  { value: '007', label: '수협은행', hide: false },
  { value: '011', label: 'NH농협은행', hide: false },
  { value: '020', label: '우리은행', hide: false },
  { value: '023', label: 'SC제일은행', hide: false },
  { value: '027', label: '한국씨티은행', hide: false },
  { value: '031', label: '대구은행', hide: false },
  { value: '032', label: '부산은행', hide: false },
  { value: '034', label: '광주은행', hide: false },
  { value: '035', label: '제주은행', hide: false },
  { value: '037', label: '전북은행', hide: false },
  { value: '039', label: '경남은행', hide: false },
  { value: '045', label: '새마을금고', hide: false },
  { value: '048', label: '신협', hide: false },
  { value: '071', label: '우체국', hide: false },
  { value: '081', label: '하나은행', hide: false },
  { value: '088', label: '신한은행', hide: false },
  { value: '089', label: '케이뱅크', hide: false },
  { value: '090', label: '카카오뱅크', hide: false },
].filter(({ hide }) => !hide)
