import dayjs from 'dayjs'
import { takeLeading } from 'redux-saga/effects'
import { all, call, put } from 'typed-redux-saga'

import BranchAPI from '@apis/branch'

import {
  GET_NOTIFICATIONS,
  NotificationAction,
  getNotificationsSuccess,
  READ_NOTIFICATIONS,
  GET_READ_AT_NOTIFICATIONS,
  setNotifications,
} from '@reducers/branch/notification.reducer'

const getLocalStorageKey = (branchId: number) => `notification-read-at-${branchId}`
const getNowReadAt = () => dayjs().format('YYYY-MM-DD HH:mm:ss')

function* getNotifications(action: NotificationAction): Generator {
  if (action.type !== GET_NOTIFICATIONS) return
  const { hqId, branchId } = action.payload

  const { data } = yield* call(BranchAPI.getBranchNotifications, { hqId, branchId }, { current: 1, pageSize: 1000 })
  const { branchNotifications } = data

  yield* put(getNotificationsSuccess(branchNotifications))
}

function* readNotificationsSaga(action: NotificationAction): Generator {
  if (action.type !== READ_NOTIFICATIONS) return
  const readAt = getNowReadAt()
  window.localStorage.setItem(getLocalStorageKey(action.payload.branchId), readAt)

  yield* put(setNotifications({ readAt }))
}
function* getReadAtNotificationsSaga(action: NotificationAction): Generator {
  if (action.type !== GET_READ_AT_NOTIFICATIONS) return
  const readAt = window.localStorage.getItem(getLocalStorageKey(action.payload.branchId)) || '2000-01-01 00:00:00'
  yield* put(setNotifications({ readAt }))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(GET_NOTIFICATIONS, getNotifications),
    takeLeading(READ_NOTIFICATIONS, readNotificationsSaga),
    takeLeading(GET_READ_AT_NOTIFICATIONS, getReadAtNotificationsSaga),
  ])
}
