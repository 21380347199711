import { ReactComponent as MessageLinesIcon } from '@assets/Icons/message-lines.svg'
import { Styles } from '@types'

type Props = {
  title: string
  startTime: string
  memo: string
}

export default function MonthEvent({ title, startTime, memo }: Props) {
  return (
    <div style={styles.monthEvent}>
      <span style={styles.eventText}>{`${startTime} ${title}`}</span>
      <span style={styles.memo}>{memo && <MessageLinesIcon fill="#7A7B7C" />}</span>
    </div>
  )
}

const styles: Styles = {
  monthEvent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  eventText: {
    width: '100%',
    textOverflow: 'ellipsis',
    lineHeight: '1.5',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  memo: { display: 'flex', alignItems: 'center', height: '100%' },
}
