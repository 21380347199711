import { FormInstance, Form, Input, Space } from 'antd'
import { Branches, Files, Images } from 'gadjet-v2-types/dist/model'

import mask from '@utils/mask'

import UploadButton from '@components/molecules/UploadButton'

type Props = {
  form: FormInstance<Branches>
}

export default function BranchSubletDocumentForm({ form }: Props): JSX.Element {
  const onUploadSignatureImage = ({ imageId }: Images) => {
    const values = form.getFieldValue(['sublet'])
    form.setFieldsValue({ sublet: { ...values, signatureImageId: imageId } })
  }

  const onUploadConsentFile = ({ fileId }: Files) => {
    const values = form.getFieldValue(['sublet'])
    form.setFieldsValue({ sublet: { ...values, consentFileId: fileId } })
  }

  return (
    <>
      <Form.Item label="전대인 이름" name={['sublet', 'name']}>
        <Input />
      </Form.Item>
      <Form.Item label="전대인 사업자번호" name={['sublet', 'businessNumber']} {...mask.businessLicense}>
        <Input />
      </Form.Item>
      <Form.Item label="전대인 법인번호" name={['sublet', 'corporationNumber']} {...mask.personalLicense}>
        <Input />
      </Form.Item>
      <Form.Item label="전대인 주소" name={['sublet', 'address']}>
        <Input />
      </Form.Item>
      <Form.Item label="전대인 연락처" name={['sublet', 'contact']} {...mask.contact}>
        <Input />
      </Form.Item>
      <Form.Item label="파일" shouldUpdate>
        <Space direction="vertical" style={{ width: '100%' }}>
          <UploadButton.Image
            category="signature"
            label="전대인 서명"
            accept="image/*"
            file={null}
            onUploadDone={onUploadSignatureImage}
          />
          <UploadButton.File
            category="sublet-consent"
            label="전대차 동의서"
            accept=".pdf"
            file={null}
            onUploadDone={onUploadConsentFile}
          />
        </Space>
      </Form.Item>
      <Form.Item label="서명" name={['sublet', 'signatureImageId']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label="전대차 동의서" name={['sublet', 'consentFileId']} hidden>
        <Input />
      </Form.Item>
    </>
  )
}
