import { Styles } from '@types'
import { Radio, Form, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useMemo, useState } from 'react'

import bufferToFileDownload from '@utils/download'
import formRule from '@utils/formRule'

import ContractAPI from '@apis/branch/contract'

import LocalDatePicker from '@components/atoms/LocalDatePicker'

type Props = {
  hqId: number
  branchId: number
  onDone?: () => void
  onClose?: () => void
}

export default function ContractExportModal({ hqId, branchId, onClose, onDone }: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [form] = useForm<{ criteria: 'startDate' | 'suspendDate'; dateRange: [Dayjs, Dayjs] }>()

  const onOk = async () => {
    const { criteria, dateRange } = await form.validateFields()
    setLoading(true)

    const { data } = await ContractAPI.exportContracts(
      { hqId, branchId },
      { [criteria]: [dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')] }
    )
    bufferToFileDownload('계약.xlsx', Buffer.from(data))

    setLoading(false)
    if (onDone) onDone()
    if (onClose) onClose()
  }

  const initialValues = useMemo(
    () => ({
      criteria: 'startDate',
      dateRange: [dayjs().startOf('month'), dayjs().endOf('month')],
    }),
    []
  )

  const reset = () => {
    form.resetFields()
    setLoading(false)
  }

  useEffect(() => {
    reset()
  }, [])

  return (
    <Modal visible onOk={onOk} onCancel={onClose} title="계약 다운로드" okText="다운로드" confirmLoading={loading}>
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item label="기준" name="criteria" rules={[formRule.required]}>
          <Radio.Group>
            <Radio value="startDate">계약 시작일</Radio>
            <Radio value="suspendDate">계약 종료일</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="기간" name="dateRange" rules={[formRule.required]}>
          <LocalDatePicker.RangePicker size="large" style={styles.datePicker} allowClear={false} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const styles: Styles = {
  datePicker: { width: '100%' },
}
