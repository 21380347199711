import { Styles } from '@types'
import { Card } from 'antd'
import { CSSProperties } from 'react'

import Loading from '@components/molecules/Loading'

type Props = {
  title: string
  children: JSX.Element
  loading: boolean
  bodyStyle?: CSSProperties
  extra?: React.ReactNode
}

export default function DashboardCard({ title, children, loading, bodyStyle, extra }: Props): JSX.Element {
  return (
    <Loading loading={loading}>
      <Card size="small" title={title} bodyStyle={{ ...styles.body, ...bodyStyle }} extra={extra}>
        <div style={styles.childrenWrapper}>{children}</div>
      </Card>
    </Loading>
  )
}

const styles: Styles = {
  body: { height: '300px' },
  childrenWrapper: { height: '100%', overflow: 'auto' },
}
