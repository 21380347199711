import { ContractStatus } from 'gadjet-v2-types/dist/type'
import { contractStatus } from 'gadjet-v2-types/dist/type/label'

import TypeTag from '@components/molecules/TypeTag'

export default function Status({ contract }: ContractColumnProps): JSX.Element {
  const { status } = contract

  return (
    <div>
      <TypeTag<ContractStatus>
        label={contractStatus[status]}
        value={status}
        switchCase={{
          values: [['before-started'], ['started'], ['extended'], ['expired', 'suspended']],
          types: ['default', 'success', 'ongoing', 'fail'],
        }}
      />
    </div>
  )
}
