import { takeLeading } from 'redux-saga/effects'
import { all, call, put } from 'typed-redux-saga'

import CommonAPI from '@apis/common'

import { GET_OPTION, setOption } from '@reducers/option.reducer'

function* getOptionSaga(): Generator {
  const {
    data: { gics },
  } = yield* call(CommonAPI.getGics)
  const {
    data: { banks },
  } = yield* call(CommonAPI.getBanks)
  yield* put(setOption({ gics, banks }))
}

export default function* optionSaga(): Generator {
  yield* all([takeLeading(GET_OPTION, getOptionSaga)])
}
