import { Cash } from 'gadjet-v2-types/dist/model'
import { CashSummary, Pagination } from 'gadjet-v2-types/dist/type'
import { CashSearch } from 'gadjet-v2-types/dist/type/search'

import { getDefaultRange } from '@utils/datetime'

// ActionType
export const SEARCH_CASH = 'cash/SEARCH_CASH' as const
export const SET_CASH_DEFAULT = 'cash/SET_DEFAULT' as const
export const SET_CASH_LOADING = 'cash/SET_CASH_LOADING' as const
export const SET_CASH_SEARCH_RESULT = 'cash/SET_CASH_SEARCH_RESULT' as const
export const SET_CASH_SEARCH = 'cash/SET_CASH_SEARCH' as const
export const SET_CASH_PAGINATION = 'cash/SET_CASH_PAGINATION' as const
export const SET_CASH_SUMMARY = 'cash/SET_CASH_SUMMARY' as const
export const SET_CASH_PAGE_CONFIG = 'cash/SET_CASH_PAGE_CONFIG' as const

// Action
export const searchCash = (hqId: number, branchId: number) => ({
  type: SEARCH_CASH,
  payload: { hqId, branchId },
})
export const setCashDefault = (hqId: number, branchId: number) => ({
  type: SET_CASH_DEFAULT,
  payload: { hqId, branchId },
})
export const setCashLoading = (loading: boolean) => ({
  type: SET_CASH_LOADING,
  payload: { loading },
})
export const setCashSearchResult = (result: Partial<CashState['searchResult']>) => ({
  type: SET_CASH_SEARCH_RESULT,
  payload: { result },
})
export const setCashSearch = (search: Partial<CashSearch>) => ({
  type: SET_CASH_SEARCH,
  payload: { search },
})
export const setCashPagination = (pagination: Partial<Pagination>) => ({
  type: SET_CASH_PAGINATION,
  payload: { pagination },
})
export const setCashSummary = (summary: CashSummary) => ({
  type: SET_CASH_SUMMARY,
  payload: { summary },
})

export type CashAction =
  | ReturnType<typeof searchCash>
  | ReturnType<typeof setCashDefault>
  | ReturnType<typeof setCashLoading>
  | ReturnType<typeof setCashSearchResult>
  | ReturnType<typeof setCashSearch>
  | ReturnType<typeof setCashPagination>
  | ReturnType<typeof setCashSummary>

type CashState = {
  loading: boolean
  searchResult: { branchId: number; total: number; cash: Cash[] }
  search: CashSearch
  pagination: Pagination
  summary: CashSummary
}

const cashState: CashState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, cash: [] },
  search: { query: '', date: getDefaultRange('month', 'date') },
  pagination: { current: 1, pageSize: 10 },
  summary: { amount: 0 },
}

const cashReducer = (state = cashState, action: CashAction): CashState => {
  switch (action.type) {
    case SET_CASH_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_CASH_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_CASH_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_CASH_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    case SET_CASH_SUMMARY:
      return { ...state, summary: action.payload.summary }
    default:
      return state
  }
}

export default cashReducer
