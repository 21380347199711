import { Products, ProductSales } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { ProductSaleSearch } from 'gadjet-v2-types/dist/type/search'

import { getDefaultRange } from '@utils/datetime'

// ActionType
export const SEARCH_PRODUCT_SALES = 'productSale/SEARCH_PRODUCT_SALES' as const
export const SET_PRODUCT_SALE_DEFAULT = 'productSale/SET_DEFAULT' as const
export const SET_PRODUCT_SALE_LOADING = 'productSale/SET_PRODUCT_SALE_LOADING' as const
export const SET_PRODUCT_SALE_SEARCH_RESULT = 'productSale/SET_PRODUCT_SALE_SEARCH_RESULT' as const
export const SET_PRODUCT_SALE_SEARCH = 'productSale/SET_PRODUCT_SALE_SEARCH' as const
export const SET_PRODUCT_SALE_PAGINATION = 'productSale/SET_PRODUCT_SALE_PAGINATION' as const
export const SET_PRODUCT_SALE_SUMMARY = 'productSale/SET_PRODUCT_SALE_SUMMARY' as const
export const SET_PRODUCT_SALE_PAGE_CONFIG = 'productSale/SET_PRODUCT_SALE_PAGE_CONFIG' as const
export const SET_PRODUCT_SALE_PRODUCT = 'productSale/SET_PRODUCT_SALE_PRODUCT' as const

// Action
export const searchProductSales = (hqId: number, branchId: number) => ({
  type: SEARCH_PRODUCT_SALES,
  payload: { hqId, branchId },
})
export const setProductSaleDefault = (hqId: number, branchId: number) => ({
  type: SET_PRODUCT_SALE_DEFAULT,
  payload: { hqId, branchId },
})
export const setProductSaleLoading = (loading: boolean) => ({
  type: SET_PRODUCT_SALE_LOADING,
  payload: { loading },
})
export const setProductSaleSearchResult = (result: Partial<ProductSaleState['searchResult']>) => ({
  type: SET_PRODUCT_SALE_SEARCH_RESULT,
  payload: { result },
})
export const setProductSaleSearch = (search: Partial<ProductSaleSearch>) => ({
  type: SET_PRODUCT_SALE_SEARCH,
  payload: { search },
})
export const setProductSalePagination = (pagination: Partial<Pagination>) => ({
  type: SET_PRODUCT_SALE_PAGINATION,
  payload: { pagination },
})
export const setProductSaleProduct = (product: Partial<ProductSaleState['product']>) => ({
  type: SET_PRODUCT_SALE_PRODUCT,
  payload: { product },
})

export type ProductSaleAction =
  | ReturnType<typeof searchProductSales>
  | ReturnType<typeof setProductSaleDefault>
  | ReturnType<typeof setProductSaleLoading>
  | ReturnType<typeof setProductSaleSearchResult>
  | ReturnType<typeof setProductSaleSearch>
  | ReturnType<typeof setProductSalePagination>
  | ReturnType<typeof setProductSaleProduct>

type ProductSaleState = {
  product: { list: Products[]; loading: boolean }
  loading: boolean
  searchResult: { branchId: number; total: number; productSales: ProductSales[] }
  search: ProductSaleSearch
  pagination: Pagination
}

const productSaleState: ProductSaleState = {
  product: { list: [], loading: false },
  loading: true,
  searchResult: { branchId: 0, total: 0, productSales: [] },
  search: { query: '', datetime: getDefaultRange('month', 'datetime'), productIds: [] },
  pagination: { current: 1, pageSize: 10 },
}

const productSaleReducer = (state = productSaleState, action: ProductSaleAction): ProductSaleState => {
  switch (action.type) {
    case SET_PRODUCT_SALE_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_PRODUCT_SALE_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_PRODUCT_SALE_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_PRODUCT_SALE_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    case SET_PRODUCT_SALE_PRODUCT:
      return { ...state, product: { ...state.product, ...action.payload.product } }
    default:
      return state
  }
}

export default productSaleReducer
