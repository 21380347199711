import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

export type BranchRentalAction = 'add'

type Props = {
  onAction: (type: BranchRentalAction) => void
}

export default function BranchRentalTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [{ label: '추가', buttonType: 'default', onClick: () => onAction('add') }]

  return <PageTitle title="대관/대여 관리" actions={actions} noItemOnTop />
}
