import Picture from '@pages/Landing/FeaturePages/atoms/Picture'
import TenantIcon from '@pages/Landing/FeaturePages/atoms/TenantIcon'
import * as S from '@pages/Landing/FeaturePages/styles'

export default function ElectronicContract(): JSX.Element {
  return (
    <S.SectionInnerWrapper>
      <S.Container
        defaultStyles={{
          flexDirection: 'row',
          alignItems: 'center',
          background: 'linear-gradient(287.19deg, #e9f6ef -0.08%, #f2f0fc 58.46%)',
          height: '480px',
          position: 'relative',
        }}
        mediaStyles={{ flexDirection: 'column', height: '500px' }}
      >
        <S.DescriptionWrapper defaultStyle={{ padding: '0 0 0 80px' }} mediaStyle={{ padding: '40px 0 0 30px' }}>
          <S.BoxTitle>비대면 전자 계약</S.BoxTitle>
          <S.Description>
            계약 정보만 입력하면
            <br /> 계약서가 입주사의
            <br /> 가젯 앱으로 전송돼요.
          </S.Description>
        </S.DescriptionWrapper>
        <Picture
          srcWEBP="/images/landing/features/contract/create-contract.webp"
          srcPNG="/images/landing/features/contract/create-contract.png"
          alt="add-contract-form"
          width={494}
          height={430}
          defaultStyle={{ alignSelf: 'flex-end' }}
          mediaScale={0.7}
        />
      </S.Container>
      <S.BoxGroup>
        <S.Container
          defaultStyles={{
            flexDirection: 'column',
            alignItems: 'center',
            background: 'linear-gradient(90deg, rgba(26, 26, 26, 0.95) 0%, rgba(26, 26, 26, 0.90) 100%)',
            height: '520px',
            position: 'relative',
          }}
          mediaStyles={{ flexDirection: 'column', height: '520px' }}
        >
          <S.DescriptionWrapper defaultStyle={{ textAlign: 'center', padding: '40px 0 0 0' }}>
            <S.BoxTitle color="#ffffff">모바일 서명</S.BoxTitle>
            <S.Description color="#ffffff">
              앱에서 서명하면
              <br />
              계약 체결 완료!
            </S.Description>
            <TenantIcon />
          </S.DescriptionWrapper>
          <S.ImageWrapper defaultStyle={{ alignItems: 'center' }}>
            <Picture
              srcWEBP="/images/landing/features/contract/signature.webp"
              srcPNG="/images/landing/features/contract/signature.png"
              alt="signature"
              width={250}
              height={301}
            />
          </S.ImageWrapper>
        </S.Container>
        <S.Container
          defaultStyles={{
            flexDirection: 'column',
            alignItems: 'center',
            background: 'linear-gradient(90deg, rgba(26, 26, 26, 0.95) 0%, rgba(26, 26, 26, 0.90) 100%)',
            width: '100%',
            height: '520px',
            position: 'relative',
          }}
          mediaStyles={{ flexDirection: 'column', height: '520px' }}
        >
          <S.DescriptionWrapper defaultStyle={{ padding: '40px 0 0 40px' }}>
            <S.BoxTitle color="#ffffff">계약서 직접 출력</S.BoxTitle>
            <S.Description color="#ffffff">
              종이 계약서가 필요하다면 <br />
              입주사가 가젯 앱에서 <S.LineBreakMobile /> 다운받아
              <br />
              직접 출력할 수 있어요.
            </S.Description>
            <TenantIcon />
          </S.DescriptionWrapper>
          <S.ImageWrapper defaultStyle={{ alignItems: 'center' }}>
            <Picture
              srcWEBP="/images/landing/features/contract/contract-print.webp"
              srcPNG="/images/landing/features/contract/contract-print.png"
              width={222}
              height={92}
              alt="mobile-print"
            />
          </S.ImageWrapper>
        </S.Container>
      </S.BoxGroup>
    </S.SectionInnerWrapper>
  )
}
