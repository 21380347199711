import { Branches, Hqs } from 'gadjet-v2-types/dist/model'
import { takeLatest } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import ManagerRoleAPI from '@apis/manager/role'

import { getMenuSuccess, GET_MENU, MenuAction } from '@reducers/branch/menu.reducer'
import { RootState } from '@reducers/index'

import { BRANCH_MENUS } from '../../hooks/useMenu/index'

const getIndexPath = (hqs: Hqs[], branches: Branches[]) => {
  if (hqs.length === 0 && branches.length === 0) return '/code'

  if (hqs.length > 0) return `/hq/${hqs[0].hqId}`

  if (branches.length > 0) {
    const _branch = branches[0]

    const branchRoles = _branch.roles

    if (!branchRoles || branchRoles?.length === 0) return '/code'

    const branchRole = branchRoles[0]

    const menu = BRANCH_MENUS.find(({ key }) => {
      const role = branchRole[key]

      return [1, 2].some((r) => r === role)
    })

    if (!menu) return '/code'

    const branchPath = `/hq/${_branch.hqId}/branch/${_branch.branchId}/${menu.path}`

    if (menu.sub && menu.sub[0]) return `${branchPath}/${menu.sub[0].path}`

    return branchPath
  }

  return '/code'
}

function* getMenuSaga(action: MenuAction): Generator {
  if (action.type !== GET_MENU) return
  const { managerId } = action.payload
  const { hq, branch } = yield* select((state: RootState) => state.menu)

  const {
    data: { hqs, branches },
  } = yield* call(ManagerRoleAPI.getRoles, { managerId })

  yield* put(
    getMenuSuccess({
      hq: hq || hqs[0],
      branch: branch || branches[0],
      indexPath: getIndexPath(hqs, branches),
      hqs,
      branches,
    })
  )
}

export default function* menuSaga(): Generator {
  yield* all([takeLatest(GET_MENU, getMenuSaga)])
}
