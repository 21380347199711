import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Col, Row } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { RentalReservations } from 'gadjet-v2-types/dist/model'
import { RentalReservationSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect, useState } from 'react'
import * as RBC from 'react-big-calendar'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  setRentalReservationSearch,
  searchRentalReservations,
  setRentalReservationDefault,
} from '@reducers/branch/rental-reservation.reducer'
import { RootState } from '@reducers/index'

import BranchRentalReservationCalendar from '@components/organisms/Branch/RentalReservation/Calendar'
import CalendarAgenda from '@components/organisms/Branch/RentalReservation/Calendar/CalendarAgenda'
import RentalReservationFormModal from '@components/organisms/Branch/RentalReservation/FormModal'
import BranchRentalReservationTitle from '@components/organisms/Branch/RentalReservation/Title'

export type BranchRentalReservationAction = 'add'

export default function RentalReservationPage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const location = useLocation<{ dateRange?: [string, string] }>()

  const { loading, search, searchResult } = useSelector((state: RootState) => state.rentalReservation)

  const [view, setView] = useState<RBC.View>('week')
  const [date, setDate] = useState(dayjs())

  const [modal, onVisible, onClose] = useModal<{
    rentalReservation: Partial<RentalReservations>
  }>({
    visible: false,
    rentalReservation: {},
  })

  const onChangeSearch = (_search: Partial<RentalReservationSearch>) => dispatch(setRentalReservationSearch(_search))

  const onSearch = () => dispatch(searchRentalReservations(hqId, branchId))
  const onResetAndSearch = useCallback(() => dispatch(setRentalReservationDefault(hqId, branchId)), [hqId, branchId])
  const onDoneRentalReservation = () => onResetAndSearch()

  const onNavigate = (_date: Dayjs) => setDate(_date)
  const onRangeChange = ({ start, end }: { start: Dayjs; end: Dayjs }) => {
    onChangeSearch({ date: [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')] })
    onSearch()
  }
  const onClickEvent = (_reservation: RentalReservations) => {
    onVisible({ rentalReservation: _reservation })
  }
  const onSelectRange = ({ start, end }: { start: Dayjs; end: Dayjs }) => {
    const startTime = start.format('HH:mm')
    const endTime = end.format('HH:mm')

    if (startTime !== endTime) {
      onVisible({
        rentalReservation: {
          date: start.format('YYYY-MM-DD'),
          startTime,
          endTime,
        },
      })
    }
  }

  const onAction = (action: BranchRentalReservationAction) => {
    if (action === 'add') onVisible({ rentalReservation: {} })
  }

  useEffect(() => {
    if (location?.state?.dateRange) {
      const [startDate, endDate] = location?.state?.dateRange
      setView('month')
      onNavigate(dayjs(startDate))
      onRangeChange({ start: dayjs(startDate), end: dayjs(endDate) })
    }
    if (searchResult.total === 0 || searchResult.branchId !== branchId) {
      onResetAndSearch()
    }
    onSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchRentalReservationTitle onAction={onAction} />
      <Row>
        <Col span={18}>
          <BranchRentalReservationCalendar
            loading={loading}
            view={view}
            rentalReservations={searchResult.rentalReservations}
            onNavigate={onNavigate}
            onChangeView={setView}
            onClickEvent={onClickEvent}
            onSelectRange={onSelectRange}
            onRangeChange={onRangeChange}
            date={date}
          />
        </Col>
        <Col span={6}>
          <CalendarAgenda
            rentalReservations={searchResult.rentalReservations}
            hqId={hqId}
            branchId={branchId}
            search={search}
            onChangeSearch={onChangeSearch}
            onClickEvent={onClickEvent}
            onSearch={onSearch}
          />
        </Col>
      </Row>
      <RentalReservationFormModal
        visible={modal.visible}
        rentalReservation={modal.rentalReservation}
        hqId={hqId}
        branchId={branchId}
        onClose={onClose}
        onDone={onDoneRentalReservation}
      />
    </div>
  )
}
