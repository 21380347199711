import { Form, Modal, notification, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { BranchAutomations } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'

import BranchAPI from '@apis/branch'

type Props = {
  hqId: number
  branchId: number
  onClose?: () => void
  onDone?: () => void
}

export default function ContractAutomationConfigModal({ hqId, branchId, onClose, onDone }: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [form] = useForm<BranchAutomations>()

  const onOk = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)

      await BranchAPI.updateBranchAutomation({ hqId, branchId }, { automation: values })

      notification.success({ message: '저장되었습니다.' })
      setLoading(false)
      if (onDone) onDone()
      if (onClose) onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const getBranchAutomation = async () => {
    setLoading(true)
    const { data } = await BranchAPI.getBranchAutomation({ hqId, branchId })
    form.setFieldsValue(data.automation)
    setLoading(false)
  }

  useEffect(() => {
    form.resetFields()
    getBranchAutomation()
  }, [])

  const automation: Partial<BranchAutomations> = {}

  return (
    <Modal onCancel={onClose} onOk={onOk} title="자동화 설정" okText="저장" confirmLoading={loading}>
      <Form form={form} layout="vertical" initialValues={automation}>
        <Form.Item label="청구서 자동 발송" name="billing">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  )
}
