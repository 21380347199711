import { Form, Input } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import { Bills } from 'gadjet-v2-types/dist/model'
import { TaxInvoice, TaxInvoiceDetail } from 'gadjet-v2-types/dist/popbill/tax-invoice'
import { ChangeEvent, useMemo } from 'react'

import mask from '@utils/mask'

import LocalDatePicker from '@components/atoms/LocalDatePicker'
import FormListTable, { FormListTableColumn } from '@components/molecules/FormListTable'

type Props = { bill?: Partial<Bills>; form: FormInstance<TaxInvoice> }

export default function BillTaxInvoiceDetailList({ bill, form }: Props): JSX.Element {
  const defaultValue = useMemo(
    () => ({
      serialNum: '',
      purchaseDT: bill?.startDate || dayjs().format('YYYYMMDD'),
      itemName: '',
      qty: '',
      unitCost: '',
      supplyCost: '0',
      tax: '0',
    }),
    [bill]
  )

  const onChangeSupplyCost = (value: string, index: number) => {
    const isTaxtation = form.getFieldValue('taxType') === '과세'

    if (isTaxtation) {
      const numberValue = Number(value.replace(/,/g, ''))
      const tax = Math.floor(Number(numberValue) / 10)

      const values = form.getFieldsValue()
      if (values.detailList) {
        const fieldValue: TaxInvoiceDetail[] = [...values?.detailList]
        fieldValue[index].tax = String(tax)

        form.setFieldsValue({ detailList: fieldValue })
      }
    }
  }

  const columns: FormListTableColumn[] = useMemo(
    () => [
      {
        label: '일련번호',
        width: '8%',
        render: (field) => (
          <Form.Item name={[field.name, 'serialNum']} isListField required noStyle>
            <Input />
          </Form.Item>
        ),
      },
      {
        label: '구매일',
        width: '150px',
        render: (field) => (
          <Form.Item
            noStyle
            name={[field.name, 'purchaseDT']}
            isListField
            getValueProps={(value) => ({ value: dayjs(value) })}
            getValueFromEvent={(value) => dayjs(value).format('YYYYMMDD')}
          >
            <LocalDatePicker allowClear={false} />
          </Form.Item>
        ),
      },
      {
        label: '품명',
        width: '16%',
        render: (field) => (
          <Form.Item name={[field.name, 'itemName']} isListField noStyle>
            <Input />
          </Form.Item>
        ),
      },
      {
        label: '수량',
        width: '8%',
        render: (field) => (
          <Form.Item name={[field.name, 'qty']} isListField noStyle>
            <Input />
          </Form.Item>
        ),
      },
      {
        label: '단가',
        render: (field) => (
          <Form.Item name={[field.name, 'unitCost']} isListField noStyle {...mask.krw}>
            <Input />
          </Form.Item>
        ),
      },
      {
        label: '공급가액',
        render: (field, index) => (
          <Form.Item name={[field.name, 'supplyCost']} isListField noStyle {...mask.krw}>
            <Input onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeSupplyCost(event.target.value, index)} />
          </Form.Item>
        ),
      },
      {
        label: '세액',
        render: (field) => (
          <Form.Item name={[field.name, 'tax']} isListField noStyle {...mask.krw}>
            <Input />
          </Form.Item>
        ),
      },
    ],
    []
  )

  return <FormListTable name="detailList" columns={columns} title="품목" defaultValue={defaultValue} />
}
