import * as ROLE from 'gadjet-v2-types/dist/api/manager/role'

import axios from '@utils/axios'

const ManagerRoleAPI = {
  // 매니저 권한 조회
  getRoles: async ({ managerId }: ROLE.GET_PARAMS): Promise<{ data: ROLE.GET_RESPONSE }> =>
    axios.get(`/managers/${managerId}/roles`),

  // 매니저 초대 수락
  acceptInvite: async (
    { managerId }: ROLE.INVITE_ACCEPT_PARAMS,
    { code }: ROLE.INVITE_ACCEPT_BODY
  ): Promise<{ data: ROLE.INVITE_ACCEPT_RESPONSE }> => axios.post(`/managers/${managerId}/roles/accept`, { code }),
}

export default ManagerRoleAPI
