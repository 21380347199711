import { ArrowRightOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import dayjs from 'dayjs'
import { BranchNotifications } from 'gadjet-v2-types/dist/model'
import { useMemo } from 'react'

type Props = {
  notification: BranchNotifications
  readAt: string
  onClickMove: (link: string) => void
}

export default function NotificationCard({ notification, readAt, onClickMove }: Props): JSX.Element {
  const { title, content, sendDatetime, link } = notification
  const datetime = dayjs(sendDatetime).format('YYYY/MM/DD HH:mm:ss')

  const isRead = useMemo(() => sendDatetime < readAt, [readAt])

  return (
    <Row
      style={{
        padding: '12px 24px',
        borderBottom: '1px solid #eee',
        backgroundColor: isRead ? 'white' : '#52c41a20',
      }}
      gutter={10}
    >
      <Col flex={1}>
        <div style={{ opacity: 0.6, fontSize: 12 }}>{title}</div>
        <div style={{ padding: '10px 0', fontSize: 16, wordBreak: 'keep-all', whiteSpace: 'pre-wrap' }}>{content}</div>
        <div style={{ opacity: 0.6, fontSize: 12 }}>{datetime}</div>
      </Col>
      <Col style={{ display: 'flex', alignItems: 'center' }}>
        {link && <Button icon={<ArrowRightOutlined />} type="text" size="large" onClick={() => onClickMove(link)} />}
      </Col>
    </Row>
  )
}
