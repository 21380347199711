import { Tenants } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { TenantSearch } from 'gadjet-v2-types/dist/type/search'

// ActionType
export const SEARCH_TENANTS = 'tenant/SEARCH_TENANTS' as const
export const SET_TENANT_DEFAULT = 'tenant/SET_DEFAULT' as const
export const SET_TENANT_LOADING = 'tenant/SET_TENANT_LOADING' as const
export const SET_TENANT_SEARCH_RESULT = 'tenant/SET_TENANT_SEARCH_RESULT' as const
export const SET_TENANT_SEARCH = 'tenant/SET_TENANT_SEARCH' as const
export const SET_TENANT_PAGINATION = 'tenant/SET_TENANT_PAGINATION' as const
export const SET_TENANT_SUMMARY = 'tenant/SET_TENANT_SUMMARY' as const
export const SET_TENANT_PAGE_CONFIG = 'tenant/SET_TENANT_PAGE_CONFIG' as const

// Action
export const searchTenants = (hqId: number, branchId: number) => ({
  type: SEARCH_TENANTS,
  payload: { hqId, branchId },
})
export const setTenantDefault = (hqId: number, branchId: number) => ({
  type: SET_TENANT_DEFAULT,
  payload: { hqId, branchId },
})
export const setTenantLoading = (loading: boolean) => ({
  type: SET_TENANT_LOADING,
  payload: { loading },
})
export const setTenantSearchResult = (result: Partial<TenantState['searchResult']>) => ({
  type: SET_TENANT_SEARCH_RESULT,
  payload: { result },
})
export const setTenantSearch = (search: Partial<TenantSearch>) => ({
  type: SET_TENANT_SEARCH,
  payload: { search },
})
export const setTenantPagination = (pagination: Partial<Pagination>) => ({
  type: SET_TENANT_PAGINATION,
  payload: { pagination },
})

export type TenantAction =
  | ReturnType<typeof searchTenants>
  | ReturnType<typeof setTenantDefault>
  | ReturnType<typeof setTenantLoading>
  | ReturnType<typeof setTenantSearchResult>
  | ReturnType<typeof setTenantSearch>
  | ReturnType<typeof setTenantPagination>

type TenantState = {
  loading: boolean
  searchResult: { branchId: number; total: number; tenants: Tenants[] }
  search: TenantSearch
  pagination: Pagination
}

const tenantState: TenantState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, tenants: [] },
  search: { query: '', contractStatus: [] },
  pagination: { current: 1, pageSize: 10 },
}

const tenantReducer = (state = tenantState, action: TenantAction): TenantState => {
  switch (action.type) {
    case SET_TENANT_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_TENANT_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_TENANT_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_TENANT_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    default:
      return state
  }
}

export default tenantReducer
