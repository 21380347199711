import styled from 'styled-components'

type Props = {
  selectedButton: string
  onClick: (button: string) => void
}
const switches: { label: string; key: string }[] = [
  {
    label: 'ON',
    key: 'on',
  },
  {
    label: 'OFF',
    key: 'off',
  },
]

export default function PromotionSwitch({ selectedButton, onClick }: Props): JSX.Element {
  return (
    <Wrapper>
      프로모션
      <ButtonGroup>
        {switches.map(({ label, key }) => (
          <Button isSelected={selectedButton === key} key={key} onClick={() => onClick(key)}>
            {label}
          </Button>
        ))}
      </ButtonGroup>
    </Wrapper>
  )
}

type StyleProps = {
  isSelected: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 6px 6px 16px;
  gap: 16px;

  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #2e2e2e;

  background: #f3f4f5;
  border-radius: 12px;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
`
const Button = styled.button`
  padding: 6px 16px;
  gap: 10px;

  width: 60px;
  height: 36px;

  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: ${({ isSelected }: StyleProps) => (isSelected ? '#22AF5D' : '#9a9c9c')};

  background: ${({ isSelected }: StyleProps) => (isSelected ? '#ffffff' : 'transparent')};
  box-shadow: ${({ isSelected }: StyleProps) => (isSelected ? '0px 8px 8px -2px rgba(24, 39, 75, 0.08)' : 'none')};
  border: none;
  border-radius: 8px;
  cursor: pointer;
`
