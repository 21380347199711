import { Files, Images } from 'gadjet-v2-types/dist/model'
import { FileCategory, ImageCategory } from 'gadjet-v2-types/dist/type'

import { storage } from '@utils/firebase'

import CommonAPI from '@apis/common'

const HOST = 'https://storage.googleapis.com/gadjet-v2.appspot.com' as const

export const imageUpload = async (_file: File, category: ImageCategory): Promise<Images> => {
  const result = await storage.ref(`IMAGE/${category}/${Date.now()}`).put(_file, { contentType: _file.type })
  const path = result.metadata.fullPath
  const { data } = await CommonAPI.addImage({
    image: { uri: `${HOST}/${path}`, path, size: _file.size, category, width: 0, height: 0 },
  })
  return data
}

export const fileUpload = async (_file: File, category: FileCategory): Promise<Files> => {
  const result = await storage.ref(`FILE/${category}/${Date.now()}`).put(_file, { contentType: _file.type })
  const path = result.metadata.fullPath
  const { data } = await CommonAPI.addFile({
    file: { uri: `${HOST}/${path}`, size: _file.size, category, path },
  })
  return data
}

const upload = { image: imageUpload, file: fileUpload }

export default upload
