import { ReactComponent as ArrowRightMIcon } from '@assets/Icons/arrow-right-m.svg'
import { Button } from 'antd'
import { BranchDashboardEventSummary } from 'gadjet-v2-types/dist/type'
import styled from 'styled-components'

import Loading from '@components/molecules/Loading'
import { Items } from '@components/organisms/Branch/Dashboard/DashboardSummary/index'

type Props = {
  items: Items
  summary: BranchDashboardEventSummary
  loading?: boolean
}

export default function SummaryCard({ items, summary, loading }: Props): JSX.Element {
  return (
    <Loading loading={loading}>
      <Wrapper>
        {items.map(({ label, color, onClick, value, isButtonHidden }) => (
          <div key={label}>
            <SummaryContainer>
              <Divider color={color} />
              <Summary>
                <Title>{label}</Title>
                <Value>{value && value(summary)}</Value>
              </Summary>
            </SummaryContainer>

            {!isButtonHidden && <CardButton icon={<ArrowRightMIcon stroke="#2e2e2e" />} onClick={onClick} />}
          </div>
        ))}
      </Wrapper>
    </Loading>
  )
}

type StyleProps = {
  color?: string
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 200px;
  width: max-content;
  background: #ffffff;
  box-shadow: 0px 2px 8px -6px rgba(24, 39, 75, 0.04), 0px 12px 16px -6px rgba(24, 39, 75, 0.02);
  border-radius: 16px;
  padding: 60px 40px;
  position: relative;
  gap: 100px;
`
const SummaryContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
  gap: 20px;
`

const Divider = styled.div`
  width: 3px;
  height: 56px;
  background-color: ${({ color }: StyleProps) => color || '#3EBD73'};
`
const Summary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #777d83;
`
const Value = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
`
const CardButton = styled(Button)`
  width: 44px;
  height: 44px;
  padding: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  position: absolute;
  right: 0;
  bottom: 0;
  &:hover {
    background-color: #f4f5f7;
    border-color: #e9eaeb;
  }

  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    text-decoration: none;
    border-color: #e9eaeb;
    background: #ffffff;
  }
`
