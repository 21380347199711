import { BREAKPOINTS } from '@const'
import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  topTitle: string | ReactNode
  subTitle: string
  children: ReactNode
}
export default function CalulatorBox({ topTitle, subTitle, children }: Props): JSX.Element {
  return (
    <Container>
      <TitleWrapper>
        <TopTitle>{topTitle}</TopTitle>
        <SubTitle>{subTitle}</SubTitle>
      </TitleWrapper>
      <InnerWrapper>{children}</InnerWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 420px;
  height: 331px;
  gap: 25px;

  background: #f3f4f5;

  border: 1px solid #e9eaeb;
  border-radius: 24px;

  @media ${BREAKPOINTS.lg} {
    width: 600px;
  }
  @media ${BREAKPOINTS.md} {
    width: 382px;
  }
  @media ${BREAKPOINTS.sm} {
    width: 100%;
    min-width: 340px;
    height: 300px;
    gap: 16px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  @media ${BREAKPOINTS.sm} {
    gap: 2px;
  }
`
const TopTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
  color: #2e2e2e;
  cursor: 'pointer';
`
const SubTitle = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #7a7b7c;
`
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  @media ${BREAKPOINTS.sm} {
    gap: 16px;
  }
`
