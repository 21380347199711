import styled from 'styled-components'

type Props = {
  discountRate?: string
  isVisibleDurationMonth?: boolean
  promotion?: Pick<Promotion, 'durationMonth' | 'rate' | 'isVisible'>
}

export default function DiscountBadge({ discountRate, promotion, isVisibleDurationMonth }: Props): JSX.Element {
  return (
    <Badge isVisible={promotion?.isVisible || !!discountRate}>
      {discountRate}
      {promotion?.isVisible && (
        <>
          {discountRate && '+'}
          {isVisibleDurationMonth && `${promotion?.durationMonth}개월 `}
          {`${promotion.rate * 100}%`}
        </>
      )}
    </Badge>
  )
}

export const Badge = styled.span`
  display: ${({ isVisible }: { isVisible: boolean }) => (isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  padding: 5px 6px;

  background: #ffffff;
  border: 1px solid #f43f24;
  border-radius: 8px;

  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #f43f24;
`
