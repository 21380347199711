const tenantQueryKey = {
  getCredits: (hqId: number, branchId: number, tenantId: number, search: unknown) => [
    'hq',
    hqId,
    'branch',
    branchId,
    'tenant',
    tenantId,
    'credit',
    search,
  ],
}

export default tenantQueryKey
