import { BIZ_BREAKPOINTS, GADJET_BIZ_COLORS } from '@const'
import { ReactNode } from 'react'
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs'
import styled from 'styled-components'

export default function FeatureTabGroup() {
  const featureList: {
    key: string
    tabLabel: string
    title: string | ReactNode
    subTitle: string
    content: string | ReactNode
    image: string
  }[] = [
    {
      key: 'manage-property',
      tabLabel: '부동산 운영',
      title: '보유한 부동산 목록부터\n최신 부동산 트렌드까지',
      subTitle: '흩어져있는 부동산 관련 정보를 모두 모아 꼼꼼히 정리합니다.\n부동산 경영의 기반을 든든히 다지세요.',
      content: (
        <ul>
          <li>부동산 보유 목록</li>
          <li>부동산 가격</li>
          <li>등기부등본, 건축물대장 조회</li>
          <li>부동산 관련 뉴스 큐레이션</li>
        </ul>
      ),
      image: 'building.png',
    },
    {
      key: 'maintainance',
      tabLabel: '유지보수',
      title: '부동산의 감가상각 방어를 위한\n꾸준하고 꼼꼼한 관리',
      subTitle: '사람의 손이 닿을 수록 부동산의 가치가 올라갑니다.\n구석구석 틈틈이 살펴 언제나 새 것처럼 관리하세요.',
      content: (
        <ul>
          <li>유지보수 요청</li>
          <li>작업 이력</li>
          <li>정기 및 반복 일정</li>
          <li>용역 및 인력관리</li>
        </ul>
      ),
      image: 'chart.png',
    },
    {
      key: 'manage-contract',
      tabLabel: '계약 관리',
      title: '계약 관리를 위한\n정기 반복 업무는 자동으로',

      subTitle: '계약 관리는 가젯의 기반이자 핵심입니다.\n가젯이 알아서 챙겨드릴게요.',
      content: (
        <ul>
          <li>임차인 관리</li>
          <li>계약서 작성 및 관리</li>
          <li>계약 갱신 안내 및 관리</li>
          <li>공실 매물</li>
        </ul>
      ),
      image: 'computer.png',
    },
    {
      key: 'config-usage',
      tabLabel: '건물 이용 권한 설정',
      title: '임차인부터 방문객까지\n세부 동선 관리',
      subTitle:
        '사용자의 권한을 세분화할수록 공간의 보안과 수익을\n극대화할 수 있습니다. 공간을 더 전략적으로 구성하세요.',
      content: (
        <ul>
          <li>시설 목록</li>
          <li>이용자 접근 권한 관리</li>
          <li>주차 관리</li>
          <li>공간 대관</li>
        </ul>
      ),
      image: 'security.png',
    },
    {
      key: 'advanced-feature',
      tabLabel: '고급 기능',
      title: '더 전문적인 부동산 경영을\n위한 추가 기능',
      subTitle:
        '다수의 부동산을 관리하는 전문 비즈니스를 위해 준비했습니다.\n20채 이상의 부동산을 관리할 경우 문의하세요.',
      content: (
        <ul>
          <li>건물 수익 및 현황 보고서</li>
          <li>API 제공</li>
          <li>화이트레이블</li>
          <li>건물 별 드라이브</li>
        </ul>
      ),
      image: 'advanced-feature.png',
    },
  ]

  return (
    <StyledTabs>
      <StyledTabList>
        {featureList.map(({ key, tabLabel }) => (
          <Tab key={key} selectedClassName="selected">
            {tabLabel}
          </Tab>
        ))}
      </StyledTabList>

      {featureList.map(({ key, content, title, subTitle, image }) => (
        <StyledTabPanel key={key}>
          <Box>
            <InformationArea>
              <TitleWrapper>
                <Title>{title}</Title>
                <SubTitle>{subTitle}</SubTitle>
              </TitleWrapper>
              <ContentWrapper>{content}</ContentWrapper>
            </InformationArea>
            <ImageWrapper>
              <Image src={`/images/landing/business/features/${image}`} alt={key} />
            </ImageWrapper>
          </Box>
        </StyledTabPanel>
      ))}
    </StyledTabs>
  )
}

const StyledTabs = styled(Tabs)`
  width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${BIZ_BREAKPOINTS.lg} {
    width: 100%;
    max-width: 500px;
  }
`

const StyledTabList = styled(TabList)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 28px;
  list-style: none;
  margin: 0;
  padding-left: 0;
  padding-bottom: 32px;
  overflow-x: scroll;

  @media ${BIZ_BREAKPOINTS.lg} {
    max-width: 500px;
    justify-content: flex-start;
    gap: 16px;
    padding-bottom: 20px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  li {
    color: ${GADJET_BIZ_COLORS.textBlack};
    font-weight: 400;
    font-size: 17px;
    line-height: 120%;
    letter-spacing: -0.34px;
    white-space: nowrap;
    padding: 12px 2px;
    cursor: pointer;
  }
  .react-tabs__tab {
    :focus {
      outline: none;
    }
  }
  .selected {
    color: #000b43;
    font-weight: 700;
    border-bottom: 2px solid #000b43;
  }
`

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 40px;
  background: linear-gradient(118deg, rgba(0, 11, 67, 0.95) 25.72%, rgba(47, 56, 107, 0.85) 93.69%);
  border-radius: 40px;
  padding: 80px 0px 80px 80px;

  box-shadow: ${GADJET_BIZ_COLORS.boxShadow};
  @media ${BIZ_BREAKPOINTS.lg} {
    flex-direction: column;
    gap: 32px;
    height: 100%;
    padding: 50px 20px 40px 20px;
    border-radius: 24px;
  }
`

const ImageWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  width: 200px;
  height: 200px;
  aspect-ratio: 1 / 1;

  @media ${BIZ_BREAKPOINTS.lg} {
    width: 120px;
    height: 120px;
  }
`
const InformationArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 24px;
  width: 100%;
  color: #fff;
  @media ${BIZ_BREAKPOINTS.lg} {
    gap: 16px;
  }
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${BIZ_BREAKPOINTS.lg} {
    text-align: center;
    gap: 16px;
  }
`

const Title = styled.div`
  font-weight: 700;
  white-space: break-spaces;
  font-size: 30px;
  line-height: 140%;
  @media ${BIZ_BREAKPOINTS.lg} {
    font-size: 24px;
  }
`

const SubTitle = styled.div`
  white-space: break-spaces;
  font-size: 18px;
  font-weight: 400;
  @media ${BIZ_BREAKPOINTS.lg} {
    font-size: 16px;
  }
`

const ContentWrapper = styled.div`
  font-size: 18px;

  ul {
    font-size: 17px;
    line-height: 150%;
    margin-bottom: 0;
    padding: 0 0 0 20px;
    @media ${BIZ_BREAKPOINTS.lg} {
      font-size: 15px;
    }
  }
`
