import { ReactComponent as EmptyBox } from '@assets/Icons/empty-box.svg'
import { Styles } from '@types'

export default function NoReservations(): JSX.Element {
  return (
    <div style={styles.wrapper}>
      <EmptyBox width="22" height="22" fill="#7A7B7C" />
      <div style={styles.text}>예약된 항목이 없습니다.</div>
    </div>
  )
}

const styles: Styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  text: { color: '#7A7B7C', fontWeight: 400, fontSize: '14px', marginTop: '10px' },
}
