import { takeEvery, takeLeading } from 'redux-saga/effects'
import { all, call, put, select } from 'typed-redux-saga'

import AdditionAPI from '@apis/branch/addition'

import {
  AdditionAction,
  setAdditionLoading,
  setAdditionSearchResult,
  SEARCH_ADDITIONS,
  SET_ADDITION_DEFAULT,
  SET_ADDITION_PAGINATION,
  SET_ADDITION_SEARCH,
  SET_ADDITION_PAGE_CONFIG,
  setAdditionPagination,
  searchAdditions,
} from '@reducers/branch/addition.reducer'
import { RootState } from '@reducers/index'
import { setStorageAddition } from '@reducers/storage.reducer'

function* searchAdditionSaga(action: AdditionAction): Generator {
  if (action.type !== SEARCH_ADDITIONS) return
  const { hqId, branchId } = action.payload
  yield* put(setAdditionLoading(true))

  const search = yield* select((state: RootState) => state.addition.search)
  const pagination = yield* select((state: RootState) => state.addition.pagination)

  const {
    data: { additions, total },
  } = yield* call(AdditionAPI.getAdditions, { hqId, branchId }, { ...search, ...pagination })

  yield* put(setAdditionSearchResult({ branchId, additions, total }))

  yield* put(setAdditionLoading(false))
}

function* savePageConfigSaga(action: AdditionAction): Generator {
  const state: Parameters<typeof setStorageAddition>[0] = {}

  if (action.type === SET_ADDITION_PAGINATION) {
    const { pageSize } = action.payload.pagination
    state.pageSize = pageSize
  }

  yield* put(setStorageAddition(state))
}

function* setDefaultPageConfigSaga(action: AdditionAction): Generator {
  if (action.type !== SET_ADDITION_DEFAULT) return
  const { pageSize } = yield* select((state: RootState) => state.storage.additionConfig)
  yield* all([put(setAdditionPagination({ pageSize }))])

  const { hqId, branchId } = action.payload
  yield* put(searchAdditions(hqId, branchId))
}

export default function* authSaga(): Generator {
  yield* all([
    takeLeading(SEARCH_ADDITIONS, searchAdditionSaga),
    takeEvery(SET_ADDITION_SEARCH, savePageConfigSaga),
    takeEvery(SET_ADDITION_PAGINATION, savePageConfigSaga),
    takeEvery(SET_ADDITION_PAGE_CONFIG, savePageConfigSaga),
    takeEvery(SET_ADDITION_DEFAULT, setDefaultPageConfigSaga),
  ])
}
