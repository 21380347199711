/* eslint-disable no-console, @typescript-eslint/no-explicit-any */
import { TOKEN_KEY, TOKEN_TYPE } from '@const'
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

import config from '@configs/index'

const { IS_DEV, API_HOST, API_HOST_DEV } = config

export const getToken = (): string => window.localStorage.getItem(TOKEN_KEY) || ''

const instance = axios.create({
  baseURL: !IS_DEV ? API_HOST : API_HOST_DEV,
  withCredentials: true,
  headers: { authorization: `${TOKEN_TYPE} ${getToken()}` },
})

const requestErrorHandler = (err: AxiosError) => {
  console.log('==== axios request err : ', err)
}

const blockList = ['hq', 'code', 'test']
/**
 * login 페이지로 리다이렉트가 필요한 pathname인지 판단하는 함수.
 * @param pathname window.location.pathname
 * @returns pathname의 첫 번째 path가 blockList에 포함되어있을 경우 true 반환.
 */
const checkRedirect = (pathname: string): boolean => {
  const firstPath = pathname.split('/')[1]
  return blockList.findIndex((arg) => firstPath === arg) !== -1
}

/**
 * axios response error(서버 요청에 대한 응답 에러)가 발생했을 때 처리하는 함수
 * @param err
 */
const responseErrorHandler = (err: AxiosError) => {
  const { response } = err

  if (response?.status === 401) {
    setToken('')

    if (checkRedirect(window.location.pathname)) {
      window.location.href = '/login'
    }
    return
  }

  console.log('==== axios response err : ', err)
}

instance.interceptors.request.use((request: AxiosRequestConfig) => {
  return request
}, requestErrorHandler)

instance.interceptors.response.use((response: AxiosResponse) => {
  return response
}, responseErrorHandler)

export const setToken = (token: string): void => {
  instance.defaults.headers.authorization = `${TOKEN_TYPE} ${token}`
  window.localStorage.setItem(TOKEN_KEY, token)
}

export default instance
