import { InputNumber, InputNumberProps } from 'antd'

export default function InputPrice(props: InputNumberProps) {
  return (
    <InputNumber
      {...props}
      formatter={(value) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      style={{ width: '100%' }}
    />
  )
}
