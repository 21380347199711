import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { COLORS } from '@const'
import { Styles } from '@types'
import { Button, Modal, Space } from 'antd'
import { useMemo, useState } from 'react'

import Loading from '@components/molecules/Loading'

type ConfirmModalType = 'warn' | 'success'

type Props = {
  visible: boolean
  title: string | React.ReactNode
  description?: React.ReactNode
  onConfirm: () => Promise<boolean | void> | boolean | void
  onClose?: () => void
  onDone?: () => void
  okText?: string
  type?: ConfirmModalType
  loading?: boolean
  showIcon?: boolean
  isConfirmResign?: boolean
}

export default function ConfirmModal({
  visible,
  title,
  description,
  okText = '확인',
  onConfirm,
  onClose,
  onDone,
  type = 'warn',
  loading = false,
  showIcon = true,
  isConfirmResign,
}: Props): JSX.Element {
  const [confirming, setConfirming] = useState(false)

  const onOk = async () => {
    setConfirming(true)
    const isConfirm = await onConfirm()
    setConfirming(false)
    if (isConfirm === false) return

    if (onDone) onDone()
    if (onClose) onClose()
  }

  const icon = useMemo(() => {
    if (!showIcon) return ''
    if (type === 'success') return <CheckCircleOutlined style={{ ...styles.titleIcon, color: COLORS.success }} />
    return <WarningOutlined style={{ ...styles.titleIcon, color: COLORS.warn }} />
  }, [type])

  return (
    <Modal title={null} footer={null} closable={false} okText={okText} onOk={onOk} visible={visible}>
      <Loading loading={confirming || loading}>
        <>
          <Space align="center">
            {icon}
            <span style={styles.title}>{title}</span>
          </Space>
          {description && <div style={styles.description}>{description}</div>}
          <div style={styles.footer}>
            {!isConfirmResign ? (
              <Space>
                <Button onClick={onClose}>닫기</Button>
                <Button type="primary" onClick={onOk}>
                  {okText}
                </Button>
              </Space>
            ) : (
              <Space>
                <Button type="default" onClick={onOk}>
                  {okText}
                </Button>
                <Button onClick={onClose}>닫기</Button>
              </Space>
            )}
          </div>
        </>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  title: { fontSize: '20px' },
  titleIcon: { fontSize: '20px' },
  description: { marginTop: '10px', padding: '10px', borderLeft: '4px solid #eee' },
  footer: { width: '100%', textAlign: 'right', marginTop: '10px' },
}
