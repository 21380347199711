import { CreditType } from 'gadjet-v2-types/dist/type'
import styled from 'styled-components'

type Props = {
  isActive?: boolean
  label: string
  onClick?: () => void
  id?: CreditType
}

export default function ChipButton({ isActive, label, onClick, id }: Props): JSX.Element {
  return (
    <Wrapper isActive={isActive} onClick={onClick} id={id}>
      {label}
    </Wrapper>
  )
}

type PropsStyle = { isActive?: boolean }

const Wrapper = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  height: 28px;
  color: ${({ isActive }: PropsStyle) => (isActive ? '#38B553' : 'black')};
  background: ${({ isActive }: PropsStyle) => (isActive ? '#ffffff' : '#f2f3f4')};
  border: ${({ isActive }: PropsStyle) => (isActive ? '1px solid #38B553' : '1px solid #f2f3f4')};
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
`
