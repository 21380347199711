import { DownloadOutlined } from '@ant-design/icons'
import { useEffect } from 'react'

import { BranchReceiptActionType } from '@pages/Branch/ReceiptPage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchReceiptActionType) => void
  popbillId?: null | string
}

export default function BranchReceiptTitle({ onAction, popbillId }: Props): JSX.Element {
  const actions: PageTitleAction[] = [
    { label: '다운로드', buttonType: 'dashed', icon: <DownloadOutlined />, onClick: () => onAction('export-receipt') },
  ]

  useEffect(() => {}, [popbillId])

  return <PageTitle title="증빙" actions={actions} />
}
