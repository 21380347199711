import { Contracts } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { ContractSearch } from 'gadjet-v2-types/dist/type/search'

// ActionType
export const SEARCH_CONTRACTS = 'contract/SEARCH_CONTRACTS' as const
export const SET_CONTRACT_DEFAULT = 'contract/SET_CONTRACT_DEFAULT' as const
export const SET_CONTRACT_LOADING = 'contract/SET_CONTRACT_LOADING' as const
export const SET_CONTRACT_SEARCH_RESULT = 'contract/SET_CONTRACT_SEARCH_RESULT' as const
export const SET_CONTRACT_SEARCH = 'contract/SET_CONTRACT_SEARCH' as const
export const SET_CONTRACT_PAGINATION = 'contract/SET_CONTRACT_PAGINATION' as const
export const SET_CONTRACT_TABLE_CONFIG = 'contract/SET_CONTRACT_TABLE_CONFIG' as const

// Action
export const searchContracts = (hqId: number, branchId: number) => ({
  type: SEARCH_CONTRACTS,
  payload: { hqId, branchId },
})
export const setContractDefault = (hqId: number, branchId: number) => ({
  type: SET_CONTRACT_DEFAULT,
  payload: { hqId, branchId },
})
export const setContractLoading = (loading: boolean) => ({
  type: SET_CONTRACT_LOADING,
  payload: { loading },
})
export const setContractSearchResult = (result: Partial<ContractState['searchResult']>) => ({
  type: SET_CONTRACT_SEARCH_RESULT,
  payload: { result },
})
export const setContractSearch = (search: Partial<ContractSearch>) => ({
  type: SET_CONTRACT_SEARCH,
  payload: { search },
})
export const setContractPagination = (pagination: Partial<Pagination>) => ({
  type: SET_CONTRACT_PAGINATION,
  payload: { pagination },
})
export const setContractTableConfig = (tableConfig: Partial<ContractState['tableConfig']>) => ({
  type: SET_CONTRACT_TABLE_CONFIG,
  payload: { tableConfig },
})

export type ContractAction =
  | ReturnType<typeof searchContracts>
  | ReturnType<typeof setContractDefault>
  | ReturnType<typeof setContractLoading>
  | ReturnType<typeof setContractSearchResult>
  | ReturnType<typeof setContractSearch>
  | ReturnType<typeof setContractPagination>
  | ReturnType<typeof setContractTableConfig>

type ContractState = {
  loading: boolean
  searchResult: { branchId: number; total: number; contracts: Contracts[] }
  search: ContractSearch
  pagination: Pagination
  tableConfig: { activeColumns: Array<keyof Contracts> }
}

const contractState: ContractState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, contracts: [] },
  search: { query: '', spaceTypes: [], status: ['before-started', 'expired', 'extended', 'started', 'suspended'] },
  pagination: { current: 1, pageSize: 10 },
  tableConfig: { activeColumns: [] },
}

const contractReducer = (state = contractState, action: ContractAction): ContractState => {
  switch (action.type) {
    case SET_CONTRACT_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_CONTRACT_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_CONTRACT_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_CONTRACT_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    case SET_CONTRACT_TABLE_CONFIG:
      return { ...state, tableConfig: { ...state.tableConfig, ...action.payload.tableConfig } }
    default:
      return state
  }
}

export default contractReducer
