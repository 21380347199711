import { notification } from 'antd'

import ContractAPI from '@apis/branch/contract'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  contractId: number
  onClose?: () => void
  onDone?: () => void
}

export default function AdditionDeleteModal({ hqId, branchId, contractId, onClose, onDone }: Props): JSX.Element {
  const onConfirm = async () => {
    if (!contractId) return
    await ContractAPI.updateContractSignatureStatus({ hqId, branchId, contractId }, { signatureStatus: 'waiting' })
    notification.success({ message: '서명을 요청했습니다.' })
  }

  return (
    <ConfirmModal
      title="서명 요청"
      description="입주사 서명 후 계약서를 확인할 수 있습니다."
      onConfirm={onConfirm}
      visible
      onClose={onClose}
      onDone={onDone}
    />
  )
}
