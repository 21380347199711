import { ReactComponent as ChevronRightIcon } from '@assets/Icons/chevron-right.svg'
import { BREAKPOINTS } from '@const'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { featureMenuList } from '@pages/Landing/organisms/Header'

export default function BottomFeatureMenu(): JSX.Element {
  const { pathname: currentPath } = useLocation()
  return (
    <Wrapper>
      {featureMenuList
        .filter(({ path }) => !currentPath.includes(path))
        .map(({ icon, key, label, path }) => (
          <li key={key}>
            <Item to={{ pathname: path }}>
              <img src={`/images/landing/features/${icon}`} alt={key} width={40} height={40} />
              <Label>{label}</Label>
              <ChevronRightIcon width="28" height="28" stroke="#232530" />
            </Item>
          </li>
        ))}
    </Wrapper>
  )
}

const Wrapper = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  list-style: none;
  margin: 0;
  padding: 162px 0 140px 0;
  @media ${BREAKPOINTS.lg} {
    padding: 100px 0 100px 0;
  }
  @media (max-width: 1050px) {
    flex-direction: column;
  }
`
const Item = styled(Link)`
  width: 320px;
  height: 90px;

  background: #f4f5f7;
  border-radius: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  :hover {
    background: #e8eaf0;
    transition: all 0.3s ease-in-out;
  }
`
const Label = styled.span`
  color: #232530;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
`
