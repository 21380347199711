import { notification } from 'antd'

import RoleAPI from '@apis/branch/role'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  branchRoleId: number
  visible: boolean
  onClose: () => void
  onDone?: () => void
}
export default function BranchRoleDeleteModal({
  hqId,
  branchId,
  branchRoleId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    await RoleAPI.deleteRole({ hqId, branchId, branchRoleId })
    notification.success({ message: '선택한 사용자의 접근 권한이 삭제되었습니다' })

    if (onDone) onDone()
  }

  return (
    <ConfirmModal
      onClose={onClose}
      onConfirm={onConfirm}
      visible={visible}
      title="접근 권한 삭제"
      description="해당 사용자의 접근 권한을 삭제해 가젯 로그인을 막습니다."
      okText="삭제"
    />
  )
}
