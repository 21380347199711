import { ReactComponent as ArrowRightMIcon } from '@assets/Icons/arrow-right-m.svg'
import { BOOK_DEMO_URL, BREAKPOINTS } from '@const'
import styled from 'styled-components'

type Props = {
  text: string
}

export default function BookDemoButton({ text }: Props): JSX.Element {
  return (
    <Button href={BOOK_DEMO_URL} target="_blank" rel="noreferrer">
      <span>{text}</span>
      <ColoredText>
        데모 시연 신청하기
        <ArrowRightMIcon stroke="#9C60FF" />
      </ColoredText>
    </Button>
  )
}

const Button = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;

  width: 1000px;
  height: 80px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #e9eaeb;
  border-radius: 20px;

  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: #7e7e7e;

  transition: all ease-in-out 0.3s;
  cursor: pointer;
  :hover {
    color: #7e7e7e;
    background: #f6f6f6;
  }

  @media ${BREAKPOINTS.lg} {
    font-weight: 400;
    font-size: 16px;
    width: 343px;
    flex-direction: column;
    gap: 2px;
  }
`
const ColoredText = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #9c60ff;

  @media ${BREAKPOINTS.lg} {
    font-weight: 400;
    font-size: 16px;
  }
`
